import PropTypes from "prop-types"

import { useCallback } from 'react';

import { formatToFloat } from "../../ui/utilities/functions"
import { AllocationDataArray } from "../../../propTypes/AppPropTypes"
import "./styles.css"
import useCheckPortfolioExists from "../../../hooks/useCheckPortfolioExists"
import useColorScheme from "../../../hooks/useColorScheme"

function CompositionsChart({ chartData, name, colors, showTitle }) {
  const hasDoubleBar = "valueB" in chartData[0]
  const { colorScheme } = useColorScheme()
  const getMaximumValue = useCallback(() => {
    let maxValue = 0;
    let minValue = 0;
    chartData.forEach( item => {
      maxValue = maxValue = Math.max(maxValue, item.value);
      minValue = Math.min(minValue, item.value);
      if( hasDoubleBar ){
        maxValue = Math.max(maxValue, item.value, item?.valueB);
        minValue = Math.min(minValue, item.value, item?.valueB);
      }
    })
    const overallMax = Math.max(Math.abs(minValue), maxValue);
    const maxData = overallMax < 100 ? 100 : overallMax
  
    return maxData;
  }, [chartData])
  const maxDataValue = getMaximumValue();
  // FUNCTIONS

  const checkPortfolioExists = useCheckPortfolioExists()
  const portfolioExistenceStatus = checkPortfolioExists()
  const checkColor = () => {
    if (
      portfolioExistenceStatus.portFolioB &&
      !portfolioExistenceStatus.portFolioA
    ) {
      return colors[1]
    }
    if (
      portfolioExistenceStatus.portFolioA &&
      !portfolioExistenceStatus.portFolioB
    ) {
      return colors[0]
    }
    return colors[0]
  }
  const getBarColor = (value, color) => {
    if (value < 0) {
      if (colorScheme === "dark") {
        return "#fb7185"
      }
      return "#F43F5E"
    }
    return color
  }
  
  const getBarWidth = (value) => {
    // Calculamos el ancho de la barrita
    const returnedValue = (Math.abs(value) * 100) / maxDataValue
    return returnedValue
  }

  const getRowValue = (value) => {
    let returnedValue = `${formatToFloat(value, 2, "").replace(/[.,]00$/, "")}%`
    if (value === 0) returnedValue = "0%"
    if (value === 100) returnedValue = "100%"
    return returnedValue
  }

  return (
    <div className="CompositionsChart__bar">
      {showTitle && (
        <h2 className="text-xs font-semibold uppercase text-slate-600 dark:text-slate-500 mb-2">
          {name}
        </h2>
      )}
      <div
        className={`flex flex-col items-start justify-start w-full
        ${hasDoubleBar ? "gap-3" : "gap-3"}
      `}
      >
        {chartData.map((rowData) => (
          <div
            key={`${name}-${rowData.label}-${rowData.value}`}
            className={`w-full grid grid-cols-[160px,_1fr]
              3xl:grid-cols-[180px,_1fr]
              ${hasDoubleBar ? "items-start" : "items-center"}
            `}
          >
            <p className="text-xs text-slate-900 dark:text-slate-50">
              {rowData.label}
            </p>
            <div>
              <div className="grid grid-cols-[1fr,_70px] items-center">
                <div className="w-full h-2 bg-slate-100 dark:bg-slate-700 relative">
                  <span
                    className="h-2 absolute top-0 left-0"
                    style={{
                      width: `${getBarWidth(rowData.value)}%`,
                      backgroundColor: getBarColor(rowData.value, checkColor()),
                    }}
                  />
                </div>
                <p className="text-xs text-right text-slate-900 dark:text-slate-50">
                  {getRowValue(rowData.value)}
                </p>
              </div>

              {hasDoubleBar && (
                <div className="grid grid-cols-[1fr,_70px] items-center">
                  <div className="w-full h-2 bg-slate-100 dark:bg-slate-700 relative">
                    <span
                      className="h-2 absolute top-0 left-0"
                      style={{
                        width: `${getBarWidth(rowData.valueB)}%`,
                        backgroundColor: getBarColor(rowData.valueB, colors[1]),
                      }}
                    />
                  </div>
                  <p className="text-xs text-right text-slate-900 dark:text-slate-50">
                    {getRowValue(rowData.valueB)}
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

CompositionsChart.propTypes = {
  chartData: AllocationDataArray.isRequired,
  name: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  showTitle: PropTypes.bool,
}

CompositionsChart.defaultProps = {
  colors: ["#FBBF24", "#14B8A6"],
  showTitle: true,
}

export default CompositionsChart
