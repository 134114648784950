import { useState, useContext } from "react";
import { Link, Navigate } from "react-router-dom";

// PROPTYPES
import { userPropTypes } from "../../propTypes/AppPropTypes";

// CONTEXT
import FirebaseContext from "../../firebase/FirebaseContext";

// COMPONENTS
import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";
import StatusMessage from "../../components/ui/utilities/StatusMessage";
import FormGroup from "../../components/ui/forms/FormGroup";
import AuthInputField from "../../components/ui/forms/AuthForms/AuthInputField";

// CONSTANTS
import * as ROUTES from "../../routes/APP_ROUTES";
import { Form } from "@/components/ui/auth/formLayouts/FormLayouts";

const INITIAL_STATE = {
  email: "",
  error: null,
  message: "",
  status: "",
};
const ErrorMap = new Map([
  ["app/empty-fields", "Por favor ingrese su correo."],
  ["auth/invalid-email", "El correo ingresado es incorrecto"],
  ["auth/user-not-found", "El correo no está registrado"],
]);

function PasswordForget({ user }) {
  const { doPasswordReset } = useContext(FirebaseContext);

  const [formFields, setFormFields] = useState(INITIAL_STATE);
  const isInvalid = formFields.email === "";

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const { email } = formFields;

    if (isInvalid) {
      setFormFields({
        ...formFields,
        ...{
          error: true,
          message: ErrorMap.get("app/empty-fields"),
          status: "warning",
        },
      });
    } else {
      doPasswordReset(email)
        .then(() => {
          // setFormFields(INITIAL_STATE);
          setFormFields({
            email: "",
            error: true,
            message:
              "Enviamos un correo electrónico con instrucciones para recuperar su contraseña.",
            status: "success",
          });
        })
        .catch((error) => {
          setFormFields({
            ...formFields,
            ...{
              error,
              message: ErrorMap.get(error.code),
              status: "error",
            },
          });
        });
    }
  };
  const onChangeHandler = (event) => {
    setFormFields({
      ...formFields,
      ...{ [event.target.name]: event.target.value },
    });
  };

  return user ? (
    <Navigate to={ROUTES.SCREENER} />
  ) : (
    <AuthPageFrame
      title="Recupera tu contraseña"
      description="Te enviaremos un mensaje con instrucciones para recuperar tu contraseña."
    >
      <Form onSubmit={onSubmitHandler} noValidate className="w-full">
        <FormGroup>
          <AuthInputField
            type="email"
            name="email"
            placeholder="nombre@micorreo.com"
            defaultValue={formFields.email}
            label="Ingresa tu correo electrónico"
            callback={onChangeHandler}
          />
        </FormGroup>

        {formFields.error && (
          <StatusMessage
            title={
              formFields.status === "warning"
                ? "Encontramos un problema"
                : "Información"
            }
            text={formFields.message}
            status={formFields.status}
          />
        )}
        <div className="flex flex-col items-center justify-center">
          <button
            type="submit"
            className="py-4 px-9 w-full text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
          >
            Enviar solicitud
          </button>

          <div className="over-label mt-5">
            <Link
              to={ROUTES.LOGIN}
              className="text-blue-500 transition hover:text-blue-400 focus:text-slate-900"
            >
              Volver a la página de ingreso
            </Link>
          </div>
        </div>
      </Form>
    </AuthPageFrame>
  );
}

PasswordForget.propTypes = {
  user: userPropTypes,
};
PasswordForget.defaultProps = {
  user: null,
};

export default PasswordForget;
