import { useState } from "react"
import PropTypes from "prop-types"

// COMPONENTS
import AnalysisInfo from "./AnalysisInfo"
import AnalysisScreener from "./AnalysisScreener"
import InfoModal from "../dialogs/InfoModal"

function AnalysisProposalInfo({ onOfComponent, summaryData, handleTakeScreenshot }) {
  const [factSheetModalMessage, setFactSheetModalMessage] = useState("")
  const [factSheetModalOpen, setFactSheetModalOpen] = useState(false)

  return (
    <div className="flex flex-col gap-20">
      <AnalysisInfo
        onOfComponent={onOfComponent}
        summaryData={summaryData}
        handleTakeScreenshot={handleTakeScreenshot}
      />
      <AnalysisScreener
        onOfComponent={onOfComponent}
        modalInfo={{ setFactSheetModalMessage, setFactSheetModalOpen }}
      />
      <InfoModal
        message={factSheetModalMessage}
        isOpen={factSheetModalOpen}
        setFactSheetModalOpen={setFactSheetModalOpen}
      />
    </div>
  )
}

AnalysisProposalInfo.propTypes = {
  onOfComponent: PropTypes.func.isRequired,
}

export default AnalysisProposalInfo
