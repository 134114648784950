import React from "react";
import { useSelector } from "react-redux";

function AnalysisLogo() {
  const colorScheme = useSelector((state) => state.colorScheme.value);
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig);
  const { logo } = analysisDataConfig.data;

  const returnImgAccoringToColorScheme = () => {
    if (colorScheme === "light") {
      return logo?.light;
    }
    return logo?.dark ?? logo?.light;
  };

  return logo ? (
    <div className="flex items-center justify-end">
      <div className="w-[200px] h-16">
        <img
          src={returnImgAccoringToColorScheme()}
          alt="Logo"
          className="object-contain w-full h-full"
        />
      </div>
    </div>
  ) : null;
}
export default AnalysisLogo;
