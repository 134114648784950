import React from "react";

import useColorScheme from "../../hooks/useColorScheme";

// STYLES
import APP_LOGO from "../../assets/images/logos/app_logo.svg";
import APP_LOGO_LIGHT from "../../assets/images/logos/app_logo_light.svg";

function MaintenancePage() {
  const [colorScheme] = useColorScheme();
  return (
    <div className="relative w-screen h-screen">
      <div className="w-3/4 max-w-[1024px] mx-0 my-auto flex flex-row items-start justify-between gap-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <img
          src={`${colorScheme === "dark" ? APP_LOGO : APP_LOGO_LIGHT}?v=0.6`}
          alt="elevest"
          className="w-[300px] pt-8"
        />

        <main className="w-[500px] p-8 bg-white dark:bg-slate-800 rounded-sm shadow-sm">
          <h2 className="mb-4 pb-4 text-lg font-light uppercase border-b border-slate-700 dark:border-slate-300">
            !Volvemos enseguida!
          </h2>
          <p>
            El servicio no está disponible por mantenimiento, esperamos{" "}
            <strong>estar de vuelta en unos minutos</strong>.
          </p>
          <p>
            Si tienes alguna consulta contáctanos a{" "}
            <em className="text-blue-500">soporte@lvaindices.com</em>.
          </p>
          <p>Muchas gracias por tu paciencia.</p>
        </main>
      </div>
    </div>
  );
}

export default MaintenancePage;
