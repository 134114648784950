import { createSlice } from "@reduxjs/toolkit"

const DEFAULT_VALUE = "fundDuration"

const initialState = {
  current: DEFAULT_VALUE,
  options: {
    fundDuration: {
      name: "Duración",
      label: "Duración",
      type: "float",
      suffix: "",
      order: 1,
    },
    fundReturn1M: {
      name: "Ret 1M",
      label: "Rentab. 1M",
      type: "float",
      suffix: "%",
      order: 1,
    },
    fundReturn3M: {
      name: "Ret 3M",
      label: "Rentab. 3M",
      type: "float",
      suffix: "%",
      order: 2,
    },
    fundReturn6M: {
      name: "Ret 6M",
      label: "Rentab. 6M",
      type: "float",
      suffix: "%",
      order: 3,
    },
    fundReturn2Y: {
      name: "Ret 2Y",
      label: "Rentab. 2A",
      type: "float",
      suffix: "%",
      order: 4,
    },
    fundReturn3Y: {
      name: "Ret 3Y",
      label: "Rentab. 3A",
      type: "float",
      suffix: "%",
      order: 5,
    },
    fundReturnYTD: {
      name: "Ret YTD",
      label: "Rentab. YTD",
      type: "float",
      suffix: "%",
      order: 6,
    },
  },
}

export const customColumnSlice = createSlice({
  name: "column",
  initialState,
  reducers: {
    updateCurrent: (state, action) => {
      state.current = action.payload
    },
    resetCurrent: (state) => {
      state.current = DEFAULT_VALUE
    },
  },
})

export const { updateCurrent, resetCurrent } = customColumnSlice.actions

export default customColumnSlice.reducer
