// STORE
import { useSelector, useDispatch } from "react-redux"
import {
  togglePreviewPanel,
  // setOverviewPanelOpen,
} from "../../../store/portfolioPanelSlice"
// HOOKS
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"

function PortfoliosButton() {
  // HOOKS
  const { getPortfolioInfo } = usePreviewPanel()
  // STORE
  const dispatch = useDispatch()
  const panelOpen = useSelector((state) => state.portfolioPanel.isPreviewOpen)
  // HANDLER
  const handleButtonClick = () => {
    // dispatch(setOverviewPanelOpen(false))
    dispatch(togglePreviewPanel(!panelOpen))
  }

  return (
    <div
      className={
        getPortfolioInfo().totalFundsSelected === 0 ? "hidden" : "block"
      }
    >
      <button
        type="button"
        title="Previsualizar análisis y ver fondos seleccionados"
        className="w-8 h-8 md:w-auto md:h-auto uppercase rounded-md flex items-center leading-0 justify-between gap-2 md:gap-4 px-3 py-1 lg:py-1.5 text-xss md:text-xs text-slate-50 transition-colors duration-500 ease-in-out bg-blue-500 hover:bg-blue-400"
        onClick={() => handleButtonClick()}
      >
        <span className="hidden md:inline text-xxs lg:text-xs">
          Vista previa
        </span>
        <span className="w-5 h-5 rounded-full md:text-blue-500 place-content-center font-semibold md:grid md:bg-white">
          {getPortfolioInfo().totalFundsSelected}
        </span>
      </button>
    </div>
  )
}

export default PortfoliosButton
