import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import localforage from "localforage"

import filtersReducer from "./filtersSlice"
import sortingReducer from "./sortingSlice"
import reduceDataReducer from "./reducedDataSlice"
import searchSliceReducer from "./searchSlice"
import MinimumInvestmentReducer from "./minimumInvestmentSlice"
import paginationSliceReducer from "./paginationSlice"
import customColumnSliceReducer from "./customColumnSlice"
import UserReducer from "./userSlice"
import AppDataReducer from "./appDataSlice"
import UrlsReducer from "./urlsSlice"
import portfolioSelectionReducer from "./portfolioSelectionSlice"
import portfolioCompositionReducer from "./portfolioCompositionSlice"
import portfolioPanelReducer from "./portfolioPanelSlice"
import chartsDataReducer from "./chartsDataSlice"
import proposalTypeReducer from "./proposalTypeSlice"
import NotificationsSliceReducer from "./notificationsSlice"
import AnalysisDataConfigReducer from "./analysisDataConfigSlice"
import CatalogByFundsSliceReducer from "./catalogByFundsSlice"
import RawCatalogSliceReducer from "./rawCatalogSlice"
import OverviewPanelSliceReducer from "./overviewPanelSlice"
import categoriesMappingSliceReducer from "./categoriesMappingSlice"
import ColorSchemeReducer from "./colorSchemeSlice"
import portfoliosNameReducer from "./portfoliosNameSlice"

const persistConfig = {
  key: "root",
  storage: localforage,
  // blacklist sirve para agregar una lista de los reducers que NO se quiere persistir
  blacklist: [
    "filters",
    "sorting",
    "reducer",
    "search",
    "pagination",
    "column",
    "user",
    "appData",
    "urls",
    "portfolioPanel",
    "notifications",
    "minimumInvestment",
    "overviewPanel",
  ],
}
const rootReducer = {
  filters: filtersReducer,
  sorting: sortingReducer,
  reducer: reduceDataReducer,
  search: searchSliceReducer,
  pagination: paginationSliceReducer,
  column: customColumnSliceReducer,
  user: UserReducer,
  appData: AppDataReducer,
  urls: UrlsReducer,
  portfolioSelection: portfolioSelectionReducer,
  portfolioComposition: portfolioCompositionReducer,
  portfolioPanel: portfolioPanelReducer,
  chartsData: chartsDataReducer,
  proposalType: proposalTypeReducer,
  notifications: NotificationsSliceReducer,
  analysisDataConfig: AnalysisDataConfigReducer,
  catalogByFunds: CatalogByFundsSliceReducer,
  minimumInvestment: MinimumInvestmentReducer,
  overviewPanel: OverviewPanelSliceReducer,
  categoriesMapping: categoriesMappingSliceReducer,
  colorScheme: ColorSchemeReducer,
  portfoliosName: portfoliosNameReducer,
  rawCatalog: RawCatalogSliceReducer,
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(rootReducer),
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
export default { store, persistor }
