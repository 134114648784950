import PropTypes from "prop-types"

// UTILS
import { formatToFloat } from "../utilities/functions"

function FundDetailsPerformanceTableAPV({
  displaySeries,
  tableData,
  currentShareClass,
  hasLessThanOneYear,
}) {
  const apvExcludePeriods = ["1 mes", "3 meses", "6 meses", "YTD"]

  const getTableData = () => {
    const data = {}
    Object.keys(tableData).forEach((periodRow) => {
      if (hasLessThanOneYear && periodRow === "6 meses") {
        data["6 meses"] = tableData["6 meses"]
      } else if (!apvExcludePeriods.includes(periodRow)) {
        data[periodRow] = tableData[periodRow]
      }
    })
    return data
  }

  return (
    <div className="table-container">
      <table className="table-frame">
        <thead className="table-head">
          <tr className="border-b border-slate-300 dark:border-slate-600">
            <th
              scope="col"
              className="pb-1 text-left font-light w-[80px] sticky left-0 z-10 bg-slate-50 dark:bg-slate-800"
            >
              Período
            </th>
            {displaySeries.map((serie) => (
              <th
                key={serie}
                scope="col"
                className={`pb-1 font-light w-[70px]
                          ${
                            displaySeries.length > 1
                              ? "text-right"
                              : "text-center"
                          }
                          ${
                            currentShareClass === serie
                              ? "text-blue-500 dark:text-blue-400"
                              : ""
                          }`}
              >
                {`Serie ${serie}`}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(getTableData()).map((period) => (
            <tr
              key={period}
              className="border-b border-slate-300 dark:border-slate-600"
            >
              <td className="py-2 text-xs sticky left-0 z-10 bg-slate-50 dark:bg-slate-800">
                {period}
              </td>
              {tableData[period].map((element) => (
                <td
                  key={`${period}-${Object.keys(element)[0]}`}
                  className={`py-2 text-xs tabular-nums
                            ${
                              displaySeries.length > 1
                                ? "text-right"
                                : "text-center"
                            }
                            ${
                              currentShareClass === Object.keys(element)[0]
                                ? "text-blue-500 dark:text-blue-400"
                                : "text-slate-900 dark:text-slate-50"
                            }`}
                >
                  {formatToFloat(Object.values(element)[0]) || "-"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

FundDetailsPerformanceTableAPV.propTypes = {
  displaySeries: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number)),
  ).isRequired,
  currentShareClass: PropTypes.string.isRequired,
  hasLessThanOneYear: PropTypes.bool.isRequired,
}

export default FundDetailsPerformanceTableAPV
