// CUSTOM HOOKS
import useColorCompany from "../../../hooks/useColorCompany";
// COMPONENTS
import PortfolioHeader from "../../ui/PortfolioHeader/PortfolioHeader"
import CompositionsOver100PercAlert from "../../ui/CompositionsOver100PercAlert/CompositionsOver100PercAlert";

const EquityDebtExtended = ({data, headerData}) => {
    // HOOKS
    const { getColorPortfolio } = useColorCompany()
    const color = getColorPortfolio("portfolioA")
    const totales = Object.keys(data).map(comp => data[comp].total);
    const showWarningOver100Perc = totales.some(num => num !== 100);

    return (
        <div className="@container space-y-5">
            <PortfolioHeader portfolioName={headerData.portfolioName} colorScheme={headerData.colorScheme} bgColor={headerData.bgColor} mainColor={color} />
            <div className="flex items-start @[550px]:divide-x @[550px]:divide-slate-300 @[550px]:dark:divide-slate-700 @[550px]:items-stretch">
                {Object.keys(data).map((compKey) => (
                    <div key={compKey} className="space-y-3 w-full"> 
                        <h2 className="text-base font-semibold text-center @[550px]:text-lg" style={{color: color}}>{compKey}</h2>
                        <div className="space-y-2 @[550px]:grid @[550px]:grid-cols-2 @[550px]:items-center @[550px]:space-y-0">
                            <p className="font-semibold text-2xl text-center @[550px]:text-3xl" style={{color:color}}>{data[compKey].total}%</p>
                            <div className="w-full text-center grid grid-rows-auto place-content-center @[550px]:place-content-start">
                                {data[compKey].detalle.map( (itemMap) => {
                                    const key = Object.keys(itemMap)[0]; 
                                    return (
                                        <div key={key} className="flex gap-2 items-center justify-start text-xs @[550px]:text-left">
                                            <p className="w-[40px] shrink-0 text-right">{itemMap[key]}%</p>
                                            <p className="">{key}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <CompositionsOver100PercAlert shouldShow={showWarningOver100Perc} />
        </div>
    )
}

export default EquityDebtExtended