import { Link } from "react-router-dom"

// STORE
import { useSelector } from "react-redux"

// COMPONENTS
import * as ROUTES from "../../routes/APP_ROUTES"
import {
  AccountBodyBox,
  AccountBoxHeading,
  AccountBoxContent,
} from "../../components/ui/accounts/AccountComponents"

import RenderIf from "../../components/helpers/RenderIf"
import SETTINGS from "../../settings/settings"

function AdminPage() {
  // STORE
  const urls = useSelector((state) => state.urls)
  const user = useSelector((state) => state.user.user)

  return (
    <main className="px-8 pt-20">
      <RenderIf isTrue={user.role.includes(SETTINGS.USER_PROFILES.APP_ADMINISTRATOR)}>
        <div className="my-5 grid grid-cols-[1fr,_140px] items-center gap-4">
          <h1 className="text-base">Administración de cuentas</h1>
        </div>
        <div className="grid grid-cols-3 gap-5 items-stretch">
          <AccountBodyBox>
            <AccountBoxHeading>
              <h3 className="text-base font-light">Usuarios</h3>
              <p className="text-sm text-slate-400">
                Utiliza los siguientes accesos para administrar los usuarios
              </p>
            </AccountBoxHeading>

            <AccountBoxContent>
              <nav className="flex flex-col items-start justify-start gap-4">
                <Link to={ROUTES.USERS} className="text-sm hover:text-blue-500">
                  Ver todos los usuarios registrados
                </Link>
                <Link
                  to={ROUTES.EDIT_USER}
                  className="text-sm hover:text-blue-500"
                >
                  Crear un nuevo usuario
                </Link>
              </nav>
            </AccountBoxContent>
          </AccountBodyBox>

          <AccountBodyBox>
            <AccountBoxHeading>
              <h3 className="text-base font-light">Compañias</h3>
              <p className="text-sm text-slate-400">
                Utiliza los siguientes accesos para administrar las compañías
              </p>
            </AccountBoxHeading>

            <AccountBoxContent>
              <nav className="flex flex-col items-start justify-start gap-4">
                <Link
                  to={ROUTES.COMPANIES}
                  className="text-sm hover:text-blue-500"
                >
                  Ver todas las compañias registradas
                </Link>
                <Link
                  to={ROUTES.EDIT_COMPANY}
                  className="text-sm hover:text-blue-500"
                >
                  Crear un nueva compañía
                </Link>
              </nav>
            </AccountBoxContent>
          </AccountBodyBox>
        </div>
      </RenderIf>
    </main>
  )
}

export default AdminPage
