import SETTINGS from "../settings/settings"
import { FirebaseContext } from "../firebase"
import { useContext } from "react"
import { Fetch } from "./useFetch"

const useFundFactsheets = () => {
  const firebase = useContext(FirebaseContext)
  const openWindow = (url) => {
    window.open(
      url,
      "_blank",
      "Height=550,Width=750,menubar,resizable,scrollbars",
    )
  }

  const openFactsheet = async (run, serie, runAdministradora, onError) => {
    Fetch(`${SETTINGS.urls.API_URL}/factsheet_url`, {
      run,
      serie,
      rutAdmin: runAdministradora,
      },
      {firebase},
      (response) => {
        if (response === "#") {
          onError()
        } else {
          openWindow(response)
        }
      }
    )
  }

  const openProspectus = (run, serie, prospectusCode, onError) => {
    const url = `https://www.cmfchile.cl/documentos/rfm/rfm_${prospectusCode}.pdf`
    if (url === "#") {
      onError()
    } else {
      openWindow(url)
    }
  }

  return [openFactsheet, openProspectus]
}

export default useFundFactsheets
