// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
// import App from './App.jsx'
// import './index.css'

// createRoot(document.getElementById('root')).render(
//   <StrictMode>
//     <App />
//   </StrictMode>,
// )
// import SETTINGS from './settings/settings'

import { FlagsProvider } from "flagged"

// STORE
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "./store/store"

// FIREBASE
import { Firebase, FirebaseContext } from "./firebase"

// COMPONENTS
import App from "./app/App"

// STYLES
import "./index.css";
import { FEATURE_FLAGS } from "./utils/featureFlags"
import TrackingProvider from "./tracking/TrackingProvider"
import FreshworksWidget from "./components/ui/utilities/FreshworksWidget"

const featureFlagValues = Object.keys(FEATURE_FLAGS).map((flag) =>
  FEATURE_FLAGS[flag].value ? FEATURE_FLAGS[flag].name : undefined,
)

createRoot(document.getElementById("root"))
  .render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <TrackingProvider>
          <FlagsProvider features={featureFlagValues}>
            <FirebaseContext.Provider value={new Firebase()}>
              <App />
              <FreshworksWidget />
            </FirebaseContext.Provider>
          </FlagsProvider>
        </TrackingProvider>
      </PersistGate>
    </Provider>,
  )
