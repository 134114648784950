import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import useColorScheme from "../../../hooks/useColorScheme";

import APP_LOGO from "../../../assets/images/logos/app_logo.svg";
import APP_LOGO_LIGHT from "../../../assets/images/logos/app_logo_light.svg";

function SplashScreen() {
  const [colorScheme] = useColorScheme();
  return (
    <div className="fixed top-0 left-0 w-full h-full">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-2">
        <img
          src={`${colorScheme === "dark" ? APP_LOGO : APP_LOGO_LIGHT}?v=0.6`}
          alt="Elevest Logo"
          className="block w-[120px] h-auto"
        />
        <Cog6ToothIcon className="w-5 h-5 animate-slowSpin text-blue-500" />
      </div>
    </div>
  );
}

export default SplashScreen;
