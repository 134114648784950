/* eslint-disable no-undef */
import PropTypes from "prop-types"
// import { sortBy } from "lodash"

import { getCompositionsData } from "@lva/functions"
// STORE
import { useSelector } from "react-redux"

// HOOKS
import useCheckPortfolioExists from "../../../hooks/useCheckPortfolioExists"
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"

// COMPONENTS
import ToggleSectionButton from "./ToggleSectionButton"
import ScreenshotButton from "../ScreenshotModal/ScreenshotButton"
import CompositionsChart from "../../charts/CompositionsChart/CompositionsChart"
import RenderIf from "../../helpers/RenderIf"

// UTILS
import { EXPOSURE_TYPE_NAME_MAPPING } from "../../../utils/ExposurePresenter"
import useColorCompany from "../../../hooks/useColorCompany"
import CompositionsOver100PercAlert from "../CompositionsOver100PercAlert/CompositionsOver100PercAlert"

function AnalysisExposure({
  exposureData,
  onOfComponent,
  handleTakeScreenshot,
}) {
  // STORE
  const appData = useSelector((state) => state.appData.data)
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const portfoliosName = useSelector((state) => state.portfoliosName.data)
  // HOOKS
  const checkPortfolioExists = useCheckPortfolioExists()
  const portfolioExistenceStatus = checkPortfolioExists()
  const { getColorsCompany, getColorPortfolio } = useColorCompany()
  // DATA
  const { getCompositionsOrder, compositionHasOver100Perc } = usePrepareDataForCharts()
  const compositionsData = getCompositionsData(exposureData)
  const showWarningOver100Perc = compositionHasOver100Perc(compositionsData)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )

  // CALLBACKS
  const takeScreenshotHandler = () => {
    const today = new Date()
    const modalData = {
      type: "exposure",
      filename: `Composición del portafolio - ${new Intl.DateTimeFormat(
        "es-CL",
      ).format(today)}`,
      itemOrder: getCompositionsOrder(),
      chartsData: compositionsData,
      portfolioExistenceStatus,
    }
    handleTakeScreenshot(modalData)
  }

  return (
    <div id="exposureAll">
      <header className="mb-10 flex items-center justify-between">
        <div className="flex items-center">
          <h2 className="analysis-box-header">Composición del portafolio</h2>
          <ToggleSectionButton component="exposureAll" action={onOfComponent} />
          <RenderIf isTrue={appData.screenshot_ability}>
            <ScreenshotButton takeScreenshotHandler={takeScreenshotHandler} />
          </RenderIf>
        </div>
        <div className="flex items-center gap-4 justify-end">
          {Object.keys(portfolios).map(
            (portfolioKey) =>
              portfolios[portfolioKey].portfolioFunds.length > 0 && (
                <span
                  key={portfolioKey}
                  className="flex items-center gap-1 text-xxs"
                >
                  <span
                    className="w-3 h-3 rounded-sm block"
                    style={{
                      backgroundColor: `${getColorPortfolio(portfolioKey)}`,
                    }}
                  />
                  {portfoliosName[portfolioKey].portfolioName}
                </span>
              ),
          )}
        </div>
      </header>
      <div className="transition-opacity duration-500 space-y-5">
        <div className="grid grid-cols-2 gap-10 xl:grid-cols-3 xl:gap-30">
          {getCompositionsOrder().map((item) => {
            const name = `Por ${EXPOSURE_TYPE_NAME_MAPPING[item]}`
            const rows = compositionsData[item]
            return (
              rows && (
                <div key={name} className="transition-opacity duration-500">
                  <div className="flex items-center mb-8">
                    <h2 className="text-xs font-semibold uppercase text-slate-600 dark:text-slate-500">
                      {name}
                    </h2>
                    <ToggleSectionButton
                      component={`exposure.${item}`}
                      action={onOfComponent}
                    />
                  </div>
                  <div
                    className={
                      analysisDataConfig.showComponents.exposure[item]
                        ? "opacity-100"
                        : "opacity-30"
                    }
                  >
                    <CompositionsChart
                      key={item}
                      chartData={rows}
                      name={name}
                      colors={getColorsCompany()}
                      showTitle={false}
                    />
                  </div>
                </div>
              )
            )
          })}
        </div>
        <CompositionsOver100PercAlert shouldShow={showWarningOver100Perc} />
      </div>
    </div>
  )
}

AnalysisExposure.propTypes = {
  exposureData: PropTypes.shape({
    ASSET_CLASS: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        valueB: PropTypes.number,
      }),
    ),
    COUNTRY: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        valueB: PropTypes.number,
      }),
    ),
    CURRENCY: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        valueB: PropTypes.number,
      }),
    ),
    ZONE: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        valueB: PropTypes.number,
      }),
    ),
  }).isRequired,
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
}

export default AnalysisExposure
