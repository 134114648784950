import localforage from "localforage"
import pako from "pako"
import SETTINGS from "../settings/settings"
import version from "../version.json"
// SENTRY
import * as Sentry from "@sentry/browser";


const compressData = (data) => {
  const jsonString = JSON.stringify(data)
  return pako.deflate(jsonString, { to: "string" })
}

const decompressData = (data) => {
  const jsonString = pako.inflate(data, { to: "string" })
  return JSON.parse(jsonString)
}

/**
 * Configura y prepara la base de datos para su uso.
 * @param {Object} config - La configuración para abrir la base de datos.
 * @param {string} [config.name="lva-db"] - El nombre de la base de datos.
 * @param {string} [config.storeName="funds"] - El nombre del almacén de objetos a utilizar.
 * @returns {Promise} Promesa que resuelve una vez que LocalForage esté listo.
 */
const openDB = ({ name = "lva-db", storeName = "funds" }) => {
  localforage.config({
    name,
    storeName,
  })
  return localforage.ready()
}

/**
 * Inserta un nuevo objeto en la base de datos si no existe uno con la misma clave.
 * @param {Object} params - Parámetros para la inserción.
 * @param {string} params.storeName - El nombre del almacén donde insertar el objeto.
 * @param {string} params.key - La clave del objeto a insertar.
 * @param {Object} params.data - Los datos del objeto a insertar.
 * @returns {Promise<string|null>} Promesa que resuelve la clave del objeto insertado o null si ya existe.
 */
export const insertInStorageByKey = async ({ storeName, key, data }) => {
  let dataForSend = null
  // Comprimir los datos si la configuración lo indica
  if (SETTINGS.DATA_COMPRESS) {
    dataForSend = compressData(data)
  } else {
    dataForSend = data
  }
  const dataToStore = {
    isCompressed: SETTINGS.DATA_COMPRESS, // Indicador de que los datos se enviarán comprimidos
    data: dataForSend,
    version: version.tag,
  }
  await openDB({ storeName })

  await localforage.setItem(key.toString(), dataToStore)
  return key
}

/**
 * Lee un objeto de la base de datos.
 * @param {Object} params - Parámetros para la operación de lectura.
 * @param {string} params.storeName - El nombre del almacén de la base de datos.
 * @param {string} params.key - La clave del objeto a leer.
 * @returns {Promise<Object|null>} Promesa que resuelve el objeto leído o null si no existe.
 */
export const getDataInStorage = async ({ storeName, key }) => {
  await openDB({ storeName })
  const storedValue = await localforage.getItem(key.toString())

  const isCompressed = storedValue?.isCompressed
  const data = storedValue?.data
  if (isCompressed) {
    return decompressData(data) !== null
      ? { version: storedValue?.version, data: decompressData(data) }
      : null
  }
  const found = { version: storedValue?.version, data: storedValue?.data }
  return found !== null ? found : null
}

/**
 * Elimina un objeto de la base de datos.
 * @param {Object} params - Parámetros para la operación de eliminación.
 * @param {string} params.storeName - El nombre del almacén de la base de datos, por defecto "funds".
 * @param {string} params.key - La clave del objeto a eliminar.
 * @returns {Promise<string>} Promesa que resuelve la clave del objeto eliminado.
 */
export const removeDataInStorage = async ({ storeName = "funds", key }) => {
  await openDB({ storeName })
  await localforage.removeItem(key.toString())
  return key
}

/**
 * Obtiene todos los objetos del almacén de la base de datos.
 * @param {Object} params - Parámetros para la operación de obtención.
 * @param {string} params.storeName - El nombre del almacén de la base de datos.
 * @returns {Promise<Object[]>} Promesa que resuelve un arreglo de todos los objetos en el almacén.
 */
export const getAllDataInStorage = async ({ storeName }) => {
  await openDB({ storeName })
  const items = []
  await localforage.iterate((value) => {
    items.push(value)
  })
  // Descomprimir la cadena
  const jsonString = pako.inflate(items, { to: "string" })
  // Convertir la cadena JSON de nuevo a un objeto
  const compressed = JSON.parse(jsonString)
  let dataForSend = null
  // Comprimir los datos si la configuración lo indica
  if (SETTINGS.DATA_COMPRESS) {
    dataForSend = compressed
  } else {
    dataForSend = items
  }
  return dataForSend
}
export const clearAllDataInStorage = async () => {
  await localforage.clear()
  await removeDataInStorage({ key: "persist:root" })
}

/**
 * Almacena un objeto en el almacén de datos con un tiempo de vida (TTL).
 * Una vez expirado, el objeto ya no debería ser accesible.
 *
 * @param {string} key - La clave única para almacenar el objeto.
 * @param {Object} value - El valor del objeto a almacenar.
 * @param {number} ttl - El tiempo de vida del objeto en milisegundos.
 */
const setWithExpiry = (key, value, ttl) => {
  const now = new Date()

  const item = {
    value,
    expiry: now.getTime() + ttl,
  }

  insertInStorageByKey({ key, data: item })
    .then((insertedKey) => {
      console.log(`Data inserted with key: ${insertedKey}`)
    })
    .catch((error) => {
      console.error(`Error inserting data:`, error)
    })
}

/**
 * Recupera un objeto almacenado por su clave si no ha expirado.
 * Si el objeto ha expirado, lo elimina del almacén de datos.
 *
 * @param {string} key - La clave del objeto a recuperar.
 * @returns {Promise<Object|null>} Promesa que resuelve el valor del objeto si no ha expirado, de lo contrario null.
 */
const getWithExpiry = async (key) => {
  try {
    const data = await getDataInStorage({ key })
    const fund = data?.data
    if (fund && fund.value) {
      const expiryTime = fund.expiry
      const now = new Date()
      // Compara el tiempo de expiración del objeto con el tiempo actual
      if (now.getTime() > expiryTime) {
        // Si el objeto ha expirado, elimina el objeto del almacenamiento y devuelve null
        await removeDataInStorage({ key })
        console.log(`Data removed with key: ${key}`)
        return null
      }
      return fund.value
    }
    return null
  } catch (error) {
    Sentry.captureException(error);
    console.error(`Error retrieving data:`, { error })
    return null
  }
}
export { setWithExpiry, getWithExpiry }
