import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"

function ToggleSectionButton({ component, action, tooltip }) {
  // STORE
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)
  const { showComponents } = analysisDataConfig.data

  const componentParts = component ? component.split(".") : []
  const showComponent = componentParts.reduce(
    (obj, part) => obj[part],
    showComponents,
  )

  return (
    <button
      type="button"
      onClick={() => action(component)}
      className="relative group border-none rounded-md w-8 h-8 grid place-content-center text-blue-500 dark:text-blue-400
          hover:text-blue-400 dark:hover:text-blue-500 transition-colors duration-500"
    >
      {showComponent ? (
        <EyeIcon className="w-4 h-4" />
      ) : (
        <EyeSlashIcon className="w-4 h-4" />
      )}
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bottom-full
        mb-2 px-2 py-1 text-xs text-slate-900 dark:text-slate-50 bg-slate-300 dark:bg-slate-700 rounded hidden
        group-hover:block transition-opacity duration-500 w-28"
      >
        {showComponent ? tooltip[0] : tooltip[1]}
      </span>
    </button>
  )
}

ToggleSectionButton.propTypes = {
  component: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  tooltip: PropTypes.arrayOf(PropTypes.string),
}

ToggleSectionButton.defaultProps = {
  tooltip: [
    "Ocultar esta sección en la propuesta",
    "Mostrar esta sección en la propuesta",
  ],
}

export default ToggleSectionButton
