import { useSelector } from "react-redux";
import {
  QuestionMarkCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

// STORE

// eslint-disable-next-line react/prop-types
function FreshDeskButton({ errorPage }) {
  const user = useSelector((state) => state.user.user);
  let securityTimer = 0;

  const updateFreshDeskWidgetLabels = () => {
    const interval = window.setInterval(() => {
      // Cancelar si demora mucho.
      if (securityTimer > 8000) window.clearInterval(interval);
      const container = document.querySelector("#freshworks-container");
      const contentIframe = container.querySelector("#widget-frame");
      if (contentIframe !== null) {
        // const title = contentIframe.contentWindow.document.querySelector(
        //   ".PageWrapper__SubTitle-xmkvb5-2",
        // )
        const backgroundWrapper =
          contentIframe.contentWindow.document.querySelector(
            "[class^='WidgetHeader__Wrapper']",
          );
        if (backgroundWrapper) {
          backgroundWrapper.style.backgroundColor = "#1E293B";
        }
        const nameLabel =
          contentIframe.contentWindow.document.querySelector(
            'label[for="name"]',
          );
        // const email_label =
        //   contentIframe.contentWindow.document.querySelector(
        //     'label[for="email"]',
        //   )
        // const subject_label =
        //   contentIframe.contentWindow.document.querySelector(
        //     'label[for="subject"]',
        //   )
        const descriptionLabel =
          contentIframe.contentWindow.document.querySelector(
            'label[for="description"]',
          );
        const captureButton =
          contentIframe.contentWindow.document.querySelector("#takeScreenshot");
        const freshdesk = contentIframe.contentWindow.document.querySelector(
          'div[data-testid="poweredby"]',
        );
        const formButton =
          contentIframe.contentWindow.document.querySelector("#form-button");
        if (nameLabel !== null) {
          // Ocultar el título
          // title.style.display = "none";
          // Cambiar label de nombre
          nameLabel.textContent = "Nombre";
          descriptionLabel.innerHTML =
            "Si tienes alguna sugerencia, pregunta o comentario, cuéntanos a través de los campos de abajo.";
          descriptionLabel.style.textAlign = "center";
          descriptionLabel.style.fontWeight = "bold";
          descriptionLabel.style.marginBottom = "10px";
          descriptionLabel.nextElementSibling.style.height = "230px";
          descriptionLabel.nextElementSibling.placeholder =
            "Indícanos tu feedback";
          // Ocultar Inputs
          // nameLabel.parentNode.style.display = "none";
          // email_label.parentNode.style.display = "none";
          // subject_label.parentNode.style.display = "none";
          // Cambiar texto de boton para capturar pantalla
          const newText = document.createTextNode("Capturar pantalla");
          const item = captureButton.childNodes[1];
          captureButton.removeChild(item);
          captureButton.appendChild(newText);
          // Ocultar Identificacion de Freshdesk
          freshdesk.style.display = "none";
          // Cambiar color del boton
          formButton.style.backgroundColor = "#3B82F6";
          formButton.style.color = "#F8FAFC";
          // Acceder a pantalla de cierre
          formButton.addEventListener("click", () => {
            const innerInterval = window.setInterval(() => {
              const thanksMessage =
                contentIframe.contentWindow.document.querySelector(
                  'div[data-testid="thanks-message"]',
                );
              const thanksButton =
                contentIframe.contentWindow.document.querySelector(
                  "#thankyou-button",
                );
              if (thanksButton !== null) {
                thanksMessage.textContent = "¡te contactaremos a la brevedad!";
                thanksButton.textContent = "Cerrar";
                window.clearInterval(innerInterval);
              }
            }, 100);
          });
          // CERRAR EL INTERVALO AL FINAL
          window.clearInterval(interval);
        }
      }
      securityTimer += 100;
    }, 100);
  };

  const completeFormWithUserInfo = () => {
    if (user) {
      window.FreshworksWidget("prefill", "ticketForm", {
        name: `${user.name} ${user.lastname}`,
        email: user.email,
        subject: "Feedback Elevest",
        description: "",
        priority: 1,
        status: 2,
        group_id: 48000632403, // Asesores Independientes
      });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const container = document.querySelector("#freshworks-container");
    const contentIframe = container.querySelector("#widget-frame");
    if (contentIframe !== null) {
      window.FreshworksWidget("close");
    } else {
      updateFreshDeskWidgetLabels();
      completeFormWithUserInfo();
      window.FreshworksWidget("open");
    }
  };

  return (
    <a href="/" onClick={(e) => handleClick(e)} title="Enviar Feedback">
      {!errorPage ? (
        <QuestionMarkCircleIcon className="w-7 h-7" />
      ) : (
        <ExclamationCircleIcon className="w-7 h-7" />
      )}
    </a>
  );
}

export default FreshDeskButton;
