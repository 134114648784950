import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {},
}

export const RawCatalogSlice = createSlice({
  name: "rawCatalog",
  initialState,
  reducers: {
    updateRawCatalog: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { updateRawCatalog } = RawCatalogSlice.actions

export default RawCatalogSlice.reducer
