import PortfolioManager from "./PortfolioManager"

class PortfolioManagerFactory {
  static getPortfolioManagerInstance() {
    if (PortfolioManagerFactory.portfolioManager) {
      return PortfolioManagerFactory.portfolioManager
    }
    PortfolioManagerFactory.portfolioManager = new PortfolioManager()
    return PortfolioManagerFactory.portfolioManager
  }
}
export default PortfolioManagerFactory
