import { useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import Cleave from "cleave.js/react"
import { debounce } from "lodash"

// STORE
import {
  updateDistributionOption,
  updateBaseAmount,
} from "../../../store/portfolioSelectionSlice"
import useTracking from "../../../hooks/useTracking"

// FIREBASE
import { FirebaseContext } from "../../../firebase"

// HOOKS
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"

function DistributionToggle() {
  // CONTEXT
  const { logAnalyticsEvent } = useContext(FirebaseContext)
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)
  // HOOKS
  const trackEvent = useTracking()
  const {
    updatePortfoliosBaseAmountsByWeight,
    handleDistributionToggleChange,
  } = usePreviewPanel()

  const handleDistributionOptionChange = (option) => {
    handleDistributionToggleChange(option)
    logAnalyticsEvent("select_content", {
      content_type: "Distribución de portafolios",
      content_id: option,
      username: `${user.name} ${user.lastname}`,
      company: user.company,
    })
    dispatch(updateDistributionOption(option))
  }

  const handleAmountChange = debounce((event) => {
    const value = event.target.rawValue === "" ? 0 : event.target.rawValue
    const regex = /^[0-9\b]+$/
    if (value === "" || regex.test(value)) {
      trackEvent({
        action: "CHANGE_BASE_AMOUNT_PORTFOLIOS",
        timestamp: Date.now(),
        type: "INPUT",
        data: value,
      })
    }
    dispatch(updateBaseAmount(+value > 0 ? +value : null))
    updatePortfoliosBaseAmountsByWeight(+value)
  }, 150)

  return (
    <div className="flex items-center gap-1 justify-end">
      {distributionOption === "weight" && (
        <div className="flex items-stretch justify-start ">
          <span className="bg-white dark:bg-slate-900 px-1 py-1 text-center text-xs rounded-tl-md rounded-bl-md border-t border-l border-b border-r-0 border-blue-500 dark:border-transparent">
            $
          </span>
          <Cleave
            options={{
              numeral: true,
              numeralThousandsGroupStyle: "thousand",
              numeralDecimalMark: ",",
              delimiter: ".",
            }}
            placeholder="Monto total"
            value={!baseAmount || baseAmount === 0 ? "" : baseAmount}
            onChange={handleAmountChange}
            onPaste={handleAmountChange}
            className="w-[100px] shrink-0 bg-white dark:bg-slate-900 pr-1 pl-0 py-1 text-left tabular-nums text-xs border-t border-r border-b border-l-0 border-blue-500 dark:border-transparent rounded-tr-md rounded-br-md focus:outline-none focus:ring-0"
          />
        </div>
      )}
      <div className="flex items-center border border-blue-500 rounded-md overflow-hidden">
        <button
          type="button"
          onClick={() => handleDistributionOptionChange("weight")}
          className={`text-xs px-2 py-1 ${
            distributionOption === "weight"
              ? "bg-blue-500 text-slate-50"
              : "text-blue-500 bg-white dark:bg-slate-900 dark:text-blue-400"
          }`}
        >
          Porcentaje
        </button>
        <button
          type="button"
          onClick={() => handleDistributionOptionChange("amount")}
          className={`text-xs px-2 py-1 ${
            distributionOption === "amount"
              ? "bg-blue-500 text-slate-50"
              : "text-blue-500 bg-white dark:bg-slate-900 dark:text-blue-400"
          }`}
        >
          Monto
        </button>
      </div>
    </div>
  )
}

export default DistributionToggle
