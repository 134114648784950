import { useRef, Fragment, useEffect } from "react"
import { Transition } from "@headlessui/react"
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"
import { XMarkIcon } from "@heroicons/react/20/solid"

// STORE
import { useSelector, useDispatch } from "react-redux"
import { hideNotifications } from "../../../store/notificationsSlice"

function Notifications() {
  const timerRef = useRef(null)
  const dispatch = useDispatch()
  const { show, status, title, message, autoHide, time, confirmCallback } =
    useSelector((state) => state.notifications.data)
  // CALLBACK
  const getIcon = () => {
    switch (status) {
      case "success":
        return (
          <CheckCircleIcon
            className="h-6 w-6 text-emerald-400"
            aria-hidden="true"
          />
        )
      case "error":
        return (
          <XCircleIcon className="h-6 w-6 text-rose-400" aria-hidden="true" />
        )
      case "warning":
        return (
          <ExclamationCircleIcon
            className="h-6 w-6 text-amber-400"
            aria-hidden="true"
          />
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (show && autoHide) {
      timerRef.current = setTimeout(() => {
        dispatch(hideNotifications())
      }, time)
    }
    return () => clearTimeout(timerRef.current)
  }, [show, dispatch, autoHide, time])

  return (
    <div
      id="notifications"
      aria-live="assertive"
      className="pointer-events-none fixed z-[70] inset-0 flex items-end px-4 py-6 sm:items-start"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-in-out duration-200 transition"
          enterFrom="translate-x-28"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-200"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-28"
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-slate-200 dark:bg-slate-800 shadow-lg ring-1 ring-slate-200 ring-opacity-10">
            <div className="flex items-start">
              <div className="flex-shrink-0 py-4 pl-4">{getIcon()}</div>
              <div className="ml-3 w-0 flex-1 py-4 pr-4">
                {title && <p className="text-sm font-semibold">{title}</p>}
                {message && (
                  <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                    {message}
                  </p>
                )}
              </div>

              {!confirmCallback ? (
                <div className="ml-4 flex flex-shrink-0 py-4 pr-4">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-slate-300 dark:bg-slate-700 text-slate-900 dark:text-slate-50 hover:text-slate-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => {
                      dispatch(hideNotifications())
                    }}
                  >
                    <span className="sr-only">Cerrar</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              ) : (
                <div className="flex flex-col divide-y divide-slate-400 dark:divide-slate-600 border-l border-slate-400 dark:border-slate-600">
                  <div className="flex h-0 flex-1">
                    <button
                      type="button"
                      className="flex w-full items-center justify-center px-2 py-3 text-sm text-slate-900 dark:text-slate-50 hover:bg-blue-400 dark:hover:bg-blue-500 hover:text-slate-50 dark:hover:text-slate-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onClick={() => {
                        confirmCallback()
                        dispatch(hideNotifications())
                      }}
                    >
                      Confirmar
                    </button>
                  </div>
                  <div className="flex h-0 flex-1">
                    <button
                      type="button"
                      className="flex w-full items-center justify-center px-2 py-3 text-sm text-slate-500 dark:text-slate-400 hover:bg-slate-300 dark:hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onClick={() => dispatch(hideNotifications())}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default Notifications
