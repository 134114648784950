import PropTypes from "prop-types"
import { childrenPropTypes } from "../../../propTypes/AppPropTypes"

function AccountBodyBox({ children }) {
  return (
    <div className="w-full p-4 bg-white dark:bg-slate-900 rounded-lg border border-slate-200 dark:border-slate-700">
      {children}
    </div>
  )
}

function AccountBoxHeading({ children }) {
  return <div className="mb-5">{children}</div>
}

function AccountBoxContent({ children }) {
  return (
    <div className="mb-5 border-b border-slate-300 dark:border-slate-600 last:border-0">
      {children}
    </div>
  )
}

function AccountBoxInfo({ children }) {
  return (
    <div className="grid grid-cols-[200px,_1fr] items-center">{children}</div>
  )
}

function AccountBoxInfoButton({ children, option, callback }) {
  return (
    <button
      type="button"
      onClick={() => callback(option)}
      className="group w-full grid grid-cols-[200px,_1fr] items-centertransition-colors duration-300 px-2 rounded-sm
      hover:bg-blue-500 hover:bg-opacity-10
      "
    >
      {children}
    </button>
  )
}

AccountBodyBox.propTypes = {
  children: childrenPropTypes.isRequired,
}
AccountBoxHeading.propTypes = {
  children: childrenPropTypes.isRequired,
}
AccountBoxContent.propTypes = {
  children: childrenPropTypes.isRequired,
}
AccountBoxInfo.propTypes = {
  children: childrenPropTypes.isRequired,
}
AccountBoxInfoButton.propTypes = {
  option: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  children: childrenPropTypes.isRequired,
}

export {
  AccountBodyBox,
  AccountBoxHeading,
  AccountBoxContent,
  AccountBoxInfo,
  AccountBoxInfoButton,
}
