import { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"


// FUNCTIONS
import getCompanies from "../../../utils/companies"

//CONTEXTS
import FirebaseContext from "../../../firebase/FirebaseContext"

// COMPONENTS
import InputField from "../forms/InputField"
import LoadingSpinner from "../loadingSpinner/LoadingSpinner"
import SETTINGS from "../../../settings/settings"
// SENTRY
import * as Sentry from "@sentry/browser";


function FormGroup({ children }) {
  return <div className="relative w-full mb-5">{children}</div>
}

function AccountRegisterUsersForm({
  formFields,
  setFormFields,
  onInputChange,
  onSubmit,
  isInvalid,
  isLoading,
  actionLabel,
  isEditing,
}) {

  // FIREBASE
  const firebase = useContext(FirebaseContext)
  // STATES
  const [companies, setCompanies] = useState({})

  useEffect(() => {
    const getCompaniesData = async () => {
      try {
        if (firebase !== undefined) {
          await getCompanies({ firebase }, (companiesData) => {
            setCompanies(companiesData);
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error al obtener los datos:', error);
      }
    };
    getCompaniesData();
  });


  // FUNCTIONS
  const updateContactServicesValues = (service, value, key = null) => {
    let newContactServices
    if (key) {
      const newValue = {
        ...formFields.contactServices[service].value,
        ...{ [key]: value },
      }

      const newObjt = {
        ...formFields.contactServices[key],
        ...{ value: newValue },
      }
      newContactServices = {
        ...formFields.contactServices,
        ...{ [service]: newObjt },
      }
    } else {
      const newObjt = {
        ...formFields.contactServices.email,
        ...{ value },
      }
      newContactServices = {
        ...formFields.contactServices,
        ...{ [service]: newObjt },
      }
    }
    setFormFields({
      ...formFields,
      ...{ contactServices: newContactServices },
    })
  }

  const getContactServicesValues = (service) => {
    const { contactServices } = formFields
    if (
      contactServices &&
      contactServices[service] &&
      contactServices[service].value
    ) {
      return contactServices[service].value
    }
    return ""
  }

  return (
    <form className="w-full" onSubmit={onSubmit} noValidate>
      <div className="flex flex-col gap-5">
        <div className="border border-slate-600 p-5 rounded-lg">
          <section className="mb-4">
            <h3 className="text-md">Información personal</h3>
            <p className="text-xs text-slate-400">
              Datos necesarios para la cuenta del usuario.
            </p>
          </section>
          <FormGroup>
            <InputField
              type="text"
              name="email"
              placeholder="correo@micorreo.com"
              defaultValue={formFields.email}
              label="Correo electrónico"
              callback={onInputChange}
              readonly={isEditing}
              size="small"
            />
          </FormGroup>
          <FormGroup>
            <InputField
              type="text"
              name="name"
              placeholder="Nombre del usuario"
              defaultValue={formFields.name}
              label="Primer nombre"
              callback={onInputChange}
              size="small"
            />
          </FormGroup>
          <FormGroup>
            <InputField
              type="text"
              name="lastname"
              placeholder="Apellido del usuario"
              defaultValue={formFields.lastname}
              label="Apellido o apellidos"
              callback={onInputChange}
              size="small"
            />
          </FormGroup>
          <FormGroup>
            <span className="text-xs text-slate-400">Compañía</span>
            <select
              id="company"
              name="company"
              value={formFields.company.id}
              className="w-full relative h-10 border border-slate-600 bg-slate-800  text-slate-50 rounded-md p-2 text-xs selected:text-slate-400"
              onChange={(e) => {
                const { value } = e.target
                if (value !== "") {
                  setFormFields({
                    ...formFields,
                    ...{ company: { "id": value } },
                  })
                }
              }}
            >
              <option
                value=""
                disabled
                className="text-slate-400 selected:text-slate-400"
              >
                Seleccionar la compañía del usuario
              </option>
              {Object.keys(companies).map((key) => (
                <option key={key} value={key}>
                  {companies[key]}
                </option>
              ))}
            </select>
          </FormGroup>

          {!isEditing && (
            <FormGroup>
              <span className="text-xs text-slate-400">Rol del usuario</span>
              <select
                id="company"
                name="company"
                value={formFields.role.length === 0 ? "" : formFields.role}
                className="w-full relative h-10 border border-slate-600 bg-slate-800  text-slate-50 rounded-md p-2 text-xs selected:text-slate-400"
                onChange={(e) => {
                  const { value } = e.target
                  if (value !== "") {
                    setFormFields({
                      ...formFields,
                      ...{ role: [value] },
                    })
                  }
                }}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="text-slate-400 selected:text-slate-400"
                >
                  Seleccionar la compañía del usuario
                </option>
                {Object.keys(SETTINGS.USER_PROFILES).map((key) => (
                  <option key={key} value={key}>{SETTINGS.USER_PROFILES[key]}</option>
                ))}
              </select>
            </FormGroup>
          )}
        </div>

        <div className="border border-slate-600 p-5 rounded-lg">
          <section className="mb-4">
            <div className="flex items-center justify-between gap-2">
              <div>
                <h3 className="text-md">Información de contacto</h3>
                <p className="text-xs text-slate-400">
                  Ingresa las opciones de contacto que aparecerán en los
                  análisis públicos del asesor
                </p>
              </div>
            </div>
          </section>
          <div className="flex flex-col items-start justify-stretch gap-5">
            <FormGroup>
              <div className="grid grid-cols-[200px,_1fr] gap-2 items-center place-items-start">
                <p className="text-sm">Correo electrónico de contacto</p>
                <InputField
                  type="text"
                  name="contactServices/email"
                  placeholder="correo@micorreo.com"
                  defaultValue={getContactServicesValues("email")}
                  label=""
                  callback={(event) =>
                    updateContactServicesValues("email", event.target.value)
                  }
                  size="small"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="grid grid-cols-[200px,_1fr] gap-2 items-center place-items-start">
                <p className="text-sm">Teléfono de contacto</p>
                <InputField
                  type="text"
                  name="contactServices/phone"
                  placeholder="+56912345678"
                  defaultValue={getContactServicesValues("phone")}
                  label=""
                  callback={(event) =>
                    updateContactServicesValues("phone", event.target.value)
                  }
                  size="small"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="grid grid-cols-[200px,_1fr] gap-2 items-center place-items-start">
                <p className="text-sm">Número de WhatsApp</p>
                <InputField
                  type="text"
                  name="contactServices/whatsapp"
                  placeholder="+56912345678"
                  defaultValue={getContactServicesValues("whatsapp")}
                  label=""
                  callback={(event) =>
                    updateContactServicesValues("whatsapp", event.target.value)
                  }
                  size="small"
                />
              </div>
            </FormGroup>
          </div>
        </div>

        {formFields.error && (
          <div
            className={`relative mb-5 px-4 py-2 text-sm rounded-md
            ${formFields.error.status === "error"
                ? "bg-rose-50 text-rose-400 border-rose-400"
                : "bg-emerald-50 text-emerald-400 border-emerald-400"
              }
          `}
          >
            <span>{formFields.error.message}</span>
          </div>
        )}

        <div className="flex justify-end">
          <div className="flex justify-end items-center gap-5 w-[400px]">
            {isLoading && (
              <div className="shrink-0 w-[50px]">
                <LoadingSpinner />
              </div>
            )}
            <button
              type="submit"
              disabled={isInvalid}
              className="w-full text-sm grid place-content-center border border-blue-500 text-slate-900 bg-blue-500 rounded-lg px-4 py-3
                hover:bg-blue-500
                disabled:pointer-events-none disabled:bg-slate-800 disabled:text-slate-400 disabled:border-slate-600 disabled:cursor-not-allowed
              "
            >
              {actionLabel}
            </button>
          </div>
          {/* {formFields.error && <p>{formFields.message}</p>} */}
        </div>
      </div>
    </form>
  )
}

AccountRegisterUsersForm.defaultProps = {
  isEditing: false,
}

AccountRegisterUsersForm.propTypes = {
  formFields: PropTypes.shape({
    name: PropTypes.string,
    lastname: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    email: PropTypes.string,
    phone: PropTypes.shape({
      area: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      country: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    contactServices: PropTypes.shape({
      email: PropTypes.shape({
        isEnabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      phone: PropTypes.shape({
        isEnabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.shape({
          area: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          country: PropTypes.string,
          number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      }),
      whatsapp: PropTypes.shape({
        isEnabled: PropTypes.bool,
        label: PropTypes.string,
        value: PropTypes.shape({
          area: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          country: PropTypes.string,
          number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      }),
    }),
    role: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.shape({
      message: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
  setFormFields: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  actionLabel: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
}

FormGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}
export default AccountRegisterUsersForm
