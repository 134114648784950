import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    advisorData: {},
    title: "",
    comments: "",
    objective: "",
    clientName: "",
    clientProfile: "",
    clientInvestmentType: "",
    distribution: "weight",
    date: null,
    showComponents: {
      portfolio: true,
      returns: true,
      exposureAll: true,
      equitydebt: true,
      exposure: {
        ASSET_CLASS: true,
        COUNTRY: true,
        CURRENCY: true,
        ZONE: true,
        SECTOR_ICB: true,
        RISK: true,
      },
      issuers: true,
      risks: true,
      tac: true,
    },
    disclaimer: "",
    companySiteConfig: {},
  },
}
const DEFAULT_DATA = initialState

export const analysisDataConfigSlice = createSlice({
  name: "chartsData",
  initialState,
  reducers: {
    updateAnalysisDataConfig: (state, action) => {
      state.data = action.payload
    },
    resetAnalysisDataConfigSlice: (state) => {
      state.data = DEFAULT_DATA
    },
  },
})

export const { updateAnalysisDataConfig, resetAnalysisDataConfigSlice } =
  analysisDataConfigSlice.actions

export default analysisDataConfigSlice.reducer
