import PropTypes from "prop-types"
import { Tooltip as FlowbiteTooltip } from "flowbite-react"

function Tooltip({ content, children }) {
  return (
    <FlowbiteTooltip
      content={content}
      arrow={false}
      className="p-2 rounded-lg bg-slate-300 dark:bg-slate-700 text-slate-900 dark:text-slate-50 text-xs"
    >
      {children}
    </FlowbiteTooltip>
  )
}

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Tooltip
