/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import useAddLogo from "../../hooks/useAddLogo";
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner";
import useLogoCompany from "../../hooks/useLogoCompany";
import useColorScheme from "../../hooks/useColorScheme";

function ImageUpload({ changeToggle }) {
  const { handlerUploadImage } = useAddLogo();
  const { getLogo, getLogoSchema } = useLogoCompany();
  const [colorScheme] = useColorScheme();

  const [file, setFile] = useState(null);
  const [imageName, setImageName] = useState("");
  const [previewUrl, setPreviewUrl] = useState(getLogo());
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const logoUrl = getLogoSchema(changeToggle);
    setPreviewUrl(logoUrl);
  }, [changeToggle, getLogoSchema]);

  useEffect(() => {
    const logoUrl = getLogoSchema(colorScheme);
    setPreviewUrl(logoUrl);
  }, [colorScheme, getLogoSchema]);

  const setFileAndPreview = (selectedFile) => {
    if (selectedFile) {
      setFile(selectedFile);
      setImageName(selectedFile.name);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setError(""); // Limpiar el error si es válido
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(selectedFile.type)) {
        setError(
          "Formato de archivo no válido. Solo se permiten archivos PNG, JPG o JPEG."
        );
        setFile(null); // No agregar el archivo si es inválido
        setImageName("");
        return;
      }
      setFileAndPreview(selectedFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) return;
    setDisableButton(true);
    await handlerUploadImage({ file, schema: changeToggle });
    setDisableButton(false);
    setFile(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dataTransferFiles = event.dataTransfer.files;
    if (dataTransferFiles.length) {
      const selectedFile = dataTransferFiles[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(selectedFile.type)) {
        setError(
          "Formato de archivo no válido. Solo se permiten archivos PNG, JPG o JPEG."
        );
        setFile(null);
        setImageName("");
        return;
      }
      setFileAndPreview(selectedFile);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit}>
        <div
          className="w-full bg-slate-100 dark:bg-slate-800 border-dotted border-2 border-slate-300 dark:border-slate-600 rounded-lg h-20 flex items-center justify-center  text-slate-500 dark:text-slate-400 hover:border-blue-500 hover:text-blue-500 dark:hover:border-blue-500 dark:hover:text-blue-400"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <label htmlFor="inputTag" className="flex w-full items-center ">
            {!file && (
              <div className="flex items-center justify-center gap-2 cursor-pointer w-full">
                <CloudArrowUpIcon className="w-5 " />
                <span className="text-xs">
                  Seleccionar o arrastra una imagen aquí
                </span>{" "}
              </div>
            )}
            <input
              id="inputTag"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            {disableButton ? (
              <LoadingSpinner />
            ) : (
              <span className="ml-2">{file && imageName}</span>
            )}
          </label>
        </div>
        {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
        {previewUrl && (
          <div className="mt-4 w-auto h-20 flex justify-center">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <img
                src={previewUrl}
                alt={`Preview ${imageName}`}
                className="object-contain h-[inherit]"
              />
            )}
          </div>
        )}
        <div className="flex justify-end w-full">
          <button
            type="submit"
            disabled={!file || disableButton}
            className="mt-4 h-8 shrink-0 w-20 py-1 px-2 text-xxs rounded-md border bg-blue-500 text-slate-50 dark:text-slate-900 hover:bg-blue-400 disabled:opacity-50 disabled:bg-slate-500 disabled:text-slate-300 disabled:border-slate-700 disabled:cursor-default
                      hover:disabled:bg-slate-600 hover:disabled:text-slate-400"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
}

ImageUpload.propTypes = {
  changeToggle: PropTypes.string.isRequired,
};

export default ImageUpload;
