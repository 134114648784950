import cn from "../../utilities/ClassMerge";
import ProposalDataPropType from "../ProposalDataPropType";

function StatsSidebar({
  advisors,
  companies,
  selectedUserID,
  selectedCompanyID,
  updateFilters,
  showSidebarBy,
}) {
  return (
    <div className="w-full space-y-1">
      <div className="w-full flex items-center justify-between text-xxs px-1 text-slate-500">
        <p>{`Nombre ${showSidebarBy === "advisors" ? "asesor/a" : "empresa"}`}</p>
        <p className="w-[75px] shrink-0 text-center">Nº Propuestas</p>
      </div>
      <div className="space-y-1">
        {showSidebarBy === "advisors" &&
          advisors.map(
            ({
              advisor_id,
              advisor_name,
              advisor_lastname,
              advisor_company,
              total_proposal,
            }) => (
              <button
                key={advisor_id}
                type="button"
                className={cn(
                  "group w-full flex items-center justify-between px-2 py-1 bg-slate-50 rounded-lg hover:bg-blue-500 hover:text-slate-50 duration-500 transition-colors",
                  {
                    "bg-blue-500 text-slate-50": selectedUserID === advisor_id,
                  },
                )}
                onClick={() => updateFilters("advisorID", advisor_id)}
              >
                <span className="flex flex-col items-start justify-start">
                  <span className="text-xs">{`${advisor_name} ${advisor_lastname}`}</span>
                  <span
                    className={cn(
                      "text-[10px] text-slate-700 group-hover:text-slate-50 duration-500 transition-colors",
                      {
                        "bg-blue-500 text-slate-50":
                          selectedUserID === advisor_id,
                      },
                    )}
                  >
                    {advisor_company.name}
                  </span>
                </span>
                <span className="w-[75px] text-xs shrink-0">
                  {total_proposal}
                </span>
              </button>
            ),
          )}
        {showSidebarBy === "companies" &&
          companies.map(({ company_id, company_name, total_proposal }) => (
            <button
              key={company_id}
              type="button"
              className={cn(
                "group w-full flex items-center justify-between p-1 bg-slate-50 rounded-lg hover:bg-blue-500 hover:text-slate-50 duration-500 transition-colors",
                {
                  "bg-blue-500 text-slate-50": selectedCompanyID === company_id,
                },
              )}
              onClick={() => updateFilters("companyID", company_id)}
            >
              <span className="flex flex-col items-start justify-start">
                <span className="text-xs">{company_name}</span>
              </span>
              <span className="w-[75px] text-xs shrink-0">
                {total_proposal}
              </span>
            </button>
          ))}
      </div>
    </div>
  );
}

StatsSidebar.propTypes = {
  data: ProposalDataPropType.isRequired,
};

export default StatsSidebar;

{
  /*
    <p className="text-xs pl-2">
        {`${advisorCount} (${formatToFloat( (advisorCount * 100) / data.length )})`}
    </p>
*/
}
