import { useCallback } from "react"

const usePdfCheckPortfolioExists = ({ portfolios }) =>
  useCallback(() => {
    const existA = !!portfolios?.portfolioA?.portfolioFunds.length
    const existB = !!portfolios?.portfolioB?.portfolioFunds.length
    let portfolioExistenceStatus = {
      portFolioA: existA,
      portFolioB: existB,
      doblePortFolio: false,
      portFolioNameA: portfolios?.portfolioA?.portfolioName,
      portFolioNameB: portfolios?.portfolioB?.portfolioName,
    }
    if (existA && existB) {
      portfolioExistenceStatus = {
        ...portfolioExistenceStatus,
        doblePortFolio: true,
      }
    }
    return portfolioExistenceStatus
  }, [portfolios])

export default usePdfCheckPortfolioExists
