import PropTypes from "prop-types"

// HOOKS
import usePrepareDataForCharts from "../../hooks/usePrepareDataForCharts"
import useColorScheme from "../../hooks/useColorScheme"
// UTILS
import { displayString, formatToFloat } from "../ui/utilities/functions"

import addOpacityToHexColor from "../../utils/addOpacityToHexColor"

function TACTable({ title, shareClasses, tac, color, portfolios }) {
  const [colorScheme] = useColorScheme()
  const { getTACTableData } = usePrepareDataForCharts()
  const TACTableData = getTACTableData(shareClasses, portfolios?.portfolioFunds)

  const getTACValue = (tacValue) => {
    let value = ""
    if (tacValue !== undefined) {
      value =
        tacValue === 0 || Number.isNaN(tacValue)
          ? "-"
          : formatToFloat(tacValue, 2, "%")
    }
    return value
  }

  const getInstrumentName = (fund) => {
    const fundData = portfolios.portfolioFunds.find(
      (f) =>
        f.fundId === fund.fundId && f.shareClassName === fund.shareclassName,
    )
    switch (fundData.fundType) {
      case "IN":
        return `${displayString(fund?.fund_name)}`
      default:
        return `${displayString(fund?.fund_name)} | ${fund?.shareclassName}`
    }
  }

  return (
    <div className="w-full">
      <div
        className={`w-full flex items-center justify-between gap-4 p-2 mb-4 overflow-x-hidden rounded-sm
          ${colorScheme === "dark" ? "bg-slate-800" : ""}
        `}
        style={{
          backgroundColor:
            colorScheme === "light" ? addOpacityToHexColor(color, 0.3) : null,
        }}
      >
        <div className="flex items-center gap-2">
          <span
            className="text-base w-4 h-4 rounded-sm grid place-content-center"
            style={{ backgroundColor: color }}
          />
          <p className="text-slate-900 dark:text-slate-50 text-sm font-semibold">
            {title}
          </p>
        </div>
        <div className="text-right">
          <span className="text-xs">Tac ponderado: </span>
          <span className="text-sm font-semibold">{getTACValue(tac)}</span>
        </div>
      </div>

      {TACTableData.map((fund, index) => {
        const fundKey = `${fund.fundId}-${fund.fund_name}-${fund.shareclassName}-${index}`
        return (
          <div key={fundKey} className="flex justify-between pb-3">
            <p className="text-xs">{getInstrumentName(fund)}</p>
            <p className="text-xs">{getTACValue(fund.tac)}</p>
          </div>
        )
      })}
    </div>
  )
}

TACTable.propTypes = {
  title: PropTypes.string.isRequired,
  shareClasses: PropTypes.arrayOf(
    PropTypes.shape({
      shareclassName: PropTypes.string,
      tac: PropTypes.number,
      tacDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  tac: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  portfolios: PropTypes.shape({
    portfolioName: PropTypes.string.isRequired,
    portfolioFunds: PropTypes.arrayOf(
      PropTypes.shape({
        fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        fund_name: PropTypes.string.isRequired,
        shareClassName: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        weight: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
}
TACTable.defaultProps = {
  shareClasses: [],
}
export default TACTable
