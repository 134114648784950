import  { Fragment, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  ExclamationTriangleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline"
import { Menu, Transition } from "@headlessui/react"

// ROUTES
import { useSelector, useDispatch } from "react-redux"
import * as ROUTES from "../../../routes/APP_ROUTES"

// HOOKS
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"
import useTracking from "../../../hooks/useTracking"

// STORE
import { toggleProposalType } from "../../../store/proposalTypeSlice"
import { resetPortfolioSelection } from "../../../store/portfolioSelectionSlice"
import { resetPortfolioComposition } from "../../../store/portfolioCompositionSlice"
import { resetCharstData } from "../../../store/chartsDataSlice"
import { resetFilters } from "../../../store/filtersSlice"
import { resetSearch } from "../../../store/searchSlice"
import { resetReducer } from "../../../store/reducedDataSlice"
import { togglePreviewPanel } from "../../../store/portfolioPanelSlice"

function ProposalTypeDropdown() {
  // STORE
  const dispatch = useDispatch()
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  // HOOKS
  const trackEvent = useTracking()
  const location = useLocation()
  const navigate = useNavigate()
  const { getPortfolioInfo } = usePreviewPanel()
  // STATE
  const [showModal, setShowModal] = useState(false)
  const [selection, setSelection] = useState(null) // LA SELECCIÓN ES UN VALOR SEMI TEMPORAL, NO SURTE EFECTO HASTA QUE SE CAMBIE LA SELECCIÓN EN EL STORE

  const clearScreenerFilters = () => {
    // LIMPIAR FILTROS
    dispatch(resetFilters())
    dispatch(resetSearch())
    dispatch(resetReducer())
    // CERRAR PREVIEW
    dispatch(togglePreviewPanel(false))
  }
  const updateProposalType = (newSelection) => {
    dispatch(toggleProposalType(newSelection))
    setSelection(null)
  }

  const handleSelect = (value) => {
    if (value !== isAPV) {
      if (getPortfolioInfo().totalFundsSelected === 0) {
        // No hay fondos seleccionados, cambiar el universo directamente.
        updateProposalType(value)
        clearScreenerFilters()
      } else {
        setSelection(value)
        setShowModal(true)
      }
    }
  }

  const handleProposalConfirm = () => {
    // CLEAR ALL PORTFOLIOS
    dispatch(resetPortfolioSelection())
    dispatch(resetPortfolioComposition())
    dispatch(resetCharstData())
    clearScreenerFilters()
    updateProposalType(selection)
    setShowModal(false)
    trackEvent({
      action: "CHANGE_PROPOSAL_TYPE",
      timestamp: Date.now(),
      type: "SWITCH",
      data: selection,
    })
    if (location.pathname !== ROUTES.SCREENER) {
      navigate(ROUTES.SCREENER)
    }
  }
  const handleProposalCancel = () => {
    setSelection(null)
    setShowModal(false)
  }

  return (
    <div className="lg:flex items-center gap-1">
      <span className="hidden text-xs lg:inline">Tipo de propuesta:</span>

      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-40 h-8 justify-between items-center px-2 py-1 text-xs uppercase rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            {isAPV ? "APV" : "General"}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-40 origin-top-right divide-y border rounded-md shadow-lg ring-1 text-sm bg-white dark:bg-slate-700 border-slate-300 dark:border-transparent divide-slate-300 dark:divide-slate-500  ring-black/5 focus:outline-none">
            <div className="px-2 py-2">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`group flex w-full cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md uppercase 
                      ${
                        active
                          ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                          : "text-slate-900 dark:text-slate-50"
                      }
                    `}
                    onClick={() => handleSelect(false)}
                  >
                    General
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`group flex w-full cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md uppercase 
                    ${
                      active
                        ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                        : "text-slate-900 dark:text-slate-50"
                    }
                  `}
                    onClick={() => handleSelect(true)}
                  >
                    APV
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[90] outline-none focus:outline-none isolate">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="px-8 py-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent outline-none focus:outline-none space-y-8">
                <div className="flex flex-col items-center justify-start gap-0">
                  <ExclamationTriangleIcon className="w-10 h-10 text-blue-500" />
                  <h3 className="text-xl font-semibold">Advertencia</h3>
                </div>

                <p className="text-center text-sm">
                  Al cambiar el tipo de propuesta, perderás tu selección actual
                  de fondos.
                </p>

                <div className="flex flex-col items-center justify-start gap-1border-t border-solid border-slate-100 dark:border-slate-700 rounded-b space-y-2">
                  <button
                    className="w-full rounded-md leading-0 px-3 py-2 text-xs text-center uppercase transition-colors duration-300 ease-in-out text-slate-50 bg-blue-500 hover:bg-blue-400"
                    type="button"
                    onClick={() => handleProposalConfirm()}
                  >
                    Confirmar
                  </button>
                  <button
                    className="w-full rounded-md leading-0 px-3 py-2 text-xs text-center uppercase transition-colors duration-300 ease-in-out text-blue-500 dark:text-blue-400 hover:underline"
                    type="button"
                    onClick={() => handleProposalCancel()}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-white dark:bg-slate-900" />
        </>
      ) : null}
    </div>
  )
}

export default ProposalTypeDropdown
