import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {},
}

export const CategoriesMappingSlice = createSlice({
  name: "categoriesMapping",
  initialState,
  reducers: {
    updateCategoriesMapping: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { updateCategoriesMapping } = CategoriesMappingSlice.actions

export default CategoriesMappingSlice.reducer
