import ReturnDatePicker from "../ReturnDatePicker/ReturnDatePicker";

export default function ReturnDatePickerWithSelect({
  valueSelect,
  onChangeSelect,
  onChangeDateFrom,
  // onChangeDateTo,
  periodList,
  firstReturnDate,
  lastReturnDate,
  minDateFrom,
  // MaxDateTo,
  isActiveSelect,
}) {
  // const returnTextButtons = (key, value) => {
  //   if (key === "YTD") {
  //     return key
  //   }
  //   if (key === "Manual") {
  //     return ""
  //   }
  //   return (`${key.split("")[0]}${key.split("")[2]}`)

  // }
  // console.log({ valueSelect});

  return (
    <>
      <div className="flex justify-end h-8 flex-row gap-2">
        <select
          name="TimePeriod"
          value={valueSelect}
          onChange={onChangeSelect}
          className="justify-self-end bg-slate-100 dark:bg-slate-700 w-24 px-2 py-1 text-xs rounded-md text-slate-900 dark:text-slate-50 !outline-none border border-slate-400 dark:border-slate-600"
        >
          {periodList.map((tp) => (
            <option value={tp.value} key={tp.key}>
              {tp.key}
            </option>
          ))}
        </select>
        {valueSelect === 0 && (
          <>
            <span
              className="text-xs text-slate-500 dark:text-slate-400"
              style={{
                width: "1px",
                height: "auto",
                borderLeft: "solid 1px  #cbd5e1",
              }}
            />

            <div className="flex flex-row gap-0 items-center">
              <ReturnDatePicker
                active={isActiveSelect}
                onChange={onChangeDateFrom}
                value={firstReturnDate}
                minDate={minDateFrom}
                maxDate={lastReturnDate}
                // label={"Período gráfico"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
