import { useState, useEffect, useMemo, useRef } from "react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Transition } from "@headlessui/react"
import { panelDataPropTypes } from "../../../propTypes/AppPropTypes"

// COMPONENTS
import LineChart from "../../charts/LineChart"
import FundDetailsPerformanceTableAPV from "./FundDetailsPerformanceTableAPV"

// HOOKS
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"

// UTILS
import {
  calculateRelativeDateFrom,
  parseAPIDate,
  getDifferenceInMonths,
} from "../../../utils/DateCalculations"

// import { graphShift } from "../../../utils/ReturnsCalculations"

import { formatDataInfoDate, displayString } from "../utilities/functions"

function FundDetailsPerformance({ data }) {
  // HOOKS
  const { filterUnqualifiedSeries } = usePrepareDataForCharts()

  // REF
  const infoModalRef = useRef()

  // MEMO
  const apvExcludePeriods = [1, 3, -2, 6]
  const timePeriods = useMemo(
    () => [
      "6 Meses",
      // "YTD",
      "1 Año",
      "2 Años",
      "3 Años",
      "5 Años",
    ],
    [],
  )

  // STATE
  const [showInfo, setShowInfo] = useState(false)
  const [timePeriod, setTimePeriod] = useState(-1)
  const [performanceData, setPerformanceData] = useState(data.performance)
  const initialPeriodList = [
    { key: "5 Años", value: 60 },
    { key: "3 Años", value: 36 },
    { key: "2 Años", value: 24 },
    { key: "1 Año", value: 12 },
    // { key: "YTD", value: -2 },
    { key: "6 meses", value: 6 },
  ]
  const [periodList, setPeriodList] = useState(initialPeriodList)
  const [nullDates, setNullDates] = useState([])
  const [firstPortfoliosPerformanceDate] = useState(
    parseAPIDate(data.performance[-1][0].data[0].date),
  )
  const [lastPortfoliosPerformanceDate] = useState(
    parseAPIDate(
      data.performance[-1][0].data[data.performance[-1][0].data.length - 1]
        .date,
    ),
  )
  const ageInMonths = getDifferenceInMonths(
    lastPortfoliosPerformanceDate,
    firstPortfoliosPerformanceDate,
  )

  const hasLessThanOneYear = ageInMonths < 12 && ageInMonths >= 6

  const tableReturns = filterUnqualifiedSeries(data.returnsTable.data)

  const tableFill = (tData) => {
    const table = {}
    tData.forEach((tableRow) => {
      table[tableRow.serie] = {
        real_ann_ret_6m: tableRow.real_ann_ret_6m,
        real_ann_ret_1y: tableRow.real_ann_ret_1y,
        real_ann_ret_2y: tableRow.real_ann_ret_2y,
        real_ann_ret_3y: tableRow.real_ann_ret_3y,
        real_ann_ret_5y: tableRow.real_ann_ret_5y,
        // real_ann_ret_ytd: tableRow.real_ann_ret_ytd,
      }
    })
    return table
  }

  const displaySeries = Object.keys(tableFill(tableReturns))

  const addDataToTable = (table, shareClassName, shareClassData) => {
    table["6 meses"].push({
      [shareClassName]: shareClassData.real_ann_ret_6m,
    })
    table["12 meses"].push({
      [shareClassName]: shareClassData.real_ann_ret_1y,
    })
    table["2 años"].push({ [shareClassName]: shareClassData.real_ann_ret_2y })
    table["3 años"].push({ [shareClassName]: shareClassData.real_ann_ret_3y })
    table["5 años"].push({ [shareClassName]: shareClassData.real_ann_ret_5y })
    // table.YTD.push({ [shareClassName]: shareClassData.real_ann_ret_ytd })

    return table
  }

  const presenter = (tData) => {
    const currentTData = { ...tData }
    let table = {
      "6 meses": [],
      "12 meses": [],
      "2 años": [],
      "3 años": [],
      "5 años": [],
      // YTD: [],
    }
    // add data for current shareclass
    const currentShareClassName = data.overview.shareClass.shareClassName
    const tableRowCS = currentTData[currentShareClassName]
    table = addDataToTable(table, currentShareClassName, tableRowCS)
    // move current shareclass to begin
    displaySeries.splice(displaySeries.indexOf(currentShareClassName), 1)
    displaySeries.unshift(currentShareClassName)

    delete currentTData[currentShareClassName]
    Object.keys(currentTData).forEach((s) => {
      const tableRow = currentTData[s]
      table = addDataToTable(table, s, tableRow)
    })
    return table
  }

  const [tableData] = useState(presenter(tableFill(tableReturns)))

  const performanceToNull = (p, nullDates) => {
    const newP = p.map((row) => {
      if (nullDates.includes(row.date)) {
        return { ...row, value: null }
      }
      return row
    })
    return newP
  }

  useEffect(() => {
    const filteredPeriodList = []
    let firstPerfDate = null
    periodList.forEach((pl) => {
      if (pl.key !== "YTD") {
        firstPerfDate = calculateRelativeDateFrom(
          lastPortfoliosPerformanceDate,
          pl.value,
        )
        if (firstPerfDate >= firstPortfoliosPerformanceDate) {
          filteredPeriodList.push(pl)
        }
      } else {
        filteredPeriodList.push(pl)
      }
    })
    // SI filteredPeriodList está vacío, entonces el fondo no tiene historia suficiente.
    if (Object.keys(filteredPeriodList).length === 0) {
      setPeriodList(filteredPeriodList)
      setTimePeriod(null)
    } else {
      setPeriodList(filteredPeriodList)
      const sc = data.overview.shareClass.instance
      const per = sc.calcAccRet(
        performanceData[-1][0].name,
        firstPortfoliosPerformanceDate,
        lastPortfoliosPerformanceDate,
        filteredPeriodList[0]?.value,
      )
      const performanceFPL = per[0]
      setNullDates([sc.returnsLagoon])
      setPerformanceData({
        ...performanceData,
        ...{
          [filteredPeriodList[0].value]: [
            {
              name: performanceFPL.name,
              data: performanceToNull(performanceFPL.data, sc.returnsLagoon),
            },
          ],
        },
      })
      setTimePeriod(filteredPeriodList[0].value)
    }
  }, [data, timePeriods])

  if (Object.keys(data.performance).length === 0) {
    return (
      <div className="w-full">
        <p>No data</p>
      </div>
    )
  }

  const getColDistribution = () => {
    let spanList = []
    switch (displaySeries.length) {
      case 1:
        spanList = ["col-span-10", "col-span-2"]
        break
      case 2:
      case 3:
        spanList = ["col-span-9", "col-span-3"]
        break
      case 4:
      case 5:
        spanList = ["col-span-8", "col-span-4"]
        break
      case 6:
        spanList = ["col-span-7", "col-span-5"]
        break
      default:
        spanList = ["col-span-6", "col-span-6"]
    }
    return spanList
  }

  return (
    periodList && (
      <section className="h-full">
        <div className="flex ">
          <h1 className="overview-section-title">Rentabilidad Real</h1>
          <div className="group relative mx-1 leading-0 translate-y-0.5 z-50">
            <button
              className="ml-2 block text-blue-400 shrink-0 cursor-pointer"
              onClick={() => setShowInfo(!showInfo)}
              onMouseLeave={() => setShowInfo(false)}
            >
              <InformationCircleIcon className="w-5 h-5 shrink-0" />
            </button>
            <Transition
              as="div"
              ref={infoModalRef}
              show={showInfo}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute top-6 right-0 md:translate-x-1/2 w-[200px] opacity-0 z-50"
            >
              <div className="p-4 rounded-sm bg-slate-300 dark:bg-slate-700 text-xs">
                <p>
                  El cálculo de rentabilidad considera todos los eventos de
                  capital (dividendos, repartos, etc) y los valores están
                  deflactados por la U.F. (rentabilidad real).
                </p>
              </div>
            </Transition>
          </div>
        </div>
        {timePeriod ? (
          <div className="overview-section-grid ">
            {Object.keys(data.performance).length > 0 && (
              <div className={getColDistribution()[0]}>
                <header className="flex items-center justify-between mb-5">
                  <h2 className="overview-section-subtitle !mb-0">
                    Rentabilidad histórica - Serie{" "}
                    {displayString(data.overview.shareClass.shareClassName)}
                  </h2>
                  <select
                    name="TimePeriod"
                    value={timePeriod}
                    // onChange={(event) => setTimePeriod(event.target.value)}
                    onChange={(event) => {
                      // arreglar setPerformance para no volver a calcular
                      // caso volver a historico
                      if (!performanceData[event.target.value]) {
                        const per =
                          data.overview.shareClass.instance.calcAccRet(
                            performanceData[-1][0].name,
                            firstPortfoliosPerformanceDate,
                            lastPortfoliosPerformanceDate,
                            +event.target.value,
                          )[0]
                        setPerformanceData({
                          ...performanceData,
                          ...{
                            [event.target.value]: [
                              {
                                name: per.name,
                                data: performanceToNull(
                                  per.data,
                                  data.overview.shareClass.instance
                                    .returnsLagoon,
                                ),
                              },
                            ],
                          },
                        })
                      }
                      setTimePeriod(+event.target.value)
                    }}
                    className="bg-slate-100 dark:bg-slate-800 w-24 px-2 py-1 text-xs rounded-md text-slate-900 dark:text-slate-50 !outline-none border border-slate-300 dark:border-slate-700"
                  >
                    {periodList.map((tp) => {
                      let option = null
                      if (hasLessThanOneYear && tp.value === 6) {
                        option = (
                          <option value={6} key="6 meses">
                            6 meses
                          </option>
                        )
                      } else if (!apvExcludePeriods.includes(tp.value)) {
                        option = (
                          <option value={tp.value} key={tp.key}>
                            {tp.key}
                          </option>
                        )
                      } else {
                        return null
                      }
                      return option
                    })}
                  </select>
                </header>
                <LineChart
                  performanceData={performanceData[timePeriod]}
                  colors={["#60A5FA"]}
                  emptyDates={nullDates}
                  isSeriesChart
                />
              </div>
            )}
            <div className={getColDistribution()[1]}>
              <header className="mb-5">
                <h2 className="overview-section-subtitle !mb-0">
                  Rentabilidad por series APV
                </h2>
                <p className="text-xs text-slate-500 dark:text-slate-400">
                  Al {formatDataInfoDate(data.overview.shareClass.returnsDate)}
                </p>
              </header>
              <div className="flex flex-col gap-2">
                <FundDetailsPerformanceTableAPV
                  displaySeries={displaySeries}
                  tableData={tableData}
                  currentShareClass={data.overview.shareClass.shareClassName}
                  hasLessThanOneYear={hasLessThanOneYear}
                />
                <p className="text-xxs text-slate-600 dark:text-slate-400 flex gap-1">
                  <InformationCircleIcon className="w-4 h-4" /> Los datos de
                  esta tabla corresponden a rentabilidad real anualizada.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full text-xs">
            <h2 className="font-bold">Lo sentimos</h2>
            <p className="">
              Este fondo aún no tiene la suficiente historia para mostrar su
              rentabilidad
            </p>
          </div>
        )}
      </section>
    )
  )
}
FundDetailsPerformance.propTypes = {
  data: panelDataPropTypes.isRequired,
}
export default FundDetailsPerformance
