import { useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { ArrowLeftIcon, LinkIcon } from "@heroicons/react/24/solid"
import { FolderArrowDownIcon, ClockIcon } from "@heroicons/react/24/outline"
import _ from "lodash"

// HOOKS
import { Fetch } from "../../../hooks/useFetch"

// CONTEXT
import { FirebaseContext } from "../../../firebase"

// UTILS
import {
  loadingTitle,
  loadingFavicon,
  loadingBody,
} from "../utilities/ProposalLoadingScreen"
import useColorScheme from "../../../hooks/useColorScheme"
// SENTRY
import * as Sentry from "@sentry/browser";


function AnalysisNav({ showButtons }) {
  // STATE
  const [gettingPDf, setIsGettingPDF] = useState(false)
  const [gettingDocs, setIsGettingDocs] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  const { logAnalyticsEvent } = firebase

  // STORE
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((store) => store.urls)
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)
  const chartsData = useSelector((state) => state.chartsData.data)
  const portfolioSelection = useSelector((state) => state.portfolioSelection)
  const { activePortfolio, portfolioBEnabled, distributionOption, baseAmount } =
    portfolioSelection
  const portfoliosName = useSelector((state) => state.portfoliosName.data)
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  // HOOKS
  const [colorScheme] = useColorScheme()

  // REF
  const openTab = useRef(null)

  const updatePortfolioNames = (originalData, newNames) => {
    // Hacer una copia profunda del objeto original usando lodash
    const updatedData = _.cloneDeep(originalData)

    Object.keys(newNames).forEach((key) => {
      if (updatedData[key]) {
        updatedData[key].portfolioName = newNames[key].portfolioName
      }
    })

    return updatedData
  }

  const handlePublishAnalysis = (openNewTab, downloadType = "") => {
    setIsGenerating(true)
    if (openNewTab) {
      openTab.current = window.open()
      openTab.current.document.title = loadingTitle
      const favicon = openTab.current.document.createElement("link")
      favicon.rel = "icon"
      favicon.href = loadingFavicon
      openTab.current.document.head.appendChild(favicon)
      openTab.current.document.body.innerHTML = loadingBody
    }

    if (!openNewTab) {
      if (downloadType === "pdf") {
        setIsGettingPDF(true)
      }
      if (downloadType === "docs") {
        setIsGettingDocs(true)
      }
    }

    const { data } = analysisDataConfig

    const analysisInfo = {
      title: data?.title,
      clientName: data?.clientName,
      clientProfile: data?.clientProfile,
      clientInvestmentType: data?.clientInvestmentType,
      analysisObjective: data?.objective,
      analysisComments: data?.comments,
      description: data?.description,
      url: data?.url,
      address: data?.address,
      phone: data?.phone,
      disclaimer: data?.disclaimer,
    }

    const {
      performanceChartData,
      exposureSummaryData,
      maxMinProfitLossesChartData,
      portfoliosNumber,
      exposureData,
      issuersData,
      tacData,
      portfoliosTableReturns,
      timePeriod,
      periodList,
      screenerTable,
    } = chartsData

    const updatedData = updatePortfolioNames(
      portfolioSelection?.data,
      portfoliosName,
    )
    const analysisData = {
      ...data,
      performanceChartData: JSON.stringify(performanceChartData),
      exposureSummaryData: JSON.stringify(exposureSummaryData),
      maxMinProfitLossesChartData: JSON.stringify(maxMinProfitLossesChartData),
      portfoliosNumber: JSON.stringify(portfoliosNumber),
      exposureData: JSON.stringify(exposureData),
      portfolios: JSON.stringify(updatedData),
      portfoliosConfigData: {
        activePortfolio,
        portfolioBEnabled,
        distributionOption,
        baseAmount,
      },
      issuersData: JSON.stringify(issuersData),
      tacData: JSON.stringify(tacData),
      portfoliosTableReturns: JSON.stringify(portfoliosTableReturns),
      timePeriod: JSON.stringify(timePeriod),
      periodList: JSON.stringify(periodList),
      screenerTable: JSON.stringify(screenerTable),
      analysisInfo: JSON.stringify(analysisInfo),
      logo: data?.logo ? data?.logo : {},
      advisorData: {
        ...data?.advisorData,
        name: `${user?.name} ${user?.lastname}`,
      },
      colorScheme,
      proposalType: isAPV ? "APV" : "General",
    }

    try {
      Fetch(
        `${urls.API_URL}/proposals/create`,
        {
          userId: user?.uid,
          analysisData,
        },
        { firebase },
        (response) => {
          if (openNewTab) {
            if (openTab.current) {
              openTab.current.opener = null
              openTab.current.location.href = `${urls.API_URL}/public-link/${user.uid}/${response.payload.aid}/`
            }
          } else {
            // DESCARGAR ARCHIVO
            const link = document.createElement("a")
            if (downloadType === "pdf") {
              setIsGettingPDF(false)
              link.href = response.payload.pdf_path
              link.setAttribute("download", "propuesta.pdf")
            }
            if (downloadType === "docs") {
              setIsGettingDocs(false)
              link.href = response.payload.docs_path
              link.setAttribute("download", "documentos-normativos.pdf")
            }
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
          }
          logAnalyticsEvent("publicar_propuesta", {
            proposal_method: openNewTab ? "Propuesta Web" : "Propuesta PDF",
            proposal_id: response?.payload?.aid || "-",
            username: `${user.name} ${user.lastname}`,
            company: user.company,
          })
          setIsGenerating(false)
          console.log("Análisis agregado con éxito. ID:", response.payload.aid)
        },
        true,
      )
    } catch (err) {
      Sentry.captureException(err);
      console.error("Error al agregar el análisis:", err)
    } finally {
      if (openNewTab && openTab.current) {
        openTab.current.focus()
      }
    }
  }

  return (
    <div className="w-full sticky top-14 h-14 px-8 grid grid-cols-[250px,_1fr] items-center border-t border-t-white dark:border-t-slate-900 z-10 bg-slate-50 dark:bg-slate-800">
      <div>
        <Link
          to="/"
          className="text-xs font-normal text-blue-500 dark:text-blue-400 hover:text-blue-400 dark:hover:text-blue-500 transition-colors duration-300 flex items-center gap-2"
        >
          <ArrowLeftIcon className="w-3 h-3" /> Volver
        </Link>
      </div>

      <div className="flex items-center justify-end">
        {showButtons && (
          <div className="flex items-center justify-end gap-4">
            <button
              type="button"
              className={`w-[155px] flex items-center justify-center gap-2 py-2 px-4 bg-blue-500 text-slate-50 text-xs font-normal text-center rounded-md hover:bg-blue-400 transition-colors duration-300 disabled:cursor-not-allowed
                  `}
              onClick={() => handlePublishAnalysis(true)}
              disabled={isGenerating}
            >
              <LinkIcon className="w-4 h-4" /> Propuesta WEB
            </button>
            <button
              type="button"
              className={`w-[155px] flex items-center justify-center gap-2 py-2 px-4 bg-blue-500 text-slate-50 text-xs font-normal text-center rounded-md hover:bg-blue-400 transition-colors duration-300 disabled:cursor-not-allowed
                  `}
              onClick={() => handlePublishAnalysis(false, "pdf")}
              disabled={gettingPDf || isGenerating}
            >
              {gettingPDf ? (
                <>
                  <ClockIcon className="w-4 h-4 animate-slowSpin" />
                  Preparando PDF
                </>
              ) : (
                <>
                  <FolderArrowDownIcon className="w-4 h-4" /> Propuesta PDF
                </>
              )}
            </button>
            <button
              type="button"
              className="w-[155px] flex items-center justify-center gap-2 py-2 px-2 bg-transparent text-xs font-normal text-center rounded-md text-blue-500 dark:text-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-slate-200 dark:hover:bg-slate-700 transition-colors duration-300 disabled:cursor-not-allowed"
              onClick={() => handlePublishAnalysis(false, "docs")}
              disabled={gettingDocs || isGenerating}
            >
              {gettingDocs ? (
                <>
                  <ClockIcon className="w-4 h-4 animate-slowSpin" />
                  Generando
                </>
              ) : (
                <>
                  <FolderArrowDownIcon className="w-4 h-4" /> Documentos
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

AnalysisNav.propTypes = {
  showButtons: PropTypes.bool,
}
AnalysisNav.defaultProps = {
  showButtons: true,
}

export default AnalysisNav
