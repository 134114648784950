import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    portfolioA: {
      portfolioName: "Portafolio A",
    },
    portfolioB: {
      portfolioName: "Portafolio B",
    },
  },
  defaultData: {
    portfolioA: {
      portfolioName: "Portafolio A",
    },
    portfolioB: {
      portfolioName: "Portafolio B",
    },
  },
}

export const PortfoliosNameSlice = createSlice({
  name: "colorScheme",
  initialState,
  reducers: {
    updatePortfoliosName: (state, action) => {
      state.data = action.payload
    },
    resetPortfoliosName: (state) => {
      state.data = state.defaultData
    },
  },
})

export const { updatePortfoliosName } = PortfoliosNameSlice.actions

export default PortfoliosNameSlice.reducer
