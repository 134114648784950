import { useState } from "react";

// HOOKS
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// COMPONENTS
import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";

// SCHEMA
export const registerSchema = z
  .object({
    name: z.string().trim().min(3, {
      message: "Por favor ingresa tu nombres",
    }),
    last_name: z.string().trim().min(3, {
      message: "Por favor ingresa tu nombres",
    }),
    email: z.string().trim().email({
      message: "Por favor ingresa un correo válido",
    }),
    password: z.string().min(8, {
      message: "La contraseña debe tener como mínimo 8 carácteres.",
    }),
    confirmPassword: z.string().min(8, {
      message: "La contraseña debe tener como mínimo 8 carácteres.",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Las contraseñas no coinciden",
  });

function RegisterPage({ user }) {
  // STATE
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // FORM
  const form = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  // CALLBACKS
  const onSubmit = (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      console.log("INFO REGISTRO: ", formData);
      console.log("ENVIAR A ENDPOINT SIN VALIDACION DE TOKEN");
    } catch (error) {
      console.log("ERROR: ", error);
      console.log("ENVIAR EL ERROR A SENTRY.");
      setError("MENSAJE SIGNIFICATIVO");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthPageFrame user={user} title="Registro">
      {error && <p>Hay un error, avisar con un mensaje más encachao.</p>}
      <div className="space-y-5">
        <p>Registro</p>
      </div>
    </AuthPageFrame>
  );
}

export default RegisterPage;
