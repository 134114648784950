import { useDispatch, useSelector } from "react-redux"
import { updateAnalysisDataConfig } from "../../../store/analysisDataConfigSlice"
import useTracking from "../../../hooks/useTracking"

function AnalysisDisclaimer() {
  // TRACK
  const trackEvent = useTracking()
  // STORE
  const dispatch = useDispatch()
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)

  const maxLength = 2000 // Límite estático de caracteres

  // CALLBACKS
  const handleChange = (e) => {
    let { value } = e.target
    if (value.length > maxLength) {
      value = value.slice(0, maxLength)
    }
    trackEvent({
      action: "ADD_DISCLAIMER_ANALYSIS_PAGE",
      timestamp: Date.now(),
      type: "input",
      data: value,
    })
    dispatch(
      updateAnalysisDataConfig({
        ...analysisDataConfig.data,
        disclaimer: value,
      }),
    )
  }

  return (
    <div id="disclaimer" className="relative w-full space-y-2">
      <textarea
        name="disclaimer"
        onChange={handleChange}
        value={analysisDataConfig.data.disclaimer}
        placeholder="Disclaimers y notas adicionales..."
        className="w-full px-2 py-1 h-24 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500 resize-none"
        maxLength={maxLength}
      />
      <p className="text-xs">La información es provista por LVA Índices en base a datos publicados en www.cmfchile.cl, www.bolsadesantiago.com y modelos propietarios de renta fija de LVA Indices para los depósitos a plazo. LVA Índices no ofrece garantías, no asume responsabilidades sobre la información o por las decisiones de inversión adoptadas por terceros. Los valores calculados son propiedad de LVA Índices.</p>
    </div>
  )
}

export default AnalysisDisclaimer
