import { useContext } from "react"
import { useSelector, useDispatch } from "react-redux"

// STORE
import { updateColorScheme } from "../store/colorSchemeSlice"

// CONTEXT
import { FirebaseContext } from "../firebase"

// HOOKS
import { Fetch } from "./useFetch"
import useTracking from "./useTracking"
// SENTRY
import * as Sentry from "@sentry/browser";

const useColorScheme = () => {
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  // STORE
  const dispatch = useDispatch()
  const colorScheme = useSelector((state) => state.colorScheme.value)
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((store) => store.urls)
  // HOOKS
  const trackEvent = useTracking()

  const toggleColorScheme = () => {
    const newScheme = colorScheme === "dark" ? "light" : "dark"

    // UPDATE PREFERENCE IN FIREBASE
    try {
      Fetch(
        `${urls.API_URL}/advisors/${user?.uid}/update_color_scheme`,
        {
          userId: user?.uid,
          newScheme: newScheme,
        },
        { firebase },
        () => {
          trackEvent({
            action: "CHANGE_COLOR_SCHEME",
            timestamp: Date.now(),
            type: "CLICK",
            data: newScheme,
          })
        },
      )
    } catch (err) {
      Sentry.captureException(err);
      console.error("Error al cambiar campo:", err)
    }

    // TODO: SAVE TO USER FIREBASE
    dispatch(updateColorScheme(newScheme))
  }

  return [colorScheme, toggleColorScheme]
}

export default useColorScheme
