/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useNavigate, useLocation } from "react-router-dom"
import {
  ExclamationTriangleIcon,
  ClipboardDocumentIcon,
  ClockIcon,
} from "@heroicons/react/24/outline"

// ROUTES
import { useSelector, useDispatch } from "react-redux"
import * as ROUTES from "../../../../routes/APP_ROUTES"

// HOOKS
// import usePreviewPanel from "../../PreviewPanel/usePreviewPanel"
import useTracking from "../../../../hooks/useTracking"
import usePrefetchChartsData from "../../../../hooks/usePrefetchChartsData"
import useCatalogByFunds from "../../../../hooks/useCatalogByFunds"

// STORE
import { setPortfolioSelectionWithValues } from "../../../../store/portfolioSelectionSlice"
import { updateAnalysisDataConfig } from "../../../../store/analysisDataConfigSlice"
import { toggleProposalType } from "../../../../store/proposalTypeSlice"
import { setPortfolioComposition } from "../../../../store/portfolioCompositionSlice"

function MyAnalysisUseTemplate({ analysis }) {
  // STORE
  const dispatch = useDispatch()
  const portfolioSelection = useSelector((state) => state.portfolioSelection)
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const rawCatalog = useSelector((state) => state.rawCatalog.data)
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data)

  // REFS
  const newAnalysisDataConfig = useRef({
    ...analysisDataConfig.data,
    title: analysis?.title,
    clientName: analysis?.client_name,
    clientInvestmentType: analysis?.client_investment_type,
    clientProfile: analysis?.client_profile,
    objective: analysis?.objective,
    comments: analysis?.comment,
    description: analysis?.description,
    url: analysis?.url,
    address: analysis?.address,
    phone: analysis?.phone,
    disclaimer: analysis?.disclaimer,
  })

  // HOOKS
  const trackEvent = useTracking()
  const location = useLocation()
  const navigate = useNavigate()
  const { setCatalogsByFunds } = useCatalogByFunds()
  // const { getPortfolioInfo } = usePreviewPanel()
  const { instancesCreated, prefetchChartsData } = usePrefetchChartsData()
  // STATE
  const [showModal, setShowModal] = useState(false)
  const [isPrefetching, setIsPrefetching] = useState(false)

  const getFundInfo = (fid) => {
    const returnData = {
      fundProviderId: null,
      prospectusCode: null,
      fundType: null, // SI VIENE NULL. QUIERE DECIR QUE EL FONDO YA NO EXISTE. Y HAY QUE SACARLO DEL PORTAFOLIO
    }
    const fundData = rawCatalog.find((serie) => serie.run === String(fid))
    if (fundData) {
      returnData.fundProviderId = fundData.fund_provider_id
      returnData.prospectusCode = fundData.prospectusCode
      returnData.fundType = fundData.fund_type
    }

    return returnData
  }

  const getPortfolioSelectionData = () => {
    const newPortfolios = {}

    Object.keys(analysis?.portfolios).forEach((portfolioKey) => {
      const newPortfolioFunds = []
      analysis.portfolios[portfolioKey].portfolioFunds.forEach((fund) => {
        const fundProviderId =
          "fundProviderId" in fund
            ? fund.fundProviderId
            : getFundInfo(fund.fundId).fundProviderId
        const prospectusCode =
          "prospectusCode" in fund
            ? fund.prospectusCode
            : getFundInfo(fund.fundId).prospectusCode
        const fundType =
          "fundType" in fund ? fund.fundType : getFundInfo(fund.fundId).fundType

        const newFund = {
          amount: fund.amount,
          fundId: +fund.fundId,
          fund_name: fund.fund_name,
          shareClassName: fund.shareClassName,
          weight: fund.weight,
          fundType,
          fundProviderId,
          prospectusCode,
        }
        if (fundType) newPortfolioFunds.push(newFund)
      })
      newPortfolios[portfolioKey] = {
        ...analysis.portfolios[portfolioKey],
        ...{ portfolioFunds: newPortfolioFunds },
      }
    })

    return {
      ...portfolioSelection,
      data: newPortfolios,
      activePortfolio: "portfolioA",
      portfolioBEnabled: true,
      distributionOption: "weight",
      baseAmount: 0,
    }
  }

  const buildPortfolioCompositions = (newPortfolioSelectionData) => {
    const distributionOption = "weight"
    const portfolioAFundList =
      newPortfolioSelectionData.data.portfolioA.portfolioFunds
    const portfolioBFundList =
      newPortfolioSelectionData.data.portfolioB.portfolioFunds

    const sumPortfolioAmount = (list) =>
      list.reduce((acc, curr) => acc + curr.amount, 0)
    const buildStructure = (list) => {
      const result = []
      const totalAmount = sumPortfolioAmount(list)
      list.forEach((fund) => {
        const fundAmount = fund?.amount
        const fundWeight = fund?.weight
        let composition = {}
        if (distributionOption === "weight") {
          composition = {
            weight: {
              weight: fundWeight,
              amount: fundAmount,
            },
            amount: {
              amount: 100,
              weight: 100 / list.length,
            },
          }
        }
        if (distributionOption === "amount") {
          composition = {
            weight: {
              weight: 100 / list.length,
              amount: 100,
            },
            amount: {
              amount: fundAmount,
              weight: fundWeight,
            },
          }
        }
        result.push({
          fundId: fund?.fundId,
          fund_name: fund?.fund_name,
          shareClassName: fund?.shareClassName,
          fundProviderId: fund?.fundProviderId,
          prospectusCode: fund?.prospectusCode,
          fundType: fund?.fundType,
          composition,
        })
      })
      return result
    }

    const portfolioAComposition =
      portfolioAFundList.length > 0 ? buildStructure(portfolioAFundList) : []
    const portfolioBComposition =
      portfolioBFundList.length > 0 ? buildStructure(portfolioBFundList) : []

    return {
      portfolioA: portfolioAComposition,
      portfolioB: portfolioBComposition,
    }
  }

  // LIFECYCLE
  useEffect(() => {
    if (instancesCreated) {
      // BUILD PORTFOLIO SELECTION
      const newPortfolioSelectionData = getPortfolioSelectionData()

      // DISPATCH
      const newPortfolioCompositions = buildPortfolioCompositions(
        newPortfolioSelectionData,
      )
      setCatalogsByFunds()
      dispatch(setPortfolioSelectionWithValues(newPortfolioSelectionData))
      dispatch(setPortfolioComposition(newPortfolioCompositions))
      dispatch(updateAnalysisDataConfig(newAnalysisDataConfig.current))

      setShowModal(false)
      trackEvent({
        action: "USE_TEMPLATE",
        timestamp: Date.now(),
        type: "SWITCH",
        data: "ID PROPUESTA",
      })
      setIsPrefetching(false)
      // AL FINALIZAR REDIRECCIONAR AL ANALYSIS
      if (location.pathname !== ROUTES.ANALYSIS) {
        navigate(ROUTES.ANALYSIS)
      }
    }
  }, [instancesCreated])

  const prefetchDataForReplace = () => {
    const prefetchPortfolios = analysis?.portfolios
    const prefetchIsAPV = !!analysis?.is_apv
    const prefetchDistributionOption = "weight"
    const prefetchBaseAmount = null

    // When prefetchChartsData is done, it will change the satte of intancesCreated
    prefetchChartsData(
      prefetchPortfolios,
      prefetchIsAPV,
      prefetchDistributionOption,
      prefetchBaseAmount,
    )
  }

  const handleClick = () => {
    setShowModal(true)
  }

  useEffect(() => {
    if (isPrefetching) prefetchDataForReplace()
  }, [catalogByFunds])

  useEffect(() => {
    const isApvProposal = analysis.is_apv ? analysis.is_apv : false
    if (isAPV === isApvProposal && isPrefetching) {
      setCatalogsByFunds()
    }
  }, [isAPV, isPrefetching])

  const handleConfirm = () => {
    // SI LA PROPUESTA ES APV, SETEAR EL UNIVERSO PRIMERO.
    const isApvProposal = analysis.is_apv ? analysis.is_apv : false
    dispatch(toggleProposalType(isApvProposal))
    setIsPrefetching(true)
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  return (
    <div className="lg:flex items-center gap-1">
      <button onClick={() => handleClick()}>
        <ClipboardDocumentIcon className="w-4 h-4" />
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[90] outline-none focus:outline-none isolate">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              <div className="px-8 py-10 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent outline-none focus:outline-none space-y-8">
                <div className="flex flex-col items-center justify-start gap-0">
                  <ExclamationTriangleIcon className="w-10 h-10 text-blue-500" />
                  <h3 className="text-xl font-semibold">Información</h3>
                </div>

                {!isPrefetching ? (
                  <>
                    <p className="text-center text-sm">
                      Vas a utilizar la información de esta propuesta para crear
                      una nueva.
                    </p>

                    <div className="flex flex-col items-center justify-start gap-1border-t border-solid border-slate-100 dark:border-slate-700 rounded-b space-y-2">
                      <button
                        className="w-full rounded-md leading-0 px-3 py-2 text-xs text-center uppercase transition-colors duration-300 ease-in-out text-slate-50 bg-blue-500 hover:bg-blue-400"
                        type="button"
                        disabled={isPrefetching}
                        onClick={() => handleConfirm()}
                      >
                        Confirmar
                      </button>
                      <button
                        className="w-full rounded-md leading-0 px-3 py-2 text-xs text-center uppercase transition-colors duration-300 ease-in-out text-blue-500 dark:text-blue-400 hover:underline"
                        type="button"
                        disabled={isPrefetching}
                        onClick={() => handleCancel()}
                      >
                        Cancelar
                      </button>
                    </div>
                  </>
                ) : (
                  <p className="text-center text-sm flex items-center gap-2">
                    <ClockIcon className="w-5 h-5 animate-slowSpin" /> Estamos
                    generando tu nueva propuesta
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-white dark:bg-slate-900" />
        </>
      ) : null}
    </div>
  )
}
MyAnalysisUseTemplate.propTypes = {
  analysis: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default MyAnalysisUseTemplate
