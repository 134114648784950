import { useState } from "react"
import PropTypes from "prop-types"
import { CameraIcon, Cog6ToothIcon } from "@heroicons/react/24/solid"

const DEFAULT_TITLE = "Guardar una captura de esta sección"

function ScreenshotButton({ takeScreenshotHandler }) {
  const [isLoading, setIsLoading] = useState(false)
  const [title, setTitle] = useState(DEFAULT_TITLE)

  const handleClick = () => {
    setIsLoading(true)
    setTitle("Creando imagen de la sección")
    takeScreenshotHandler()
    setTimeout(() => {
      setIsLoading(false)
      setTitle(DEFAULT_TITLE)
    }, 1000)
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="relative group border-none rounded-md w-8 h-8 grid place-content-center text-blue-500 dark:text-blue-400
      hover:text-blue-400 dark:hover:text-blue-500 transition-colors duration-500
      "
    >
      {!isLoading ? (
        <CameraIcon className="w-4 h-4" />
      ) : (
        <Cog6ToothIcon className="w-4 h-4 animate-slowSpin" />
      )}
      <span className="absolute left-1/2 transform -translate-x-1/2 -translate-y-[4px] bottom-full mb-2 px-2 py-1 text-xs text-slate-900 dark:text-slate-50 bg-slate-300 dark:bg-slate-700 rounded hidden group-hover:block transition-opacity duration-200 w-28">
        {title}
      </span>
    </button>
  )
}

ScreenshotButton.propTypes = {
  takeScreenshotHandler: PropTypes.func.isRequired,
}

export default ScreenshotButton
