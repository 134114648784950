/* eslint-disable react/prop-types */

import { formatToFloat, formatDataInfoDate } from "../utilities/functions"

function FundDetailsPerformanceBySerie({
  getColDistribution,
  dataShareClass,
  displaySeries,
  tableData,
}) {
  return (
    <div className={getColDistribution}>
      <header className="mb-5">
        <h2 className="overview-section-subtitle !mb-0">
          Rentabilidad por series
        </h2>
        <p className="text-xs text-slate-500 dark:text-slate-400">
          Al {formatDataInfoDate(dataShareClass.returnsDate)}
        </p>
      </header>
      <div className="flex flex-col gap-5">
        <div className="table-container">
          <table className="table-frame">
            <thead className="table-head">
              <tr className="border-b border-slate-300 dark:border-slate-600">
                <th
                  scope="col"
                  className="pb-1 text-left font-light w-[80px] sticky left-0 z-10 bg-slate-50 dark:bg-slate-800"
                >
                  Período
                </th>
                {displaySeries.map((serie) => (
                  <th
                    key={serie}
                    scope="col"
                    className={`pb-1 font-light w-[70px]
                          ${
                            displaySeries.length > 1
                              ? "text-right"
                              : "text-center"
                          }
                          ${
                            dataShareClass.shareClassName === serie
                              ? "text-blue-500 dark:text-blue-400"
                              : ""
                          }`}
                  >
                    {`Serie ${serie}`}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(tableData).map((period) => (
                <tr
                  key={period}
                  className="border-b border-slate-300 dark:border-slate-600"
                >
                  <td className="py-2 text-xs sticky left-0 z-10 bg-slate-50 dark:bg-slate-800">
                    {period}
                  </td>
                  {tableData[period].map((element) => (
                    <td
                      key={`${period}-${Object.keys(element)[0]}`}
                      className={`py-2 text-xs tabular-nums
                            ${
                              displaySeries.length > 1
                                ? "text-right"
                                : "text-center"
                            }
                            ${
                              dataShareClass.shareClassName ===
                              Object.keys(element)[0]
                                ? "text-blue-500 dark:text-blue-400"
                                : "text-slate-900 dark:text-slate-50"
                            }`}
                    >
                      {formatToFloat(Object.values(element)[0]) || "-"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default FundDetailsPerformanceBySerie
