// SENTRY
import * as Sentry from "@sentry/browser";

const windowsSizeMapping = {
  91: "3 meses",
  182: "6 meses ",
  365: "12 meses",
  730: "24 meses",
}

const formatPortfolioMaxMinProfitAndLossesToChart = (
  portfoliosMaxMinProfitAndLosses,
  base,
) => {
  try {
    const maxMinProfitLossesChartData = []

    Object.keys(windowsSizeMapping).forEach((windowSize) => {
      const windowsData = {
        label: windowsSizeMapping[windowSize],
        portfolios: [],
        // total: base,
      }
      const pData = []
      Object.keys(portfoliosMaxMinProfitAndLosses).forEach((portfolioName) => {
        pData.push({
          max:
            portfoliosMaxMinProfitAndLosses[portfolioName][windowSize].max !==
            undefined
              ? portfoliosMaxMinProfitAndLosses[portfolioName][windowSize].max
              : "-",
          min:
            portfoliosMaxMinProfitAndLosses[portfolioName][windowSize].min !==
            undefined
              ? portfoliosMaxMinProfitAndLosses[portfolioName][windowSize].min
              : "-",
          name: portfolioName,
          total: base[portfolioName],
        })
      })
      windowsData.portfolios = pData
      maxMinProfitLossesChartData.push(windowsData)
    })
    return maxMinProfitLossesChartData
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

const formatShareClassMaxMinProfitAndLossesToChart = (
  shareClassMaxMinProfitAndLosses,
  shareClassId,
) => {
  try {
    const maxMinProfitLossesChartData = []

    Object.keys(windowsSizeMapping).forEach((windowSize) => {
      const windowsData = {
        label: windowsSizeMapping[windowSize],
        portfolios: [],
      }
      const pData = []
      pData.push({
        max:
          shareClassMaxMinProfitAndLosses[windowSize].max !== undefined
            ? shareClassMaxMinProfitAndLosses[windowSize].max
            : "-",
        min:
          shareClassMaxMinProfitAndLosses[windowSize].min !== undefined
            ? shareClassMaxMinProfitAndLosses[windowSize].min
            : "-",
        name: shareClassId,
      })
      windowsData.portfolios = pData
      maxMinProfitLossesChartData.push(windowsData)
    })
    return maxMinProfitLossesChartData
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

export {
  windowsSizeMapping,
  formatPortfolioMaxMinProfitAndLossesToChart,
  formatShareClassMaxMinProfitAndLossesToChart,
}
