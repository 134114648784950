import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"

// CONSTANTS
import * as ROUTES from "../../../routes/APP_ROUTES"

function AccountUserCreatedResult({
  userName,
  userLastname,
  userEmail,
  userPsw,
}) {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-start justify-start gap-5">
      <div className="flex items-center gap-2">
        <h1 className="text-base">
          La cuenta de <strong>{`${userName} ${userLastname}`}</strong> está
          creada.
        </h1>
      </div>
      <div className="flex flex-col gap-5">
        <div className="border border-slate-600 p-5 rounded-lg">
          <section className="mb-4">
            <h3 className="text-md">Datos de inicio de sesión</h3>
            <p className="text-xs text-slate-400">
              Estos datos debes entregarlos al cliente para que pueda ingresar a
              la plataforma con su nueva cuenta.
            </p>
            <p className="text-xs text-slate-400">
              <strong>Importante:</strong>
            </p>
            <p className="text-xs text-slate-400">
              - La contraseña a continuación debe ser considerada{" "}
              <em>temporal</em> y el usuario debe actualizarla la primera vez
              que ingrese.
            </p>
            <p className="text-xs text-slate-400">
              - Si sales de esta página sin guardar o enviar la contraseña, no
              podrás acceder a ella nuevamente.
            </p>
          </section>
          <div className="w-[300px] mx-auto relative mb-2 px-3 py-2 bg-slate-800 rounded-md">
            <span className="text-sm mb-1">Correo electrónico:</span>
            <p className="text-base font-semibold">{`${userEmail}`}</p>
          </div>
          <div className="w-[300px] mx-auto relative mb-2 px-3 py-2 bg-slate-800 rounded-md">
            <span className="text-sm mb-1">Contraseña:</span>
            <p className="text-base font-semibold">{userPsw}</p>
          </div>
        </div>
        <div className="w-full flex items-center justify-end gap-2">
          <Link
            to={ROUTES.USERS}
            className="w-full text-sm grid place-content-center border border-slate-600 text-blue-500 bg-transparent rounded-lg px-4 py-3 hover:bg-blue-500 hover:text-slate-900"
          >
            Volver a lista de usuarios
          </Link>
          <button
            type="button"
            onClick={() => navigate(0)}
            className="w-full text-sm grid place-content-center border border-slate-600 text-blue-500 bg-transparent rounded-lg px-4 py-3 hover:bg-blue-500 hover:text-slate-900"
          >
            Crear nuevo usuario
          </button>
        </div>
      </div>
    </div>
  )
}

AccountUserCreatedResult.propTypes = {
  userName: PropTypes.string.isRequired,
  userLastname: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userPsw: PropTypes.string.isRequired,
}

export default AccountUserCreatedResult
