import PropTypes from "prop-types"

// UTILS
import { displayString, formatToFloat } from "../ui/utilities/functions"
import addOpacityToHexColor from "../../utils/addOpacityToHexColor"

// HOOKS
import useColorScheme from "../../hooks/useColorScheme"

function IssuersTable({ title, issuers, color, maxIssuers }) {
  const [colorScheme] = useColorScheme()
  const data = maxIssuers ? issuers.slice(0, maxIssuers) : issuers

  return (
    <div className="inline-block w-full align-middle">
      <div
        className={`w-full p-2 mb-4 overflow-x-hidden rounded-sm
          ${colorScheme === "dark" ? "bg-slate-800" : ""}
        `}
        style={{
          backgroundColor:
            colorScheme === "light" ? addOpacityToHexColor(color, 0.3) : null,
        }}
      >
        <div className="flex items-center gap-2">
          <span
            className="text-base w-4 h-4 rounded-sm grid place-content-center"
            style={{ backgroundColor: color }}
          />
          <p className="text-slate-900 dark:text-slate-50 text-sm font-semibold">
            {title}
          </p>
        </div>
      </div>

      {data.map((issuer, index) => (
        <div
           
          key={`${issuer.label}-${index}`}
          className="flex justify-between items-center text-xs"
        >
          <p className="pb-3 pr-2">{displayString(issuer.label)}</p>
          <p className="pb-3 text-right tabular-nums">
            {formatToFloat(+issuer.value)}
          </p>
        </div>
      ))}
    </div>
  )
}

IssuersTable.propTypes = {
  title: PropTypes.string.isRequired,
  issuers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  color: PropTypes.string.isRequired,
  maxIssuers: PropTypes.number,
}
IssuersTable.defaultProps = {
  maxIssuers: null,
}

export default IssuersTable
