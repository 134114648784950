import PropTypes from "prop-types"

// SHAPES
export const PanelOverviewShape = {
  avg_credit_quality: PropTypes.number,
  benckmark: PropTypes.string,
  bond_style_box: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  category: PropTypes.string,
  corporate_sustainability_score: PropTypes.number,
  currency: PropTypes.string,
  domicile: PropTypes.string,
  duration: PropTypes.number,
  envorinmental_score: PropTypes.number,
  equity_style_box: PropTypes.number,
  expense_ratio: PropTypes.number,
  fund_provider: PropTypes.string,
  governance_score: PropTypes.number,
  h_corporate_sustainability_score: PropTypes.number,
  h_sovereign_sustainability_score: PropTypes.number,
  inception_date: PropTypes.string,
  initial_purchase: PropTypes.number,
  investment_strategy: PropTypes.string,
  isin: PropTypes.string,
  management_fee: PropTypes.number,
  minimum_investment: PropTypes.string,
  minimum_investment_clp: PropTypes.number,
  ms_sustainability_rating: PropTypes.number,
  portfolio_managers: PropTypes.arrayOf(
    PropTypes.shape({
      DisplayPreference: PropTypes.number,
      FamilyName: PropTypes.string,
      GivenName: PropTypes.string,
      ManagerId: PropTypes.number,
      ManagerProvidedBiography: PropTypes.string,
      StartDate: PropTypes.string,
    }),
  ),
  social_score: PropTypes.number,
  sovereign_sustainability_score: PropTypes.number,
  aum: PropTypes.number,
  unallocated_score: PropTypes.number,
}

// ALLOCATIONS
export const AllocationDataArray = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
)
export const AllocationValuesShape = {
  name: PropTypes.string,
  data: AllocationDataArray,
}
export const PanelAllocationsShape = {
  allocations_date: PropTypes.object,
  values: PropTypes.objectOf(PropTypes.shape(AllocationValuesShape)),
}

// RISK STATISTICS
export const PanelRisksStatisticsShape = {
  columns: PropTypes.arrayOf(PropTypes.string),
  risks_date: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      "1 Year": PropTypes.number,
      "3 Years": PropTypes.number,
      "5 Years": PropTypes.number,
      Measure: PropTypes.string,
    }),
  ),
}

// RISK RATINGS
export const PanelRisksRatingsShape = {
  columns: PropTypes.arrayOf(PropTypes.string),
  ratings_date: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      "3 Years": PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Overall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Period: PropTypes.string,
    }),
  ),
}

// RETURNS
export const PanelTrailingReturnsShape = {
  columns: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      Category: PropTypes.number,
      Fund: PropTypes.number,
      "Time Period": PropTypes.string,
    }),
  ),
  trailing_date: PropTypes.string,
}

// PERFORMANCE
export const performanceShapeArray = PropTypes.arrayOf(
  PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        formattedDate: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    name: PropTypes.string,
  }),
)
export const PanelPerformanceShape = {
  "1 Year": performanceShapeArray,
  "2 Years": performanceShapeArray,
  "3 Years": performanceShapeArray,
  "5 Years": performanceShapeArray,
  "10 Years": performanceShapeArray,
}

// FUND DATA SHAPE ( Also known as rowData)
export const FundDataShape = {
  asset_class: PropTypes.string,
  fund_provider_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string,
  run: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  serie: PropTypes.string,
  fund_name: PropTypes.string,
  ret_6m: PropTypes.number,
  ret_1y: PropTypes.number,
  ret_2y: PropTypes.number,
  ret_3y: PropTypes.number,
  aum: PropTypes.number,
}

// PROP TYPES
export const appFiltersProptypes = PropTypes.shape({
  asset_class: PropTypes.arrayOf(PropTypes.string).isRequired,
  category: PropTypes.arrayOf(PropTypes.string).isRequired,
  fund_provider: PropTypes.arrayOf(PropTypes.string).isRequired,
  aum: PropTypes.arrayOf(PropTypes.string).isRequired,
})

// DATA GRID CONFIG
export const dataGridRowConfigShape = PropTypes.shape({
  align: PropTypes.string,
  cellKey: PropTypes.string,
  justify: PropTypes.string,
  name: PropTypes.string,
  sortable: PropTypes.bool,
  span: PropTypes.string,
})
export const dataGridConfigProptypes = PropTypes.arrayOf(dataGridRowConfigShape)

// PANEL DATA
export const panelDataPropTypes = PropTypes.shape({
  status: PropTypes.string,
  overview: PropTypes.shape(PanelOverviewShape),
  allocations: PropTypes.shape(PanelAllocationsShape),
  risks_statistics: PropTypes.shape(PanelRisksStatisticsShape),
  risks_ratings: PropTypes.shape(PanelRisksRatingsShape),
  trailing_returns: PropTypes.shape(PanelTrailingReturnsShape),
  performance: PropTypes.shape(PanelPerformanceShape),
})

export const appCatalogPropTypes = PropTypes.arrayOf(
  PropTypes.shape(FundDataShape),
)

export const userPropTypes = PropTypes.shape({
  uid: PropTypes.string,
  email: PropTypes.string,
  createdAt: PropTypes.string,
  creationTime: PropTypes.string,
  lastLoginAt: PropTypes.string,
  lastSignInTime: PropTypes.string,
  phoneNumber: PropTypes.string,
  photoURL: PropTypes.string,
  company: PropTypes.string,
  lastname: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
})

export const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
])

// MIN MAX BAR CHARTS
export const minMaxChartDataPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    portfolios: PropTypes.arrayOf(
      PropTypes.shape({
        max: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        min: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }),
)
export const minMaxChartDataOptions = PropTypes.shape({
  portfolioNumber: PropTypes.number,
  barGroupHeight: PropTypes.number,
  labelWidth: PropTypes.number,
  barLabelGap: PropTypes.number,
  barLabelEdgeOffset: PropTypes.number,
  barGroupVerticalGap: PropTypes.number,
  colorPalette: PropTypes.arrayOf(PropTypes.string),
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
})
export const minMaxChartConfig = PropTypes.shape({
  maxWidth: PropTypes.number.isRequired,
  labelWidth: PropTypes.number.isRequired,
  barGroupVerticalGap: PropTypes.number.isRequired,
  barGroupHeight: PropTypes.number.isRequired,
  innerWidth: PropTypes.number.isRequired,
  barGroupWidth: PropTypes.number.isRequired,
  barHeight: PropTypes.number.isRequired,
  innerHeight: PropTypes.number.isRequired,
  barLabelGap: PropTypes.number.isRequired,
})

export const tacDataPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    portfolio: PropTypes.string.isRequired,
    shareClasses: PropTypes.arrayOf(
      PropTypes.shape({
        fund_name: PropTypes.string.isRequired,
        shareclassName: PropTypes.string.isRequired,
        tac: PropTypes.number.isRequired,
        tacDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
    tac: PropTypes.number.isRequired,
  }),
)
