import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import verifyMajorMinorVersion from "../../../utils/verifyMajorMinorVersion";
import SETTINGS from "../../../settings/settings";
import {
  clearAllDataInStorage,
  getDataInStorage,
} from "../../../utils/CacheManager"
// SENTRY
import * as Sentry from "@sentry/browser";


export default function ModalVersion() {
  const clearInSettings = SETTINGS.CLEAR_SITE_DATA_ACCORDING_VERSION;

  const [showModal, setShowModal] = React.useState(false);

  // STORE
  const portfolioSelection = useSelector(
    (state) => state.portfolioSelection.data,
  );
  const isOverviewPanelOpen = useSelector(
    (state) => state.portfolioPanel.isOverviewOpen,
  );

  const verifyDataVersion = async (keyFund) => {
    let key = null;
    const keyOverviewPanelOpen =
      isOverviewPanelOpen && isOverviewPanelOpen.split("-")[1];
    if (keyFund) {
      key = keyFund;
    } else {
      key = keyOverviewPanelOpen;
    }
    try {
      const fund = await getDataInStorage({ key });
      const versionFund = fund?.version;
      return versionFund;
    } catch (error) {
      Sentry.captureException(error);
      console.error({ error })
    }
    return null;
  };

  const handlerVerifyReduxDataVersion = async () => {
    const { versionServer, updated } = await verifyMajorMinorVersion();
    portfolioSelection?.portfolioA?.portfolioFunds?.map(async (fund) => {
      const versionData = await verifyDataVersion(fund?.fundId);
      if (!versionData) {
        return null;
      }
      if (updated || versionData !== versionServer) {
        setShowModal(true);
        return null;
      }
      return null;
    });
    portfolioSelection?.portfolioB?.portfolioFunds?.map(async (fund) => {
      const versionData = await verifyDataVersion(fund?.fundId);
      if (!versionData) {
        return null;
      }
      if (updated || versionData !== versionServer) {
        setShowModal(true);
        return null;
      }
      return null;
    });
  };

  const handlerVerifyVersion = async () => {
    const versionData = await verifyDataVersion();
    const { versionServer, updated } = await verifyMajorMinorVersion();
    if (!versionData) {
      return null;
    }
    if (updated || versionData !== versionServer) {
      setShowModal(true);
      return null;
    }
    return null;
  };
  const handlerClearAllDataInStorage = async () => {
    setShowModal(false);
    clearAllDataInStorage();
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    if (isOverviewPanelOpen && clearInSettings) {
      handlerVerifyVersion();
    }
  }, [isOverviewPanelOpen]);
  useEffect(() => {
    if (clearInSettings) {
      handlerVerifyReduxDataVersion();
    }
  }, []);

  useEffect(() => {
    if (clearInSettings) {
      handlerVerifyReduxDataVersion();
    }
  }, [portfolioSelection]);

  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[90] outline-none focus:outline-none isolate">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/* content */}
              <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center  p-5 border-b border-solid border-slate-100 dark:border-slate-700 rounded-t gap-4">
                  <ExclamationCircleIcon className="w-5 h-5 text-blue-500 dark:text-blue-400" />
                  <h3 className="text-xl font-semibold">
                    Actualización disponible
                  </h3>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto bg-white dark:bg-slate-800 text-justify">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    ¡Buenas noticias! Hemos lanzado una actualización para
                    mejorar tu experiencia. En breve, te redirigiremos a la
                    página de inicio para que puedas disfrutar de las nuevas
                    funcionalidades y mejoras.
                  </p>
                  <p className="my-4 text-blueGray-500 text-xs leading-relaxed">
                    No te preocupes, todos tus datos están seguros. Solo
                    limpiaremos tus archivos temporales para asegurarnos de que
                    tengas acceso a la última versión con todas sus ventajas.
                    Este proceso es rápido y no afectará tu información
                    almacenada ni tu progreso.
                  </p>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-slate-100 dark:border-slate-700 rounded-b">
                  <button
                    className="rounded-full flex items-center leading-0 justify-between gap-5 px-3 py-1.5 text-xs transition-colors duration-500 ease-in-out text-slate-50 bg-blue-500 hover:bg-blue-400"
                    type="button"
                    onClick={() => handlerClearAllDataInStorage()}
                  >
                    Actualizar ahora
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-white dark:bg-slate-900" />
        </>
      ) : null}
    </div>
  );
}
