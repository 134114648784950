import { useState, useContext } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import { Fetch } from "../../../hooks/useFetch"
import { updateAnalysisDataConfig } from "../../../store/analysisDataConfigSlice"
import useTracking from "../../../hooks/useTracking"

import Tooltip from "../../libs/Tooltip"

import { FirebaseContext } from "../../../firebase"
import { updateUser } from "../../../store/userSlice"
// SENTRY
import * as Sentry from "@sentry/browser";


function OrientationButton({ title, type, orientation, cb }) {
  const getDimensions = () => {
    let dims = "w-5 h-3"
    if (type === "portrait") dims = "w-3 h-5"
    return dims
  }
  return (
    <Tooltip content={title}>
      <button
        type="button"
        title={title}
        onClick={() => cb(type)}
        className={`group shrink-0 w-8 h-8 border grid place-content-center rounded-md
            ${orientation === type
            ? "bg-blue-500 dark:bg-blue-400 border-transparent pointer-event-none"
            : "bg-transparent border-slate-300 dark:border-slate-700 pointer-event-auto hover:bg-slate-300 hover:dark:bg-slate-700"
          }
            `}
      >
        <span
          className={`inline-block ${getDimensions()} border rounded-sm group-hover:border-slate-slate-50
            ${orientation === type
              ? "border-slate-50"
              : "border-slate-400 dark:border-slate-600"
            }
            `}
        />
      </button>
    </Tooltip>
  )
}

function AnalysisOrientationSelector() {
  // TRACK
  const trackEvent = useTracking()
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  // STORE
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((state) => state.urls)
  const dispatch = useDispatch()
  // STATE
  const [orientation, setOrientation] = useState(
    user?.orientation || "portrait",
  )

  // FUNCTIONS
  const updateUserOrientation = (uid, orientation) => {
    try {
      Fetch(
        `${urls.API_URL}/advisors/${uid}/update_orientation`,
        {
          userId: user?.uid,
          orientation: orientation,
        },
        { firebase },
        () => {
          trackEvent({
            action: "CHANGE_PDF_ORIENTATION",
            timestamp: Date.now(),
            type: "CLICK",
            data: orientation,
          })
        },
      )
    } catch (err) {
      Sentry.captureException(err);
      console.error("Error al cambiar campo:", err)
    }
  }
  const updateAnalysisConfig = (selection) =>
    dispatch(
      updateAnalysisDataConfig({
        ...analysisDataConfig.data,
        ...{ orientation: selection },
      }),
    )

  const handleClick = (selection) => {
    const uid = user?.uid;
    // ACTUALIZAR PORTAFOLIO INFO
    updateAnalysisConfig(selection)
    // ACTUALIZAR USUARIO EN FIRESTORE
    updateUserOrientation(uid, selection)
    setOrientation(selection)
    // ACTUALIZAR REDUX DISPATCH NUEVA INFO EN EL USUARIO.
    dispatch(updateUser({
      ...user,
      ...{ orientation: selection },
    }))
  }

  return (
    <div className="space-y-2">
      <p className="text-xs text-slate-600 dark:text-slate-500">Orientación:</p>
      <div className="flex gap-2">
        <OrientationButton
          title="PDF Vertical"
          type="portrait"
          orientation={orientation}
          cb={handleClick}
        />
        <OrientationButton
          title="PDF Horizontal"
          type="landscape"
          orientation={orientation}
          cb={handleClick}
        />
      </div>
    </div>
  )
}

OrientationButton.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  cb: PropTypes.func.isRequired,
}

export default AnalysisOrientationSelector
