import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {},
}

export const CatalogByFundsSlice = createSlice({
  name: "catalogByFunds",
  initialState,
  reducers: {
    updateCatalogByFunds: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { updateCatalogByFunds } = CatalogByFundsSlice.actions

export default CatalogByFundsSlice.reducer
