import { flatRollup, sort, rollup } from "d3";

const usePrepareProposalStatisticsData = () => {
  // PARSING METHODS
  // const formatDate = (d) => {
  //   const year = d.getUTCFullYear()
  //   const month = String(d.getUTCMonth() + 1).padStart(2, "0")
  //   const day = String(d.getUTCDate()).padStart(2, "0")
  //   // SHOW BY DAY
  //   return `${year}-${month}-${day}`
  // }
  // GENERAL INFO
  const totalProposals = (data) => data.length;
  const totalClients = (data) =>
    flatRollup(
      data,
      (v) => v.length,
      (d) => d.advisor_id,
    ).length;
  const totalCompanies = (data) =>
    flatRollup(
      data,
      (v) => v.length,
      (d) => d.advisor_company.id,
    ).length;
  const proposalsByDistribution = (data) =>
    flatRollup(
      data,
      (v) => v.length,
      (d) => d.distribution,
    );
  const getProposalsGeneralStatistics = (data) => {
    let maxSum = -Infinity;
    let minSum = Infinity;
    let totalSum = 0;
    let maxProposal = null;
    let minProposal = null;

    data.forEach((proposal) => {
      const { portfolios } = proposal;
      const totalAmount =
        portfolios.portfolioA.portfolioFunds.reduce(
          (sum, fund) => sum + fund.amount,
          0,
        ) +
        portfolios.portfolioB.portfolioFunds.reduce(
          (sum, fund) => sum + fund.amount,
          0,
        );

      // PROPOSAL WITH MAXIMUM AMOUNT
      if (totalAmount > maxSum) {
        maxSum = totalAmount;
        maxProposal = proposal;
      }
      // MINIMUM AMOUNT
      if (totalAmount < minSum) {
        minSum = totalAmount;
        minProposal = proposal;
      }
      // ACCUMULATE THE SUM
      totalSum += totalAmount;
    });

    // CALCULATE AVERAGE
    const averageSum = totalSum / data.length;
    return {
      maxProposal,
      maxAmount: maxSum,
      minProposal,
      minAmount: minSum,
      averageAmount: averageSum,
    };
  };

  // PROCCESS DATA METHODS
  const getFlatRollupForAdvisors = (data) => {
    // console.log("RollData: ", data)
    const rollup = flatRollup(
      data,
      (v) => v.length,
      (d) => d.advisor_id,
    );
    return sort(
      rollup.map((roll) => {
        const needle = data.find((proposal) => proposal.advisor_id === roll[0]);
        return [
          ...roll,
          `${needle.advisor_name} ${needle.advisor_lastname}`,
          needle.advisor_company,
        ];
      }),
      (d) => d[1],
    ).reverse();
  };

  const getFlatRollupForCompanies = (data) => {
    const rollup = flatRollup(
      data,
      (v) => v.length,
      (d) => d.advisor_company.id,
      (d) => d.advisor_company.name,
    );
    return sort(rollup, (d) => d[2]).reverse();
  };

  const getRollupForProposals = (data) => {
    const groupedData = rollup(
      data,
      (v) => v.length,
      (d) => d.date,
    );
    return sort(groupedData, (d) => d[0]);
  };

  const getAdvisorList = (proposalData, excludeLVA) => {
    // console.log(proposalData);
    const advisorMap = new Map();
    const companiesMap = new Map();

    proposalData.forEach((analysis) => {
      const { advisor_id, advisor_name, advisor_lastname, advisor_company } =
        analysis;

      if (excludeLVA && advisor_company.id !== "15") {
        if (!advisorMap.has(advisor_id)) {
          advisorMap.set(advisor_id, {
            advisor_id,
            advisor_name,
            advisor_lastname,
            advisor_company,
            total_proposal: 1,
          });
        } else {
          const advisorInfo = advisorMap.get(advisor_id);
          advisorInfo.total_proposal += 1;
          advisorMap.set(advisor_id, advisorInfo);
        }
        if (!companiesMap.has(advisor_company.id)) {
          companiesMap.set(advisor_company.id, {
            company_id: advisor_company.id,
            company_name: advisor_company.name,
            total_proposal: 1,
          });
        } else {
          const companyInfo = companiesMap.get(advisor_company.id);
          companyInfo.total_proposal += 1;
          companiesMap.set(advisor_company.id, companyInfo);
        }
      }
    });

    return {
      advisors: Array.from(advisorMap.values()),
      companies: Array.from(companiesMap.values()),
    };
  };

  const getAdvisorName = (id, proposalData) => {
    const advisor = proposalData.find((u) => u.advisor_id === id);
    if (advisor) {
      return `${advisor.advisor_name} ${advisor.advisor_lastname}`;
    } else {
      return "Sin información";
    }
  };

  const getCompanyName = (id, proposalData) => {
    const advisor_data = proposalData.find((u) => u.advisor_company.id === id);
    if (advisor_data) {
      return advisor_data.advisor_company.name;
    } else {
      return "Sin información";
    }
  };

  return {
    totalProposals,
    totalClients,
    totalCompanies,
    proposalsByDistribution,
    getProposalsGeneralStatistics,
    getFlatRollupForAdvisors,
    getFlatRollupForCompanies,
    getAdvisorList,
    getAdvisorName,
    getCompanyName,
    getRollupForProposals,
  };
};

export default usePrepareProposalStatisticsData;
