import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
// STORE
import { useDispatch, useSelector } from "react-redux";
import { updatePortfoliosName } from "../../../store/portfoliosNameSlice";

// HOOKS
import useColorCompany from "../../../hooks/useColorCompany";
import usePreviewPanel from "../PreviewPanel/usePreviewPanel";
import useColorScheme from "../../../hooks/useColorScheme";

// COMPONENTS
import AnalysisScreenerFund from "./AnalysisScreenerFund";
import AnalysisPortfolioTotals from "./AnalysisPortfolioTotals";

// UTILS
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor";
import { formatAmount, formatToFloat } from "../utilities/functions";

function AnalysisScreenerPortfolio({ portfolioKey, modalInfo }) {
  // HOOKS
  const { getColorPortfolio } = useColorCompany();
  const { getPortfolioTotal } = usePreviewPanel();
  const [colorScheme] = useColorScheme();
  // STORE
  const dispatch = useDispatch();
  // const portfolioSelection = useSelector((state) => state.portfolioSelection)
  const portfolios = useSelector((state) => state.portfolioSelection.data);
  const chartsData = useSelector((state) => state.chartsData.data);
  const portfoliosName = useSelector((state) => state.portfoliosName.data);
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  );
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  );
  // STATE
  const [isEditingName, setIsEditingName] = useState(false);
  const [portafolioNameValue, setPortfolioNameValue] = useState(null);
  const [defaultPortafolioName, setDefaultPortfolioName] = useState(null);

  useEffect(() => {
    setPortfolioNameValue({
      ...portafolioNameValue,
      [portfolioKey]: portfoliosName?.[portfolioKey]?.portfolioName,
    });
    setDefaultPortfolioName({
      ...defaultPortafolioName,
      [portfolioKey]: portfoliosName?.[portfolioKey]?.portfolioName,
    });
  }, []);

  const getWeightedTAC = () => {
    const tacDataIndex = portfolioKey === "portfolioA" ? 0 : 1;
    const tacValue =
      chartsData?.tacData?.[tacDataIndex]?.tac ?? chartsData?.tacData[0]?.tac2;

    if (tacValue === 0 || Number.isNaN(tacValue)) {
      return "-";
    }

    return formatToFloat(tacValue, 2, "%");
  };

  const updatePortfolioNameState = (name, value) => {
    setPortfolioNameValue({
      ...portafolioNameValue,
      [name]: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updatePortfolioNameState(name, value);
  };

  const cancelPortfolioChange = (defaultValue) => {
    setIsEditingName(false);
    updatePortfolioNameState(portfolioKey, defaultValue);
  };

  const savePortfolioName = () => {
    if (portafolioNameValue?.[portfolioKey] === "") {
      setPortfolioNameValue({
        ...portafolioNameValue,
        [portfolioKey]: defaultPortafolioName?.[portfolioKey],
      });
      dispatch(
        updatePortfoliosName({
          ...portfoliosName,
          [portfolioKey]: {
            portfolioName: defaultPortafolioName?.[portfolioKey],
          },
        }),
      );
    } else {
      dispatch(
        updatePortfoliosName({
          ...portfoliosName,
          [portfolioKey]: {
            portfolioName: portafolioNameValue?.[portfolioKey],
          },
        }),
      );
    }
    setIsEditingName(false);
  };

  return (
    <div className="flex flex-col items-start justify-start gap-8">
      <div
        className={`w-full flex items-center justify-between gap-4 px-2 py-3 rounded-md
          ${colorScheme === "dark" ? "bg-slate-800" : ""}
        `}
        style={{
          backgroundColor:
            colorScheme === "light"
              ? addOpacityToHexColor(getColorPortfolio(portfolioKey), 0.3)
              : null,
        }}
      >
        <div className="flex items-center gap-2 grow max-w-[400px]">
          <span
            className="text-base w-5 h-5 rounded-sm grid place-content-center"
            style={{
              backgroundColor: `${getColorPortfolio(portfolioKey)}`,
            }}
          />
          <div className="grow max-w-[400px]">
            {isEditingName ? (
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  name={`${portfolioKey}`}
                  value={portafolioNameValue?.[portfolioKey]}
                  placeholder="Nombre del portafolio"
                  onChange={handleChange}
                  onKeyUp={(e) => {
                    if (e.key === "Escape" || e.keyCode === 27)
                      cancelPortfolioChange(
                        portfoliosName?.[portfolioKey]?.portfolioName,
                      );
                    if (e.key === "Enter" || e.keyCode === 13)
                      savePortfolioName();
                  }}
                  className="w-full p-2 rounded-md border text-sm bg-slate-50 dark:bg-slate-900
                  border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
                />
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={() => savePortfolioName()}
                    className="p-2 rounded-md  text-blue-500 dark:text-blue-400 bg-transparent hover:bg-slate-50 dark:hover:bg-slate-900 hover:bg-opacity-20"
                  >
                    <CheckIcon className="w-4 h-4" />
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      cancelPortfolioChange(
                        portfoliosName?.[portfolioKey]?.portfolioName,
                      )
                    }
                    className="p-2 rounded-md  text-slate-900 dark:text-slate-50 bg-transparent hover:bg-slate-50 dark:hover:bg-slate-900 hover:bg-opacity-20"
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="flex items-center gap-2 p-2 rounded-md text-sm font-bold bg-transparent hover:bg-slate-50 dark:hover:bg-slate-900 hover:bg-opacity-20 transition-colors duration-300"
                onClick={() => setIsEditingName(true)}
              >
                <span>{portafolioNameValue?.[portfolioKey]}</span>
                <PencilIcon className="w-3 h-3" />
              </button>
            )}
          </div>
        </div>
        <p
          className={`text-sm ${
            analysisDataConfig.showComponents.tac
              ? "opacity-100 pointer-events-auto"
              : "opacity-30 pointer-events-none"
          }`}
        >
          TAC Ponderado:
          <span className="ml-2 font-bold">{getWeightedTAC()}</span>
        </p>
        {distributionOption === "amount" && (
          <p className="text-sm">
            Total:
            <span className="ml-2 font-bold">
              ${formatAmount(getPortfolioTotal(portfolioKey))}
            </span>
          </p>
        )}
      </div>

      <div className="w-full">
        <div className="mb-5 grid grid-cols-12 gap-5 text-xs text-left font-semibold uppercase text-slate-600 dark:text-slate-500">
          <div className="col-span-3">Instrumento</div>
          <div className="col-span-1">Tipo</div>
          <div className="col-span-1">Serie</div>
          <div className="col-span-1">Clase</div>
          <div
            className={`col-span-1 text-right ${
              analysisDataConfig.showComponents.tac
                ? "opacity-100 pointer-events-auto"
                : "opacity-30 pointer-events-none"
            }`}
          >
            TAC
          </div>
          <div className="col-span-1" title="Documentos normativos">
            Docs.
          </div>
          <div className="col-span-4">Distribución</div>
        </div>
        <div className="text-xs text-left w-full space-y-1.5">
          {chartsData?.screenerTable?.[portfolioKey].map((fund, index) => {
            const fundKey = `${fund.run}-${fund.serie}-${index}`;
            const fundPortfolioData = portfolios[
              portfolioKey
            ].portfolioFunds.filter(
              (f) =>
                `${f.fundId}` === `${fund.run}` &&
                f.shareClassName === fund.serie,
            );
            return (
              fundPortfolioData?.[0] && (
                <AnalysisScreenerFund
                  key={fundKey}
                  currentPortfolio={portfolioKey}
                  fund={fund}
                  fundIndex={index}
                  fundPortfolioData={fundPortfolioData?.[0]}
                  modalInfo={modalInfo}
                />
              )
            );
          })}
        </div>
        <AnalysisPortfolioTotals portfolioKey={portfolioKey} />
      </div>
    </div>
  );
}

AnalysisScreenerPortfolio.propTypes = {
  portfolioKey: PropTypes.oneOf(["portfolioA", "portfolioB"]).isRequired,
  modalInfo: PropTypes.shape({
    setFactSheetModalMessage: PropTypes.func,
    setFactSheetModalOpen: PropTypes.func,
  }).isRequired,
};

export default AnalysisScreenerPortfolio;
