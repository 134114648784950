import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    portfolioA: {
      portfolioName: "Portafolio A",
      portfolioFunds: [],
      totalDistribution: 0,
    },
    portfolioB: {
      portfolioName: "Portafolio B",
      portfolioFunds: [],
      totalDistribution: 0,
    },
  },
  activePortfolio: "portfolioA",
  portfolioBEnabled: false,
  distributionOption: "weight",
  baseAmount: null,
}

export const portfolioSelectionSlice = createSlice({
  name: "portfolioSelection",
  initialState,
  reducers: {
    setPortfolioSelectionWithValues: (state, action) => {
      state.data = action.payload.data
      state.activePortfolio = action.payload.activePortfolio
      state.portfolioBEnabled = action.payload.portfolioBEnabled
      state.distributionOption = action.payload.distributionOption
      state.baseAmount = action.payload.baseAmount
    },
    setPortfolioSelection: (state, action) => {
      state.data = action.payload.data
      state.activePortfolio = action.payload.activePortfolio
      state.portfolioBEnabled = action.payload.portfolioBEnabled
    },
    updatePortfolioSelection: (state, action) => {
      state.data = action.payload
    },
    enablePortfolioB: (state, action) => {
      state.portfolioBEnabled = action.payload
    },
    updateActivePortfolio: (state, action) => {
      state.activePortfolio = action.payload
    },
    updateDistributionOption: (state, action) => {
      state.distributionOption = action.payload
    },
    updateBaseAmount: (state, action) => {
      state.baseAmount = action.payload
    },
    resetPortfolioSelection: (state) => {
      state.portfolioBEnabled = initialState.portfolioBEnabled
      state.activePortfolio = initialState.activePortfolio
      state.data = initialState.data
      state.distributionOption = initialState.distributionOption
    },
  },
})

export const {
  setPortfolioSelectionWithValues,
  setPortfolioSelection,
  updatePortfolioSelection,
  enablePortfolioB,
  updateActivePortfolio,
  updateDistributionOption,
  updateBaseAmount,
  resetPortfolioSelection,
} = portfolioSelectionSlice.actions

export default portfolioSelectionSlice.reducer
