import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: 0,
}

export const MinimumInvestmentSlice = createSlice({
  name: "minimumInvestment",
  initialState,
  reducers: {
    updateMinimunInvestment: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { updateMinimunInvestment } = MinimumInvestmentSlice.actions

export default MinimumInvestmentSlice.reducer
