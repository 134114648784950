import { createSlice } from "@reduxjs/toolkit"

// SETTINGS
import SETTINGS from "../settings/settings"

const initialState = {
  API_URL: SETTINGS.urls.API_URL,
  SITE_URL: SETTINGS.urls.SITE_URL,
}

export const UrlsSlice = createSlice({
  name: "urls",
  initialState,
  reducers: {},
})

export default UrlsSlice.reducer
