import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null,
}

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { updateUser } = UserSlice.actions

export default UserSlice.reducer
