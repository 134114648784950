import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import PropTypes from "prop-types";
import { XMarkIcon } from "@heroicons/react/24/outline";

function ColorPicker({
  handleColorSelected,
  baseColor,
  openColorPicker,
  setOpenColorPicker,
  namePortfolio,
}) {
  const [color, setColor] = useState(baseColor);
  const pickerRef = useRef();

  const handleChangeComplete = (colorResult) => {
    setColor(colorResult.hex);
  };

  useEffect(() => {
    handleColorSelected({ namePortfolio, color });
  }, [color]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setOpenColorPicker({ [namePortfolio]: false });
      }
    };
    if (openColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openColorPicker, setOpenColorPicker, namePortfolio]);

  return (
    <div className="absolute z-10" ref={pickerRef}>
      {openColorPicker && (
        <>
          <button
            className="w-4 h-4 rounded-sm z-10 absolute right-0 top-0 flex items-center justify-center cursor-pointer"
            onClick={() => setOpenColorPicker({ [namePortfolio]: false })}
          >
            <XMarkIcon className="w-4 h-4" />
          </button>

          <ChromePicker
            color={color}
            onChangeComplete={(val) => handleChangeComplete(val)}
            disableAlpha
          />
        </>
      )}
    </div>
  );
}
ColorPicker.propTypes = {
  handleColorSelected: PropTypes.func.isRequired,
  baseColor: PropTypes.string,
  openColorPicker: PropTypes.bool,
  setOpenColorPicker: PropTypes.func.isRequired,
  namePortfolio: PropTypes.string.isRequired,
};
ColorPicker.defaultProps = {
  baseColor: "#ffffff",
  openColorPicker: false,
};

export default ColorPicker;
