import { useState, useMemo, useEffect, useContext } from "react"
import { createSearchParams, useNavigate, Link } from "react-router-dom"
import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import { useSelector } from "react-redux"
import { sortBy } from "lodash"
import * as ROUTES from "../../routes/APP_ROUTES"

// FUNCTIONS
import getCompanies from "../../utils/companies"

// HOOKS
import { useFetch } from "../../hooks/useFetch"

//CONTEXTS
import FirebaseContext from "../../firebase/FirebaseContext"

// COMPONENTS
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner"
import SelectField from "../../components/ui/forms/SelectField"
import AccountSearchBar from "../../components/ui/accounts/AccountSearchBar"
import AccountPagination from "../../components/ui/accounts/AccountPagination"
import { displayString } from "../../components/ui/utilities/functions"
import SETTINGS from "../../settings/settings"
// SENTRY
import * as Sentry from "@sentry/browser";


function UsersPage() {
  // STORE
  const urls = useSelector((state) => state.urls)
  // MEMO
  const INITIAL_FILTERS = useMemo(
    () => ({
      rowPerPage: 20,
      currentPage: 1,
      company: "all",
      disabled: "all",
      searchTerm: "",
      sortOptions: {
        sortBy: "name",
        sortOrder: "DESC",
      },
    }),
    [],
  )
  // CONTEXT
  const navigate = useNavigate()
  // FIREBASE
  const firebase = useContext(FirebaseContext)

  // DATA
  const [rawData, setRawData] = useState(null)
  const [dataList, setDataList] = useState(null)
  const [companies, setCompanies] = useState({})
  // FILTERS AND SEARCH
  const [numRows, setNumRows] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  // UPDATE USER STATUS
  const handleActivationButton = (uid) => {
    // GET USER
    const user = dataList.find((u) => u.uid === uid)
    console.log(user);
    // @TODO: Crear y llamar a metodo que actualiza el estado del usuario en firebase
    console.log("Activation button not implemented")
  }

  // LIFE CYCLE
  useFetch(
    `${urls.API_URL}/advisors/list`,
    {},
    (response) => {
      const { data } = response
      setRawData(data)
    },
    [setRawData],
  )

  useEffect(() => {
    const getCompaniesData = async () => {
      try {
        if (firebase !== undefined) {
          await getCompanies({ firebase }, (companiesData) => {
            companiesData["all"] = "Todas las compañias";
            setCompanies(companiesData);
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error('Error al obtener los datos:', error);
      }
    };
    getCompaniesData();
  });

  // HANDLE SEARCH AND FILTERS
  const filterList = () => {
    const filteredList = []
    Object.keys(rawData).forEach((key) => {
      const regex = new RegExp(filters.searchTerm, "gi") // g: Global, i: Case insensitive
      const { name } = rawData[key]
      const { lastname } = rawData[key]
      const { company } = rawData[key]
      if (
        (filters.company === "all" ||
          filters.company === String(rawData[key].company.id)) &&
        (filters.searchTerm === "" ||
          name.match(regex) ||
          lastname.match(regex) ||
          company.name.match(regex))
      ) {
        filteredList.push({
          ...{ uid: key },
          ...rawData[key],
        })
      }
    })

    // ORDENAR
    const sortedData = sortBy(
      filteredList,
      (o) => o[filters.sortOptions.sortBy],
    )
    if (filters.sortOptions.sortOrder === "ASC") {
      sortedData.reverse()
    }
    // TOTAL DE FILAS DESPUÉS DE FILTRAR
    setNumRows(sortedData.length)
    // PAGINATE AND SET DATA
    setTotalPages(Math.ceil(sortedData.length / filters.rowPerPage))
    const sliceStart = (filters.currentPage - 1) * filters.rowPerPage
    const sliceEnd = sliceStart + filters.rowPerPage
    setDataList(sortedData.slice(sliceStart, sliceEnd))
  }

  const updateFilters = (keyToUpdate, data) => {
    setFilters({
      ...filters,
      ...{ [keyToUpdate]: data },
    })
  }

  useEffect(() => {
    /* Aplicar el filtro cuando llega la data por primera. */
    if (rawData) filterList()
  }, [rawData])

  useEffect(() => {
    /* Aplicar el filtro cuando se modifica algun filtro. */
    if (rawData) filterList()
  }, [filters])
  return (
    <main className="px-8 pt-20 mb-20">
      {!dataList ? (
        <LoadingSpinner />
      ) : (
        <>
          <header className="mb-5">
            <Link
              to={ROUTES.USER}
              className="text-sm text-slate-400 hover:text-blue-500 flex items-center gap-1 mb-2"
            >
              <ChevronLeftIcon className="w-3 h-3" /> Volver
            </Link>
            <div className="mb-5 grid grid-cols-[1fr,_140px] items-center gap-4">
              <div>
                <h1 className="text-base">Administración de usuarios</h1>
                <p className="text-sm text-slate-400">
                  La siguiente lista contiene todos los usuarios registrados en
                  el sistema. Para ver detalles y actualizar alguno haz click.
                </p>
              </div>
              <Link
                to={ROUTES.EDIT_USER}
                className="w-full h-7 grid place-content-center py-2 text-sm rounded-full transition-all duration-300 text-blue-500 border border-blue-500
                hover:bg-blue-500 hover:text-slate-900"
              >
                Crear usuario
              </Link>
            </div>
            <div className="w-full mb-4 grid grid-cols-[1fr,_250px] gap-5">
              <AccountSearchBar
                term={filters.searchTerm}
                handleChange={(term) => updateFilters("searchTerm", term)}
                placeholder="Buscar por usuario o compañia"
              />

              <SelectField
                label=""
                placeholder=""
                selectedValue={filters.company}
                setSelectedValue={(selection) =>
                  updateFilters("company", selection)
                }
                data={companies}
                returnValue={false}
              />
            </div>
            <div className="mb-5 grid grid-cols-2 items-end">
              <p className="m-0 text-xs text-slate-400">{`Página ${filters.currentPage} de ${totalPages}`}</p>
              <p className="m-0 text-xs text-slate-400 justify-self-end">{`Mostrando ${filters.rowPerPage > numRows ? numRows : filters.rowPerPage
                } de ${numRows} usuarios encontrados.`}</p>
            </div>
          </header>

          <div>
            <table className="min-w-full divide-y divide-slate-600">
              <thead>
                <tr className="group text-xxs font-normal uppercase text-slate-400 text-left">
                  <th className="py-2">Nombre</th>
                  <th className="py-2">Compañia</th>
                  <th className="py-2">Rol</th>
                  <th className="py-2">Email</th>
                  <th className="py-2">Propuestas</th>
                  <th className="py-2">Estado</th>
                  <th className="py-2">Perfil</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-slate-600">
                {dataList.map((user) => (
                  <tr key={user.uid} className="font-light text-sm text-left">
                    <td className="py-2">
                      {user.name} {user.lastname}
                    </td>
                    <td className="py-2">
                      {displayString(user?.company.name)}
                    </td>
                    <td className="py-2">{SETTINGS.USER_PROFILES[user.role[0]]}</td>
                    <td className="py-2">{user.email}</td>
                    <td className="py-2">
                      <button
                        type="button"
                        className="py-1 px-2 text-xxs rounded-md border border-slate-600 hover:bg-blue-500 hover:text-slate-900"
                        onClick={() =>
                          navigate({
                            pathname: ROUTES.MY_ANALISIS,
                            search: createSearchParams({
                              uid: user.uid,
                            }).toString(),
                          })
                        }
                      >
                        Ver propuestas
                      </button>
                    </td>
                    <td
                      className={`py-2 ${user.disabled ? "disabled" : "enabled"
                        }`}
                    >
                      <button
                        type="button"
                        disabled
                        className={`py-1 px-2 text-xxs rounded-md border border-slate-600 hover:bg-blue-500 hover:text-slate-900
                            ${user.disabled
                            ? "border-amber-400 text-amber-200"
                            : ""
                          }
                        `}
                        onClick={() => handleActivationButton(user.uid)}
                      >
                        {user.disabled ? "Desabilitado" : "Activo"}
                      </button>
                    </td>
                    <td className="py-2">
                      <button
                        type="button"
                        className="py-1 px-2 text-xxs rounded-md border border-slate-600 hover:bg-blue-500 hover:text-slate-900"
                        onClick={() =>
                          navigate({
                            pathname: ROUTES.EDIT_USER,
                            search: createSearchParams({
                              uid: user.uid,
                            }).toString(),
                          })
                        }
                      >
                        Ver
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="grid grid-cols-[1fr,_130px] justify-end items-end gap-4">
              <AccountPagination
                filters={filters}
                callback={updateFilters}
                totalPages={totalPages}
              />
              <SelectField
                label="Resultados por página:"
                placeholder=""
                selectedValue={filters.rowPerPage}
                setSelectedValue={(selection) =>
                  updateFilters("rowPerPage", selection)
                }
                data={{
                  5: 5,
                  10: 10,
                  20: 20,
                  50: 50,
                  100: 100,
                }}
                size="small"
              />
            </div>
          </div>
        </>
      )}
    </main>
  )
}

export default UsersPage
