import { useSelector, useDispatch } from "react-redux"
import { updateCatalogByFunds } from "../store/catalogByFundsSlice"

const useCatalogByFunds = () => {
  // STORE
  const dispatch = useDispatch()
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const rawCatalog = useSelector((state) => state.rawCatalog.data)
  // FUNCTIONS
  const setCatalogsByFunds = () => {
    if (rawCatalog) {
      const catalogByFunds = {}
      rawCatalog.forEach((serie) => {
        const key = `${serie.run}`
        if (isAPV && serie.is_apv) {
          if (!(key in catalogByFunds)) {
            catalogByFunds[key] = []
          }
          catalogByFunds[key].push(serie)
        }
        if (!isAPV && !serie.is_apv) {
          if (!(key in catalogByFunds)) {
            catalogByFunds[key] = []
          }
          catalogByFunds[key].push(serie)
        }
      })
      dispatch(updateCatalogByFunds(catalogByFunds))
    }
  }
  return { setCatalogsByFunds }
}
export default useCatalogByFunds
