import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {},
}

export const AppDataSlice = createSlice({
  name: "appData",
  initialState,
  reducers: {
    updateAppData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { updateAppData } = AppDataSlice.actions

export default AppDataSlice.reducer
