import { useSelector } from "react-redux"
import { useCallback } from "react"
import useColorScheme from "./useColorScheme"

function useLogoCompany() {
  const user = useSelector((state) => state?.user?.user)
  const { logo } = user
  const [colorScheme] = useColorScheme()

  const getLogo = () => {
    if (!logo) {
      return null
    }
    if (!logo?.dark) {
      return logo?.light
    }
    return logo?.[colorScheme]
  }
  const getLogoSchema = useCallback(
    (schema) => {
      if (!logo) {
        return null
      }
      if (!logo?.dark) {
        return logo?.light
      }
      return logo?.[schema]
    },
    [logo],
  )

  return { getLogo, getLogoSchema }
}

export default useLogoCompany
