import { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
// HOOKS
import useColorScheme from "../../../hooks/useColorScheme";
// UTILS
import { formatToFloat } from "../../ui/utilities/functions";

// CHART CONFIG
ChartJS.register(ArcElement, Tooltip, Legend);

function EquityDebtDoughnut({ chartData }) {
  // HOOKS
  const [colorScheme] = useColorScheme();
  const options = useMemo(
    () => ({
      responsive: true,
      animation: false,
      cutout: 50,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          padding: 4,
          backgroundColor: colorScheme === "dark" ? "#0f172a" : "#ffffff",
          titleColor: colorScheme === "dark" ? "#F8FAFC" : "#0f172a",
          titleFont: {
            size: 10,
            style: "normal",
            weight: "normal",
          },
          bodyFont: {
            size: 10,
            style: "normal",
            weight: "normal",
          },
          bodyColor: colorScheme === "dark" ? "#F8FAFC" : "#0f172a",
          bodySpacing: 8,
          borderWidth: 1,
          borderColor: colorScheme === "dark" ? "#0f172a" : "#ffffff",
          callbacks: {
            label(context) {
              return formatToFloat(context.raw);
            },
          },
        },
      },
    }),
    [colorScheme],
  );

  return <Doughnut data={chartData} options={options} />;
}

export default EquityDebtDoughnut;
