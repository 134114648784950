import { useState } from "react"
import PropTypes from "prop-types"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"

// HOOKS
import useFundFactsheets from "../../../hooks/useFundFactsheets"

// UI
import { PanelOverviewShape } from "../../../propTypes/AppPropTypes"
import InfoModal from "../dialogs/InfoModal"
import ParagraphWithTooltip from "../ParagraphWithTooltip/ParagraphWithTooltip"

import {
  formatDataInfoDate,
  displayString,
  formatAmounts,
  formatToFloat,
  formatMinimumInvestment,
} from "../utilities/functions"

function FundDetailsGeneralDAP({ data }) {
  const [factSheetModalMessage, setFactSheetModalMessage] = useState("")
  const [factSheetModalOpen, setFactSheetModalOpen] = useState(false)
  const [openFactsheet, openProspectus] = useFundFactsheets()

  return (
    <section>
      <h1 className="overview-section-title">Información General</h1>
      <div className="overview-section-grid">
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Objetivo de inversión</h2>
          {data.fund.objective ? (
            <ParagraphWithTooltip text={data.fund.objective} />
          ) : (
            <p className="overview-section-text">Sin información</p>
          )}
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Información del Fondo</h2>
          <div className="overview-section-text overview-detail-list">
            {data.fund.fundId && (
              <div className="overview-detail-row">
                <p>RUN</p>
                <p>{data.fund.fundId}</p>
              </div>
            )}
            {data.fund.asset_class && (
              <div className="overview-detail-row">
                <p>Clase de activo</p>
                <p>{data.fund.asset_class}</p>
              </div>
            )}
            {data.fund.currency && (
              <div className="overview-detail-row">
                <p>Moneda</p>
                <p>{data.fund.currency}</p>
              </div>
            )}
            {data.fund.category && (
              <div className="overview-detail-row">
                <p>Categoría</p>
                <p>{data.fund.category}</p>
              </div>
            )}
            <div className="overview-detail-row">
              <p>Rescatable</p>
              <p>{data.fund.isRedeemable ? "Si" : "No"}</p>
            </div>
            {data.fund.aum && (
              <div className="overview-detail-row">
                <p>Patrimonio del fondo</p>
                <p>{formatAmounts(+data.fund.aum)}</p>
              </div>
            )}
            {data.fund.risks && (
              <div className="overview-detail-row">
                <p>Riesgos</p>
                <p>{data.fund.risks}</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">
            Características Serie{" "}
            {displayString(data.shareClass.shareClassName)}
          </h2>
          <div className="overview-section-text overview-detail-list">
            {data.fund.entryRequirements !== null && (
              <div className="overview-detail-row">
                <p>Requisitos de ingreso</p>
                <ParagraphWithTooltip
                  text={data.fund.entryRequirements}
                  length={50}
                  justify={false}
                />
              </div>
            )}
            {data.fund.minimumInvestmentClp !== null && (
              <div className="overview-detail-row">
                <p>Monto mínimo de inversión</p>
                <p>{formatMinimumInvestment(data.fund.minimumInvestment)}</p>
              </div>
            )}
            {data.shareClass.maxRedemptionPaymentTime !== null && (
              <div className="overview-detail-row">
                <p>Rescates</p>
                <p>{data.shareClass.maxRedemptionPaymentTime}</p>
              </div>
            )}
            {data.shareClass.tac !== null && (
              <div className="overview-detail-row">
                <p>
                  TAC{" "}
                  {data.fund.fundType === "FI" ? " del fondo" : " de la serie"}
                </p>
                <p>{`${formatToFloat(data.shareClass.tac)}`}</p>
              </div>
            )}
            {data.shareClass.benefits !== null ||
              (data.shareClass.benefits !== "" && (
                <div className="overview-detail-row">
                  <p>BeneficiosTributarios</p>
                  <p>{data.shareClass.benefits}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Administración</h2>
          <div className="overview-section-text overview-detail-list">
            {/* {data.fund.entryRequirements && (
              <div className="overview-detail-row">
                <p>Requisitos de ingreso</p>
                <p>{data.fund.entryRequirements}</p>
              </div>
            )} */}
            {data.manager.managerName && (
              <div className="overview-detail-row">
                <p>Administradora</p>
                <p>{displayString(data.manager.managerName)}</p>
              </div>
            )}
            {data.fund.fundInceptionDate && (
              <div className="overview-detail-row">
                <p>Fecha de inicio del fondo</p>
                <p>{formatDataInfoDate(data.fund.fundInceptionDate)}</p>
              </div>
            )}
            {data.shareClass.shareClassInceptionDate && (
              <div className="overview-detail-row">
                <p>Fecha de inicio de la serie</p>
                <p>
                  {formatDataInfoDate(data.shareClass.shareClassInceptionDate)}
                </p>
              </div>
            )}
            <div className="overview-detail-row">
              <p>Documentos:</p>
              <div className="flex flex-col gap-3">
                <button
                  type="button"
                  className="overview-detail-external-link"
                  onClick={() =>
                    openFactsheet(
                      data.fund.fundId,
                      data.shareClass.shareClassName,
                      data.manager.managerId,
                      () => {
                        setFactSheetModalMessage(
                          `No existe ficha reportada en la CMF para el Fondo ${data.fund.fundId} y serie ${data.shareClass.shareClassName}.`,
                        )
                        setFactSheetModalOpen(true)
                      },
                    )
                  }
                >
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" /> Folleto
                  informativo serie {data.shareClass.shareClassName}
                </button>
                <button
                  type="button"
                  className="overview-detail-external-link"
                  onClick={() =>
                    openProspectus(
                      data.fund.fundId,
                      data.shareClass.shareClassName,
                      data.fund.prospectusCode,
                      () => {
                        setFactSheetModalMessage(
                          `No existe reglamento interno reportado en la CMF para el Fondo ${data.fund.fundId} y serie ${data.shareClass.shareClassName}.`,
                        )
                        setFactSheetModalOpen(true)
                      },
                    )
                  }
                >
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" /> Reglamento
                  interno del fondo
                </button>
              </div>
            </div>
          </div>
        </div>
        <InfoModal
          message={factSheetModalMessage}
          isOpen={factSheetModalOpen}
          setFactSheetModalOpen={setFactSheetModalOpen}
        />
      </div>
    </section>
  )
}
FundDetailsGeneralDAP.propTypes = {
  data: PropTypes.shape(PanelOverviewShape).isRequired,
}

export default FundDetailsGeneralDAP
