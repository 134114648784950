const PortfolioHeader = ({colorScheme, bgColor, mainColor, portfolioName}) => {
    return (
        <div
            className={`w-full flex items-start justify-start gap-2 px-2 py-3 rounded-md text-sm font-semibold ${colorScheme === "dark" ? "bg-slate-800" : ""}`}
            style={{backgroundColor: colorScheme === "light" && bgColor }}
        >
            <span className="w-5 h-5 rounded-sm" style={{ backgroundColor: mainColor }} />
            <p>{portfolioName}</p>
        </div>
    )
}

export default PortfolioHeader