import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"

function ToggleSectionButtonOutlined({ component, action, tooltip }) {
  // STORE
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)
  const { showComponents } = analysisDataConfig.data

  const componentParts = component ? component.split(".") : []
  const showComponent = componentParts.reduce(
    (obj, part) => obj[part],
    showComponents,
  )

  return (
    <button
      type="button"
      onClick={() => action(component)}
      className="relative group rounded-md px-2 h-7 flex items-center justify-center gap-1 text-xs text-blue-500 dark:text-blue-400 bg-blue-50 dark:bg-transparent border border-blue-200 dark:border-blue-400
          hover:text-blue-400 dark:hover:text-blue-500 hover:bg-blue-100 dark:hover:bg-slate-800 transition-colors duration-500"
    >
      {showComponent ? (
        <>
          <EyeIcon className="w-4 h-4" />
          <span>Costos</span>
        </>
      ) : (
        <>
          <EyeSlashIcon className="w-4 h-4" />
          <span>Costos</span>
        </>
      )}
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bottom-full
        mb-2 px-2 py-1 text-xs text-slate-900 dark:text-slate-50 bg-slate-300 dark:bg-slate-700 rounded hidden
        group-hover:block transition-opacity duration-500 w-28"
      >
        {showComponent ? tooltip[0] : tooltip[1]}
      </span>
    </button>
  )
}

ToggleSectionButtonOutlined.propTypes = {
  component: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  tooltip: PropTypes.arrayOf(PropTypes.string),
}

ToggleSectionButtonOutlined.defaultProps = {
  tooltip: [
    "Quitar sección de la vista pública",
    "Mostrar sección en la vista pública",
  ],
}

export default ToggleSectionButtonOutlined
