import { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Transition } from "@headlessui/react"

// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside"

function ParagraphWithTooltip({ text, length, justify, position }) {
  const maxChars = length
  const tooltipRef = useRef()
  const [showTooltip, setShowTooltip] = useState(false)
  const showReadMore = text.length > maxChars

  useOnClickOutside(tooltipRef, () => setShowTooltip(false))

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 27) {
        if (showTooltip) {
          setShowTooltip(false)
        }
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [showTooltip, setShowTooltip])

  const getPosition = () => {
    switch (position) {
      case "top":
        return {
          bottom: "0",
          left: "50%",
          transform: "translate(-50%, -20px)",
        }
      case "bottom":
        return {
          top: "0",
          left: "50%",
          transform: "translate(-50%, 20px)",
        }
      case "left":
        return {
          top: "0",
          right: "100%",
          transform: "translate(8px, -20px)",
        }
      default:
        return {
          top: "0",
          left: "100%",
          transform: "translate(8px, -20px)",
        }
    }
  }

  return (
    <div className="relative">
      <p className={`overview-section-text ${justify && "text-justify"}`}>
        {showReadMore ? text.slice(0, maxChars) : text}
        {showReadMore && (
          <button
            onClick={() => setShowTooltip(!showTooltip)}
            className="text-blue-500 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-500"
          >
            {" "}
            ...leer más
          </button>
        )}
      </p>
      {showReadMore && (
        <Transition
          ref={tooltipRef}
          as="div"
          show={showTooltip}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute z-10 min-w-[250px] max-h-[250px] bg-slate-300 dark:bg-slate-700 p-4 rounded-sm overview-section-text text-justify overflow-auto"
          style={getPosition()}
        >
          {text}
        </Transition>
      )}
    </div>
  )
}

ParagraphWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number,
  justify: PropTypes.bool,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
}

ParagraphWithTooltip.defaultProps = {
  length: 600,
  justify: true,
  position: "right",
}

export default ParagraphWithTooltip
