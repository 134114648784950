import SETTINGS from "../settings/settings";

// Feature flags. Modification is disallowed in the meantime.
const FEATURE_FLAGS = Object.freeze({
  ROUTE_ANALYSIS: {
    name: "ROUTE_ANALYSIS",
    value: SETTINGS.featureConditionals.ROUTE_ANALYSIS || false,
  },
});

export { FEATURE_FLAGS };
// export default FEATURE_FLAGS
