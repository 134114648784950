/* eslint-disable react/prop-types */
import PropTypes from "prop-types"

import { useSelector } from "react-redux"
import TACTable from "../../../charts/TACTable"
import useColorCompany from "../../../../hooks/useColorCompany"

function SectionTAC({ data, portfolios }) {
  const { getColorPortfolio } = useColorCompany()
  const portfoliosName = useSelector((state) => state.portfoliosName.data)

  const renderTACTable = (portfolioData) => {
    const validKeyByColor =
      portfolioData?.portfolio === "Portafolio A" ? "portfolioA" : "portfolioB"
    const portfolioKey = portfolioData?.key ?? validKeyByColor
    return (
      <TACTable
        key={portfolioKey}
        portfolioKey={portfolioKey}
        title={portfoliosName?.[portfolioKey]?.portfolioName}
        shareClasses={portfolioData.shareClasses}
        tac={portfolioData.tac}
        color={getColorPortfolio(portfolioKey)}
        portfolios={portfolios[portfolioKey]}
      />
    )
  }

  return (
    data && (
      <div className="grid gap-8 grid-cols">
        {Array.isArray(data)
          ? data.map((portfolioData) => renderTACTable(portfolioData))
          : renderTACTable(data)}
      </div>
    )
  )
}

SectionTAC.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        portfolio: PropTypes.string.isRequired,
        tac: PropTypes.number.isRequired,
        shareClasses: PropTypes.arrayOf(
          PropTypes.shape({
            shareclassName: PropTypes.string.isRequired,
            fund_name: PropTypes.string.isRequired,
            tac: PropTypes.number.isRequired,
            tacDate: PropTypes.string.isRequired,
          }),
        ),
        key: PropTypes.string.isRequired,
      }),
    ),
    PropTypes.shape({
      portfolio: PropTypes.string.isRequired,
      tac: PropTypes.number.isRequired,
      shareClasses: PropTypes.arrayOf(
        PropTypes.shape({
          shareclassName: PropTypes.string.isRequired,
          fund_name: PropTypes.string.isRequired,
          tac: PropTypes.number.isRequired,
          tacDate: PropTypes.string.isRequired,
        }),
      ),
      key: PropTypes.string.isRequired,
    }),
  ]).isRequired,
}

export default SectionTAC
