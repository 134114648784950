import { useState, useCallback, useEffect, useContext } from "react"
// ROUTER
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import * as APP_ROUTES from "../../routes/APP_ROUTES"

// STORE
import { updateAnalysisDataConfig } from "../../store/analysisDataConfigSlice"
import { updatePortfolioSelection } from "../../store/portfolioSelectionSlice"

// CONTEXT
import { FirebaseContext } from "../../firebase"

// COMPONENTS
import AnalysisBox from "../../components/ui/AnalysisSections/AnalysisBox"
import AnalysisSidebar from "../../components/ui/AnalysisSections/AnalysisSidebar"
import AnalysisNav from "../../components/ui/AnalysisSections/AnalysisNav"
import AnalysisSections from "../../components/ui/AnalysisSections/AnalysisSections"
import AnalysisSectionsAPV from "../../components/ui/AnalysisSections/AnalysisSectionsAPV"
import ScreenshotModal from "../../components/ui/ScreenshotModal/ScreenshotModal"

import RenderIf from "../../components/helpers/RenderIf"
import { getDataInStorage } from "../../utils/CacheManager"

function AnalysisPage() {
  // CONTEXT
  const { logAnalyticsEvent } = useContext(FirebaseContext)
  // STATE
  const [screenshotData, setScreenshotData] = useState(null)
  const [isAsyncDataLoaded, setIsAsyncDataLoaded] = useState(null)
  // TRACK
  const dispatch = useDispatch()
  // STORE
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig)
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const user = useSelector((state) => state.user.user)
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const { showComponents } = analysisDataConfig.data

  const hasFundsInPortfolios = () => {
    if (
      portfolios?.portfolioA.portfolioFunds.length ||
      portfolios?.portfolioB.portfolioFunds.length
    ) {
      return true
    }
    return false
  }

  const updateExposureAll = useCallback(
    (components) => {
      const allExposureFalse = Object.values(showComponents.exposure).every(
        (value) => value === false,
      )

      if (allExposureFalse && components.exposureAll !== false) {
        dispatch(
          updateAnalysisDataConfig({
            ...analysisDataConfig.data,
            showComponents: {
              ...analysisDataConfig.data.showComponents,
              exposureAll: false,
            },
          }),
        )
      }

      return components
    },
    [analysisDataConfig.data, dispatch, showComponents.exposure],
  )

  useEffect(() => {
    updateExposureAll(showComponents)
  }, [showComponents, updateExposureAll])

  const onOfComponent = (component) => {
    // REPRENSTA EL ID DE LA SECCION O UN ARREGLO ["exposure", SECCION]
    const componentParts = component ? component.split(".") : []
    // RECUPERA EL VALOR ACTUAL
    const showComponent = componentParts.reduce(
      (obj, part) => obj[part],
      showComponents,
    )
    // PARA COMPOSICIONES
    if (
      componentParts[0] === "exposureAll" ||
      componentParts[0] === "exposure"
    ) {
      let exposureAllValue
      let exposuresValues = {}
      const availableExposures = Object.keys(showComponents.exposure)
      const isExposureAll = componentParts[0] === "exposureAll"
      // VALOR ACTUAL: showComponent
      // SI APRETE EL EXPOSUREALL
      // SI ES TRUE, SE MARCA EXPOSUREALL COMO TRUE Y SE MARCAN TODAS LAS EXPOSICIONES COMO TRUE;
      // SI ES FALSE, SE MARCA EXPOSUREALL COMO FALSE Y SE MARCAN TODAS LAS EXPOSICIONES COMO FALSE;

      // SI NO APRETE EL EXPOSURE ALL, APRETE UNA SECCION EN componentParts[1] que me indica el ID de la seccion.
      // SI APRETE TRUE EN LA SECCION, DEBE MARCARSE COMO TRUE ESA SECCION Y EN EXPOSUREALL.
      // SI APRETE FALSE EN LA SECCION, DEBE MARCARSE COMO FALSE ESA SECCION.
      if (isExposureAll) {
        if (showComponent === true) {
          exposureAllValue = false
          availableExposures.forEach((exp) => {
            exposuresValues[exp] = false
          })
        } else {
          exposureAllValue = true
          availableExposures.forEach((exp) => {
            exposuresValues[exp] = true
          })
        }
      } else if (showComponent === true) {
        // CAMBIAR A FALSE
        exposuresValues = {
          ...analysisDataConfig.data.showComponents.exposure,
          ...{ [componentParts[1]]: false },
        }
        // SI TODOS SON FALSE, entonces exposureAllValue es FALSE, si no es verdadero
        const trueArray = Object.keys(exposuresValues).filter(
          (exp) => exposuresValues[exp] === true,
        )
        exposureAllValue = true
        if (trueArray.length === 0) {
          exposureAllValue = false
        }
      } else {
        exposureAllValue = true
        exposuresValues = {
          ...analysisDataConfig.data.showComponents.exposure,
          ...{ [componentParts[1]]: true },
        }
      }

      dispatch(
        updateAnalysisDataConfig({
          ...analysisDataConfig.data,
          showComponents: {
            ...analysisDataConfig.data.showComponents,
            ...{ exposureAll: exposureAllValue },
            ...{ exposure: exposuresValues },
          },
        }),
      )
    } else {
      // PARA EL RESTO DE LAS SECCIONES.
      dispatch(
        updateAnalysisDataConfig({
          ...analysisDataConfig.data,
          showComponents: {
            ...analysisDataConfig.data.showComponents,
            [component]: !showComponents[component],
          },
        }),
      )
    }
    logAnalyticsEvent("select_content", {
      content_type: "Ocultar/Mostrar sección en propuesta",
      content_id: component,
      username: `${user.name} ${user.lastname}`,
      company: user.company,
    })
  }

  const handleTakeScreenshot = (data) => {
    setScreenshotData(data)
  }
  const resetScreenshotModal = useCallback(() => {
    setScreenshotData(null)
  }, [])

  // SETEAR DATA ASINCRONA A LOS PORTAFOLIOS SELECCIONADOS
  useEffect(() => {
    const getFundGeneralInfo = async (run, serie) => {
      const fund = await getDataInStorage({ key: run })
      return { ...fund?.data?.value?.generalInfo, run, serie }
    }
    const processPortfolio = async () => {
      const newPortfolios = {}
      const promises = []
      Object.keys(portfolios).forEach((portfolioKey) => {
        if (portfolios[portfolioKey].portfolioFunds.length > 0) {
          promises.push(
            ...portfolios[portfolioKey].portfolioFunds.map((fund) =>
              getFundGeneralInfo(fund.fundId, fund.shareClassName),
            ),
          )
        }
      })
      const fundsGeneralInfo = await Promise.all(promises)
      // ACTUALIZAR DATA DEL PORTAFOLIO
      Object.keys(portfolios).forEach((portfolioKey) => {
        const newPortfolioFunds = []
        if (portfolios[portfolioKey].portfolioFunds.length > 0) {
          portfolios[portfolioKey].portfolioFunds.forEach((fund) => {
            const fundInfo = fundsGeneralInfo.filter(
              (f) => f.run === fund.fundId && f.serie === fund.shareClassName,
            )
            const newFund = {
              ...fund,
              ...{ prospectusCode: fundInfo[0].prospectusCode },
            }
            newPortfolioFunds.push(newFund)
          })
        }
        newPortfolios[portfolioKey] = {
          ...portfolios[portfolioKey],
          ...{ portfolioFunds: newPortfolioFunds },
        }
      })

      dispatch(updatePortfolioSelection(newPortfolios))
      setIsAsyncDataLoaded(true)
    }
    processPortfolio()
  }, [])

  return (
    isAsyncDataLoaded && (
      <div id="inicio" className="w-full pt-14 bg-slate-50 dark:bg-slate-800">
        <div className="max-w-[1440px] mx-auto">
          <AnalysisNav showButtons={hasFundsInPortfolios()} />

          <div className="grid grid-cols-[250px,_1fr] items-start">
            <AnalysisSidebar />
            <RenderIf
              isTrue={hasFundsInPortfolios()}
              componentOtherwise={
                <AnalysisBox>
                  <div>
                    <div className="flex items-center gap-1 mb-4">
                      <InformationCircleIcon className="w-6 h-6 text-slate-500 dark:text-slate-400" />
                      <h1 className="text-2xl font-bold">
                        No hay fondos seleccionados
                      </h1>
                    </div>
                    <p className="mb-4">
                      Construye tu propuesta seleccionando fondos desde el
                      catálogo.
                    </p>
                    <Link
                      to={APP_ROUTES.SCREENER}
                      className="font-normal text-blue-500 dark:text-blue-400 hover:text-blue-400 dark:hover:text-blue-500 transition-colors duration-300 flex items-center gap-2"
                    >
                      Ir al catálogo
                    </Link>
                  </div>
                </AnalysisBox>
              }
            >
              <div className="relative px-8 pb-7 pt-0 ">
                {isAPV ? (
                  <AnalysisSectionsAPV
                    onOfComponent={onOfComponent}
                    handleTakeScreenshot={handleTakeScreenshot}
                  />
                ) : (
                        <AnalysisSections
                          onOfComponent={onOfComponent}
                          handleTakeScreenshot={handleTakeScreenshot}
                        />
                )}
              </div>
            </RenderIf>
          </div>
        </div>

        {screenshotData && (
          <ScreenshotModal
            screenshotData={screenshotData}
            resetScreenshotModal={resetScreenshotModal}
          />
        )}
      </div>
    )
  )
}

export default AnalysisPage
