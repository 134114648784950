import Portfolio from "../../../utils/Portfolio"
import ShareClassFactory from "../../../utils/ShareClassFactory"
// SENTRY
import * as Sentry from "@sentry/browser";


async function createPortfolioInstances(portfolios, isAPV, firebase) {
  const portfoliosInstancesN = []
  if (portfolios.portfolioA.portfolioFunds.length > 0) {
    const portfolioA = new Portfolio("portfolioA", portfolios.portfolioA.portfolioName)
    const promiseA = portfolios.portfolioA.portfolioFunds.map(
      async (fundInfo) => {
        const shareClassInstance = await ShareClassFactory.getShareClass(
          fundInfo.fundId,
          fundInfo.shareClassName,
          isAPV,
          firebase
        )
        portfolioA.addShareClass(shareClassInstance, fundInfo.weight / 100.0)
      },
    )
    await Promise.all(promiseA)
    portfoliosInstancesN.push(portfolioA)
  }

  if (portfolios.portfolioB.portfolioFunds.length > 0) {
    const portfolioB = new Portfolio("portfolioB", portfolios.portfolioB.portfolioName)
    const promiseB = portfolios.portfolioB.portfolioFunds.map(
      async (fundInfo) => {
        const shareClassInstance = await ShareClassFactory.getShareClass(
          fundInfo.fundId,
          fundInfo.shareClassName,
          isAPV,
          firebase
        )
        portfolioB.addShareClass(shareClassInstance, fundInfo.weight / 100.0)
      },
    )
    await Promise.all(promiseB)
    portfoliosInstancesN.push(portfolioB)
    // portfolioB.getExposure()
  }
  return portfoliosInstancesN
}

class PortfolioManager {
  constructor() {
    this.changeQueue = []
    this.isProcessing = false
  }

  enqueueChange(portfolios, isAPV, firebase) {
    return new Promise((resolve, reject) => {
      // Agregar el cambio a la cola
      this.changeQueue.push({
        portfolios,
        resolve,
        reject,
      })

      // Si la cola no se está procesando, comenzar el procesamiento
      if (!this.isProcessing) {
        this.processQueue(isAPV, firebase)
      }
    })
  }

  async processQueue(isAPV, firebase) {
    // Establecer la bandera para indicar que la cola se está procesando
    this.isProcessing = true
    let lastChange
    try {
      // Obtener los eventos que se van a descartar
      const deletedEvents = this.changeQueue.slice(0, -1)

      // Modificar la cola para dejar el último cambio
      this.changeQueue = this.changeQueue.splice(-1)

      // Tomar el último cambio de la cola
      lastChange = this.changeQueue.pop()

      // Descartar Eventos
      deletedEvents.forEach((df) =>
        df.resolve({
          status: "REJECTED",
        }),
      )

      // Realizar el cálculo de rentabilidad
      const portfolioInstances = await createPortfolioInstances(
        lastChange.portfolios,
        isAPV,
        firebase
      )

      // Resolver la promesa del último cambio
      lastChange.resolve({
        status: "OK",
        data: portfolioInstances,
      })
    } catch (error) {
      Sentry.captureException(error);
      // Rechazar la promesa del último cambio en caso de error
      lastChange.reject(error)
    } finally {
      // Resetear la bandera cuando se completa el procesamiento de la cola
      this.isProcessing = false
      // Si hay más cambios en la cola, procesarlos
      if (this.changeQueue.length > 0) {
        this.processQueue(isAPV)
      }
    }
  }
}

export default PortfolioManager
