import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";
// HOOKS
import useTracking from "../../hooks/useTracking";
import useColorScheme from "../../hooks/useColorScheme";

function AccountColorSchemeToggle({ setChangeToggle }) {
  // HOOKS
  const trackEvent = useTracking();
  const [colorScheme] = useColorScheme();
  const [toggle, setToggle] = useState(colorScheme);

  useEffect(() => {
    setToggle(colorScheme);
  }, [colorScheme]);

  const handleChange = (state) => {
    const newScheme = state ? "dark" : "light";
    setToggle(newScheme);
    setChangeToggle(newScheme, "logo");

    trackEvent({
      action: "ACCOUNT_PROFILE_COLORS_CHANGE_COLOR_SCHEME",
      timestamp: Date.now(),
      type: "CLICK",
      data: newScheme,
    });
  };

  return (
    <div className="flex items-center justify-center gap-4 mb-2">
      <span className="text-xs text-slate-500 dark:text-slate-400">Light</span>
      <Switch
        checked={toggle === "dark"}
        onChange={handleChange}
        className={`${
          toggle === "dark" ? "bg-slate-600" : "bg-slate-300"
        } relative inline-flex h-4 w-10 items-center rounded-full`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`
          inline-block h-3 w-3 transform rounded-full transition
          ${toggle === "dark" ? "translate-x-6" : "translate-x-1"}
          ${toggle === "dark" ? "bg-slate-400" : "bg-slate-50"}
          `}
        />
      </Switch>
      <span className="text-xs text-slate-500 dark:text-slate-400">
        Dark (opcional)
      </span>
    </div>
  );
}
AccountColorSchemeToggle.propTypes = {
  setChangeToggle: PropTypes.func.isRequired,
};

export default AccountColorSchemeToggle;
