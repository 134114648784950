import PropTypes from "prop-types"

import useColorScheme from "../../../hooks/useColorScheme"

function DragIcon({ w, h }) {
  const [colorScheme] = useColorScheme()

  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="opacity-50"
    >
      <circle
        cx="5"
        cy="3"
        r="2"
        fill={colorScheme === "dark" ? "#F8FAFC" : "#0F172A"}
      />
      <circle
        cx="5"
        cy="10"
        r="2"
        fill={colorScheme === "dark" ? "#F8FAFC" : "#0F172A"}
      />
      <circle
        cx="5"
        cy="16"
        r="2"
        fill={colorScheme === "dark" ? "#F8FAFC" : "#0F172A"}
      />
      <circle
        cx="12"
        cy="3"
        r="2"
        fill={colorScheme === "dark" ? "#F8FAFC" : "#0F172A"}
      />
      <circle
        cx="12"
        cy="10"
        r="2"
        fill={colorScheme === "dark" ? "#F8FAFC" : "#0F172A"}
      />
      <circle
        cx="12"
        cy="16"
        r="2"
        fill={colorScheme === "dark" ? "#F8FAFC" : "#0F172A"}
      />
    </svg>
  )
}
DragIcon.propTypes = {
  w: PropTypes.number,
  h: PropTypes.number,
}
DragIcon.defaultProps = {
  w: 16,
  h: 16,
}

export default DragIcon
