import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    performanceChartData: { "-1": [] },
    maxMinProfitLossesChartData: null,
    portfoliosNumber: 2,
    exposureData: null,
    exposureSummaryData: null,
    portfolios: null,
    issuersData: null,
    timePeriod: null,
  },
}
const DEFAULT_DATA = initialState

export const chartsDataSlice = createSlice({
  name: "chartsData",
  initialState,
  reducers: {
    updateCharstData: (state, action) => {
      state.data = action.payload
    },
    resetCharstData: (state) => {
      state.data = DEFAULT_DATA
    },
  },
})

export const { updateCharstData, resetCharstData } = chartsDataSlice.actions

export default chartsDataSlice.reducer
