import PropTypes from "prop-types"
import { ChevronDownIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid"
// STORE
import { useSelector } from "react-redux"
// PROPTYPES
import { dataGridRowConfigShape } from "../../../propTypes/AppPropTypes"
// COMPONENTS
import TableFilterButton from "../filters/TableFilterButton"
import TableFilterButtonType from "../filters/TableFilterButtonType"
import TableFilterButtonCategory from "../filters/TableFilterButtonCategory"
import TableSearchField from "../filters/TableSearchField"
// UTILS
import { doLineBreak } from "../utilities/functions"

function DataGridHeaderCol({ headingInfo, handleSort }) {
  const sortOptions = useSelector((state) => state.sorting.data)

  const displaySortArrow = () => {
    let opacity = "opacity-100"
    let rotate = "rotate-0"
    let textColor = "text-slate-500 dark:text-slate-400"
    // EL ACTIVO
    if (sortOptions.sortBy === headingInfo.cellKey) {
      opacity = "opacity-100"
      textColor = "text-blue-500 dark:text-blue-400"
      rotate = sortOptions.sortOrder === "ASC" ? "rotate-180" : "rotate-0"
    }
    return (
      <button
        className={`w-4 h-full shrink-0 grid place-items-center transition duration-300
          ${opacity} ${textColor} group-hover:opacity-100
        `}
        onClick={() => handleSort(headingInfo.cellKey)}
      >
        {sortOptions.sortBy === headingInfo.cellKey ? (
          <ChevronDownIcon
            className={`h-4 w-4 transition-transform ${rotate}`}
          />
        ) : (
          <ChevronUpDownIcon className="h-3 w-3" />
        )}
      </button>
    )
  }

  const displayActionValue = () => {
    switch (headingInfo.action) {
      case "filter":
        return (
          <TableFilterButton
            title={headingInfo.name}
            filterId={headingInfo.cellKey}
            showSearch={headingInfo.showSearch}
          />
        )
      case "providerFilter":
        return (
          <TableFilterButton
            title={headingInfo.name}
            filterId={headingInfo.cellKey}
            showSearch={headingInfo.showSearch}
          />
        )
      case "typeFilter":
        return (
          <TableFilterButtonType
            title={headingInfo.name}
            filterId={headingInfo.cellKey}
          />
        )
      case "catFilter":
        return (
          <TableFilterButtonCategory title="Categoría" filterId="category" />
        )
      case "search":
        return <TableSearchField />
      default:
        return (
          <span
            role="button"
            tabIndex="0"
            onClick={() => handleSort(headingInfo.cellKey)}
            onKeyUp={() => {}}
            className={`text-xxs 3xl:text-[11px] uppercase
            ${headingInfo.align}
            ${
              headingInfo.action === "custom"
                ? "text-blue-400"
                : "text-slate-500 dark:text-slate-400"
            }
          `}
          >
            {headingInfo.lineBreak
              ? doLineBreak(headingInfo.name, headingInfo.lineBreak)
              : headingInfo.name}
          </span>
        )
    }
  }

  return (
    <div
      className={`group relative pr-0 flex gap-0.5 items-center bg-slate-100 dark:bg-slate-800
        3xl:pr-0
        ${headingInfo.span}
        ${headingInfo.display}
        ${headingInfo.justify}
      `}
      title={headingInfo.title ? headingInfo.title : headingInfo.name}
    >
      {displayActionValue()}
      {headingInfo.sortable && displaySortArrow()}
    </div>
  )
}

DataGridHeaderCol.propTypes = {
  headingInfo: dataGridRowConfigShape.isRequired,
  handleSort: PropTypes.func.isRequired,
}

export default DataGridHeaderCol
