/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Transition } from "@headlessui/react"
import { parseISO } from "date-fns"
import { performanceShapeArray } from "../../../propTypes/AppPropTypes"

// HOOKS
import useCheckPortfolioExists from "../../../hooks/useCheckPortfolioExists"

// COMPONENTS
import ToggleSectionButton from "./ToggleSectionButton"
import ScreenshotButton from "../ScreenshotModal/ScreenshotButton"
import LineChart from "../../charts/LineChart"
import AnalysisReturnsTable from "../../charts/ReturnsTable/AnalysisReturnsTable"
import ReturnsTableLegacy from "../../charts/ReturnsTable/ReturnsTableLegacy"
import RenderIf from "../../helpers/RenderIf"
import useColorCompany from "../../../hooks/useColorCompany"

import { formatReturnsDate, formatToFloat } from "../utilities/functions"
import useChartDataHandlers from "../../../hooks/useChartDataHandlers"
import ReturnDatePicker from "../ReturnDatePicker/ReturnDatePicker"
import ReturnDatePickerWithSelect from "../ReturnDatePickerWithSelect/ReturnDatePickerWithSelect"
import { calculateRelativeDateFrom } from "../../../utils/DateCalculations"
import InfoTooltip from "../Tooltip/InfoTooltip"

function AnalysisReturns({
  performanceData,
  returnsDates,
  performanceTableData,
  timePeriod,
  periodList,
  isDownSample,
  emptyDates,
  onOfComponent,
  handleTakeScreenshot,
}) {
  // REF
  const infoModalRef = useRef()
  // STATE
  const [showInfo, setShowInfo] = useState(false)
  // HOOKS
  const checkPortfolioExists = useCheckPortfolioExists()
  const portfolioExistenceStatus = checkPortfolioExists()
  const { getColorsCompany } = useColorCompany()

  // STORE
  const appData = useSelector((state) => state.appData.data)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )


  const minDate = typeof (returnsDates?.[0]) === "string" ? parseISO(returnsDates?.[0]) : returnsDates?.[0]
  const maxDate = typeof (returnsDates?.[1]) === "string" ? parseISO(returnsDates?.[1]) : returnsDates?.[1]
  const minDateForClosingDate = calculateRelativeDateFrom(maxDate, 2)

  const selectedDate = periodList?.find((item) => item.value === timePeriod)?.date
  const selectedTimePeriod = periodList?.find((item) => item.value === timePeriod)

  const [firstSelected, setFirstSelected] = useState(typeof (selectedDate?.[0]) === "string" ? parseISO(selectedDate?.[0]) : selectedDate?.[0])
  const [lastSelected, setLastSelected] = useState(typeof (selectedDate?.[1]) === "string" ? parseISO(selectedDate?.[1]) : selectedDate?.[1])

  const periodToDisplay = {
    "5 Años": "de los últimos 5 años",
    "3 Años": "de los últimos 3 años",
    "1 Año": " del último año",
    "YTD": "desde inicio de año",
    "6 meses": "de los últimos 6 meses",
    "3 meses": "de los últimos 3 meses",
    "1 mes": "del último mes"
  }

  useEffect(() => {
    setFirstSelected(typeof (selectedDate?.[0]) === "string" ? parseISO(selectedDate?.[0]) : selectedDate?.[0])
    setLastSelected(typeof (selectedDate?.[1]) === "string" ? parseISO(selectedDate?.[1]) : selectedDate?.[1])
  }, [selectedDate])

  const {
    handleStartDateChange,
    handleEndDateChange,
    handleSelector
  } = useChartDataHandlers(
    firstSelected,
    lastSelected,
    setFirstSelected,
    setLastSelected,
  )

  // CALLBACKS
  const takeScreenshotHandler = () => {
    const today = new Date()
    const modalData = {
      type: "returns",
      portfolioExistenceStatus,
      filename: `Rentabilidad ${periodList?.filter((obj) => obj.value === +timePeriod)[0].key
        } - ${new Intl.DateTimeFormat("es-CL").format(today)}`,
      chart: {
        data: performanceData,
        timePeriod,
        isScreenerPage: false,
      },
      table: {
        show: performanceTableData,
        data: performanceTableData,
      },
    }

    handleTakeScreenshot(modalData)
  }

  const getLastDate = () => {
    return (
      timePeriod === 0 ? (
        <p className="text-xs font-bold ">
          Rentabilidad desde el {formatReturnsDate(firstSelected)}
        </p>
      ) : (
        <p className="text-xs">
          <span className="font-bold">Rentabilidad {periodToDisplay[selectedTimePeriod.key]} </span>
        </p>
      )
    )
  }

  const tableTitle = () => {
    return (
      <p className="text-xs font-bold pb-4">
        Rentabilidad histórica al {formatReturnsDate(lastSelected)}
      </p>
    )
  }

  return (
    <div id="returns">
      <header className="w-full flex flex-between justify-between">
        <div className="flex items-center">
          <h2 className="text-base font-bold">Rentabilidad nominal</h2>
          <div className="relative group border-none rounded-md w-8 h-8 grid place-content-center ">
            <InfoTooltip content="El cálculo de rentabilidad considera todos los eventos de capital (dividendos, repartos, etc) y está calculado en Pesos (CLP)" />
          </div>
          <ToggleSectionButton component="returns" action={onOfComponent} />
          <RenderIf isTrue={appData.screenshot_ability}>
            <ScreenshotButton takeScreenshotHandler={takeScreenshotHandler} />
          </RenderIf>
        </div>
        <div className="flex justify-end items-center gap-4">
          <p className="text-xs text-slate-500 dark:text-slate-400"> Información al: </p>
          <ReturnDatePicker
            value={lastSelected}
            onChange={handleEndDateChange}
            minDate={minDateForClosingDate}
            maxDate={maxDate}
          />
        </div>
      </header>
      <div
        className={`
        transition-opacity duration-500
          ${analysisDataConfig.showComponents.returns
            ? "opacity-100 pointer-events-auto"
            : "opacity-30 pointer-events-none"
          }
        `}
      >
        {performanceData && (
          <>
            <div className="col-span-2 justify-between flex gap-4 py-4">
              {getLastDate()}
              <ReturnDatePickerWithSelect
                valueSelect={timePeriod}
                onChangeSelect={(event) => { handleSelector(event) }}
                onChangeDateFrom={handleStartDateChange}
                onChangeDateTo={handleEndDateChange}
                periodList={periodList}
                firstReturnDate={firstSelected}
                lastReturnDate={lastSelected}
                minDateFrom={minDate}
                MaxDateTo={maxDate}
              />
            </div>

            <div data-name="chart" className="mb-10">
              <LineChart
                performanceData={performanceData}
                downSample={isDownSample}
                emptyDates={emptyDates}
                colors={getColorsCompany()}
                showLegend={true}
              />
            </div>
          </>
        )}
        {performanceTableData && (
          <div data-name="table" className="w-full text-xs">
            {tableTitle()}
            {Object.hasOwn(
              performanceTableData[Object.keys(performanceTableData)[0]],
              "portfolioData",
            ) ? (
              <AnalysisReturnsTable
                data={performanceTableData}
                portfolioExistenceStatus={portfolioExistenceStatus}
                colors={getColorsCompany()}
              />
            ) : (
              <ReturnsTableLegacy
                data={performanceTableData}
                portfolioExistenceStatus={portfolioExistenceStatus}
                colors={getColorsCompany()}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

AnalysisReturns.propTypes = {
  performanceData: performanceShapeArray.isRequired,
  performanceTableData: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
        PropTypes.array,
      ]),
    ),
  ).isRequired,
  timePeriod: PropTypes.number,
  periodList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  isDownSample: PropTypes.bool,
  emptyDates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
}

AnalysisReturns.defaultProps = {
  isDownSample: false,
  emptyDates: [[], []],
  timePeriod: -2,
  periodList: [{ key: "YTD", value: -2 }],
}

export default AnalysisReturns
