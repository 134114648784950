import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isPreviewOpen: false,
  previewPanelWidth: 0,
  isOverviewOpen: null,
}

export const portfolioPanelSlice = createSlice({
  name: "portfolioPanel",
  initialState,
  reducers: {
    togglePreviewPanel: (state, action) => {
      state.isPreviewOpen = action.payload
    },
    setPreviewPanelWidth: (state, action) => {
      state.previewPanelWidth = action.payload
    },
    setOverviewPanelOpen: (state, action) => {
      state.isOverviewOpen = action.payload
    },
  },
})

export const {
  togglePreviewPanel,
  setPreviewPanelWidth,
  setOverviewPanelOpen,
} = portfolioPanelSlice.actions

export default portfolioPanelSlice.reducer
