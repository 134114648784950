import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    resultsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
  },
}

export const PaginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.data.currentPage = action.payload
    },
    updateTotalPages: (state, action) => {
      state.data.totalPages = action.payload
    },
    updateResultsPerPage: (state, action) => {
      state.data.resultsPerPage = action.payload
    },
  },
})

export const { updateResultsPerPage, updateCurrentPage, updateTotalPages } =
  PaginationSlice.actions

export default PaginationSlice.reducer
