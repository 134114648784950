import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  funds: {
    portfolioA: [],
    portfolioB: [],
  },
}

export const portfolioCompositionSlice = createSlice({
  name: "portfolioComposition",
  initialState,
  reducers: {
    setPortfolioComposition: (state, action) => {
      state.funds = action.payload
    },
    resetPortfolioComposition: (state) => {
      state.funds = initialState.funds
    },
  },
})

export const { setPortfolioComposition, resetPortfolioComposition } =
  portfolioCompositionSlice.actions

export default portfolioCompositionSlice.reducer
