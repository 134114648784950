import { childrenPropTypes } from "../../../propTypes/AppPropTypes"

function FormGroup({ children }) {
  return <div className="relative w-full mb-4">{children}</div>
}
FormGroup.propTypes = {
  children: childrenPropTypes.isRequired,
}

export default FormGroup
