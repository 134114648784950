const Dictionary = new Map([
  // FUND PROVIDERS (Quitar mapeos a palabras multiples. ej: Santander no debe pasar a Santander Asset Manager. Dejar solo la palabra que busco)
  ["Alliancebernstein", "AllianceBernstein"],
  ["Blackrock", "BlackRock"],
  ["Bluebay", "BlueBay"],
  ["Doubleline", "DoubleLine"],
  ["Mainfirst", "MainFirst"],
  ["Pinebridge", "PineBridge"],
  ["Vaneck", "VanEck"],
  ["Wisdomtree", "WisdomTree"],
  ["Bancoestado", "BancoEstado"],
  ["Larrainvial", "LarrainVial"],
  ["Soyfocus", "SoyFocus"],
  ["Bancoestado", "BancoEstado"],
  ["Bice", "BICE"],
  ["Btg", "BTG"],
  ["Itau", "Itaú"],
  ["Sartor", "Sartor Finance Group"],
  ["Sura", "SURA"],
  ["Bci", "BCI"],
  ["Finasset", "FinAsset"],
  ["Hmc", "HMC"],
  ["Weg", "WEG"],
  ["Xlc", "XLC"],
  // OTHER
  ["Fic", "FIC"],
  ["Ii", "II"],
  ["Iii", "III"],
  ["Iv", "IV"],
  ["Vi", "VI"],
  ["Vii", "VII"],
  ["Viii", "VIII"],
  ["Xiii", "XIII"],
  ["Xiv", "XIV"],
  ["Xv", "XV"],
  ["Xvi", "XVI"],
  ["Xvii", "XVII"],
  ["Xviii", "XVIII"],
  ["Xix", "XIX"],
  ["Uf", "UF"],
  ["DÓLar", "Dólar"],
  ["DóLar", "Dólar"],
  ["Deposito", "Depósito"],
  ["Latam", "LATAM"],
  ["Usa", "USA"],
  ["Iii", "III"],
  ["Lva", "LVA"],
  ["Bci", "BCI"],
  ["DináMico", "Dinámico"],
  ["InversióN", "Inversión"],
  ["JapÓN", "Japón"],
  ["TemÁTico", "Temático"],
  ["MÁS", "Más"],
  ["Us", "US"],
  ["Cs", "CS"],
  ["Ishares", "iShares"],
  ["Etf", "ETF"],
  ["Msci", "MSCI"],
  ["Spdr", "SPDR"],
  ["Jpmorgan", "J.P. Morgan"],
  ["Jpm", "J.P. Morgan"],
  ["Hsbc", "HSBC"],
  ["Plc", "PLC"],
  ["Ubs", "UBS"],
  ["Usd", "USD"],
  ["Nn", "NN"],
  ["Em", "EM"],
  ["Emd", "EMD"],
  ["Isf", "ISF"],
  ["Icvc", "ICVC"],
  ["Mtx", "MTX"],
  ["Sicav", "SICAV"],
  ["Usa", "USA"],
  ["Ucits", "UCITS"],
  ["Sri", "SRI"],
  ["Jp", "J.P."],
  ["Iboxx", "iBoxx"],
  ["Adr/Gdr", "ADR/GDR"],
  ["Ftse", "FTSE"],
  ["Pimco", "PIMCO"],
  ["Bnp", "BNP"],
  ["Btg", "BTG"],
  ["Agf", "AGF"],
  ["Ag", "AG"],
  ["Glg", "GLG"],
  ["Sparx", "SPARX"],
  ["Aqr", "AQR"],
  ["Axa", "AXA"],
  ["Bmo", "BMO"],
  ["Efg", "EFG"],
  ["Dfa", "DFA"],
  ["Prc", "PRC"],
  ["Qqq", "QQQ"],
  ["Gs", "GS"],
  ["Acm", "ACM"],
  ["Qi", "QI"],
  ["Gam", "GAM"],
  ["Mbs", "MBS"],
  ["Eur", "EUR"],
  ["Tiaa", "TIAA"],
  ["Cref", "CREF"],
  ["Esg", "ESG"],
  ["Oddo", "ODDO"],
  ["Bhf", "BHF"],
  ["Oddobhf", "ODDOBHF"],
  ["Sca", "SCA"],
  ["Sam", "SAM"],
  ["Az", "AZ"],
  ["Bnymellon", "BNY "],
  ["Bny", "BNY "],
  ["ex", "Ex"],
  ["Uk", "UK"],
  ["Dws", "DWS"],
  ["Edm", "EDM"],
  ["Mfs", "MFS"],
  ["SelecciÓN", "Selección"],
  ["DepÓsito", "Depósito"],
  ["DepóSito", "Depósito"],
  ["AccióN", "Acción"],
  ["JapóN", "Japón"],
  ["Glb", "GLB"],
  ["Lv", "LV"],
  ["10dias", "10 días"],
  ["15dias", "15 días"],
  ["Habil", "Hábiles"],
  ["CreacióN", "Creación"],
  ["MíNimo", "Mínimo"],
  ["EstratéGico", "Estratégico"],
  ["dias", "días"],
  ["habil", "hábil"],
  ["usd", "USD"],
  ["clp", "CLP"],
  ["fm", "FM"],
  ["Hmc", "HMC"],
  ["S/i", "S/I"],
  ["Dva", "DVA"],
  ["Futura.�A", "Futura. A"],
  ["C��", "C"],
  ["Hph", "HPH"],
  ["Rv", "RV"],
  ["Rf", "RF"],
  ["Bi", "BI"],
  ["GestióN", "Gestión"],
  ["Asf", "ASF"],
  ["Ix", "IX"],
  ["L.P", "L.P."],
  ["íNdice", "Índice"],
  ["DíAs", "Días"],
  ["Dap", "DAP"],
  ["A", "a"],
  ["Lcp", "LCP"],
  ["AñO", "Año"],
  ["DepóSito", "Depósito"],
  ["Mvp", "MVP"],
  ["Vepf", "VEPF"],
  ["LarraíN", "Larraín"],
])

export default Dictionary
