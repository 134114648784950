import { useState, useContext } from "react";

// CONTEXT
import FirebaseContext from "../../firebase/FirebaseContext";
import AuthUserContext from "../../firebase/AuthUserContext";

// COMPONENTS
import AccountSidebar from "../../components/ui/accounts/AccountSidebar";
import InputField from "../../components/ui/forms/InputField";
import FormGroup from "../../components/ui/forms/FormGroup";
import { AccountBodyBox } from "../../components/ui/accounts/AccountComponents";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
  message: "",
  status: "",
};

function PasswordChangePage() {
  // CONTEXT
  const { doPasswordUpdate } = useContext(FirebaseContext);
  const user = useContext(AuthUserContext);
  // STATE
  const [formFields, setFormFields] = useState(INITIAL_STATE);
  const isInvalid =
    formFields.passwordOne !== formFields.passwordTwo ||
    formFields.passwordOne === "";

  // CALLBACK
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const { passwordOne } = formFields;

    doPasswordUpdate(passwordOne)
      .then(() => {
        setFormFields({
          ...INITIAL_STATE,
          ...{
            error: true,
            message: "Todo bien, ya cambiamos tu contraseña.",
            status: "success",
          },
        });
      })
      .catch((error) => {
        let message = "";
        switch (error.code) {
          case "auth/requires-recent-login":
            message =
              "Por seguridad, te solicitamos que vuelvas a ingresar antes de cambiar la contraseña";
            break;
          case "auth/weak-password":
            message =
              "La contraseña que seleccionaste es muy débil. Asegúrate que tenga más de 6 caracteres entre letras y números.";
            break;
          default:
            message =
              "Nos encontramos con un problema, por favor inténtalo nuevamente.";
        }
        setFormFields({
          ...formFields,
          ...{ error, message },
        });
      });
  };
  const onChangeHandler = (event) => {
    setFormFields({
      ...formFields,
      ...{ [event.target.name]: event.target.value },
    });
  };

  return (
    <div className="grid grid-cols-user gap-20 items-start">
      <AccountSidebar user={user} />
      <main className="pt-5 pr-20">
        <div className="mb-5">
          <h1 className="text-base">Cambiar contraseña</h1>
          <p className="text-sm text-slate-400">
            Llena los siguientes campos con tu nueva contraseña para cambiarla.
          </p>
        </div>

        <div className="flex flex-col gap-5">
          <AccountBodyBox>
            <form
              onSubmit={onSubmitHandler}
              noValidate
              className="w-full max-w-[800px]"
            >
              <FormGroup>
                <InputField
                  type="password"
                  name="passwordOne"
                  placeholder="Ingresa tu nueva contraseña"
                  defaultValue={formFields.passwordOne}
                  label="Nueva contraseña"
                  callback={onChangeHandler}
                />
              </FormGroup>

              <FormGroup>
                <InputField
                  type="password"
                  name="passwordTwo"
                  placeholder="Confirmar nueva contraseña"
                  defaultValue={formFields.passwordTwo}
                  label="Repetir nueva contraseña"
                  callback={onChangeHandler}
                />
              </FormGroup>

              <div className="flex justify-start">
                <button
                  type="submit"
                  disabled={isInvalid}
                  className="w-[200px] py-2 px-3 text-sm uppercase border border-blue-500 text-blue-500 bg-transparent rounded-md
                    hover:bg-blue-500 hover:text-slate-600
                    disabled:opacity-50 disabled:bg-slate-600 disabled:text-slate-400 disabled:border-slate-800 disabled:cursor-default
                    hover:disabled:bg-slate-600 hover:disabled:text-slate-400
                    !focus:ring-0 focus:border-blue-500 !focus-visible:ring-0
                    !active:ring-0
                    "
                >
                  Cambiar contraseña
                </button>
              </div>

              {formFields.error && <p>{formFields.message}</p>}
            </form>
          </AccountBodyBox>
        </div>
      </main>
    </div>
  );
}

export default PasswordChangePage;
