import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: "light",
}

export const ColorSchemeSlice = createSlice({
  name: "colorScheme",
  initialState,
  reducers: {
    updateColorScheme: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { updateColorScheme } = ColorSchemeSlice.actions

export default ColorSchemeSlice.reducer
