/* eslint-disable no-unused-vars */
import React from "react"
import PropTypes from "prop-types"
import "./styles.css"

function Button({
  icon,
  size,
  action,
  label,
  valueAction,
  iconPosition,
  transparent,
  className,
}) {
  return (
    <button
      type="button"
      className={`button-component button-${size} ${className || ""}
      ${icon && !label && "button-icon"}
      ${icon && !label && transparent && "button-icon-transparent"}
      ${iconPosition === "left" && "button-with-icon "}
      ${iconPosition === "right" && "button-with-icon "}
      `}
      onClick={action}
      value={valueAction}
    >
      {iconPosition !== "right" ? icon : null}
      {label || null}
      {iconPosition === "right" ? icon : null}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.element,
  valueAction: PropTypes.string,
  transparent: PropTypes.bool,
}

Button.defaultProps = {
  label: "",
  size: "",
  className: "",
  action: undefined,
  icon: undefined,
  valueAction: "",
  iconPosition: "center",
  transparent: false,
}
export default Button
