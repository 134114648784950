import PropTypes from "prop-types"
import cn from "../utilities/ClassMerge"
import { childrenPropTypes } from "../../../propTypes/AppPropTypes"

function AnalysisBox({ children, className }) {
  return (
    <section
      className={cn(
        "relative z-0 px-9 py-12 bg-white dark:bg-slate-900 rounded-lg border border-slate-200 dark:border-slate-700",
        className,
      )}
    >
      {children}
    </section>
  )
}

AnalysisBox.propTypes = {
  children: childrenPropTypes.isRequired,
  className: PropTypes.string,
}
AnalysisBox.defaultProps = {
  className: "",
}

export default AnalysisBox
