import { Link } from "react-router-dom"
import {
  ChevronDoubleLeftIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline"

import * as APP_ROUTES from "../../routes/APP_ROUTES"

function UnauthorizedPage() {
  return (
    <main className="px-8 pt-20">
      <h1 className="mb-2 flex items-center gap-2 text-slate-50 font-light text-lg">
        <LockClosedIcon className="w-4 h-4" />
        Lo sentimos
      </h1>
      <p className="text-sm text-slate-400 border-b border-slate-600 pb-4 mb-4">
        No tienes los permisos necesarios para entrar a esta sección.
      </p>
      <Link
        to={APP_ROUTES.SCREENER}
        className="w-[150px] flex items-center gap-2 text-xs font-light px-2 py-1 bg-slate-700 rounded-sm hover:bg-blue-500 hover:text-slate-900"
      >
        <ChevronDoubleLeftIcon className="w-5 h-5" /> Volver al catálogo
      </Link>
    </main>
  )
}

export default UnauthorizedPage
