import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

const InfoTooltip = ({
    content
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleMouseEnter = () => {
        setIsVisible(true);
    }
    const handleMouseLeave = () => {
        setIsVisible(false);
    }

    return (
        <div
            className="relative inline-block"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <button className="block text-blue-500 dark:text-blue-400 shrink-0 cursor-pointer">
                <InformationCircleIcon className="w-5 h-5 shrink-0" />
            </button>
            {isVisible && (
                <div className="absolute z-10 bg-slate-300 dark:bg-slate-700 text-xs rounded-lg shadow-lg w-48 text-center top-6">
                    <p className="px-4 py-2">{content}</p>
                </div>
            )}
        </div>
    )
}

export default InfoTooltip;