const filterData = (fundList, filters, omitFilter) =>
  fundList.filter((fund) => {
    if (
      (omitFilter === "asset_class" ||
        filters.asset_class.length === 0 ||
        filters.asset_class.includes(fund.asset_class)) &&
      (omitFilter === "category" ||
        filters.category.length === 0 ||
        filters.category.includes(fund.category)) &&
      (omitFilter === "fund_currency" ||
        filters.fund_currency.length === 0 ||
        filters.fund_currency.includes(fund.fund_currency)) &&
      (omitFilter === "aum" ||
        filters.aum.length === 0 ||
        fund.aum >= Math.min(...filters.aum)) &&
      (omitFilter === "fund_provider" ||
        filters.fund_provider.length === 0 ||
        filters.fund_provider.includes(fund.fund_provider)) &&
      (omitFilter === "fund_type" ||
        filters.fund_type.length === 0 ||
        filters.fund_type.includes(fund.fund_type))
    ) {
      return true
    }
    return false
  })

const reduceData = (appFilters, appliedFilters, fundList) => {
  const initialData = {}

  Object.keys(appFilters).forEach((filterName) => {
    const filteredFundList = filterData(fundList, appliedFilters, filterName)
    appFilters[filterName].forEach((filterValue) => {
      filteredFundList.forEach((fund) => {
        if (!Object.keys(initialData).includes(filterName)) {
          initialData[filterName] = []
        }
        if (filterName !== "aum") {
          if (filterValue === fund[filterName]) {
            if (filterName !== "aum") {
              if (!initialData[filterName].includes(filterValue)) {
                initialData[filterName].push(filterValue)
              }
            }
          }
        } else {
          const options = appFilters.aum.filter(
            (value) => value < fund[filterName],
          )
          options.forEach((option) => {
            if (!initialData[filterName].includes(+option)) {
              initialData[filterName].push(+option)
            }
          })
        }
      })
    })
  })

  return initialData
}

export default reduceData
