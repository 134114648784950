import PropTypes from "prop-types"

const ProposalDataPropType = PropTypes.arrayOf(
  PropTypes.shape({
    advisor_company: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string}),
    advisor_id: PropTypes.string,
    advisor_lastname: PropTypes.string,
    advisor_name: PropTypes.string,
    analysis_id: PropTypes.string,
    base_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    distribution: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    portfolios: PropTypes.shape({
      portfolioName: PropTypes.string,
      portfolioFunds: PropTypes.arrayOf(
        PropTypes.shape({
          fundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          fund_name: PropTypes.string,
          shareClassName: PropTypes.string,
          amount: PropTypes.number,
          weight: PropTypes.number,
        }),
      ),
    }).isRequired,
    timestamp: PropTypes.instanceOf(Date),
  }),
)

export default ProposalDataPropType
