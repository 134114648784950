import { useContext, useState, useEffect } from "react"
import { onSnapshot } from "firebase/firestore"
import FirebaseContext from "./FirebaseContext"

const useSnapshot = (ref) => {
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  // STATE
  const [data, setData] = useState(null)

  useEffect(() => {
    const unsubscribe = onSnapshot(ref(), async (querySnapshot) => {
      const obj = {}
      querySnapshot.forEach((doc) => {
        obj[doc.id] = doc.data()
      })
      setData(obj)
    })

    return () => unsubscribe()
  }, [firebase, ref])

  return [data]
}

export { useSnapshot }
