import { useLocation, Navigate } from "react-router-dom";

import * as ROUTES from "../../../routes/APP_ROUTES";
import SETTINGS from "@/settings/settings";

// COMPONENTS
import AuthPageFrameHeader from "./AuthPageFrameHeader";
// import AuthPageFrameFooter from "./AuthPageFrameFooter";

const NAV = [
  { path: "landing/producto", label: "Producto" },
  { path: "landing/equipo", label: "Equipo" },
  { path: "landing/blog", label: "Blog" },
  { path: "landing/faq", label: "FAQ" },
  { path: "/contact", label: "Contacto" },
];
let newNav = [];
if (SETTINGS.environment === "dev") {
  newNav = NAV.map((item) => {
    if (item.path === "/contact") {
      return { ...item };
    }
    else {
      return { ...item, path: `${item.path}.html` };
    }
  });
}

function AuthPageFrame({ user, title, subtitle, description, children }) {
  const location = useLocation();
  return user ? (
    <Navigate to={ROUTES.SCREENER} state={{ from: location }} replace />
  ) : (
    <div className="relative w-screen min-h-screen text-slate-900 bg-white flex flex-col justify-start gap-10 items-stretch">
      <AuthPageFrameHeader navigation={SETTINGS.environment === "dev" ? newNav : NAV} />
      <section className="relative pt-10 pb-10 bg-blueGray-100">
        <div className="relative z-10 container px-4 mx-auto">
          <div>
            <p className="mb-6 text-sm text-blue-500 text-center font-semibold uppercase tracking-px">
              {subtitle}
            </p>
            <h2 className="text-5xl text-center font-bold font-heading tracking-px-n leading-tight md:max-w-xl mx-auto">
              {title}
            </h2>
            <h3 className="text-base text-center leading-normal md:max-w-sm mx-auto">
              {description}
            </h3>
          </div>
          {children}
        </div>
      </section>
      {/* <AuthPageFrameFooter navigation={NAV} /> */}
    </div>
  );
}

export default AuthPageFrame;
