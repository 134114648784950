import { useState, useRef, useEffect, useCallback } from "react"
import { isEqual } from "lodash"
import { Link, useSearchParams } from "react-router-dom"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

// ROUTES
import { useSelector } from "react-redux"
import * as APP_ROUTES from "../../routes/APP_ROUTES"

// STORE

// HOOKS
import { useFetch } from "../../hooks/useFetch"
import useMyAnalysisFunctions from "../../components/ui/accounts/MyAnalysisComponents/useMyAnalysisFunctions"

// COMPONENTS
import {
  AccountBodyBox,
  AccountBoxContent,
} from "../../components/ui/accounts/AccountComponents"
import LoadingSpinner from "../../components/ui/loadingSpinner/LoadingSpinner"
import MyAnalysisProposal from "../../components/ui/accounts/MyAnalysisComponents/MyAnalysisProposal"
import AccountPagination from "../../components/ui/accounts/AccountPagination"
import MyAnalysisSidebar from "../../components/ui/accounts/MyAnalysisComponents/MyAnalysisSidebar"

function UserMyAnalisysPage() {
  // MEMO
  const INITIAL_FILTERS = {
    rowPerPage: 5,
    currentPage: 1,
    dateStart: "",
    dateEnd: "",
    clientName: "all",
    clientProfile: "all",
    clientInvestmentType: "all",
    amountMin: "",
    amountMax: null,
    portfolioNumber: "all",
    apvOnly: false,
    searchTerm: "",
  }
  // STORE
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((state) => state.urls)
  // HOOKS
  const [params] = useSearchParams()
  const { setupAnalysisData } = useMyAnalysisFunctions()
  // REF
  const UIDRef = useRef(params.get("uid") || user?.uid)
  // STATE
  const [rawData, setRawData] = useState(null)
  const [analysisList, setAnalysisList] = useState(null)
  const [controlOptions, setControlOptions] = useState(null)
  // FILTERS AND SEARCH
  const [numRows, setNumRows] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [filters, setFilters] = useState(INITIAL_FILTERS)
  const [isFilters, setIsFilters] = useState(false)

  // LIFE CYCLE
  useFetch(
    `${urls.API_URL}/advisors/${UIDRef.current}/proposal_list`,
    {},
    (response) => {
      const { data } = response
      if (Object.keys(data).length > 0) {
        const transformedData = setupAnalysisData(data, setControlOptions)
        setRawData(transformedData)
      } else {
        setAnalysisList([])
      }
    },
    false,
    [setAnalysisList],
  )

  // HANDLE SEARCH AND FILTERS
  const filterList = useCallback(() => {
    const filteredList = []

    const reducedList = rawData.filter((proposal) => {
      if (filters.apvOnly === true) {
        return !!proposal.is_apv
      }
      return proposal
    })
    if (filters?.searchTerm ==="") {
      setIsFilters(false)
    }
    reducedList.forEach((proposal) => {
      const regex = new RegExp(filters.searchTerm, "gi")
      const { title } = proposal
      if (
        (filters.clientName === "all" ||
          filters.clientName.toUpperCase() ===
          proposal.client_name.toUpperCase()) &&
        (filters.clientProfile === "all" ||
          filters.clientProfile.toUpperCase() ===
          proposal.client_profile.toUpperCase()) &&
        (filters.clientInvestmentType === "all" ||
          filters.clientInvestmentType.toUpperCase() ===
          proposal.client_investment_type.toUpperCase()) &&
        (filters.portfolioNumber === "all" ||
          +filters.portfolioNumber === +proposal.portfolios_number) &&
        (filters.dateStart === "" || proposal.timestamp >= filters.dateStart) &&
        (filters.dateEnd === "" || proposal.timestamp <= filters.dateEnd) &&
        // (filters.amountMin === "" ||
        //   proposal.totalAmount >= filters.amountMin) &&
        // (filters.amountMax === "" ||
        //   proposal.totalAmount <= filters.amountMax) &&
        (filters.searchTerm === "" || title.match(regex))
      ) {
        filteredList.push(proposal)
      }
    })
    // TOTAL DE FILAS DESPUÉS DE FILTRAR
    setNumRows(filteredList.length)
    // PAGINATE AND SET DATA
    setTotalPages(Math.ceil(filteredList.length / filters.rowPerPage))
    const sliceStart = (filters.currentPage - 1) * filters.rowPerPage
    const sliceEnd = sliceStart + filters.rowPerPage
    setAnalysisList(filteredList.slice(sliceStart, sliceEnd))
  }, [rawData, filters])

  const updateFilters = (keyToUpdate, data) => {
    setIsFilters(true)
    setFilters({
      ...filters,
      [keyToUpdate]: data,
    })
  }
  const clearFilters = () => {
    setIsFilters(false)
    setFilters(INITIAL_FILTERS)
  }

  useEffect(() => {
    /* Aplicar el filtro cuando llega la data por primera. */
    if (rawData) filterList()
  }, [rawData, filterList])
  

  const renderConditionalComponent = () => {
    if(analysisList.length === 0 && !isFilters){
      return (
        <div className="col-span-2">
        <AccountBodyBox>
          <AccountBoxContent>
            <div className="w-full py-4">
              <h2 className="mb-4 text-base">
                No hay propuestas disponibles
              </h2>
              <p className="text-xs text-slate-500 dark:text-slate-400 flex items-start justify-start gap-1">
                <ExclamationCircleIcon className="w-5 h-5" />
                Crea propuestas desde el{" "}
                <Link
                  to={APP_ROUTES.SCREENER}
                  title="Catálogo de fondos"
                  className="text-blue-500 dark:text-blue-400"
                >
                  {" "}
                  catálogo de fondos
                </Link>
                .
              </p>
            </div>
          </AccountBoxContent>
        </AccountBodyBox>
      </div>
      )
    }
    return (
      <>
        <MyAnalysisSidebar
          filters={filters}
          updateFilters={updateFilters}
          controlOptions={controlOptions}
          clearFilters={clearFilters}
          showClearFilters={!isEqual(filters, INITIAL_FILTERS)}
        />
        {analysisList.length>0? (<div>
          <div className="mb-2 grid grid-cols-2 items-end">
            <p className="m-0 text-xs text-slate-600 dark:text-slate-400">{`Página ${filters.currentPage} de ${totalPages}`}</p>
            <p className="m-0 text-xs text-slate-600 dark:text-slate-400 justify-self-end">{`Mostrando ${filters.rowPerPage > numRows
              ? numRows
              : filters.rowPerPage
              } de ${numRows} propuestas.`}</p>
          </div>
          <MyAnalysisProposal
            userID={UIDRef.current}
            analysisList={analysisList}
            updateRawData={setRawData}
          />

          <div className="my-5">
            <AccountPagination
              filters={filters}
              updateFilters={updateFilters}
              totalPages={totalPages}
            />
          </div>
        </div>): (<div className="w-full py-4">
            <h2 className="mb-4 text-base">
              {"No se encontraron propuestas para los filtros ingresados."}
            </h2>
          </div>)}
      </>
    )
  }
  return (
    <main className="h-full min-h-[calc(100vh-5px)] pt-14 bg-slate-50 dark:bg-slate-800">
      {!analysisList ? (
        <div className="w-full min-h-[500px] grid place-content-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="max-w-[1440px] mx-auto pt-14">
          <div className="grid grid-cols-[300px,_1fr] items-start gap-10">
            {renderConditionalComponent()}
          </div>
        </div>
      )}
    </main>
  )
}

export default UserMyAnalisysPage
