import PropTypes from "prop-types"

// STORE
import { useSelector } from "react-redux"
// HOOKS
// import usePreviewPanel from "../PreviewPanel/usePreviewPanel"

// PROPTYPES
import { minMaxChartDataPropTypes } from "../../../propTypes/AppPropTypes"

// COMPONENTS
import MinMaxBarChart from "../../charts/MinMaxBarChart/MinMaxBarChart"
import ToggleSectionButton from "./ToggleSectionButton"
import ScreenshotButton from "../ScreenshotModal/ScreenshotButton"
import RenderIf from "../../helpers/RenderIf"
import useColorCompany from "../../../hooks/useColorCompany"

// import { formatAmount } from "../utilities/functions"

function AnalysisRisks({ minMaxData, onOfComponent, handleTakeScreenshot }) {
  // HOOKS
  const { getColorsCompany, getColorPortfolio } = useColorCompany()
  // const { getPortfolioSelectionTotalAmount } = usePreviewPanel()
  // STORE
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)
  // const distributionOption = useSelector(
  //   (state) => state.portfolioSelection.distributionOption,
  // )
  const appData = useSelector((state) => state.appData.data)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )
  const portfoliosName = useSelector((state) => state.portfoliosName.data)

  const takeScreenshotHandler = () => {
    const today = new Date()
    const modalData = {
      type: "risks",
      filename: `Máximas pérdidas y ganancias - ${new Intl.DateTimeFormat(
        "es-CL",
      ).format(today)}`,
      data: minMaxData,
      portfolioNumber: 2,
    }
    handleTakeScreenshot(modalData)
  }

  return (
    <div id="risks">
      <header className="mb-4 flex items-center">
        <h2 className="analysis-box-header">
          Máximas pérdidas y ganancias del portafolio
        </h2>
        <ToggleSectionButton component="risks" action={onOfComponent} />
        <RenderIf isTrue={appData.screenshot_ability}>
          <ScreenshotButton takeScreenshotHandler={takeScreenshotHandler} />
        </RenderIf>
      </header>
      <div
        className="flex items-center gap-5 mb-10 justify-end"
        // className={`flex items-center gap-5 mb-10 ${
        //   distributionOption === "amount" ? "justify-between" : "justify-end"
        // }`}
      >
        {/* <p className="text-xs">
          Inversión :{" "}
          <strong className="font-bold">
            {formatAmount(getPortfolioSelectionTotalAmount())}
          </strong>
        </p> */}
        <div className="flex items-center gap-4 justify-end">
          {Object.keys(portfolios).map(
            (portfolioKey) =>
              portfolios[portfolioKey].portfolioFunds.length > 0 && (
                <span
                  key={portfolioKey}
                  className="mb-1 flex items-center gap-1 text-xxs"
                >
                  <span
                    className="w-3 h-3 rounded-sm block"
                    style={{
                      backgroundColor: `${getColorPortfolio(portfolioKey)}`,
                    }}
                  />
                  {portfoliosName[portfolioKey].portfolioName}
                </span>
              ),
          )}
        </div>
      </div>
      <div
        className={`
        transition-opacity duration-500
          ${
            analysisDataConfig.showComponents.risks
              ? "opacity-100 pointer-events-auto"
              : "opacity-30 pointer-events-none"
          }
        `}
      >
        <MinMaxBarChart
          data={minMaxData}
          chartOptions={{
            portfolioNumber: 2,
          }}
          colors={getColorsCompany()}
          showHeading={false}
          distributionOption={distributionOption}
          baseAmount={baseAmount}
        />
      </div>
    </div>
  )
}

AnalysisRisks.propTypes = {
  minMaxData: minMaxChartDataPropTypes.isRequired,
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
}

export default AnalysisRisks
