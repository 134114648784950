import { useMemo } from "react"
import PropTypes from "prop-types"
import {
  XMarkIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid"

function StatusMessage({ title, text, status, closeHandler }) {
  const statusMap = useMemo(
    () => ({
      bg: {
        error: "bg-rose-50",
        success: "bg-emerald-50",
        warning: "bg-yellow-50",
      },
      title: {
        error: "text-rose-800",
        success: "text-emerald-800",
        warning: "text-yellow-800",
      },
      text: {
        error: "text-rose-700",
        success: "text-emerald-700",
        warning: "text-yellow-700",
      },
      icon: {
        error: (
          <XCircleIcon className="h-5 w-5 text-rose-400" aria-hidden="true" />
        ),
        success: (
          <CheckCircleIcon
            className="h-5 w-5 text-emerald-400"
            aria-hidden="true"
          />
        ),
        warning: (
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        ),
      },
      button: {
        error:
          "bg-rose-50 p-1.5 text-rose-500 hover:bg-rose-100 focus:ring-rose-600 focus:ring-offset-rose-50",
        success:
          "bg-emerald-50 p-1.5 text-emerald-500 hover:bg-emerald-100 focus:ring-emerald-600 focus:ring-offset-emerald-50",
        warning:
          "bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50",
      },
    }),
    [],
  )

  return (
    <div className={`relative rounded-md p-4 mb-4 ${statusMap.bg[status]}`}>
      <div className="flex">
        <div className="flex-shrink-0">{statusMap.icon[status]}</div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${statusMap.title[status]}`}>
            {title}
          </h3>
          <div className={`mt-2 text-sm ${statusMap.text[status]}`}>
            <p>{text}</p>
          </div>
        </div>
        {closeHandler && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                onClick={() => closeHandler()}
                className={`inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${statusMap.button[status]}`}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
StatusMessage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  closeHandler: PropTypes.func,
}

StatusMessage.defaultProps = {
  closeHandler: null,
}

export default StatusMessage
