import { useState, useContext, Fragment, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import version from "../../../version.json";

// CONTEXT
import FirebaseContext from "../../../firebase/FirebaseContext";

// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import useColorScheme from "../../../hooks/useColorScheme";

// ASSETS AND COMPONENTS
import APP_LOGO from "../../../assets/images/logos/app_logo.svg";
import APP_LOGO_LIGHT from "../../../assets/images/logos/app_logo_light.svg";
import PortfoliosButton from "../buttons/PortfoliosButton";
import RenderIf from "../../helpers/RenderIf";

// CONSTANTS
import * as APP_ROUTES from "../../../routes/APP_ROUTES";
import SETTINGS from "../../../settings/settings";

// COMPONENTS
import { displayString } from "../utilities/functions";
import FreshDeskButton from "../FreshDeskButton/FreshDeskButton";
import ColorSchemeToggle from "../ColorSchemeToggle/ColorSchemeToggle";
// import ProposalTypeSwitch from "../filters/ProposalTypeSwitch"
import ProposalTypeDropdown from "../filters/ProposalTypeDropdown";

function Header() {
  // REF
  const subMenuRef = useRef(null);
  // STATE
  const [isSubMenuOpen, setIsSubmenuOpen] = useState(false);
  // CONTEXT
  const { doSignOut } = useContext(FirebaseContext);
  // STORE
  const user = useSelector((state) => state.user.user);
  const appData = useSelector((state) => state.appData.data);
  // HOOKS
  const navigate = useNavigate();
  const location = useLocation();
  const [colorScheme] = useColorScheme();
  useOnClickOutside(subMenuRef, () => setIsSubmenuOpen(false));

  return (
    <header className="h-14 fixed w-full z-[60] bg-slate-200 dark:bg-slate-800 border-b border-b-white dark:border-b-slate-900">
      <section
        className="h-full flex items-center justify-between px-2 gap-2
        sm:px-4
        lg:px-8
      "
      >
        <Link
          to={APP_ROUTES.SCREENER}
          className="flex flex-col items-end justify-center w-[130px] grow-0"
        >
          <img
            src={`${colorScheme === "dark" ? APP_LOGO : APP_LOGO_LIGHT}?v=0.6`}
            alt="Elevest"
            className="flex shrink-0 self-center w-auto h-full"
          />
          <RenderIf isTrue={appData.isBeta}>
            <span className="text-xs md:text-sm leading-none uppercase mt-1 text-blue-400 dark:text-blue-500">
              Beta
            </span>
          </RenderIf>
        </Link>

        <nav className="relative h-full flex items-center justify-end gap-1 sm:gap-2 lg:gap-4">
          <ProposalTypeDropdown />

          <span className="h-full w-[1px] bg-slate-300 dark:bg-slate-700" />

          {location.pathname === APP_ROUTES.SCREENER && <PortfoliosButton />}

          <FreshDeskButton />

          <div className="flex gap-10 items-stretch">
            <div className="text-sm leading-none grid place-content-center transition-color duration-300 text-slate-200 font-light cursor-pointer">
              <button
                id="headerSubmenuToggle"
                type="button"
                className="w-8 h-8 bg-blue-500 text-xs font-semibold leading-0 uppercase grid place-content-center rounded-full hover:bg-blue-400 hover:text-slate-50"
                onClick={() => setIsSubmenuOpen(!isSubMenuOpen)}
              >
                {`${user?.name?.charAt(0) ?? ""}${
                  user?.lastname?.charAt(0) ?? ""
                }`}
              </button>
            </div>
          </div>
          <Transition
            as={Fragment}
            show={isSubMenuOpen}
            enter="transform transition duration-500 overflow-hidden"
            enterFrom="opacity-0 h-0"
            enterTo="opacity-100 h-min"
            leave="transform duration-500 transition ease-in-out overflow-hidden"
            leaveFrom="opacity-100 h-min"
            leaveTo="opacity-0 h-0"
          >
            <div
              ref={subMenuRef}
              className="w-[220px] font-light absolute top-14 mt-1 flex flex-col gap-3 px-5 py-4 right-0 bg-slate-200 dark:bg-slate-800 rounded-md z-[60] isolate"
            >
              <div className="absolute p-1 right-0 -top-1 mt-1">
                <button
                  type="button"
                  className="flex items-center gap-1 p-1 hover:bg-blue-500 rounded-md transition-all duration-300"
                  onClick={() => setIsSubmenuOpen(false)}
                >
                  <XMarkIcon className="w-4 h-4 text-slate-50" />
                </button>
              </div>
              <div className="gap-1 font-light flex flex-col items-center justify-center">
                <h1 className="text-slate-900 dark:text-slate-50">{`${user.name} ${user.lastname}`}</h1>
                <h2 className="text-sm text-slate-500 dark:text-slate-400">
                  {displayString(user?.company.name)}
                </h2>
              </div>
              <div className="-z-10 text-sm flex flex-col gap-[1px] items-stretch justify-center rounded-md">
                <NavLink
                  to={APP_ROUTES.USER}
                  className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200 rounded-t-md"
                  onClick={() => setIsSubmenuOpen(false)}
                >
                  Mi Perfil
                </NavLink>

                <NavLink
                  to={APP_ROUTES.MY_ANALISIS}
                  className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200"
                  onClick={() => setIsSubmenuOpen(false)}
                >
                  Mis Propuestas
                </NavLink>

                <RenderIf
                  isTrue={user.role.includes(
                    SETTINGS.USER_PROFILES.APP_ADMINISTRATOR,
                  )}
                >
                  <NavLink
                    to={APP_ROUTES.ADMIN}
                    className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200"
                    onClick={() => setIsSubmenuOpen(false)}
                  >
                    Admin
                  </NavLink>
                  {/* <NavLink
                    to={APP_ROUTES.STATISTICS}
                    className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200"
                    onClick={() => setIsSubmenuOpen(false)}
                  >
                    Estadísticas
                  </NavLink> */}
                </RenderIf>

                <button
                  type="button"
                  className="px-5 py-3 text-left transition-color duration-300 bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-200 hover:bg-blue-500 dark:hover:bg-blue-500 hover:text-slate-200 rounded-bl-md rounded-br-md"
                  onClick={() => {
                    doSignOut().then(() => {
                      setIsSubmenuOpen(false);
                      navigate("/", { replace: true });
                    });
                  }}
                >
                  Salir
                </button>
              </div>
              <div>
                <ColorSchemeToggle />
                {version && (
                  <p className="text-xxs flex align-center justify-center text-slate-500 dark:text-slate-400">{`versión: ${version.tag}`}</p>
                )}
              </div>
            </div>
          </Transition>
        </nav>
      </section>
    </header>
  );
}

export default Header;
