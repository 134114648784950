import { useSelector, useDispatch } from "react-redux";
// STORE
import { updateAnalysisDataConfig } from "../../../store/analysisDataConfigSlice";

// HOOKS
import useTracking from "../../../hooks/useTracking";

function AnalysisAdvisorInfo() {
  // TRACK
  const trackEvent = useTracking();
  // STORE
  const colorScheme = useSelector((state) => state.colorScheme.value);
  const dispatch = useDispatch();
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig);
  const { logo } = analysisDataConfig.data;

  const maxLength = 1000;

  // CALLBACKS
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value.length >= maxLength.current) return;
    switch (name) {
      case "description":
        trackEvent({
          action: "ADD_DESCRIPTION_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        });
        break;
      case "url":
        trackEvent({
          action: "ADD_URL_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        });
        break;
      case "address":
        trackEvent({
          action: "ADD_ADDRESS_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        });
        break;
      default:
        trackEvent({
          action: "ADD_PHONE_ANALYSIS_PAGE",
          timestamp: Date.now(),
          type: "input",
          data: value,
        });
    }
    dispatch(
      updateAnalysisDataConfig({
        ...analysisDataConfig.data,
        [name]: value,
      }),
    );
  };

  const returnImgAccoringToColorScheme = () => {
    if (colorScheme === "light") {
      return logo?.light;
    }
    return logo?.dark ?? logo?.light;
  };

  return (
    <div
      id="acercade"
      className="grid grid-cols-[165px_1fr] gap-10 items-center"
    >
      <div className="w-full h-16">
        <img
          src={returnImgAccoringToColorScheme()}
          alt="Logo"
          className="object-contain w-full h-full"
        />
      </div>

      <div className="flex flex-col items-start justify-start gap-4 text-xs text-slate-700 dark:text-slate-50">
        <div className="w-full">
          <textarea
            name="description"
            onChange={handleChange}
            value={analysisDataConfig.data.description}
            placeholder="Ingresa una breve descripción de tu empresa"
            className="w-full px-2 py-1 h-20 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500 resize-none"
          />
        </div>

        <div className="flex items-center justify-start gap-2 w-full">
          <input
            type="text"
            name="url"
            value={analysisDataConfig.data.url}
            placeholder="http://tuempresa.cl"
            onChange={handleChange}
            className="w-full px-2 py-1 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
          />
          <span>|</span>
          <input
            type="text"
            name="address"
            value={analysisDataConfig.data.address}
            placeholder="Dirección de tu empresa"
            onChange={handleChange}
            className="w-full px-2 py-1 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
          />
          <span>|</span>
          <input
            type="text"
            name="phone"
            value={analysisDataConfig.data.phone}
            placeholder="Teléfono o teléfonos de tu empresa"
            onChange={handleChange}
            className="w-full px-2 py-1 text-xs rounded-md bg-white dark:bg-slate-900 border border-blue-500 focus:border-blue-400 dark:border-blue-400 dark:focus:border-blue-500 focus:ring-0 !outline-none placeholder:text-slate-400 dark:placeholder:text-slate-500"
          />
        </div>
      </div>
    </div>
  );
}

export default AnalysisAdvisorInfo;
