const useAnalysisConfigHelpers = () => {
  const getActiveCoverPagePath = (coverList) => {
    let active = null
    if (coverList) {
      coverList.forEach((cover, index) => {
        if (cover.active) active = coverList[index].url
      })
    }
    return active
  }

  return {
    getActiveCoverPagePath,
  }
}

export default useAnalysisConfigHelpers
