import { useContext } from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"

// CONTEXT
import FirebaseContext from "../firebase/FirebaseContext"
import * as ROUTES from "./APP_ROUTES"

function AccessRoutes() {
  const location = useLocation()
  const { getCurrentUser } = useContext(FirebaseContext)

  return getCurrentUser() ? (
    <Navigate to={ROUTES.SCREENER} state={{ from: location }} replace />
  ) : (
    <Outlet />
  )
}

export default AccessRoutes
