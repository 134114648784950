import { useContext } from "react"
import PropTypes from "prop-types"
import {
  useLocation,
  useNavigate,
  NavLink,
  // createSearchParams,
} from "react-router-dom"
import {
  UserIcon,
  ChevronRightIcon,
  LockClosedIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline"

// STORE
import { useSelector } from "react-redux"
// PROPTYPES
import { childrenPropTypes } from "../../../propTypes/AppPropTypes"

// STORE
// CONTEXT
import FirebaseContext from "../../../firebase/FirebaseContext"
// ROUTES
import * as APP_ROUTES from "../../../routes/APP_ROUTES"

function SectionTitle({ children }) {
  return <h4 className="text-xs text-slate-400 uppercase">{children}</h4>
}

function SidebarLink({ children, path }) {
  const location = useLocation()
  return (
    <NavLink
      to={path}
      className={`group w-full py-1 pl-2 text-sm font-light text-slate-50 rounded-sm flex items-center justify-start gap-1 transition-all duration-300 ease-in-out
       hover:text-blue-500
       ${location.pathname === path
          ? "bg-slate-900 bg-opacity-40 text-blue-500"
          : "bg-transparent"
        }
       `}
    >
      {children}
      <ChevronRightIcon className="w-4 h-4 ml-auto -translate-x-4 opacity-0 group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-300" />
    </NavLink>
  )
}

function AccountSidebar() {
  // STORE
  const user = useSelector((state) => state.user.user)
  // CONTEXT
  const { doSignOut } = useContext(FirebaseContext)
  const navigate = useNavigate()

  return (
    <aside className="sticky top-8 bg-slate-800 h-[calc(100vh-56px)] px-4 py-5">
      <div className="mb-5 pb-5 border-b border-slate-600">
        <div>
          <p className="text-xs text-slate-400">Administra tu cuenta</p>
          <h2 className="text-base semibold">{`${user?.name} ${user?.lastname} `}</h2>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <SectionTitle>Información personal</SectionTitle>
          <SidebarLink path={APP_ROUTES.USER}>
            <UserIcon className="w-4 h-4" />
            Perfil
          </SidebarLink>
          <SidebarLink path={APP_ROUTES.PASSWORD_CHANGE}>
            <LockClosedIcon className="w-4 h-4" />
            Cambiar contraseña
          </SidebarLink>
        </div>

        {/* <div className="flex flex-col gap-2">
          <SectionTitle>Análisis</SectionTitle>
          <SidebarLink path={APP_ROUTES.MY_ANALISIS}>
            <DocumentChartBarIcon className="w-4 h-4" />
            Ver todos
          </SidebarLink>
        </div> */}
      </div>
      <button
        type="button"
        onClick={() => {
          doSignOut().then(() => {
            navigate("/", { replace: true })
          })
        }}
        className="group absolute bottom-0 left-0 px-4 w-full py-2 text-sm font-light bg-slate-700 text-slate-50 rounded-sm flex items-center justify-start gap-1 transition-all duration-300 ease-in-out
        hover:bg-blue-500 hover:text-slate-50"
      >
        <ArrowLeftOnRectangleIcon className="w-4 h-4" />
        Salir
      </button>
    </aside>
  )
}

SidebarLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: childrenPropTypes.isRequired,
}
SectionTitle.propTypes = {
  children: childrenPropTypes.isRequired,
}

export default AccountSidebar
