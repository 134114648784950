import PropTypes from "prop-types"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"

function ScreenerAdvancedSearch({
  advancedSearchValue,
  setAdvancesSearchValue,
  advancedSearchResponse,
  handleAdvancedSearch,
  isAdvancedSearchFetching,
}) {
  const showResults = () => {
    if (isAdvancedSearchFetching)
      return (
        <p className="animated-ellipsis">
          Estamos buscando las mejores opciones
        </p>
      )
    if (advancedSearchResponse) {
      return (
        <p>
          <span className="text-xxs leading-tight">Filtros aplicados:</span>{" "}
          {advancedSearchResponse}
        </p>
      )
    }
    return ""
  }

  return (
    <div className="w-full max-w-[650px] justify-self-end pb-4 relative">
      <p className="text-xs mb-1">Búsqueda avanzada</p>
      <div className="flex items-stretch h-8 border border-slate-300 dark:border-slate-700 bg-slate-50 dark:bg-slate-800 rounded-md overflow-hidden">
        <input
          className="w-full flex-grow p-2 text-sm border-none text-slate-900 dark:text-slate-50 bg-slate-50 dark:bg-slate-800 hover:border-blue-500 hover:outline-none focus:border-blue-500 focus:ring-0"
          placeholder="Ej: Fondos balanceados que inviertan más de 40% en UF y en 10% en Sector Tecnológico"
          value={advancedSearchValue}
          onChange={(event) => {
            setAdvancesSearchValue(event.target.value)
          }}
          onKeyDown={(event) => {
            handleAdvancedSearch(event)
          }}
        />
        <button
          className="w-8 grid place-content-center shrink-0 bg-slate-50 dark:bg-slate-800"
          title="Filtro avanzado"
          onClick={(event) => handleAdvancedSearch(event)}
        >
          <MagnifyingGlassIcon className="w-3 h-3" />
        </button>
      </div>
      <div className="absolute mt-1 px-2 top-12 left-0 flex items-center text-slate-600 dark:text-slate-400 text-xxs gap-2 ">
        {showResults()}
      </div>
    </div>
  )
}

ScreenerAdvancedSearch.propTypes = {
  advancedSearchValue: PropTypes.string.isRequired,
  setAdvancesSearchValue: PropTypes.func.isRequired,
  advancedSearchResponse: PropTypes.string.isRequired,
  handleAdvancedSearch: PropTypes.func.isRequired,
  isAdvancedSearchFetching: PropTypes.bool.isRequired,
}

export default ScreenerAdvancedSearch
