import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isAPV: false,
}

export const proposalTypeSlice = createSlice({
  name: "proposalType",
  initialState,
  reducers: {
    toggleProposalType: (state, action) => {
      state.isAPV = action.payload
    },
  },
})

export const { toggleProposalType } = proposalTypeSlice.actions

export default proposalTypeSlice.reducer
