import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import {
  getAuth,
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword,
  signOut,
} from "firebase/auth"
import {
  getFirestore,
  collection,
} from "firebase/firestore"

import SETTINGS from "../settings/settings"

const analyticsAllowed = ["prod", "qa"]

class Firebase {
  constructor() {
    this.app = initializeApp(SETTINGS.firebase_config)

    // Only PROD. Initialize Analytics and get a reference to the service
    if (analyticsAllowed.includes(SETTINGS.environment))
      this.analytics = getAnalytics(this.app)

    this.auth = getAuth(this.app)
    this.db = getFirestore(this.app)
  }

  // ANALYTICS
  // SHOULD BE AVAILABLE ONLY IN PRODUCTION ENVIRONMENT
  setAnalyticsUserId = (uid) => {
    if (analyticsAllowed.includes(SETTINGS.environment))
      setUserId(this.analytics, uid)
  }

  logAnalyticsEvent = (type, data) => {
    /*
      IMPORTANTE
      Preferir el uso de tipos eventos definidos por GA y sus correspondientes parámetros.      
      https://developers.google.com/tag-platform/gtagjs/reference/events?authuser=0
    */
    if (analyticsAllowed.includes(SETTINGS.environment))
      logEvent(this.analytics, type, data)
  }

  // AUTH METHODS
  doSignInWithEmailAndPassword = (email, password) => {
    return setPersistence(this.auth, browserLocalPersistence)
      .then(() => signInWithEmailAndPassword(this.auth, email, password))
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        return {
          status: "ERROR",
          code: errorCode,
          message: errorMessage,
        }
      })
  }

  doSignOut = () => signOut(this.auth)

  getCurrentUser = () => this.auth.currentUser

  getIdToken = () => this.getCurrentUser().getIdToken(/* forceRefresh */ false)

  // MANAGE USERS
  doCreateUserWithEmailAndPassword = (email, password) => {
    const register = createUserWithEmailAndPassword(this.auth, email, password)
    this.auth.signOut()
    return register
  }

  doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email)

  doVerifyPasswordResetCode = (code) => verifyPasswordResetCode(this.auth, code)

  doConfirmPasswordReset = (actionCode, newPassword) =>
    confirmPasswordReset(this.auth, actionCode, newPassword)

  doPasswordUpdate = (password) => {
    const currentUser = this.getCurrentUser()
    return updatePassword(currentUser, password)
  }

  /* FIRESTORE */

  // APP CONFIG
  appConfig = () => collection(this.db, "config")
}

export default Firebase
