import { useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"

// HOOKS
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import useOnClickOutside from "../../../hooks/useOnClickOutside"

function SelectField({
  label,
  placeholder,
  selectedValue,
  setSelectedValue,
  data,
  returnValue,
  disabled,
  size,
}) {
  // REF
  const selectRef = useRef()
  // STATE
  const [isOpen, setIsOpen] = useState(false)

  // CALLBACK
  const handleChange = useCallback(
    (value, key) => {
      const returned = returnValue ? value : key
      setSelectedValue(returned)
      setIsOpen(false)
    },
    [setSelectedValue],
  )

  const handleOpen = () => {
    if (!disabled) {
      setIsOpen(!isOpen)
    }
  }

  // LIFE CYCLE
  useOnClickOutside(selectRef, () => setIsOpen(false))

  return (
    <div className="relative w-full">
      {label !== "" && (
        <div className="mb-2 flex items-center justify-between">
          <span
            className={`${
              size === "small" ? "text-xxs" : "text-xs"
            } text-slate-400`}
          >
            {label}
          </span>
        </div>
      )}
      <div
        className={`w-full relative ${size === "small" ? "h-7" : "h-10"}`}
        ref={selectRef}
      >
        <button
          type="button"
          onClick={handleOpen}
          className={`flex items-center justify-between w-full h-full border border-slate-600 bg-slate-800  text-slate-50 rounded-md
            ${isOpen ? "rounded-bl-none rounded-br-none" : ""}
            ${
              disabled
                ? "opacity-50 bg-slate-600 text-slate-400 cursor-not-allowed pointer-events-none"
                : ""
            }
            ${size === "small" ? "p-1 text-xs" : "p-2 text-sm"}
          `}
        >
          {selectedValue === "" ? (
            <span className="text-left text-slate-50 text-opacity-50">
              {placeholder}
            </span>
          ) : (
            data[selectedValue]
          )}{" "}
          <ChevronDownIcon className="w-4 h-4" />
        </button>
        <div
          className={`absolute top-10 left-0 w-full h-0 bg-slate-800 flex flex-col overflow-hidden rounded-bl-md rounded-br-md z-10
            ${isOpen ? "h-auto z-90" : ""}
            ${size === "small" ? "top-8" : "top-10"}
        `}
        >
          {Object.keys(data).map((key) => (
            <button
              key={key}
              type="button"
              onClick={() => handleChange(data[key], key)}
              className={`border-b border-slate-600 text-slate-50 transition-all duration-300 last-of-type:border-none hover:bg-slate-900
              ${size === "small" ? "p-1 text-xxs" : "p-2 text-xs"}
              `}
            >
              {data[key]}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

SelectField.defaultProps = {
  returnValue: true,
  disabled: false,
  size: "normal",
  data: {},
}

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  setSelectedValue: PropTypes.func.isRequired,
   
  data: PropTypes.object,
  returnValue: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "normal"]),
}

export default SelectField
