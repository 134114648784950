import { useState } from "react"
import PropTypes from "prop-types"
import { debounce } from "lodash"
import Cleave from "cleave.js/react"
import { ChevronDownIcon, MinusCircleIcon } from "@heroicons/react/24/outline"

// STORE
import { useSelector } from "react-redux"
// HOOKS
import useColorCompany from "../../../hooks/useColorCompany"
import useTracking from "../../../hooks/useTracking"
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"

// COMPONENTS
import AnalysisDistributionBar from "./AnalysisDistributionBar"
import PreviewFundSeriesSelector from "../PreviewPanel/PreviewFundSeriesSelector"
import FundDocumentsNav from "../FundDocumentsNav/FundDocumentsNav"

// UTILS
import {
  displayString,
  formatToFloat,
  formatToInteger,
} from "../utilities/functions"

function AnalysisScreenerFund({
  currentPortfolio,
  fund,
  fundIndex,
  fundPortfolioData,
  modalInfo,
}) {
  // STATE
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  // HOOKS
  const { getColorPortfolio } = useColorCompany()
  const trackEvent = useTracking()
  const {
    updatePortfolioWeights,
    updatePortfolioAmounts,
    updateFundShareClass,
    deleteFundFromPortfolio,
  } = usePreviewPanel()
  // STORE
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )

  // CALLBACKS
  const handleFundRemove = () => {
    trackEvent({
      action: "REMOVE_FUND_PORTFOLIO",
      timestamp: Date.now(),
      type: "CLICK",
      data: `${fund.fundId} | ${fund.fund_name} | ${fund.shareClassName} | ${currentPortfolio}`,
    })
    deleteFundFromPortfolio(fund, currentPortfolio, fundIndex)
  }

  const handleDistributionChange = (event, type) => {
    const value = event.target.rawValue === "" ? 0 : +event.target.rawValue
    // const regex = /^[0-9\b]+$/
    const decimalRegex = /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/

    if (value === "" || decimalRegex.test(value)) {
      if (type === "weight") {
        trackEvent({
          action: "CHANGE_FUND_WEIGHT_PORTFOLIO",
          timestamp: Date.now(),
          type: "INPUT",
          data: `${fund.fundId} => ${value} | ${currentPortfolio}`,
        })
        updatePortfolioWeights(fund, currentPortfolio, value, fundIndex)
      } else {
        trackEvent({
          action: "CHANGE_FUND_AMOUNT_PORTFOLIO",
          timestamp: Date.now(),
          type: "INPUT",
          data: `${fund.fundId} => ${value} | ${currentPortfolio}`,
        })
        updatePortfolioAmounts(fund, currentPortfolio, value, fundIndex)
      }
    }
  }

  const handleSerieChange = (fundSerie) => {
    trackEvent({
      action: "CHANGE_FUND_SERIE_PORTFOLIO_ANALYSIS",
      timestamp: Date.now(),
      type: "SELECT",
      data: `${fundPortfolioData.fundId} => ${fundSerie} | ${currentPortfolio}`,
    })
    updateFundShareClass(
      fundPortfolioData,
      currentPortfolio,
      fundSerie,
      fundIndex,
    )
  }

  const shouldShowButtons = () => {
    const excludeDocsList = ["IN"]
    if (excludeDocsList.includes(fund.fund_type)) {
      return false
    }
    return true
  }

  const shouldShowSeriesSelector = () => {
    const excludeDocsList = ["IN"]
    if (excludeDocsList.includes(fund.fund_type)) {
      return false
    }
    return true
  }

  return (
    <div
      key={`${fund.run}-${fund.serie}-${fund.fund_name.toUpperCase()}`}
      className="grid grid-cols-12 gap-5"
    >
      <div className="col-span-3 flex items-center justify-between gap-2">
        {displayString(fund.fund_name)}
      </div>
      <div className="col-span-1">{fund.fund_type}</div>
      <div className="col-span-1 relative">
        {shouldShowSeriesSelector() ? (
          <button
            type="button"
            className="relative grow-1 w-full px-1 py-0 flex-none flex items-center justify-between gap-1 bg-white dark:bg-slate-900 border border-blue-500 dark:border-transparent p-1 text-center text-xs rounded-md"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="max-w-[calc(100%-12px)] overflow-hidden whitespace-nowrap">
              {fund.serie}
            </span>
            <ChevronDownIcon className="w-3 h-3 shrink-0" />
            <PreviewFundSeriesSelector
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              catalogByFunds={catalogByFunds[fund.run]}
              selectedSerie={fund.serie}
              handleSerieChange={handleSerieChange}
            />
          </button>
        ) : (
          "-"
        )}
      </div>
      <div className="col-span-1">{fund.asset_class}</div>
      <div
        className={`col-span-1 tabular-nums text-right ${
          analysisDataConfig.showComponents.tac
            ? "opacity-100 pointer-events-auto"
            : "opacity-30 pointer-events-none"
        }`}
      >
        {formatToFloat(fund.tac)}
      </div>
      <div className="col-span-1">
        {shouldShowButtons() ? (
          <FundDocumentsNav fund={fund} modalInfo={modalInfo} />
        ) : (
          "-"
        )}
      </div>

      <div className="col-span-4 flex items-center justify-between gap-5">
        <AnalysisDistributionBar
          perc={fundPortfolioData ? fundPortfolioData.weight : 0}
          color={getColorPortfolio(currentPortfolio)}
        />
        {distributionOption === "weight" ? (
          <>
            <div className="flex items-stretch justify-start w-[70px] shrink-0">
              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={
                  fundPortfolioData &&
                  formatToFloat(fundPortfolioData?.weight, 1)
                }
                onChange={debounce(
                  (event) => handleDistributionChange(event, "weight"),
                  150,
                )}
                className="w-full bg-white dark:bg-slate-900 pl-1 pr-0 py-0 text-right tabular-nums text-xs border-t border-l border-b border-r-0 border-blue-500 dark:border-transparent rounded-tl-md rounded-bl-md focus:outline-none focus:ring-0"
              />
              <span className="bg-white dark:bg-slate-900 pl-0 pr-1 py-0 text-center text-xs rounded-tr-md rounded-br-md border-t border-r border-b border-l-0 border-blue-500 dark:border-transparent">
                %
              </span>
            </div>
            {baseAmount > 0 && (
              <p className="text-left text-xs w-[90px] shrink-0">
                $
                {fundPortfolioData?.amount &&
                  formatToInteger(+fundPortfolioData.amount)}
              </p>
            )}
          </>
        ) : (
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-stretch justify-start w-[100] shrink-0">
              <span className="bg-white dark:bg-slate-900 px-1 py-0 text-center text-xs rounded-tl-md rounded-bl-md border-t border-l border-b border-r-0 border-blue-500 dark:border-transparent">
                $
              </span>
              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={
                  fundPortfolioData.amount === 0 ? "" : fundPortfolioData.amount
                }
                onChange={debounce(
                  (event) => handleDistributionChange(event, "amount"),
                  150,
                )}
                className="w-[100px] shrink-0 bg-white dark:bg-slate-900 pr-1 pl-0 py-0 text-left tabular-nums text-xs border-t border-r border-b border-l-0 border-blue-500 dark:border-transparent rounded-tr-md rounded-br-md focus:outline-none focus:ring-0"
              />
            </div>
            <p className="text-right text-xs tabular-nums w-[40px]">
              {fundPortfolioData
                ? formatToFloat(+fundPortfolioData.weight, 1, "").replace(
                    /[.,]00$/,
                    "",
                  )
                : ""}
              %
            </p>
          </div>
        )}
        <button
          type="button"
          onClick={handleFundRemove}
          title={`Eliminar ${displayString(fund.fund_name)} | ${
            fund.shareClassName
          }`}
          className=""
        >
          <MinusCircleIcon className="w-4 h-4 text-blue-500 dark:text-blue-400" />
        </button>
      </div>
    </div>
  )
}

AnalysisScreenerFund.propTypes = {
  currentPortfolio: PropTypes.string.isRequired,
  fund: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  fundIndex: PropTypes.number.isRequired,
  fundPortfolioData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  modalInfo: PropTypes.shape({
    setFactSheetModalMessage: PropTypes.func,
    setFactSheetModalOpen: PropTypes.func,
  }).isRequired,
}

export default AnalysisScreenerFund
