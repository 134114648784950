import { StatisticsHeaderBox } from "../StatisticsLayouts";
import { StatisticsHeaderTitle } from "../StatisticsLayouts";
import usePrepareProposalStatisticsData from "../usePrepareProposalStatisticsData";

import {
  formatAmount,
  formatAmounts,
} from "src/components/ui/utilities/functions";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

const StatsSummaryBoxes = ({ proposalData, proposalGeneralData }) => {
  const { totalProposals } = usePrepareProposalStatisticsData();

  return (
    <div className="flex items-stretch justify-start gap-2">
      <StatisticsHeaderBox>
        <StatisticsHeaderTitle title="Total propuestas" />
        <p className="text-lg font-bold text-center">
          {totalProposals(proposalData)}
        </p>
      </StatisticsHeaderBox>

      {/* <StatisticsHeaderBox>
                  <StatisticsHeaderTitle title="Propuestas por distribución:" />
                  <ul>
                    {proposalsByDistribution(proposalData).map((item, index) => (
                      <li key={index}>{`${item[0]}: ${item[1]}`}</li>
                    ))}
                  </ul>
                </StatisticsHeaderBox> */}

      <StatisticsHeaderBox>
        <StatisticsHeaderTitle title="Mayor monto:" />
        <a
          target="_blank"
          href={`https://elevest.app/public-link/${proposalGeneralData.maxProposal.advisor_id}/${proposalGeneralData.maxProposal.analysis_id}/`}
          rel="noreferrer"
          className="text-xs flex items-center gap-1 justify-center"
        >
          <span className="text-lg font-bold text-center">{`$${formatAmounts(proposalGeneralData.maxAmount)}`}</span>
          <ArrowTopRightOnSquareIcon className="w-4 h-4" />
        </a>
      </StatisticsHeaderBox>

      <StatisticsHeaderBox>
        <StatisticsHeaderTitle title="Menor monto:" />
        <a
          target="_blank"
          href={`https://elevest.app/public-link/${proposalGeneralData.minProposal.advisor_id}/${proposalGeneralData.minProposal.analysis_id}/`}
          rel="noreferrer"
          className="text-xs flex items-center gap-1 justify-center"
        >
          <span className="text-lg font-bold text-center">{`$${formatAmount(proposalGeneralData.minAmount)}`}</span>
          <ArrowTopRightOnSquareIcon className="w-4 h-4" />
        </a>
      </StatisticsHeaderBox>
      <StatisticsHeaderBox>
        <StatisticsHeaderTitle title="Monto promedio:" />
        <p className="text-lg font-bold text-center">
          {formatAmounts(proposalGeneralData.averageAmount)}
        </p>
      </StatisticsHeaderBox>
    </div>
  );
};

export default StatsSummaryBoxes;
