/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import LineChart from "../../charts/LineChart"
import ReturnsTable from "../../charts/ReturnsTable/ReturnsTable"
import TACTable from "../../charts/TACTable"
import IssuersTable from "../../charts/IssuersTable"
import MinMaxBarChart from "../../charts/MinMaxBarChart/MinMaxBarChart"
import HorizontalBarChart from "../../charts/HorizontalBarChart/HorizontalBarChart"
import {
  exportComponentAsPNG,
  displayString,
  formatToInteger,
  formatToFloat,
} from "../utilities/functions"
import { EXPOSURE_TYPE_NAME_MAPPING } from "../../../utils/ExposurePresenter"

function ScreenshotModal({ screenshotData, resetScreenshotModal }) {
  const { portfolioExistenceStatus, chart, table } = screenshotData

  const portfolios = useSelector((state) => state.portfolioSelection?.data)
  const colors = {
    portfolioA: "bg-amber-500",
    portfolioB: "bg-teal-500",
  }

  const getModalContent = () => {
    switch (screenshotData.type) {
      case "returns":
        return (
          <>
            <LineChart
              performanceData={chart.data}
              isScreenerPage={chart.isScreenerPage}
              portfolioExistenceStatus={portfolioExistenceStatus}
            />
            {table.show && (
              <ReturnsTable
                data={table.data}
                portfolioExistenceStatus={portfolioExistenceStatus}
              />
            )}
          </>
        )
      case "tac":
        return (
          <div
            className={`grid gap-4 ${
              screenshotData.tableA.data && screenshotData.tableB.data
                ? "grid-cols-2"
                : "grid-cols"
            }`}
          >
            {screenshotData.tableA.data && (
              <TACTable
                title={screenshotData.tableA.data.portfolio}
                shareClasses={screenshotData.tableA.data.shareClasses}
                tac={screenshotData.tableA.data.tac}
                portfolios={portfolios.portfolioA}
                color={screenshotData.tableA.color}
              />
            )}
            {screenshotData.tableB.data && (
              <TACTable
                title={screenshotData.tableB.data.portfolio}
                shareClasses={screenshotData.tableB.data.shareClasses}
                tac={screenshotData.tableB.data.tac}
                portfolios={portfolios.portfolioB}
                color={screenshotData.tableB.color}
              />
            )}
          </div>
        )
      case "issuers":
        return (
          <div className="flex items-start justify-between w-full gap-5">
            {screenshotData.tableA.data && (
              <IssuersTable
                title={screenshotData.tableA.data.portfolioName}
                subtitle={`(al ${screenshotData.tableA.data.closingDate[0]})`}
                issuers={screenshotData.tableA.data.issuers}
                color={screenshotData.tableA.color}
                maxIssuers={screenshotData.maxIssuers}
              />
            )}
            {screenshotData.tableB.data && (
              <IssuersTable
                title={screenshotData.tableB.data.portfolioName}
                subtitle={`(al ${screenshotData.tableB.data.closingDate[0]})`}
                issuers={screenshotData.tableB.data.issuers}
                color={screenshotData.tableB.color}
                maxIssuers={screenshotData.maxIssuers}
              />
            )}
          </div>
        )
      case "risks":
        return (
          <MinMaxBarChart
            data={screenshotData.data}
            chartOptions={screenshotData.portfolioNumber}
          />
        )
      case "exposure":
        return (
          <div className="grid grid-cols-3 items-start gap-5 w-full">
            {screenshotData.itemOrder.map((item) => {
              const name = `Por ${EXPOSURE_TYPE_NAME_MAPPING[item]}`
              const rows = screenshotData.chartsData[0][item]
              return (
                <HorizontalBarChart
                  portfolioExistenceStatus={
                    screenshotData.portfolioExistenceStatus
                  }
                  key={item}
                  rawData={rows}
                  title={name}
                />
              )
            })}
          </div>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (screenshotData) {
      setTimeout(() => {
        exportComponentAsPNG({
          section: document.querySelector("#screenshotModal"),
          filename: screenshotData.filename,
        })
        resetScreenshotModal(null)
      }, 1000)
    }
  }, [screenshotData])

  return (
    screenshotData && (
      <div
        id="screenshotModal"
        className="fixed top-[-100vh] left-0 w-[1440px] bg-slate-800 z-50"
      >
        <div className="p-5 flex flex-col items-stretch justify-start gap-5">
          <div className="grid grid-cols-2 gap-5">
            {Object.keys(portfolios).map((portfolio) => (
              <div key={`screenshot-${portfolio}`}>
                <h2 className="flex items-center gap-2 mb-2">
                  <span
                    className={`w-3 h-3 rounded-sm ${colors[portfolio]} `}
                  />
                  <span className="text-xs font-semibold">
                    {portfolios[portfolio].portfolioName}
                  </span>
                </h2>
                <div className="flex flex-col items-stretch justify-start gap-2">
                  {portfolios[portfolio].portfolioFunds.map((fund) => (
                    <div
                      key={`screenshot-fund-${fund.fundId}-${fund.shareClassName}`}
                      className="grid grid-cols-[1fr_250px_150px] gap-1 text-xs"
                    >
                      <p>{`${displayString(fund.fund_name)} | ${
                        fund.shareClassName
                      }`}</p>
                      <p className="text-right">
                        ${formatToInteger(+fund.amount)}
                      </p>
                      <p className="text-right">
                        {formatToFloat(+fund.weight)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {getModalContent()}
        </div>
      </div>
    )
  )
}

export default ScreenshotModal
