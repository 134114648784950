import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid"

// STORE
import { useSelector, useDispatch } from "react-redux"
import { updateFilters } from "../../../store/filtersSlice"
import useTracking from "../../../hooks/useTracking"

function TableSearchField() {
  // TRACKING
  const trackEvent = useTracking()

  // STORE
  const filters = useSelector((state) => state.filters.data)
  const dispatch = useDispatch()
  // CALLBACKS
  const handleChange = (e) => {
    trackEvent({
      action: "SEARCH_FUND",
      timestamp: Date.now(),
      type: "SUBMIT",
      data: e.target.value,
    })
    dispatch(updateFilters({ ...filters, ...{ searchTerm: e.target.value } }))
  }

  const handleClear = () => {
    dispatch(updateFilters({ ...filters, ...{ searchTerm: "" } }))
  }

  return (
    <div
      className="relative max-w-[300px] h-8 px-2 py-1.5
      border border-slate-300 dark:border-slate-700 rounded-md bg-slate-50 dark:bg-slate-800
      text-xxs text-blue-400 uppercase
      flex items-center justify-start gap-1 grow
      hover:border-blue-500 dark:hover:border-blue-400
      3xl:px-3 3xl:text-[11px]
    "
    >
      <span className="bg-transparent grid place-content-center flex-initial">
        <MagnifyingGlassIcon className="w-3 h-3" />
      </span>
      <input
        type="text"
        value={filters.searchTerm}
        onChange={handleChange}
        placeholder="Nombre del fondo"
        className="flex-grow w-full bg-transparent py-2 pl-0 pr-6 text-xxs border-none !outline-none uppercase placeholder:text-blue-500 dark:placeholder:text-blue-400 focus:ring-transparent"
      />
      <button
        type="button"
        className={`bg-transparent w-4 grid place-content-center absolute top-1/2 right-2 -translate-y-2 ${
          filters.searchTerm === "" ? "opacity-0" : "opacity-75"
        }`}
        onClick={() => handleClear()}
      >
        <XMarkIcon className="w-4 h-4 text-blue-500 dark:text-blue-400" />
      </button>
    </div>
  )
}

export default TableSearchField
