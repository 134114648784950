import LoadingSpinner from "../ui/loadingSpinner/LoadingSpinner"

function RenderIf({
  children,
  isTrue,
  isLoading = false,
  componentOtherwise = null,
}) {
  const returnLoading = () =>
    isLoading ? <LoadingSpinner /> : componentOtherwise
  return isTrue ? children : returnLoading()
}
export default RenderIf
