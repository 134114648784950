import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCharstData } from "src/store/chartsDataSlice";
import {
  calculatePortfoliosTotals,
  createReturnsTable,
} from "src/utils/ReturnsCalculations"
import { formatPortfolioMaxMinProfitAndLossesToChart } from "src/utils/MaxMinProfitAndLossesPresenter"

const usePortfolioDates = (portfoliosInstances, portfolioGroup, portfolios, distributionOption, baseAmount, catalogByFunds, isAPV, returnsPeriodMap) => {
  const dispatch = useDispatch();
  const chartsData = useSelector((state) => state.chartsData);

  useEffect(() => {
    const callReturnsStatistics = async () => {
      if (portfoliosInstances && portfoliosInstances.length > 0) {
        const accReturns = await portfolioGroup.getAccumulatedReturns(chartsData.data.lastDate)
        const returnsDates = [portfolioGroup.getFirstDate(), portfolioGroup.getLastDate()]
        const minmax = await portfolioGroup.getMinMaxProfitAndLosses()
        const totals = calculatePortfoliosTotals(portfolios, distributionOption, baseAmount)
        const formattedMaxMinProfitLossesChartData =
          formatPortfolioMaxMinProfitAndLossesToChart(
            minmax,
            totals,
          )
        const exposureData = await portfolioGroup.getExposures()
        const issuerData = await portfolioGroup.getIssuers()
        const tacDataPG = await portfolioGroup.getTac()
        const filteredPeriodList = portfolioGroup.getFilteredPeriodList()
        if (!isAPV) {
          filteredPeriodList.push({ key: "Manual", value: 0, date: filteredPeriodList[0].date })
        }
        const shiftedReturns = { ...accReturns }
        const retTPD = portfolioGroup.getReturnTable()
        const returnsTablePortfolioData = retTPD
        const { returnsTableData, screenerTable } = createReturnsTable(portfolios, returnsTablePortfolioData, catalogByFunds, returnsPeriodMap, isAPV, portfoliosInstances, portfolioGroup.getLastDate())

        let newTimePeriod = filteredPeriodList[0].value;
        if(newTimePeriod !== -2){
          if (chartsData.data.timePeriod !== null && newTimePeriod > chartsData.data.timePeriod) {
            newTimePeriod = chartsData.data.timePeriod;
          }
        }

        dispatch(
          updateCharstData({
            performanceChartData: shiftedReturns,
            exposureSummaryData: await portfolioGroup.getExposureSummary(),
            maxMinProfitLossesChartData: formattedMaxMinProfitLossesChartData,
            portfoliosNumber: portfoliosInstances.length,
            exposureData: [exposureData],
            issuersData: {
              issuers1: issuerData[0],
              issuers2: issuerData[1],
            },
            portfoliosTableReturns: returnsTableData,
            tacData: tacDataPG,
            portfolios,
            nullDates: portfoliosInstances.map((port) => port.nullDates),
            timePeriod: newTimePeriod,
            periodList: filteredPeriodList,
            screenerTable,
            returnsDates: returnsDates,
          }),
        )
      }
    }
    callReturnsStatistics()
  }, [portfoliosInstances, dispatch, portfolios, portfolioGroup])

  useEffect(() => {
    const callTimePeriod = async () => {
      if (chartsData?.data?.timePeriod === 0) {
        const dates = chartsData?.data?.periodList?.find((p) => p.key === "Manual")?.date
        const firstDate = dates[0]
        const lastDate = dates[1]
        const accReturns = await portfolioGroup.getReturnFromTo(firstDate, lastDate);
        dispatch(
          updateCharstData({
            ...chartsData.data,
            performanceChartData: { ...chartsData.data.performanceChartData, 0: accReturns },
          }),
        )
      }
    }
    if (portfolioGroup) {
      callTimePeriod()
    }
  },
    [chartsData.data.timePeriod, chartsData.data.periodList])

  useEffect(() => {
    const callLastDate = async () => {
      const lastDateSelected = chartsData?.data?.lastDate ?? null
      const accReturns = await portfolioGroup.getAccumulatedReturns(lastDateSelected)
      const minmax = await portfolioGroup.getMinMaxProfitAndLosses(lastDateSelected)
      const totals = calculatePortfoliosTotals(portfolios, distributionOption, baseAmount)
      const formattedMaxMinProfitLossesChartData =
        formatPortfolioMaxMinProfitAndLossesToChart(
          minmax,
          totals,
        )
      let manualPeriodList;
      chartsData?.data?.periodList.forEach((pl) => {
        if (+pl.value === 0)
          manualPeriodList = pl;
      }
      )
      const filteredPeriodList = portfolioGroup.getFilteredPeriodList()
      filteredPeriodList.push({ key: "Manual", value: 0, date: chartsData?.data?.lastDate ? [manualPeriodList?.date?.[0] ?? filteredPeriodList[0].date[0], chartsData?.data?.lastDate] : filteredPeriodList[0].date })
      const shiftedReturns = { ...accReturns }
      const retTPD = portfolioGroup.getReturnTable()
      const returnsTablePortfolioData = retTPD
      const { returnsTableData, screenerTable } = createReturnsTable(portfolios, returnsTablePortfolioData, catalogByFunds, returnsPeriodMap, isAPV, portfoliosInstances, chartsData?.data?.lastDate)


      let newTimePeriod = filteredPeriodList[0].value;
      if(newTimePeriod !== -2){
        if (chartsData.data.timePeriod !== null && newTimePeriod > chartsData.data.timePeriod) {
          newTimePeriod = chartsData.data.timePeriod;
        }
      }

      dispatch(
        updateCharstData({
          ...chartsData.data,
          performanceChartData: shiftedReturns,
          portfoliosTableReturns: returnsTableData,
          periodList: filteredPeriodList,
          timePeriod: newTimePeriod,
          screenerTable,
          lastDate: chartsData?.data?.lastDate ?? null,
          maxMinProfitLossesChartData: formattedMaxMinProfitLossesChartData,
          portfoliosNumber: portfoliosInstances.length,
        }),
      )
    }
    if (chartsData?.data?.lastDate && portfolioGroup) {
      callLastDate()
    }
  }, [chartsData.data.lastDate])


};

export default usePortfolioDates;
