import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { togglePreviewPanel } from "../store/portfolioPanelSlice";
// import { useFeature } from "flagged"
import * as ROUTES from "./APP_ROUTES";
import SETTINGS from "../settings/settings";

// FEATURE FLAGS
// import { FEATURE_FLAGS } from "../utils/featureFlags"

// COMPONENTS
import RequireAuth from "./RequireAuth";
import AccessRoutes from "./AccessRoutes";
import ScrollToTop from "../components/ui/utilities/ScrollToTop";
import Header from "../components/ui/header/Header";
import Notifications from "../components/ui/notifications/Notifications";

// PUBLIC PAGES
import LoginPage from "../pages/Auth/LoginPage";
import PasswordForget from "../pages/Auth/PasswordForget";
import PasswordReset from "../pages/Auth/PasswordReset";
import RegisterPage from "../pages/Auth/RegisterPage";
import ValidatePage from "../pages/Auth/ValidatePage";
import ContactPage from "../pages/contact/ContactPage";
import RequestDemoPage from "../pages/requestDemo/RequestDemoPage";
import UnauthorizedPage from "../pages/UnauthorizedPage/UnauthorizedPage";

// PRIVATE PAGES
import ScreenerPage from "../pages/ScreenerPage/ScreenerPage";
import AnalysisPage from "../pages/AnalysisPage/AnalysisPage";

// PRIVATE CLIENT PAGES
// import ClientGoalpage from "../pages/ClientPages/ClientGoalPage"

// ACCOUNT
import AccountPage from "../pages/AccountPage/AccountPage";
import PasswordChangePage from "../pages/AccountPage/PasswordChangePage";
import UserMyAnalisysPage from "../pages/AccountPage/UserMyAnalisysPage";

// ADMIN
import AdminPage from "../pages/AdminPage/AdminPage";
import UsersPage from "../pages/AdminPage/UsersPage";
import CompaniesPage from "../pages/AdminPage/CompaniesPage";
import UserRegistryPage from "../pages/AdminPage/UserRegistryPage";
import ListComponents from "../pages/ListComponents/ListComponents";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import StatisticsPage from "../pages/StatisticsPage/StatisticsPage";
import { RedirectToLanding } from "./RedirectToLanding";

// import RenderIf from "../components/helpers/RenderIf"

const staticRoutes = [ROUTES.LOGIN, ROUTES.PASSWORD_FORGET, "/public-analysis"];
function AppRoutes() {
  // ROUTES
  const location = useLocation();
  // STORE
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  // LIFE CYCLE
  useEffect(() => {
    dispatch(togglePreviewPanel(false));
  }, [location]);

  // METHODS
  const checkControlVisibility = () => {
    const shouldShowControls = staticRoutes.includes(location.pathname);
    const urlSplited = location.pathname.split("/")[1];
    if (urlSplited === "public-analysis" || urlSplited === "pdf-analysis") {
      return true;
    }
    return shouldShowControls;
  };
  // const hasRouteFeature = useFeature(FEATURE_FLAGS.ROUTE_ANALYSIS.name)

  return (
    <div>
      <ScrollToTop />
      {user && !checkControlVisibility() ? <Notifications /> : null}
      {user && !checkControlVisibility() ? <Header /> : null}
      <Routes>
        {/* LOGIN ROUTES */}
        <Route element={<AccessRoutes />}>
          <Route exact path={ROUTES.HOME} element={<RedirectToLanding />} />
          <Route exact path={ROUTES.LANDING} element={<RedirectToLanding />} />
          <Route exact path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route
            exact
            path={ROUTES.PASSWORD_FORGET}
            element={<PasswordForget />}
          />
          <Route
            exact
            path={ROUTES.PASSWORD_RESET}
            element={<PasswordReset />}
          />
          <Route exact path={ROUTES.REGISTER_PAGE} element={<RegisterPage />} />
          <Route
            exact
            path={ROUTES.VALIDATE_ACCOUNT_PAGE}
            element={<ValidatePage />}
          />
          <Route exact path={ROUTES.CONTACT_PAGE} element={<ContactPage />} />
          {/* <Route
            exact
            path={ROUTES.REQUEST_DEMO}
            element={<RequestDemoPage />}
          /> */}
        </Route>

        {/* PUBLIC ROUTES */}
        <Route exact path={ROUTES.ERROR} element={<ErrorPage />} />

        {/* PRIVATE ROUTES */}
        <Route element={<RequireAuth />}>
          <Route path={ROUTES.SCREENER} element={<ScreenerPage />} />
        </Route>

        {/* <Route element={<RequireAuth />}>
          <Route path={ROUTES.CLIENT_GOAL} element={<ClientGoalpage />} />
        </Route> */}

        {/* ACCOUNT PAGES */}
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path={ROUTES.USER} element={<AccountPage />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route
            path={ROUTES.PASSWORD_CHANGE}
            element={<PasswordChangePage />}
          />
        </Route>

        {/* ADMIN PAGES */}
        <Route
          element={
            <RequireAuth
              allowedRoles={[SETTINGS.USER_PROFILES.APP_ADMINISTRATOR]}
            />
          }
        >
          <Route path={ROUTES.ADMIN} element={<AdminPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[SETTINGS.USER_PROFILES.APP_ADMINISTRATOR]}
            />
          }
        >
          <Route path={ROUTES.USERS} element={<UsersPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[SETTINGS.USER_PROFILES.APP_ADMINISTRATOR]}
            />
          }
        >
          <Route path={ROUTES.COMPANIES} element={<CompaniesPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[SETTINGS.USER_PROFILES.APP_ADMINISTRATOR]}
            />
          }
        >
          <Route path={ROUTES.EDIT_USER} element={<UserRegistryPage />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[SETTINGS.USER_PROFILES.APP_ADMINISTRATOR]}
            />
          }
        >
          <Route path={ROUTES.LIST_COMPONENTS} element={<ListComponents />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[SETTINGS.USER_PROFILES.APP_ADMINISTRATOR]}
            />
          }
        >
          <Route path={ROUTES.STATISTICS} element={<StatisticsPage />} />
        </Route>

        {/* ANALYSIS PAGES */}
        <Route element={<RequireAuth />}>
          <Route path={ROUTES.ANALYSIS} element={<AnalysisPage />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[]} />}>
          <Route path={ROUTES.MY_ANALISIS} element={<UserMyAnalisysPage />} />
        </Route>

        {/* MISC */}
        <Route element={<RequireAuth />}>
          <Route path={ROUTES.UNAUTHORIZED} element={<UnauthorizedPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default AppRoutes;
