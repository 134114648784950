import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import usePrepareProposalStatisticsData from "../usePrepareProposalStatisticsData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const StatsHistogram = ({ proposalData }) => {
  const { getRollupForProposals } = usePrepareProposalStatisticsData();
  const groupedData = getRollupForProposals(proposalData);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    locale: "es-CL",
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          color: "rgb(203 213 225 / 0.4)",
        },
      },
      y: {
        grid: {
          color: "rgb(203 213 225 / 0.4)",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: () => "Propuestas para: ",
          label: function (context) {
            const labelParts = context.label.split(", ");
            let label = `${labelParts[0]} ${labelParts[1]}`;
            let total = context.raw;
            return `${label}: ${total}`;
          },
        },
      },
    },
  };
  const labels = groupedData.map((d) => d[0]);
  const data = {
    labels,
    datasets: [
      {
        label: "Nº de Propuestas",
        data: groupedData.map((d) => d[1]),
        backgroundColor: "rgb(63 131 248 / 1)",
      },
    ],
  };

  return (
    <div className="w-full h-[400px]">
      <Bar options={options} data={data} />
    </div>
  );
};

export default StatsHistogram;
