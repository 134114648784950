import { useContext } from "react"
import PropTypes from "prop-types"
import { useLocation, Navigate, Outlet } from "react-router-dom"

// STORE
import { useSelector } from "react-redux"

// CONTEXT
import FirebaseContext from "../firebase/FirebaseContext"
import * as ROUTES from "./APP_ROUTES"

function RequireAuth({ allowedRoles }) {
  const location = useLocation()
  const user = useSelector((state) => state.user.user)
  const { getCurrentUser } = useContext(FirebaseContext)

  function getDestination() {
    if (allowedRoles.length === 0) {
      return <Outlet />
    }
    if (user.role.find((role) => allowedRoles.includes(role))) {
      return <Outlet />
    }
    return (
      <Navigate to={ROUTES.UNAUTHORIZED} state={{ from: location }} replace />
    )
  }
  return getCurrentUser() ? (
    getDestination()
  ) : (
    <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  )
}

RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
}
RequireAuth.defaultProps = {
  allowedRoles: [],
}

export default RequireAuth
