import PropTypes from "prop-types"
import { Transition } from "@headlessui/react"

function EquityDebt100PercAlert({ shouldShow, isFund, positioned }) {
  return (
    <Transition
      show={shouldShow}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`w-full bg-slate-50 dark:bg-slate-800 text-xs p-3 my-2 rounded-lg ${
        positioned ? "absolute top-0 left-0 z-10" : "static"
      }`}
    >
      <p><strong>Nota</strong>: {`Cuando el porcentaje total es diferente a 100%, es porque el ${isFund ? "fondo" : "portafolio"} invierte en derivados. Esto puede resultar en una sobre/sub exposición, debido a que el instrumento puede tener posiciones apalancadas o implementar estrategias de cobertura`}.</p>
    </Transition>
  )
}

EquityDebt100PercAlert.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  isFund: PropTypes.bool,
  positioned: PropTypes.bool,
}

EquityDebt100PercAlert.defaultProps = {
  isFund: false,
  positioned: false,
}

export default EquityDebt100PercAlert