/* eslint-disable react/prop-types */
import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { esES } from "@mui/x-date-pickers/locales"; // Para textos de los controles
import { es } from "date-fns/locale"; // Para manejar el formato de las fechas
import useColorScheme from "../../../hooks/useColorScheme";

const StyledStaticDatePicker = styled(DatePicker)(({ themeColor, active }) => ({
  ".MuiInputBase-root": {
    fontFamily: "ui-sans-serif, system-ui, sans-serif",
    fontWeight: "300",
    fontSize: "12px",
    maxWidth: "112px",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    backgroundColor: `${themeColor === "light" ? "#f1f5f9" : "#334155"}`,
    color: `${themeColor === "light" ? "#0f172a" : "#f8fafc"}`,
    height: "2rem",
    padding: "0.5rem 0.75rem",
    border: `1px solid ${active ? "#3f83f8" :"transparent"}`, // Aplica el borde cuando es "active"
    "&:hover": {
      borderColor: `${themeColor === "light" ? "#94a3b8" : "#475569"}`,
    },
    "&.Mui-focused": {
      borderColor: `${themeColor === "light" ? "#64748b" : "#1e293b"}`,
      boxShadow: `0 0 0 1px ${themeColor === "light" ? "#64748b" : "#1e293b"}`,
      outline: "none",
    },
    "&.Mui-error": {
      borderColor: "#f87171",
    },
  },
  ".MuiOutlinedInput-root": {
    borderRadius: "6px",
    paddingLeft: "0.3rem",
    "& fieldset": {
      borderColor: `${themeColor === "light" ? "#cbd5e1" : "#475569"}`,
    },
    "&:hover fieldset": {
      borderColor: `${themeColor === "light" ? "#94a3b8" : "#475569"}`,
    },
    "&.Mui-focused fieldset": {
      borderColor: `${themeColor === "light" ? "#64748b" : "#1e293b"}`,
    },
    "&.Mui-error fieldset": {
      borderColor: "#f87171",
    },
    "&.Mui-focused": {
      outline: "none",
    },
  },
  ".MuiInputBase-input": {
    paddingLeft: "0.2rem",
    cursor: "auto",
    "&:focus": {
      outline: "none",
      boxShadow: "none",
    },
  },
  ".MuiSvgIcon-root": {
    marginRight: "0.5rem",
    color: `${themeColor === "light" ? "#94a3b8" : "#e2e8f0"}`,
  },
  ".MuiInputAdornment-root": {
    marginRight: "-0.5rem",
    marginLeft: "0",
    width: "24px",
  },
  ".MuiButtonBase-root": {
    paddingLeft: "0",
  },
}));

function ReturnDatePicker({ onChange, value, minDate, maxDate, active, label }) {
  const [colorScheme] = useColorScheme(); // Hook para obtener el tema actual (light o dark)

  // Normalizar las fechas minDate y maxDate a las 00:00 horas
  const normalizedMinDate = new Date(minDate);
  normalizedMinDate.setHours(0, 0, 0, 0);

  const normalizedMaxDate = new Date(maxDate);
  normalizedMaxDate.setHours(23, 59, 59, 999); // Establecer la hora al final del día para maxDate

  // Función para validar la selección de fechas
  const handleDateChange = (newValue) => {
    if (newValue && newValue < normalizedMinDate) {
      console.warn("La fecha seleccionada es anterior a la mínima permitida.");
      return; // No actualiza si la fecha es menor que minDate
    }
    if (newValue && newValue > normalizedMaxDate) {
      console.warn("La fecha seleccionada es mayor que la máxima permitida.");
      return; // No actualiza si la fecha es mayor que maxDate
    }
    onChange(newValue); // Solo permite el cambio si la fecha es válida
  };

  // Función para deshabilitar fechas fuera del rango en el calendario
  const shouldDisableDate = (date) => {
    return date < normalizedMinDate || date > normalizedMaxDate; // Deshabilitar fechas fuera del rango
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={es} // Pasa la localización de date-fns para las fechas
      localeText={esES} // Traducciones para los controles de MUI
    >
      <StyledStaticDatePicker
        label={label}
        active={active}
        themeColor={colorScheme}
        minDate={normalizedMinDate}
        maxDate={normalizedMaxDate}
        shouldDisableDate={shouldDisableDate} // Función para deshabilitar fechas fuera del rango
        slotProps={{
          field: {
            size: "small",
            format: "dd MMM yyyy", // Cambia el formato aquí si es necesario
          },
          textField: {
            readOnly: true, // Evita que el campo de texto sea editable
          },
        }}
        views={['year', 'month', 'day']} // Especifica las vistas escalonadas de año, mes y día
        className="justify-self-end text-xs text-slate-900 dark:text-slate-50"
        weekStart={1} // Configura el inicio de la semana al lunes
        onChange={handleDateChange} // Validamos las fechas aquí
        value={value}
        disableFuture // Deshabilita fechas futuras
      />
    </LocalizationProvider>
  );
}

export default ReturnDatePicker;
