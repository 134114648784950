import { DataFrame, toJSON } from "danfojs"
import {
  calculateRelativeDateFrom,
  calculateYTDDateFrom,
  getFixedMonthOrDate,
} from "./DateCalculations"
// SENTRY
import * as Sentry from "@sentry/browser";


/**
 * Retrieves the return value for a specific period based on the provided date.
 *
 * @param {Array} data - An array of objects containing date and value properties.
 * @param {string} lastDate - The date for which the return value is to be retrieved.
 * @returns {number} The return value for the specified date.
 */
const getPeriodReturn = (data, lastDate) => {
  const lastValue = data.find((row) => row.date === lastDate).value
  return lastValue
}

/**
 * Retrieves the annualized return value for a specific period based on the provided date.
 */
const getPeriodAnnualizedReturn = (months, data, lastDate) => {
  let lastValue = data.find((row) => row.date === lastDate).value
  lastValue = ((1 + lastValue / 100.0) ** (12 / months) - 1) * 100.0
  return lastValue
}

/**
 * Calculates the accumulated Return of a fund
 * @param {DataFrame} dfReturns - The daily return
 * @param {int} base - Initial invesment amount
 * @returns {DataFrame} DataFrame with the new column appended
 */
function calculateAccumulatedReturn(dfReturns, base = 1) {
  try {
    if (dfReturns.size === 0) {
      return dfReturns
    }
    // copy DataFrame to work with
    const dfjson = toJSON(dfReturns, {
      format: "column",
    })
    const dfcopy = new DataFrame(dfjson)
    const sortedDF = dfcopy.copy()
    // set first 'value' point to 0 to start at {base}
    sortedDF.values[0][sortedDF.columns.indexOf("value")] = 0
    // Save the new value
    const sortedDFCero = sortedDF.copy()

    // calculate accumulated returns
    // Acc = Acc_before * (1 + Daily)
    const colPlusOne = sortedDFCero.value.add(1)
    const colCumProd = colPlusOne.cumProd()
    let colAccReturn

    // realmente necesario?
    if (base === 0) {
      colAccReturn = colCumProd.sub(1).mul(100)
    } else {
      colAccReturn = colCumProd.mul(base)
    }
    // Adding the new column to the DataFrame
    sortedDFCero.addColumn("value", colAccReturn, { inplace: true })
    return sortedDFCero.copy()
  } catch (error) {
    Sentry.captureException(error);
    console.error(error)
    throw error
  }
}

/**
 * Calculates the accumulated Return chart
 * @param {DataFrame} pDta - Acummulated returns
 * @param {int} tPeriod - Number of months
 * @param {Date} firstPerformanceDate - First date of performance data
 * @param {Date} lastPerformanceDate - Last date of performance data
 * @returns {DataFrame} DataFrame with the new column appended
 */
async function portfolioAcc(
  pData,
  tPeriod,
  firstPerformanceDate,
  lastPerformanceDate,
  base = 1,
) {
  let periodFirstPerformanceDate = null
  // YTD special case
  if (tPeriod === -2) {
    periodFirstPerformanceDate = calculateYTDDateFrom(lastPerformanceDate)
    if (periodFirstPerformanceDate < firstPerformanceDate) {
      periodFirstPerformanceDate = firstPerformanceDate
    }
  } else {
    // calculate the corresponding date of the time period
    periodFirstPerformanceDate = calculateRelativeDateFrom(
      lastPerformanceDate,
      tPeriod,
    )
  }

  // hacer bien el traspaso con caso 0 en el mes
  // const pFPDtring = `${periodFirstPerformanceDate.getFullYear()}-${getFixedMonthOrDate(
  //   periodFirstPerformanceDate.getMonth() + 1,
  // )}-${getFixedMonthOrDate(periodFirstPerformanceDate.getDate())}`

  // for each portfolio in pData
  const pDataClone = [...pData]

  const finalData = []
  const promiseArray = pDataClone.map(async (portfolio) => {
    const newDataDF = await portfolio.getAccReturns(
      lastPerformanceDate,
      periodFirstPerformanceDate,
      base,
    )
    // substract distance to each value
    const colAddDiff = newDataDF.value.add(-1).mul(100)

    // replace value column with calculated value
    const formattedDF = newDataDF.copy()
    formattedDF.addColumn("value", colAddDiff, { inplace: true })
    const formattedData = toJSON(formattedDF, {
      format: "column",
    })
    finalData.push({ name: portfolio.name, data: formattedData })
  })
  await Promise.all(promiseArray)
  return finalData
}

/**
 * Calculates the accumulated Return chart
 * @param {DataFrame} pDta - Acummulated returns
 * @param {int} tPeriod - Number of months
 * @param {Date} firstPerformanceDate - First date of performance data
 * @param {Date} lastPerformanceDate - Last date of performance data
 * @returns {DataFrame} DataFrame with the new column appended
 */
function graphShift(
  pData,
  tPeriod,
  firstPerformanceDate,
  lastPerformanceDate,
  base = 1,
) {
  let periodFirstPerformanceDate = null
  // YTD special case
  if (tPeriod === -2) {
    periodFirstPerformanceDate = calculateYTDDateFrom(lastPerformanceDate)
    if (periodFirstPerformanceDate < firstPerformanceDate) {
      periodFirstPerformanceDate = firstPerformanceDate
    }
  } else {
    // calculate the corresponding date of the time period
    periodFirstPerformanceDate = calculateRelativeDateFrom(
      lastPerformanceDate,
      tPeriod,
    )
  }

  // hacer bien el traspaso con caso 0 en el mes
  const pFPDtring = `${periodFirstPerformanceDate.getFullYear()}-${getFixedMonthOrDate(
    periodFirstPerformanceDate.getMonth() + 1,
  )}-${getFixedMonthOrDate(periodFirstPerformanceDate.getDate())}`

  const index = pData[0].data.findIndex((row) => row.date === pFPDtring)

  // for each portfolio in pData
  const pDataClone = [...pData]

  const finalData = pDataClone.map((portfolio) => {
    // filter data by new time period
    const newData = portfolio.data.slice(index)

    // get first data point
    const firstValue = newData[0].value

    // calculate distance to subtract to each values
    const diff = base - firstValue

    // create dataframe form array
    const newDataDF = new DataFrame(newData)

    // substract distance to each value
    const colAddDiff = newDataDF.value.add(diff).mul(100)

    // replace value column with calculated value
    const formattedDF = newDataDF.copy()
    formattedDF.addColumn("value", colAddDiff, { inplace: true })

    const formattedData = toJSON(newDataDF, {
      format: "column",
    })
    return { name: portfolio.name, data: formattedData }
  })
  return finalData
}

/**
 * Calculates the min and max difference given a period of time
 * @param {DataFrame} accReturnsDf - DataFrame with daily and accumulated returns
 * @param {Array} windowSizes - different periods of time
 * @returns {Object} minMaxLosses - dictionary with min and max of certain ws
 */
function calculateMinMaxLosses(accRDF, windowSizes) {
  try {
    const accReturnsDf = accRDF.copy()
    // define the dictionary
    const minMaxLosses = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const ws of windowSizes) {
      // sets new dictonary entry for ws
      minMaxLosses[ws] = {}
      // table size after shifted
      const newSize = accReturnsDf.size - ws
      // make sure table size is bigger than window size
      if (newSize > 0) {
        // get rid of the last ws values
        const initReturn = accReturnsDf.head(newSize).values
        // get rid of the first ws values
        const endReturn = accReturnsDf.tail(newSize).values

        // substraction
        const returnRollingWindows = endReturn.map(
          (valueEnd, indexInEnd) => valueEnd / initReturn[indexInEnd] - 1,
        )
        // get the min and max values
        const vmin = Math.min(...returnRollingWindows)
        const vmax = Math.max(...returnRollingWindows)

        // saving the values in percentage values
        minMaxLosses[ws] = { min: vmin * 100, max: vmax * 100 }
      }
    }
    return minMaxLosses
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

const calculateReturnsStatistics = async (portfolios, lastDate = null) => {
  const portfoliosMinMaxProfitAndLosses = {}
  const portfoliosAccReturns = []
  // const portfoliosTableReturns = {}

  // calculate maximun value of minimun portfolios date, to select start data pint
  const maxReturnsFirstDay = new Date(
    Math.max(...portfolios.map((portfolio) => portfolio.getReturnsFirstDay())),
  )

  // calculate minimun value of maximun portfolios date, to select end data pint
  const minReturnsLastDay = new Date(
    Math.min(...portfolios.map((portfolio) => portfolio.getReturnsLastDay())),
  )

  const returnLastDay = lastDate ? lastDate > minReturnsLastDay ? minReturnsLastDay : lastDate : minReturnsLastDay

  // Iterate over the entries of the 'portfolios' object
  // and use Array.prototype.map() to create a new array of promises
  const promiseArray = portfolios.map(async (portfolio) => {
    const portfolioReturnsStatistics = await portfolio.getReturnsStatistics(
      returnLastDay,
      maxReturnsFirstDay,
    )
    portfoliosMinMaxProfitAndLosses[portfolio.name] =
      portfolioReturnsStatistics.minMaxLosses
    // portfoliosTableReturns[portfolio.name] =
    //   portfolioReturnsStatistics.tableReturns
    portfoliosAccReturns.push({
      name: portfolio.name,
      data: portfolioReturnsStatistics.formattedAccReturns,
    })
  })

  // Wait for all the promises to resolve using Promise.all()
  await Promise.all(promiseArray)

  return {
    portfoliosMinMaxProfitAndLosses,
    portfoliosAccReturns,
    minReturnsLastDay,
    maxReturnsFirstDay,
    // portfoliosTableReturns,
  }
}

/**
 * Calcula los totales para todos los portafolios proporcionados.
 * 
 * @param {Object} portfolios - Objeto que contiene los portafolios.
 * @param {string} distributionOption - Opción de distribución ('weight' o cualquier otro valor).
 * @param {number} baseAmount - Monto base usado para determinar el cálculo cuando se usa la opción 'weight'.
 * 
 * @returns {Object} - Un objeto que mapea el nombre de cada portafolio a su total calculado.
 * 
 * @example
 * const portfolios = {
 *   portfolioA: {
 *     portfolioName: "Portafolio A",
 *     portfolioFunds: [{ amount: 100, weight: 50 }]
 *   },
 *   portfolioB: {
 *     portfolioName: "Portafolio B",
 *     portfolioFunds: []
 *   }
 * };
 * const distributionOption = "weight";
 * const baseAmount = 0;
 * const result = calculatePortfoliosTotals(portfolios, distributionOption, baseAmount);
 * console.log(result); // { "Portafolio A": 50, "Portafolio B": 0 }
 */
const calculatePortfoliosTotals = (portfolios, distributionOption, baseAmount) => {
  const totales = Object.keys(portfolios).reduce((acc, portfolioKey) => {
    const portfolio = portfolios[portfolioKey];

    const total = portfolio.portfolioFunds.reduce((acc, curr) => {
      let amount = acc + curr.amount;
      if (distributionOption === "weight") {
        amount = acc + curr.weight;
        if (baseAmount > 0) {
          amount = acc + curr.amount;
        }
      }
      return amount;
    }, 0);

    // Agregar el total de este portafolio al objeto de totales
    // TODO: Esto debe ser la key y no el nombre del portafolio
    acc[portfolio.portfolioName] = total;
    return acc;
  }, {});

  return totales;
};

/**
 * Creates a returns table and a screener table based on the provided portfolios, returns table portfolio data, and catalog by funds.
 * 
 * @param {Object} portfolios - An object containing portfolio information.
 * @param {Object} returnsTablePortfolioData - An object containing returns table data for each portfolio.
 * @param {Object} catalogByFunds - An object containing catalog data by funds.
 * @returns {void}
 */
const createReturnsTable = (portfolios, returnsTablePortfolioData, catalogByFunds, returnsPeriodMap, isAPV = false, portfoliosInstances, selectedDate) => {
  const returnsTableData = {}
  const screenerTable = {}
  Object.keys(portfolios).forEach((portfolio) => {
    const currPort = portfoliosInstances.find((port) => port.name.toString() === portfolios[portfolio].portfolioName.toString())
    let shareClassTableReturns = {}
    if (currPort?.shareClasses) {
      Object.keys(currPort.shareClasses).forEach((shareClass) => {
        const shareClassData = currPort.shareClasses[shareClass]
        const selectedDateString = `${selectedDate.getFullYear()}-${getFixedMonthOrDate(
          selectedDate.getMonth() + 1,
        )}-${getFixedMonthOrDate(selectedDate.getDate())}`
        shareClassTableReturns[shareClass] = shareClassData.shareClass.getTableReturnsData(selectedDateString, isAPV)
      })
    }
    // CREATE SCREENER TABLE
    screenerTable[portfolio] = []
    portfolios[portfolio].portfolioFunds.forEach((fund) => {
      // ADD SELECTED SHARECLASS TO CORRESPONDING PORTFOLIO
      const { fundId } = fund
      const { shareClassName } = fund
      const fundDataArray = catalogByFunds[fund.fundId].filter(
        (shareClass) =>
          fundId.toString() === shareClass.run.toString() &&
          shareClassName === shareClass.serie &&
          isAPV === shareClass.is_apv,
      )
      // fundDataArray = 
      screenerTable[portfolio].push(...fundDataArray)
    })
    // CREATE RETURNS TABLE - GET PERIODS THAT PORTFOLIO IS SHOWING
    if (
      returnsTablePortfolioData[portfolios[portfolio]?.portfolioName] &&
      portfolios[portfolio].portfolioFunds.length > 0
    ) {
      const portfolioPeriods = Object.keys(
        returnsTablePortfolioData[portfolios[portfolio].portfolioName],
      )
      returnsTableData[portfolio] = {
        portfolioData:
          returnsTablePortfolioData[portfolios[portfolio].portfolioName],
        fundsData: portfolios[portfolio].portfolioFunds.map((fund) => {
          const returnsByShareClass = {}
          catalogByFunds[fund.fundId].forEach((shareclass) => {
            const shareClassPeriods = {}
            portfolioPeriods.forEach((period) => {
              const fundName = `${fund.fundId}-${fund.shareClassName}`
              shareClassPeriods[period] =
                shareClassTableReturns[fundName]?.[period]
            })
            returnsByShareClass[shareclass.serie] = shareClassPeriods
          })
          return {
            fundId: fund.fundId,
            fundName: fund.fund_name,
            fundType: fund.fundType,
            returnsByShareClass,
          }
        }),
      }
    }
  })
  return { returnsTableData, screenerTable }
}


export {
  calculateReturnsStatistics,
  calculateMinMaxLosses,
  calculateAccumulatedReturn,
  calculatePortfoliosTotals,
  createReturnsTable,
  getPeriodReturn,
  getPeriodAnnualizedReturn,
  // calculateTableReturn,
  graphShift,
  portfolioAcc,
}


