import { DataFrame, merge, toJSON } from "danfojs"
import {
  calculateAccumulatedReturn,
  calculateMinMaxLosses,
  // calculateTableReturn,
} from "./ReturnsCalculations"
import { getDateToCompareFormat } from "./DateCalculations"
// SENTRY
import * as Sentry from "@sentry/browser";


function getFixedMonthOrDate(value) {
  if (value < 10) {
    return `0${value}`
  }
  return value
}

class Portfolio {
  constructor(
    id,
    name,
    maxMinProfitAndLossesWindows = [91, 182, 365, 730],
    // 1M, 3M, 6M, YTD, 1Y, 3Y, 5Y
    tablePeriods = [30, 91, 182, 365, 1095, 1825],
    shareClasses = {},
    returnsFirstDays = [],
    returnsLastDays = [],
  ) {
    this.id = id 
    // string name of the portfolio (a/b)
    this.name = name
    // list of windows sizes for max/min profint and losses
    this.maxMinProfitAndLossesWindows = maxMinProfitAndLossesWindows
    // dictonary of shareClasses
    this.shareClasses = shareClasses
    // dictonary of returnsFirstDays
    this.returnsFirstDays = returnsFirstDays
    // dictonary of returnsLastDays
    this.returnsLastDays = returnsLastDays
    // list of time periods for table returns
    this.tablePeriods = tablePeriods

    this.nullDates = this.getNullDates()
  }

  addShareClass(shareClass, weight) {
    const shareClassData = {
      shareClass,
      weight,
    }
    this.shareClasses[shareClass.shareClassId] = shareClassData
    // console.log("ShareClass Returns First Day", shareClass.returnsFirstDay)
    this.returnsFirstDays.push(shareClass.returnsFirstDay)
    this.returnsLastDays.push(shareClass.returnsLastDay)

    this.nullDates = this.getNullDates()
  }

  removeShareClass(shareClass) {
    delete this.shareClasses[shareClass.shareClassId]
    const index = this.returnsFirstDays.indexOf(shareClass.returnsFirstDay)
    if (index > -1) {
      this.returnsFirstDays.splice(index, 1)
    }
    const index2 = this.returnsLastDays.indexOf(shareClass.returnsLastDay)
    if (index2 > -1) {
      this.returnsLastDays.splice(index2, 1)
    }
  }

  changeWeight(shareClassId, weight) {
    this.shareClasses[shareClassId].weight = weight
  }

  getNullDates() {
    let nullDatesList = []
    // const shareclassesObj = this.shareClasses
    Object.entries(this.shareClasses).forEach(([, shareClassData]) => {
      const sc = shareClassData.shareClass.returnsLagoon
      nullDatesList = nullDatesList.concat(
        sc.filter((item) => nullDatesList.indexOf(item) < 0),
      )
    })
    return nullDatesList.sort()
  }

  getReturnsFirstDay() {
    return new Date(
      Math.max(
        ...this.returnsFirstDays.map(
          (date) =>
            new Date(
              date.split("-")[0],
              date.split("-")[1] - 1,
              date.split("-")[2],
            ),
        ),
      ),
    )
  }

  getReturnsLastDay() {
    return new Date(
      Math.min(
        ...this.returnsLastDays.map(
          (date) =>
            new Date(
              date.split("-")[0],
              date.split("-")[1] - 1,
              date.split("-")[2],
            ),
        ),
      ),
    )
  }

  getReturns(initDate, closingDate) {
    // calculate the returns of the portfolio
    // get the returns of each fund
    // ponderate them with the weight
    // return them
    try {
      const iterDate = new Date(
        initDate.getFullYear(),
        initDate.getMonth(),
        initDate.getDate(),
      )

      // to consider closing date
      const endDate = new Date(
        closingDate.getFullYear(),
        closingDate.getMonth(),
        closingDate.getDate(),
      )

      // Calcular la fecha máxima entre closingDate y las fechas mínimas de cada serie
      const dates = []
      const zeroValues = []
      while (
        getDateToCompareFormat(iterDate) <= getDateToCompareFormat(endDate)
      ) {
        dates.push(
          `${iterDate.getFullYear()}-${getFixedMonthOrDate(
            iterDate.getMonth() + 1,
          )}-${getFixedMonthOrDate(iterDate.getDate())}`,
        )
        zeroValues.push(0)
        iterDate.setDate(iterDate.getDate() + 1) // Increment the date.
      }

      let df = new DataFrame({ date: dates })
      df.addColumn("weighted", zeroValues, { inplace: true })
      df.addColumn("notNullTotalWeight", zeroValues, { inplace: true })
      // Portfolio
      Object.entries(this.shareClasses).forEach(([, shareClassData]) => {
        const returnsDF = shareClassData.shareClass.getReturnsDF()
        if (returnsDF) {
          // se puede abordar de otra manera sin merge
          // se extrae la columna retorno del fondo
          // se multiplica por el peso
          // se le suma el valor a la columna weighted
          df = merge({
            left: df,
            right: returnsDF,
            on: ["date"],
            how: "left",
          })

          // se agrega columna que permite calcular la suma de los pesos para cada fila
          // solo para los fondos que no tienen datos null
          const notNullTotalWeightData = []
          const notNullTotalWeightIndex =
            df.columns.indexOf("notNullTotalWeight")
          const valueIndex = df.columns.indexOf("value")
          df.values.forEach((v) => {
            const value = v[valueIndex]
            if (value !== null) {
              notNullTotalWeightData.push(
                parseFloat(v[notNullTotalWeightIndex]) +
                parseFloat(shareClassData.weight),
              )
            } else {
              notNullTotalWeightData.push(v[notNullTotalWeightIndex])
            }
          })

          const weightedColumn = df.weighted.add(
            df.value.mul(shareClassData.weight),
          )
          df.addColumn("weighted", weightedColumn, { inplace: true })
          df.addColumn("notNullTotalWeight", notNullTotalWeightData, {
            inplace: true,
          })
          df.fillNa(0, { columns: ["notNullTotalWeight"], inplace: true })
          df.drop({ columns: "value", inplace: true })
          df.drop({ columns: "name", inplace: true })
        }
      })

      // se divide el resultado del promedio ponderado por el nuevo total
      // esto implica que sólo se consideran los fondos que tienen valores distintos de null en el calculo
      // y se reponderan sus pesos
      const weightedPerNotNullTotalWeightColumn = df.weighted.div(
        df.notNullTotalWeight,
      )
      df.addColumn(
        "weightedPerNotNullTotalWeightColumn",
        weightedPerNotNullTotalWeightColumn,
        {
          inplace: true,
        },
      )
      df.drop({ columns: "weighted", inplace: true })
      df.drop({ columns: "notNullTotalWeight", inplace: true })

      const dfResult = df.copy()
      dfResult.rename(
        { weightedPerNotNullTotalWeightColumn: "value" },
        { inplace: true },
      )

      dfResult.fillNa(0, { inplace: true })
      dfResult.value.values.forEach((v) => {
        if (Number.isNaN(v)) {
          // throw new Error("dataframe has NaN values")
          console.log("dataframe has NaN values")
        }
      })
      return dfResult.copy()
    } catch (e) {
      Sentry.captureException(e);
      console.error(e)
      throw e
    }
  }

  async getAccReturns(closingDate, initDate) {
    try {
      const accReturns = calculateAccumulatedReturn(
        this.getReturns(initDate, closingDate),
      )
      return accReturns
    } catch (e) {
      Sentry.captureException(e);
      console.log(e)
      throw e
    }
  }

  async getReturnsStatistics(closingDate, initDate) {
    try {
      const accReturns = calculateAccumulatedReturn(
        this.getReturns(initDate, closingDate),
      )

      const formattedAccReturns = toJSON(accReturns, {
        format: "column",
      })

      const minMaxLosses = calculateMinMaxLosses(
        accReturns.value,
        this.maxMinProfitAndLossesWindows,
      )
      return { minMaxLosses, formattedAccReturns }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e)
      throw e
    }
  }

  getExposures() {
    // calculate the exposure of the portfolio
    // get the exposure of each fund
    // add each category to a global one
    // console.log("GET EXPOSURE 2")
    const portfolioExposure = {}
    const closingDate = []
    try {
      // Portfolio
      Object.entries(this.shareClasses).forEach(([, shareClassData]) => {
        const exposure = shareClassData.shareClass.exposures
        closingDate.push(exposure.closing_date)
        const { weight } = shareClassData
        // console.log("weight", shareClassData.weight)
        // console.log(exposure)
        const expType = Object.keys(exposure.exposures)
        expType.forEach((type) => {
          // console.log("lenght", exposure.exposures[type].length)

          // si es que hay datos para cierto tipo de exposure
          if (exposure.exposures[type].length > 0) {
            exposure.exposures[type].forEach((element) => {
              const weightedPercentage = weight * element.percentage
              if (portfolioExposure[type]) {
                let changed = false
                portfolioExposure[type] = portfolioExposure[type].map(
                  (portfolioElement) => {
                    if (portfolioElement.label === element.item) {
                      // portfolioElement.percentage += weightedPercentage
                      changed = true
                      return {
                        label: element.item,
                        value: portfolioElement.value + weightedPercentage,
                      }
                    }
                    return portfolioElement
                  },
                )
                if (!changed) {
                  portfolioExposure[type] = portfolioExposure[type].concat({
                    label: element.item,
                    value: weightedPercentage,
                  })
                }
              } else {
                portfolioExposure[type] = [
                  {
                    label: element.item,
                    value: weightedPercentage,
                  },
                ]
              }
            })
          }
        })
      })
      // console.log("portfolioExposure", portfolioExposure)
      // console.log("closing Date", closingDate)
      return [portfolioExposure, closingDate]
    } catch (e) {
      Sentry.captureException(e);
      console.error(e)
      throw e
    }
  }

  getTac() {
    // calculate the tac of the portfolio
    // get the tac of each fund
    let portfolioTac = 0
    let validWeight = 0
    const tacData = {
      portfolio: this.name,
      tac: portfolioTac,
      shareClasses: [],
      key: this.name === "Portafolio A" ? "portfolioA" : "portfolioB",
    }
    try {
      // Portfolio
      Object.entries(this.shareClasses).forEach(([, shareClassData]) => {
        const { shareClassGeneralInfo } = shareClassData.shareClass
        if (shareClassGeneralInfo) {
          const { weight } = shareClassData
          const tac = shareClassGeneralInfo.tac ? shareClassGeneralInfo.tac : 0
          if (tac !== 0) {
            validWeight += weight
          }
          // add shareclass info
          // shareclass name, fecha tac, tac
          tacData.shareClasses.push({
            shareclassName: shareClassData.shareClass.shareClassId,
            fund_name: shareClassData.shareClass.shareClassId,
            tacDate: shareClassGeneralInfo.tacDate,
            tac,
          })
          portfolioTac += tac * weight
        } else {
          tacData.shareClasses.push({
            shareclassName: shareClassData.shareClass.shareClassId,
            fund_name: shareClassData.shareClass.shareClassId,
            tacDate: 0,
            tac: 0,
          })
        }
      })
      tacData.tac = portfolioTac / validWeight
      return tacData
    } catch (e) {
      Sentry.captureException(e);
      console.error(e)
      throw e
    }
  }

  getIssuers() {
    // calculate the exposure of the portfolio
    // get the exposure of each fund
    // add each category to a global one
    const portfolioIssuers = {}
    const closingDate = []
    try {
      // Portfolio
      Object.entries(this.shareClasses).forEach(([, shareClassData]) => {
        const { issuers } = shareClassData.shareClass
        closingDate.push(issuers.closing_date)
        const { weight } = shareClassData
        if (issuers?.issuers?.length > 0) {
          issuers.issuers.forEach((element) => {
            const weightedPercentage = weight * element.percentage
            if (portfolioIssuers[element.name]) {
              portfolioIssuers[element.name] = {
                label: element.name,
                value:
                  portfolioIssuers[element.name].value + weightedPercentage,
              }
            } else {
              portfolioIssuers[element.name] = {
                label: element.name,
                value: weightedPercentage,
              }
            }
          })
        }
      })
      const sortedValues = Object.values(portfolioIssuers).sort(
        (a, b) => b.value - a.value, // Orden descendente por "value"
      )
      const top10Elements = sortedValues.slice(0, 10)

      return { issuers: top10Elements, closingDate, portfolioName: this.name }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e)
      throw e
    }
  }

  getExposureExtendedSummary() {
    const portfolioExposures = this.getExposures()
    const assetClassExposures = portfolioExposures[0].ASSET_CLASS

    const sortedAssetClassExposures = assetClassExposures.sort(function(first, second){
      return second.value - first.value
    })

    const closingDates = portfolioExposures[1]
    const portfolioId = this.id

    const maxClosingDate = closingDates.reduce((a, b) => a > b ? a : b)

    let exposureExtendedSummary = {
      "fecha": maxClosingDate
    }

    let roundedSums = {
      "Deuda": 0,
      "Capitalización": 0,
      "Otros Activos": 0
    }

    let totalSums = {
      "Deuda": 0,
      "Capitalización": 0,
      "Otros Activos": 0
    }

    exposureExtendedSummary[portfolioId]={
      "Deuda": {"total":0,
                "detalle": []
      },
      "Capitalización": {"total":0,
                        "detalle": []
      },
      "Otros Activos": {"total":0,
                        "detalle":[]
      }
    }

    sortedAssetClassExposures.forEach((exposition) => {
      const currentValue = exposition.value
      const roundedCurrentValue = Number(exposition.value.toFixed(1))
      const currentLabel = exposition.label
      let detailLabel = ""
      let fixedCurrentLabel = ""


      if (exposition.label === "Capitalización Extranjera" || exposition.label === "Capitalización Nacional"){
        fixedCurrentLabel = currentLabel.replace("Capitalización ", "")
        detailLabel = "Capitalización"
      }
      else if (exposition.label === "Deuda Extranjera" || exposition.label === "Deuda Nacional"){
        fixedCurrentLabel = currentLabel.replace("Deuda ", "")
        detailLabel = "Deuda"
      }
      else{
        fixedCurrentLabel = currentLabel.replace("", "")
        detailLabel = "Otros Activos"
      }


      let detail = {}
      roundedSums[detailLabel] += roundedCurrentValue
      detail[fixedCurrentLabel] = roundedCurrentValue
      totalSums[detailLabel] += currentValue
      
      let roundedTotal = Number(totalSums[detailLabel].toFixed(1))

      if (roundedTotal > roundedSums[detailLabel]){
        let missingDelta = roundedTotal - roundedSums[detailLabel]
        detail[fixedCurrentLabel] = Number((detail[fixedCurrentLabel] + missingDelta).toFixed(1))
        roundedSums[detailLabel] = roundedTotal
      }

      exposureExtendedSummary[portfolioId][detailLabel]["detalle"].push(detail)
      roundedSums[detailLabel] += currentValue
    })

    exposureExtendedSummary[portfolioId]["Deuda"]["total"] = Number(totalSums["Deuda"].toFixed(1))
    exposureExtendedSummary[portfolioId]["Capitalización"]["total"] = Number(totalSums["Capitalización"].toFixed(1))
    exposureExtendedSummary[portfolioId]["Otros Activos"]["total"] = Number(totalSums["Otros Activos"].toFixed(1))

    return exposureExtendedSummary
  }

  getExposureSummary() {
    const portfolioExposures = this.getExposures()
    const assetClassExposures = portfolioExposures[0].ASSET_CLASS
    const closingDates = portfolioExposures[1]
    const portfolioId = this.id

    const maxClosingDate = closingDates.reduce((a, b) => a > b ? a : b)

    let exposuredSummary = {
      "fecha": maxClosingDate,
    }
    exposuredSummary[portfolioId]={
      "total": 0,
      "detalle":{
        "Deuda": 0,
        "Capitalización": 0,
        "Otros Activos": 0}
    }

    let totalEquity = 0
    let totalFixedIncome = 0
    let totalOthers = 0

    assetClassExposures.forEach((exposition) => {
      const currentValue = exposition.value
      const currentLabel = exposition.label
      if (exposition.label === "Capitalización Extranjera" || exposition.label === "Capitalización Nacional"){
        totalEquity += currentValue
      }
      else if (exposition.label === "Deuda Extranjera" || exposition.label === "Deuda Nacional"){
        totalFixedIncome += currentValue
      }
      else{
        totalOthers += currentValue
      }
    })

    // Have values show with 1 decimal only
    totalEquity = Number(totalEquity.toFixed(1))
    totalFixedIncome = Number(totalFixedIncome.toFixed(1))
    totalOthers = Number(totalOthers.toFixed(1))

    exposuredSummary[portfolioId]["total"] = Number((totalEquity + totalFixedIncome + totalOthers).toFixed(1))

    exposuredSummary[portfolioId]["detalle"]["Deuda"] = totalFixedIncome
    exposuredSummary[portfolioId]["detalle"]["Capitalización"] = totalEquity
    exposuredSummary[portfolioId]["detalle"]["Otros Activos"] = totalOthers

    return exposuredSummary
  }
  
}
export default Portfolio
