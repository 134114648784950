import { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";

// PROPTYPES
import { userPropTypes } from "../../propTypes/AppPropTypes";

// CONTEXT
import FirebaseContext from "../../firebase/FirebaseContext";

// COMPONENTS
import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";
import StatusMessage from "../../components/ui/utilities/StatusMessage";
import FormGroup from "../../components/ui/forms/FormGroup";
import AuthInputField from "../../components/ui/forms/AuthForms/AuthInputField";
// import AuthCheckBoxField from "../../components/ui/forms/AuthForms/AuthCheckBoxField"

// CONSTANTS
import * as ROUTES from "../../routes/APP_ROUTES";
import { Form } from "@/components/ui/auth/formLayouts/FormLayouts";

const DEFAULT_LOGIN_STATE = {
  email: "",
  password: "",
  error: null,
};

function LoginPage({ user }) {
  // CONTEXTS & HOOKS
  const navigate = useNavigate();
  const { doSignInWithEmailAndPassword } = useContext(FirebaseContext);
  // STATE
  const [formFields, setFormFields] = useState(DEFAULT_LOGIN_STATE);
  // const [rememberMe, setRememberMe] = useState(false)

  // CALLBACKS
  const isInvalid = formFields.password === "" || formFields.email === "";

  const getErrorMessage = (code) => {
    let message = "";
    switch (code) {
      case "app/empty-fields":
        message = "Por favor complete todos los campos.";
        break;
      case "auth/user-not-found":
        message = "El usuario no existe.";
        break;
      case "auth/invalid-login-credentials":
        message = "El usuario o contraseña ingresada son incorrectos.";
        break;
      default:
        message = "Por favor, intente nuevamente.";
        break;
    }
    return { message };
  };

  // HANDLERS
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const { email, password } = formFields;
    if (isInvalid) {
      setFormFields({
        ...formFields,
        ...{ error: getErrorMessage("app/empty-fields") },
      });
    } else {
      doSignInWithEmailAndPassword(email, password)
        .then((response) => {
          if (response.status !== "ERROR") {
            setFormFields(DEFAULT_LOGIN_STATE);
            navigate(ROUTES.SCREENER);
          } else if (response.code === "auth/user-disabled") {
            // console.log("La cuenta está desactivada")
            // history.push("/reactivate");
          } else {
            setFormFields({
              ...formFields,
              ...{ error: getErrorMessage(response.code) },
            });
          }
        })
        .catch(() => {
          setFormFields({ ...formFields, ...{ error: getErrorMessage("") } });
        });
    }
  };
  const onChangeHandler = (event) => {
    setFormFields({
      ...formFields,
      ...{ [event.target.name]: event.target.value },
    });
  };

  return (
    <AuthPageFrame user={user} title="Bienvenido">
      <Form onSubmit={onSubmitHandler} className="w-full">
        <FormGroup>
          <AuthInputField
            type="text"
            name="email"
            placeholder="Tu correo electrónico"
            defaultValue={formFields.email}
            label="Email"
            callback={onChangeHandler}
          />
        </FormGroup>
        <FormGroup>
          <AuthInputField
            type="password"
            name="password"
            placeholder="Tu contraseña"
            defaultValue={formFields.password}
            label="Password"
            callback={onChangeHandler}
            useShowPassword
          />
        </FormGroup>

        {formFields.error && (
          <StatusMessage
            title="Encontramos un problema"
            text={formFields.error.message}
            status="error"
          />
        )}

        <div className="flex flex-col items-center justify-center">
          <button
            type="submit"
            className="py-4 px-9 w-full text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
          >
            Ingresa
          </button>
          <div className="mt-2 text-center">
            <Link
              to={ROUTES.PASSWORD_FORGET}
              className="text-blue-500 transition hover:text-blue-400 focus:text-slate-900"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </div>
        </div>
      </Form>
    </AuthPageFrame>
  );
}

LoginPage.propTypes = {
  user: userPropTypes,
};
LoginPage.defaultProps = {
  user: null,
};

export default LoginPage;
