import { useRef, useState } from "react"
import PropTypes from "prop-types"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { Transition } from "@headlessui/react"

// STORE
import { useSelector } from "react-redux"

import { formatToInteger } from "../utilities/functions"

function ScreenerStatus({ isFiltered, searchTerm, totalResultsFound }) {
  // REF
  const infoModalRef = useRef()
  // STATE
  const [showInfo, setShowInfo] = useState(false)
  // STORE
  const isAPV = useSelector((state) => state.proposalType.isAPV)

  return (
    <div className="text-xs sm:text-sm flex items-center justify-between gap-1 relative z-[45] isolate">
      <div className="group leading-0 translate-y-0.5 z-[45]">
        <button
          className="text-blue-500 dark:text-blue-400 shrink-0 cursor-pointer"
          onClick={() => setShowInfo(!showInfo)}
          onMouseLeave={() => setShowInfo(false)}
        >
          <InformationCircleIcon className="w-5 h-5 shrink-0" />
        </button>
        <Transition
          as="div"
          ref={infoModalRef}
          show={showInfo}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute top-6 left-0 w-[200px] opacity-0 z-[99]"
        >
          <div className="p-4 rounded-sm bg-slate-300 dark:bg-slate-700 text-xs">
            {!isAPV ? (
              <>
                <h1 className="font-semibold mb-2">
                  Fondos en catálogo general
                </h1>
                <p>
                  Los montos de los fondos incluidos en este catálogo están
                  expresados en Pesos Chilenos (CLP)
                </p>
              </>
            ) : (
              <>
                <h1 className="font-semibold mb-2">Fondos en catálogo APV</h1>
                <p>
                  Las rentabilidades presentadas en este catálogo son
                  anualizadas, netas de costos y comisiones, y ajustadas por la
                  variación de la Unidad de Fomento (UF), lo que refleja el
                  rendimiento real del fondo.
                </p>
              </>
            )}
          </div>
        </Transition>
      </div>
      {!isFiltered && !searchTerm ? (
        <h1 className="text-slate-900 dark:text-slate-50 text-base font-semibold">
          <span>Fondos Régimen {isAPV ? "APV" : "General"}: </span>
          <span>{formatToInteger(totalResultsFound)}</span>
        </h1>
      ) : (
        <h1 className="text-slate-900 dark:text-slate-50 text-base font-semibold">
          <span>{formatToInteger(totalResultsFound)} </span>
          <span>
            {totalResultsFound === 1
              ? `fondo ${isAPV ? "APV" : ""} encontrado`
              : `fondos ${isAPV ? "APV" : ""} encontrados`}
          </span>
        </h1>
      )}
    </div>
  )
}

ScreenerStatus.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  totalResultsFound: PropTypes.number,
}
ScreenerStatus.defaultProps = {
  totalResultsFound: null,
}

export default ScreenerStatus
