import { useState, useEffect, useRef } from "react"

let count = 0
function useScrollPosition() {
  const [open, setOPen] = useState(true)
  const ref = useRef(null)
  const lastScrollPosition = useRef(0)

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        if (count > 0) {
          count = 0
          return
        }

        const currentScrollPosition = ref.current.scrollTop
        let scrollDirection = ""
        if (lastScrollPosition.current === currentScrollPosition) {
          scrollDirection = "none"
        } else if (lastScrollPosition.current > currentScrollPosition) {
          scrollDirection = "up"
        } else if (lastScrollPosition.current < currentScrollPosition) {
          scrollDirection = "down"
        }
        // cuando la pantalla se renderiza de nuevo ocasiona que el scrollTop sea 0 por lo tanto se necesita invalidar el siguiente evento util por eso el count 1.
        lastScrollPosition.current = currentScrollPosition

        if (currentScrollPosition > 20 && scrollDirection === "down") {
          setOPen(() => false)
          count = 1
          return
        }
        if (currentScrollPosition <= 0 && scrollDirection === "up") {
          count = 1
          setOPen(() => true)
        }
      }
    }

    const element = ref.current
    if (element) {
      element.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return { open, ref }
}

export default useScrollPosition
