import React, { useEffect, useRef, useState } from "react"
import { ArrowRightIcon } from "@heroicons/react/24/solid"

// UI
import AnalysisCoverPageSelector from "./AnalysisCoverPageSelector"
import AnalysisOrientationSelector from "./AnalysisOrientationSelector"

function AnalysisSidebar() {
  const sidebarRef = useRef(null)
  const [isSticky, setIsSticky] = useState(false)
  const options = useRef([
    {
      id: "inicio",
      label: "Inicio",
    },
    {
      id: "costos",
      label: "Costos",
    },
    {
      id: "returns",
      label: "Rentabilidad",
    },
    {
      id: "exposureAll",
      label: "Composición",
    },
    {
      id: "issuers",
      label: "Emisores",
    },
    {
      id: "risks",
      label: "Pérdidas/ganancias",
    },
    {
      id: "acercade",
      label: "Acerca de",
    },
    {
      id: "disclamer",
      label: "Términos",
    },
  ])
  useEffect(() => {
    if (window) {
      const vpHeight = window.innerHeight
      const sidebarHeight = 700
      if (vpHeight - 112 > sidebarHeight) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [])

  return (
    // TODO: quitar o asignar el sticky segun el alto del viewport
    <div className="w-full">
      {isSticky ? (
        <aside
          ref={sidebarRef}
          className=" sticky top-28 h-[calc(100vh-112px)] px-8 bg-slate-50 dark:bg-slate-800 scroll-pt-28 space-y-10 z-50"
        >
          <div className="space-y-4">
            <h1 className="text-xs uppercase font-bold">Secciones</h1>
            <div className="flex flex-col items-start justify-between gap-5">
              {options.current.map((option) => (
                <a
                  key={option.id}
                  href={`#${option.id}`}
                  className="group w-full text-sm text-slate-800 dark:text-slate-300 hover:text-blue-500 dark:hover:text-blue-400 hover:font-bold flex items-center justify-between gap-2 transition-all duration-300"
                >
                  {option.label}
                  <ArrowRightIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 transition-opacity duration-300" />
                </a>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <h1 className="text-xs uppercase font-bold">Opciones PDF</h1>
            <AnalysisCoverPageSelector />
            <AnalysisOrientationSelector />
          </div>
        </aside>
      ) : (
        <aside
          ref={sidebarRef}
          className=" relative top-28 h-[calc(100vh-112px)] px-8 bg-slate-50 dark:bg-slate-800 scroll-pt-28 space-y-10 z-50"
        >
          <div className="space-y-4">
            <h1 className="text-xs uppercase font-bold">Secciones</h1>
            <div className="flex flex-col items-start justify-between gap-5">
              {options.current.map((option) => (
                <a
                  key={option.id}
                  href={`#${option.id}`}
                  className="group w-full text-sm text-slate-800 dark:text-slate-300 hover:text-blue-500 dark:hover:text-blue-400 hover:font-bold flex items-center justify-between gap-2 transition-all duration-300"
                >
                  {option.label}
                  <ArrowRightIcon className="opacity-0 group-hover:opacity-100 w-3 h-3 transition-opacity duration-300" />
                </a>
              ))}
            </div>
          </div>

          <div className="space-y-4">
            <h1 className="text-xs uppercase font-bold">Opciones PDF</h1>
            <AnalysisCoverPageSelector />
            <AnalysisOrientationSelector />
          </div>
        </aside>
      )}
    </div>
  )
}

export default AnalysisSidebar
