import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { TrashIcon } from "@heroicons/react/24/outline"

// STORE
import { updateActivePortfolio } from "../../../store/portfolioSelectionSlice"

// PROPTYPES
import { childrenPropTypes } from "../../../propTypes/AppPropTypes"

// HOOKS
import usePreviewPanel from "./usePreviewPanel"
import useTracking from "../../../hooks/useTracking"

// import { formatAmount } from "../utilities/functions"

function PreviewLayout({ children, portfolioName, portfolioKey, color }) {
  // TRACK
  const trackEvent = useTracking()
  // HOOKS
  const { removePortfolio, clearPortfolioA } = usePreviewPanel()
  // STORE
  const dispatch = useDispatch()
  const activePortfolio = useSelector(
    (state) => state.portfolioSelection.activePortfolio,
  )
  const portfolioBEnabled = useSelector(
    (state) => state.portfolioSelection.portfolioBEnabled,
  )
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  // STATE
  const [nameValue, setNameValue] = useState(portfolioName)

  useEffect(() => {
    setNameValue(portfolioName)
  }, [portfolioName])

  // METHODS
  const isActive = () => activePortfolio === portfolioKey

  const toggleActivePortfolio = () => {
    if (!isActive()) {
      dispatch(updateActivePortfolio(portfolioKey))
    }
    trackEvent({
      action: "TOGGLE_ACTIVE_PORTFOLIO",
      timestamp: Date.now(),
      type: "CLICK",
      data: portfolioKey,
    })
  }

  const getPortfolioRemoveButton = (portfolio, clear = false) => (
    // Si se pasa la opcion clear, el portafolio se limpia en vez eliminarse
    <div className="flex justify-end">
      <button
        type="button"
        className="text-xs text-blue-500 dark:text-blue-400 hover:text-blue-400 dark:hover:text-blue-500"
        title={`Limpiar ${portfolioName}`}
        onClick={() => {
          if (clear) {
            clearPortfolioA(portfolio)
          } else {
            removePortfolio(portfolio)
          }
        }}
      >
        <TrashIcon className="w-4 h-4" />
      </button>
    </div>
  )

  return (
    <div className="mb-3">
      <div className="text-sm grid grid-cols-preview items-center gap-2 bg-slate-200 dark:bg-slate-700 px-3.5 py-3 rounded-tl-md rounded-tr-md">
        <button
          type="button"
          className="w-5 h-5 rounded-sm grid place-content-center relative"
          style={{ backgroundColor: `${color}` }}
          onClick={toggleActivePortfolio}
          title={
            isActive()
              ? "Este es tu portafolio activo"
              : "Activar este portafolio"
          }
        >
          {isActive() && (
            <span className="w-2 h-2 inline-block bg-white rounded-full" />
          )}
        </button>

        <div className="col-span-3 flex justify-between	">
          <p className="w-full h-full grow text-base text-slate-900 dark:text-slate-50 rounded-md">
            {nameValue}
          </p>
          {portfolioKey === "portfolioA" &&
            !!portfolios?.portfolioA?.portfolioFunds?.length &&
            getPortfolioRemoveButton("portfolioA", !portfolioBEnabled)}
          {portfolioKey === "portfolioB" &&
            portfolioBEnabled &&
            getPortfolioRemoveButton("portfolioB")}
        </div>
      </div>
      <div className="transition-all ease-in-out duration-500 bg-white dark:bg-slate-700 dark:bg-opacity-50 rounded-bl-md rounded-br-md opacity-100">
        {children}
      </div>
    </div>
  )
}
PreviewLayout.propTypes = {
  children: childrenPropTypes.isRequired,
  portfolioName: PropTypes.string.isRequired,
  portfolioKey: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}
export default PreviewLayout
