/* eslint-disable react/prop-types */
import { useRef } from "react"

// TABLES
import { useSelector } from "react-redux"
import IssuersTable from "../../../charts/IssuersTable"
import { formatDataInfoDate } from "../../utilities/functions"
import useColorCompany from "../../../../hooks/useColorCompany"

function SectionIssuers({ data, portfolios }) {
  const maxIssuers = useRef(5)
  const { getColorPortfolio } = useColorCompany()
  const portfoliosName = useSelector((state) => state.portfoliosName.data)

  const renderIssuersTable = (issuersData, portfolioKey) => {
    if (
      !issuersData ||
      !issuersData?.issuers ||
      !issuersData?.closingDate ||
      issuersData?.closingDate?.length === 0
    ) {
      return null
    }

    return (
      <IssuersTable
        title={portfoliosName?.[portfolioKey]?.portfolioName}
        subtitle={`(al ${formatDataInfoDate(issuersData?.closingDate[0])})`}
        issuers={issuersData?.issuers}
        color={getColorPortfolio(portfolioKey)}
        maxIssuers={maxIssuers.current}
      />
    )
  }
  return (
    data && (
      <div className="w-full flex flex-col items-start justify-start gap-8">
        {data.issuers1 &&
          (data?.issuers1?.portfolioName ===
          portfolios?.portfolioA?.portfolioName
            ? renderIssuersTable(data?.issuers1, "portfolioA")
            : renderIssuersTable(data.issuers1, "portfolioB"))}
        {data?.issuers2 &&
          (data.issuers2.portfolioName === portfolios.portfolioA.portfolioName
            ? renderIssuersTable(data.issuers2, "portfolioA")
            : renderIssuersTable(data.issuers2, "portfolioB"))}
      </div>
    )
  )
}

export default SectionIssuers
