import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  ArrowTopRightOnSquareIcon,
  // Cog8ToothIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid"
import {
  ClipboardIcon,
  // XCircleIcon
} from "@heroicons/react/24/outline"

// HOOKS
import useCopyTextToClipboard from "../../../../hooks/useCopyTextToClipboard"
import useMyAnalysisFunctions from "./useMyAnalysisFunctions"

// COMPONENTS
import Tooltip from "../../../libs/Tooltip"
import MyAnalysisUseTemplate from "./MyAnalysisUseTemplate"

// UTILS
import { formatAnalysisDate } from "../../utilities/functions"
import RenderIf from "../../../helpers/RenderIf"

function MyAnalysisNav({
  proposalID,
  analysis,
  webPath,
}) {
  // HOOKS
  const { downloadProposalPDF, copyWebProposalToClipboard } =
    useMyAnalysisFunctions()
  const { isCopyToClipboardAvailable } = useCopyTextToClipboard()
  const excludeList = [
    "No tiene cliente asignado",
    "Sin perfil de riesgo",
    "Sin horizonte de inversión",
    "",
  ]
  const doNotShowMeta =
    excludeList.includes(analysis.client_name) &&
    excludeList.includes(analysis.client_profile) &&
    excludeList.includes(analysis.client_investment_tye)

  return (
    <div className="mb-4 space-y-2">
      <div className="flex items-center justify-between gap-4">
        <div>
          <h2 className="text-base font-semibold">{analysis.title}</h2>
          <p className="text-xs text-slate-600 dark:text-slate-400">
            {`${formatAnalysisDate(analysis.timestamp)}`}
          </p>
        </div>

        <div className="flex items-center justify-end gap-4">
          <Tooltip content="Reutilizar esta propuesta">
            <MyAnalysisUseTemplate analysis={analysis} />
          </Tooltip>

          {analysis.pdf_path_s3 !== "" && (
            <Tooltip content="Descargar análisis como PDF">
              <button
                onClick={() =>
                  downloadProposalPDF(proposalID, analysis.title)
                }
              >
                <ArrowDownTrayIcon className="w-4 h-4" />
              </button>
            </Tooltip>
          )}
          {isCopyToClipboardAvailable && (
            <Tooltip content="Copiar link al portapapeles">
              <button
                type="button"
                onClick={() => copyWebProposalToClipboard(webPath)}
              >
                <ClipboardIcon className="w-4 h-4" />
              </button>
            </Tooltip>
          )}
          <Tooltip content="Abrir análisis en pestaña nueva">
            <Link to={webPath} target="_blank">
              <ArrowTopRightOnSquareIcon className="w-4 h-4" />
            </Link>
          </Tooltip>
        </div>
      </div>
      <RenderIf isTrue={!doNotShowMeta}>
        <div className="flex items-start gap-4">
          {!excludeList.includes(analysis.client_name) && (
            <p className="text-xs">
              <span className="font-bold">Cliente:</span> {analysis.client_name}
            </p>
          )}
          {!excludeList.includes(analysis.client_profile) && (
            <p className="text-xs">
              <span className="font-bold">Perfil:</span>{" "}
              {analysis.client_profile}
            </p>
          )}
          {!excludeList.includes(analysis.client_investment_type) && (
            <p className="text-xs">
              <span className="font-bold">Horizonte de inversión:</span>{" "}
              {analysis.client_investment_type}
            </p>
          )}
        </div>
      </RenderIf>
    </div>
  )
}

MyAnalysisNav.propTypes = {
  // proposalID: PropTypes.string.isRequired,
  analysis: PropTypes.objectOf(PropTypes.string).isRequired,
  webPath: PropTypes.string.isRequired,
}

export default MyAnalysisNav
