import PropTypes from "prop-types"
import { Transition } from "@headlessui/react"

function PortfolioTotalComparisonAlert({ shouldShow, positioned }) {
  return (
    <Transition
      show={shouldShow}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`w-full bg-yellow-200 text-yellow-700 text-xs px-3 py-2 flex items-center justify-start gap-1 mb-5 ${
        positioned ? "absolute top-0 left-0 z-10" : "static"
      }`}
    >
      <strong>Importante:</strong> Los montos totales de inversión para cada
      portafolio no coinciden.
    </Transition>
  )
}

PortfolioTotalComparisonAlert.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  positioned: PropTypes.bool,
}

PortfolioTotalComparisonAlert.defaultProps = {
  positioned: false,
}

export default PortfolioTotalComparisonAlert
