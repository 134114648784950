import { useMemo } from "react"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline"

import { useSelector, useDispatch } from "react-redux"
import {
  updateCurrentPage,
  updateResultsPerPage,
} from "../../../store/paginationSlice"

function DataGridPagination() {
  const NUM_PAGES_OPTIONS = useMemo(() => [5, 10, 15, 25, 50, 100], [])
  const paginationInfo = useSelector((state) => state.pagination.data)
  const dispatch = useDispatch()

  // CALLBACKS
  const handlePageChange = (pageNum) => {
    let requestedPage = pageNum
    if (requestedPage === 0) {
      requestedPage = 1
    }
    dispatch(updateCurrentPage(requestedPage))
  }
  const handleNumRowsChange = (e) => {
    const { value } = e.target
    dispatch(updateResultsPerPage(+value))
  }

  return (
    <nav
      className="w-full overflow-hidden px-2 py-3 grid grid-cols-2 items-end gap-2 bg-white dark:bg-slate-900
      sm:px-4
      md:grid-cols-3 md:gap-5 md:border-t md:border-slate-300 md:dark:border-slate-700
      lg:px-8"
      aria-label="Pagination"
    >
      <div className="flex items-center gap-3">
        <div className="flex flex-col items-start justify-start gap-1">
          <select
            name="resultsPerPage"
            value={paginationInfo.resultsPerPage}
            onChange={handleNumRowsChange}
            className="bg-slate-100 dark:bg-slate-700 w-20 px-2 py-1 text-xs rounded-md text-slate-900 dark:text-slate-50 !outline-none border border-slate-400 dark:border-slate-600 order-2"
          >
            {NUM_PAGES_OPTIONS.map((num) => (
              <option value={num} key={`rpp-${num}`}>
                {num}
              </option>
            ))}
          </select>
          <p className="text-xxs text-slate-500 dark:text-slate-400 order-1">
            Fondos por página
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2 justify-self-end md:gap-4 md:justify-self-center">
        <button
          type="button"
          disabled={paginationInfo.currentPage === 1}
          className="relative inline-flex items-center rounded-full p-2 text-xs font-semibold text-slate-500 dark:text-slate-400 ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
          onClick={() => handlePageChange(1)}
        >
          <ChevronDoubleLeftIcon className="w-4 h-4" />
        </button>
        <button
          type="button"
          disabled={paginationInfo.currentPage === 1}
          className="relative inline-flex items-center rounded-full p-2 text-xs font-semibold text-slate-500 dark:text-slate-400 ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
          onClick={() => handlePageChange(paginationInfo.currentPage - 1)}
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </button>
        <p className="text-xs shrink-0">{`${paginationInfo.currentPage} / ${paginationInfo.totalPages}`}</p>
        <button
          type="button"
          disabled={paginationInfo.currentPage === paginationInfo.totalPages}
          className="relative inline-flex items-center rounded-full p-2 text-xs font-semibold text-slate-500 dark:text-slate-400 ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
          onClick={() => handlePageChange(paginationInfo.currentPage + 1)}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </button>
        <button
          type="button"
          disabled={paginationInfo.currentPage === paginationInfo.totalPages}
          className="relative inline-flex items-center rounded-full p-2 text-xs font-semibold text-slate-500 dark:text-slate-400 ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
          onClick={() => handlePageChange(paginationInfo.totalPages)}
        >
          <ChevronDoubleRightIcon className="w-4 h-4" />
        </button>
      </div>
    </nav>
  )
}

export default DataGridPagination
