/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import {
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline"
import { Transition } from "@headlessui/react"
import { performanceShapeArray } from "../../../propTypes/AppPropTypes"

// HOOKS
import useCheckPortfolioExists from "../../../hooks/useCheckPortfolioExists"

// COMPONENTS
import ToggleSectionButton from "./ToggleSectionButton"
import ScreenshotButton from "../ScreenshotModal/ScreenshotButton"
import LineChart from "../../charts/LineChart"
import AnalysisReturnsTableAPV from "../../charts/ReturnsTable/AnalysisReturnsTableAPV"
import RenderIf from "../../helpers/RenderIf"
import useColorCompany from "../../../hooks/useColorCompany"

import { formatDataInfoDate, formatReturnsDate } from "../utilities/functions"
import useChartDataHandlers from "../../../hooks/useChartDataHandlers"
import { getDifferenceInMonths } from "../../../utils/DateCalculations"

function AnalysisReturnsAPV({
  performanceData,
  performanceTableData,
  timePeriod,
  periodList,
  isDownSample,
  emptyDates,
  onOfComponent,
  handleTakeScreenshot,
}) {
  // REF
  const infoModalRef = useRef()
  // STATE
  const [showInfo, setShowInfo] = useState(false)
  // HOOKS
  const checkPortfolioExists = useCheckPortfolioExists()
  const portfolioExistenceStatus = checkPortfolioExists()
  const { getColorsCompany } = useColorCompany()
  // MEMO
  const apvExcludePeriods = [1, 3, -2, 6]

  // STORE
  const appData = useSelector((state) => state.appData.data)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )
  // const MinDate = periodList?.[0]?.date?.[0]
  // const maxDate = periodList?.[0]?.date?.[1]
  const selectedDate = periodList?.find((item) => item.value === timePeriod)?.date

  const [firstSelected, setFirstSelected] = useState(selectedDate?.[0])
  const [lastSelected, setLastSelected] = useState(selectedDate?.[1])

  const {
    handleStartDateChange,
    handleEndDateChange,
    handleSelector
  } = useChartDataHandlers(
    firstSelected,
    lastSelected,
    setFirstSelected,
    setLastSelected,
  )

  const ageInMonths = getDifferenceInMonths(
    // lastPortfoliosPerformanceDate,
    // firstPortfoliosPerformanceDate,
    firstSelected,
    lastSelected,
  )
  const hasLessThanOneYear = ageInMonths < 12 && ageInMonths >= 6


  // CALLBACKS
  const takeScreenshotHandler = () => {
    const today = new Date()
    const modalData = {
      type: "returns",
      portfolioExistenceStatus,
      filename: `Rentabilidad ${
        periodList?.filter((obj) => obj.value === +timePeriod)[0].key
      } - ${new Intl.DateTimeFormat("es-CL").format(today)}`,
      chart: {
        data: performanceData,
        timePeriod,
        isScreenerPage: false,
      },
      table: {
        show: performanceTableData,
        data: performanceTableData,
      },
    }

    handleTakeScreenshot(modalData)
  }

  const getLastDate = () => {
    return (
        timePeriod === 0 ? (
            <p className="text-xs text-slate-500 dark:text-slate-400">
                 Desde el {formatReturnsDate(firstSelected)} al {formatReturnsDate(lastSelected)}
            </p>
        ) : (
        <p className="text-xs text-slate-500 dark:text-slate-400">
            Al {formatReturnsDate(lastSelected)}
        </p>
        )
    )
  }
  /*
  const firstPortfoliosPerformanceDate = parseAPIDate(
    chartsData.performanceChartData[timePeriod][0].data[0].date
  )
  const lastDatePosition =
    chartsData.performanceChartData[timePeriod][0].data.length - 1

  const lastPortfoliosPerformanceDate = parseAPIDate(
    chartsData?.performanceChartData[timePeriod][0]?.data[lastDatePosition].date,
  )
  const ageInMonths = getDifferenceInMonths(
    lastPortfoliosPerformanceDate,
    firstPortfoliosPerformanceDate,
  )
  const hasLessThanOneYear = ageInMonths < 12 && ageInMonths >= 6
  */

  return (
    <div id="returns">
      <header className="mb-4 space-y-1">
        <div className="flex items-center">
          <h2 className="analysis-box-header">Rentabilidad real</h2>
          <div className="group relative">
            <button
              className="ml-2 block text-blue-500 dark:text-blue-400 shrink-0 cursor-pointer"
              onClick={() => setShowInfo(!showInfo)}
              onMouseLeave={() => setShowInfo(false)}
            >
              <InformationCircleIcon className="w-5 h-5 shrink-0" />
            </button>
            <Transition
              as="div"
              ref={infoModalRef}
              show={showInfo}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute top-6 right-0 md:translate-x-1/2 w-[200px] opacity-0 z-50"
            >
              <div className="p-4 rounded-sm bg-slate-300 dark:bg-slate-700 text-xs">
                <p>
                  El cálculo de rentabilidad considera todos los eventos de
                  capital (dividendos, repartos, etc) y los valores están
                  deflactados por la U.F. (rentabilidad real).
                </p>
              </div>
            </Transition>
          </div>
          <ToggleSectionButton component="returns" action={onOfComponent} />
          <RenderIf isTrue={appData.screenshot_ability}>
            <ScreenshotButton takeScreenshotHandler={takeScreenshotHandler} />
          </RenderIf>
        </div>
        {getLastDate()}
      </header>
      <div
        className={`
        transition-opacity duration-500
          ${
            analysisDataConfig.showComponents.returns
              ? "opacity-100 pointer-events-auto"
              : "opacity-30 pointer-events-none"
          }
        `}
      >
        <div className="flex items-center justify-end gap-2">
          <select
            name="TimePeriod"
            value={timePeriod}
            onChange={(event) =>
              handleSelector(event)
            }
            className={`w-24 px-2 py-1 bg-slate-50 dark:bg-slate-700 border border-slate-300 dark:border-slate-700 rounded-md text-xs text-slate-900 dark:text-slate-50 !outline-none cursor-pointer
                hover:shadow-none hover:border-blue-400 hover:outline-none
                focus:border-blue-400 focus:ring-0`}
          >
            {periodList.map((tp) => {
              let option = null
              if (hasLessThanOneYear && tp.value === 6) {
                option = (
                  <option value={6} key="6 meses">
                    6 meses
                  </option>
                )
              } else if (!apvExcludePeriods.includes(tp.value)) {
                option = (
                  <option value={tp.value} key={tp.key}>
                    {tp.key}
                  </option>
                )
              } else {
                return null
              }
              return option
            })}
          </select>
        </div>
        {performanceData && (
          <div data-name="chart" className="mb-10">
            <LineChart
              performanceData={performanceData}
              downSample={isDownSample}
              emptyDates={emptyDates}
              colors={getColorsCompany()}
            />
          </div>
        )}
        {performanceTableData && (
          <div data-name="table" className="w-full text-xs">
            <AnalysisReturnsTableAPV
              data={performanceTableData}
              portfolioExistenceStatus={portfolioExistenceStatus}
              colors={getColorsCompany()}
              hasLessThanOneYear={hasLessThanOneYear}
            />
            <p className="mt-8 text-xxs text-slate-600 dark:text-slate-400 flex gap-1">
              <InformationCircleIcon className="w-4 h-4" /> Los datos
              corresponden a rentabilidad real anualizada.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

AnalysisReturnsAPV.propTypes = {
  performanceData: performanceShapeArray.isRequired,
  performanceTableData: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
        PropTypes.array,
      ]),
    ),
  ).isRequired,
  timePeriod: PropTypes.number,
  // setTimePeriod: PropTypes.func.isRequired,
  periodList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  isDownSample: PropTypes.bool,
  emptyDates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
  // hasLessThanOneYear: PropTypes.bool.isRequired,
}

AnalysisReturnsAPV.defaultProps = {
  isDownSample: false,
  emptyDates: [[], []],
  timePeriod: -2,
  periodList: [{ key: "YTD", value: -2 }],
}

export default AnalysisReturnsAPV
