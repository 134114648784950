import SETTINGS from "../settings/settings"
import { Fetch } from "../hooks/useFetch"
// SENTRY
import * as Sentry from "@sentry/browser";


const getCompanies = async (context, callback) => {
  const { API_URL } = SETTINGS.urls
  try {
    const url = `${API_URL}/companies/list`
    const response = await Fetch(url, {}, context,
        (response) => {
        callback(response)
    },
    "post");

    return response
  } catch (error) {
    Sentry.captureException(error);
    console.error("Error:", error)
    return null
  }
}
export default getCompanies
