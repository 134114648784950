import { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
// PROPTYPES
import {
  minMaxChartDataPropTypes,
  minMaxChartDataOptions,
} from "../../../propTypes/AppPropTypes";
// HOOKS
import useResizeObserver from "../../../hooks/useResizeObserver";
import useCheckPortfolioExists from "../../../hooks/useCheckPortfolioExists";
// STYLES
import styles from "./MinMaxBarChart.module.css";
// COMPONENTS
import MinMaxBarChartSVG from "./MinMaxBarChartSVG";
// UTILS
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor";

function MinMaxBarChart({
  data,
  chartOptions,
  colors,
  distributionOption,
  baseAmount,
  disabledDarkMode,
}) {
  const checkPortfolioExists = useCheckPortfolioExists();
  const portfolioExistenceStatus = checkPortfolioExists();
  const checkColor = () => {
    if (portfolioExistenceStatus.doblePortFolio) {
      return colors;
    }
    if (
      portfolioExistenceStatus.portFolioB &&
      !portfolioExistenceStatus.portFolioA
    ) {
      return [colors[1], colors[1]];
    }
    if (
      portfolioExistenceStatus.portFolioA &&
      !portfolioExistenceStatus.portFolioB
    ) {
      return [colors[0], colors[0]];
    }
    return colors;
  };

  // CHART CONFIG
  const defaultOptions = useMemo(
    () => ({
      portfolioNumber: 2,
      barGroupHeight: 8,
      labelWidth: 80,
      barLabelGap: 4,
      barLabelEdgeOffset: 40,
      barGroupVerticalGap: 30,
      colorPalette: colors,
      minColorPalette: [
        addOpacityToHexColor(colors[0], 0.5),
        addOpacityToHexColor(colors[1], 0.5),
      ],
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    }),
    [],
  );
  const options = useMemo(
    () => ({
      ...defaultOptions,
      ...chartOptions,
    }),
    [defaultOptions, chartOptions],
  );

  // REFS
  const wrapperRef = useRef(null);
  // HOOKS
  const dimensions = useResizeObserver(wrapperRef);
  // DATA PROCCESS
  const yScaleDomain = useMemo(
    () => Array.from(Array(options.portfolioNumber).keys()),
    [options],
  );
  const dataXDomain = useMemo(() => {
    const dataArray = [];
    data?.forEach((d) =>
      d?.portfolios?.forEach((p) => {
        dataArray.push(Math.abs(p.total));
        dataArray.push(p.total * Math.abs(p.min / 100));
        dataArray.push(p.total * Math.abs(p.max / 100));
      }),
    );
    return dataArray;
  }, [data]);
  // CHART CONFIG
  const [chartConfig, setChartConfig] = useState(null);

  // SETUP CHART
  useEffect(() => {
    if (!dimensions) return;
    const color = checkColor();

    const { width } = dimensions;
    const maxWidth = width;
    const {
      barGroupHeight,
      labelWidth,
      barGroupVerticalGap,
      barLabelGap,
      barLabelEdgeOffset,
    } = options;
    const barHeight = barGroupHeight / 2;
    const innerWidth = maxWidth - options.margin.left - options.margin.right;
    const barGroupWidth = (innerWidth - labelWidth - 150) / 2;
    const innerHeight =
      (barGroupHeight + barGroupVerticalGap) * data.length -
      barGroupVerticalGap -
      options.margin.top -
      options.margin.bottom;
    setChartConfig({
      maxWidth,
      labelWidth,
      barGroupVerticalGap,
      barGroupHeight,
      innerWidth,
      barGroupWidth,
      barHeight,
      innerHeight,
      barLabelGap,
      barLabelEdgeOffset,
      colorPalette: color,
    });
  }, [data, dimensions, options]);

  return (
    <div className={styles.mmbc}>
      <div
        id="svgcontainer"
        ref={wrapperRef}
        className={disabledDarkMode ? styles.nodark : ""}
      >
        {!chartConfig ? (
          <p className="text-xs font-bold">Cargando...</p>
        ) : (
          <MinMaxBarChartSVG
            data={data}
            yScaleDomain={yScaleDomain}
            dataXDomain={dataXDomain}
            chartConfig={chartConfig}
            colors={checkColor()}
            distributionOption={distributionOption}
            baseAmount={baseAmount}
          />
        )}
      </div>
    </div>
  );
}

MinMaxBarChart.propTypes = {
  data: minMaxChartDataPropTypes.isRequired,
  chartOptions: minMaxChartDataOptions.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  distributionOption: PropTypes.string.isRequired,
  baseAmount: PropTypes.number,
  disabledDarkMode: PropTypes.bool,
};
MinMaxBarChart.defaultProps = {
  colors: ["#FBBF24", "#14B8A6"],
  disabledDarkMode: false,
  baseAmount: null,
};

export default MinMaxBarChart;
