import PropTypes from "prop-types"

// STORE
import { useSelector } from "react-redux"

// HOOKS
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"

// UTILS
import { formatToFloat, formatAmount } from "../utilities/functions"

function AnalysisPortfolioTotals({ portfolioKey }) {
  // STORE
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  )
  const baseAmount = useSelector((state) => state.portfolioSelection.baseAmount)

  // HOOKS
  const { getPortfolioTotalWeight, getPortfolioTotal } = usePreviewPanel()

  // FUNCTIONS
  const getFieldsWidths = () => {
    if (distributionOption === "weight") {
      if (baseAmount > 0) {
        return ["w-[70px] ml-5", "w-[90px] ml-5"]
      }
      return ["w-[70px] ml-5"]
    }
    return ["w-[116px] ml-5 pl-2", "w-[40px] ml-5"]
  }

  return (
    <div className="w-full mt-1.5 text-right text-xs pr-10">
      <strong className="inline-block">Total: </strong>
      {distributionOption === "weight" && (
        <>
          <span
            className={`font-bold inline-block shrink-0
              ${getFieldsWidths()[0]}
              ${
                getPortfolioTotalWeight(portfolioKey) > 100
                  ? "text-red-800 dark:text-red-300"
                  : "text-slate-900 dark:text-slate-50"
              }`}
          >
            {formatToFloat(
              +getPortfolioTotalWeight(portfolioKey),
              2,
              "",
            ).replace(/[.,]00$/, "")}
            %
          </span>
          {baseAmount && getPortfolioTotal(portfolioKey) > 0 && (
            <span
              className={`font-bold inline-block text-left
              ${getFieldsWidths()[1]}
            ${
              Math.floor(getPortfolioTotal(portfolioKey)) > baseAmount
                ? "text-red-800 dark:text-red-300"
                : "text-slate-900 dark:text-slate-50"
            }`}
            >
              ${formatAmount(getPortfolioTotal(portfolioKey))}
            </span>
          )}
        </>
      )}
      {distributionOption === "amount" && (
        <>
          <span
            className={`font-bold text-left inline-block shrink-0 ${
              getFieldsWidths()[0]
            }`}
          >
            ${formatAmount(getPortfolioTotal(portfolioKey))}
          </span>
          <span
            className={`font-bold inline-block shrink-0
            ${getFieldsWidths()[1]}
            ${
              getPortfolioTotalWeight(portfolioKey) > 100
                ? "text-red-800 dark:text-red-300"
                : "text-slate-900 dark:text-slate-50"
            }`}
          >
            {formatToFloat(
              +getPortfolioTotalWeight(portfolioKey),
              2,
              "",
            ).replace(/[.,]00$/, "")}
            %
          </span>
        </>
      )}
    </div>
  )
}

AnalysisPortfolioTotals.propTypes = {
  portfolioKey: PropTypes.oneOf(["portfolioA", "portfolioB"]).isRequired,
}

export default AnalysisPortfolioTotals
