// STORE
import { useSelector } from "react-redux"
// CUSTOM HOOKS
import useColorCompany from "../../../../hooks/useColorCompany"
import useColorScheme from "../../../../hooks/useColorScheme"
// UTILS
import addOpacityToHexColor from "../../../../utils/addOpacityToHexColor"
// CHARTS
import EquityDebtSummarized from "../../../charts/EquityDebt/EquityDebtSummarized"
import EquityDebtExtended from "../../../charts/EquityDebt/EquityDebtExtended"


function SectionEquityDebt({data}) {
  // HOOKS
  const { getColorPortfolio } = useColorCompany()
  const [colorScheme] = useColorScheme()
  // STORE
  const portfoliosName = useSelector((state) => state.portfoliosName.data)
  // STATIC
  const quantity = data.cantidad

  // HELPERS
  const getPortfolioKey = () => {
    let portfolioKey = "";
    if ( data.portfolioA ){
        portfolioKey = "portfolioA";
    } else {
        portfolioKey = "portfolioB";
    }

    return portfolioKey
  }

    return (
        <div>
            { quantity === 1 && (
                <EquityDebtExtended
                    data={data[getPortfolioKey()]}
                    headerData={{
                        portfolioName: portfoliosName[getPortfolioKey()].portfolioName,
                        colorScheme: colorScheme,
                        bgColor: addOpacityToHexColor(getColorPortfolio(getPortfolioKey()), 0.3),
                    }}
                />
            )}
            { quantity === 2 && (
                <EquityDebtSummarized
                    data={data}
                    headerData={{
                        portfoliosName: portfoliosName,
                        colorScheme: colorScheme,
                        bgColor: {
                            portfolioA: addOpacityToHexColor(getColorPortfolio("portfolioA"), 0.3),
                            portfolioB: addOpacityToHexColor(getColorPortfolio("portfolioB"), 0.3),
                        }
                    }}
                />
            )}
        </div>
  )
}

export default SectionEquityDebt
