import React from "react"
import { PlusIcon } from "@heroicons/react/24/solid"
import Button from "../../components/ui/Button/Button"

function ListComponents() {
  return (
    <div className="min-h-screen text-slate-50 font-light mt-[5rem] pl-10">
      <div className="text-4xl font-bold">Button</div>
      <div className="flex flex-col gap-4">
        <Button label="Button">Button</Button>
        <Button size="small" label="Button">
          Button
        </Button>
        <Button icon={<PlusIcon />} label="Button left" iconPosition="left">
          Button
        </Button>
        <Button icon={<PlusIcon />} label="Button right" iconPosition="right">
          Button
        </Button>
        <Button icon={<PlusIcon />}>Button</Button>
        <Button icon={<PlusIcon />} transparent>
          Button
        </Button>
      </div>
    </div>
  )
}

export default ListComponents
