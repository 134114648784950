import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";

function ValidatePage({ user }) {
  return (
    <AuthPageFrame user={user} title="Ingresar">
      <p>En esta página se valida</p>
    </AuthPageFrame>
  );
}

export default ValidatePage;
