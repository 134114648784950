import { useCallback } from "react"
import { useSelector } from "react-redux"

const useCheckPortfolioExists = () => {
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  return useCallback(() => {
    const existA = !!portfolios?.portfolioA?.portfolioFunds.length
    const existB = !!portfolios?.portfolioB?.portfolioFunds.length
    let portfolioExistenceStatus = {
      portFolioA: existA,
      portFolioB: existB,
      doblePortFolio: false,
      portFolioNameA: portfolios?.portfolioA?.portfolioName,
      portFolioNameB: portfolios?.portfolioB?.portfolioName,
    }
    if (existA && existB) {
      portfolioExistenceStatus = {
        ...portfolioExistenceStatus,
        doblePortFolio: true,
      }
    }
    return portfolioExistenceStatus
  }, [portfolios])
}

export default useCheckPortfolioExists
