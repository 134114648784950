import { useEffect, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { scaleLinear, select } from "d3";
import { merge } from "lodash";

import { AllocationDataArray } from "../../../propTypes/AppPropTypes";
import { formatToFloat } from "../../ui/utilities/functions";

// HOOKS
import useResizeObserver from "../../../hooks/useResizeObserver";

// STYLES
import styles from "./HorizontalBarChart.module.css";
import RenderIf from "../../helpers/RenderIf";
import usePdfCheckPortfolioExists from "../../../hooks/usePdfCheckPortfolioExist";

function HorizontalBarChart({
  rawData,
  title,
  hasIconShowComponent,
  iconComponent,
  config,
  colors,
  portfolios,
}) {
  const defaultOptions = useMemo(
    () => ({
      maxAllowedBars: 6,
      barGroupHeight: 27,
      barsGap: 3,
      labelHeight: 16,
      verticalGap: 30,
      horizontalGap: 8,
      valueFontSize: 12,
      margins: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 0,
      },
      colors,
    }),
    [],
  );
  const options = merge(defaultOptions, config);

  // REFS
  const data = rawData.slice(0, options.maxAllowedBars);
  const hasDoubleBar = "valueB" in data[0];
  const fullGroupHeight = options.barGroupHeight + options.labelHeight;
  const svgHeight =
    data.length * fullGroupHeight + options.horizontalGap * data.length - 1;

  const wrapperRef = useRef();
  const svgRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);

  const checkPortfolioExists = usePdfCheckPortfolioExists({ portfolios });
  const portfolioExistenceStatus = checkPortfolioExists();
  const colorScale = () => {
    if (portfolioExistenceStatus.doblePortFolio) {
      return colors[0];
    }
    if (
      portfolioExistenceStatus.portFolioB &&
      !portfolioExistenceStatus.portFolioA
    ) {
      return colors[1];
    }
    if (portfolioExistenceStatus.portFolioA) {
      return colors[0];
    }
    return colors[0];
  };

  const getSingleValue = useCallback(
    (i) =>
      (options.barGroupHeight + options.horizontalGap + options.labelHeight) *
        i +
      options.labelHeight +
      options.barGroupHeight / 2,
    [options],
  );

  // HOOKS
  useEffect(() => {
    if (!dimensions) return;

    // UPDATE HORIZONTAL DIMENSIONS
    const { width } = dimensions;
    const barsWidth =
      width -
      options.margins.right -
      options.margins.left -
      options.verticalGap;

    const svg = select(svgRef.current);
    //  Esto es el valor que determina la escala de los valores del grafico, (maximo valor, será el ancho de la barra)
    const maxDomain = Math.max(...rawData.map((item) => item.value));

    const xScale = scaleLinear().domain([0, maxDomain]).range([0, barsWidth]);

    // TODO: Test cleanup
    svg.selectAll(".bar_bg > *").remove();
    svg.selectAll(".bars > *").remove();
    svg.selectAll(".barsB > *").remove();
    svg.selectAll(".valuesA > *").remove();
    svg.selectAll(".valuesB > *").remove();
    svg.selectAll(".label-container > *").remove();
    svg.selectAll(".clip_paths > *").remove();

    // DATA JOIN
    svg
      .select(".label-container")
      .attr("transform", `translate(0,${options.margins.top})`)
      .selectAll("text")
      .attr("class", "hbc-labels-text")
      .data(data)
      .join("text")
      .attr("x", 0)
      .attr(
        "y",
        (d, i) =>
          (options.barGroupHeight +
            options.horizontalGap +
            options.labelHeight) *
          i,
      )
      .attr("clip-path", (d, i) => `url(#test-${i})`)
      .text((d) => d.label);
    svg
      .select(".clip_paths")
      .attr("transform", `translate(0,${options.margins.top})`)
      .selectAll("clipPath")
      .data(data)
      .join("clipPath")
      .attr("id", (d, i) => `test-${i}`)
      .append("rect")
      .attr("x", 0)
      .attr(
        "y",
        (d, i) =>
          (options.barGroupHeight +
            options.horizontalGap +
            options.labelHeight) *
          i,
      )
      .attr("width", barsWidth)
      .attr("height", options.labelHeight);

    svg
      .select(".bar_bg")
      .attr(
        "transform",
        `translate(${options.margins.left},${options.margins.top})`,
      )
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("className", "rect")
      // .attr("width", Math.abs(barsWidth))
      .attr("width", barsWidth)
      .attr(
        "height",
        hasDoubleBar ? options.barGroupHeight / 2 : options.barGroupHeight,
      )
      .attr("x", 0)
      .attr(
        "y",
        (d, i) =>
          (fullGroupHeight + options.horizontalGap) * i + options.labelHeight,
      )
      .attr("fill", (d) =>
        (!hasDoubleBar && d.value === 0) ||
        (hasDoubleBar && d.valueB === 0 && d.value === 0)
          ? "transparent"
          : "#f1f5f9",
      );
    if (hasDoubleBar) {
      svg
        .select(".bar_bg_b")
        .attr(
          "transform",
          `translate(${options.margins.left},${options.margins.top})`,
        )
        .selectAll("rect")
        .data(data)
        .join("rect")
        .attr("className", "rect")
        // .attr("width", Math.abs(barsWidth))
        .attr("width", barsWidth)
        .attr(
          "height",
          hasDoubleBar ? options.barGroupHeight / 2 : options.barGroupHeight,
        )
        .attr("x", 0)
        .attr(
          "y",
          (d, i) =>
            (fullGroupHeight + options.horizontalGap) * i +
            +3 +
            options.labelHeight +
            options.barGroupHeight / 2,
        )
        .attr("fill", (d) =>
          (!hasDoubleBar && d.value === 0) ||
          (hasDoubleBar && d.valueB === 0 && d.value === 0)
            ? "transparent"
            : "#f1f5f9",
        );
    }
    svg
      .select(".bars")
      .attr(
        "transform",
        `translate(${options.margins.left},${options.margins.top})`,
      )
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr(
        "height",
        hasDoubleBar ? options.barGroupHeight / 2 : options.barGroupHeight,
      )
      .attr("x", 0)
      .attr(
        "y",
        (d, i) =>
          (fullGroupHeight + options.horizontalGap) * i + options.labelHeight,
      )
      .attr("fill", (d) => {
        let clr = colorScale();

        if (d.value === 0) {
          clr = "#f1f5f9";
        }
        if (d.value < 0) {
          clr = "#F43F5E";
        }
        return clr;
      })
      .attr("width", (d) => {
        const value =
          d.value === 0 && d.valueB !== 0
            ? xScale(100)
            : Math.abs(xScale(d.value));
        return value;
      });

    if (hasDoubleBar) {
      svg
        .select(".barsB")
        .attr(
          "transform",
          `translate(${options.margins.left},${options.margins.top})`,
        )
        .selectAll("rect")
        .data(data)
        .join("rect")
        .attr("height", options.barGroupHeight / 2)
        .attr("x", 0)
        .attr(
          "y",
          (d, i) =>
            (fullGroupHeight + options.horizontalGap) * i +
            +options.barsGap +
            options.labelHeight +
            options.barGroupHeight / 2,
        )
        .attr("fill", (d) => {
          let clr = colors[1];
          if (d.valueB === 0) {
            clr = "#f1f5f9";
          }
          if (d.valueB < 0) {
            clr = "#F43F5E";
          }
          return clr;
        })
        .attr("width", (d) => {
          const value = Math.abs(xScale(d.valueB));
          // d.value === 0 && d.valueB !== 0
          //   ? xScale(100)
          //   : Math.abs(xScale(d.valueB))
          return value;
        });
    }

    svg
      .select(".valuesA")
      .attr(
        "transform",
        `translate(${barsWidth},${options.margins.top + options.barsGap / 3})`,
      )
      .selectAll("text")
      .data(data)
      .join("text")
      .attr("class", (d) => {
        let className = "";
        if (d.value < 0) className += `${styles.negative} `;
        if (hasDoubleBar) className += styles.doubled;
        return className;
      })
      .attr("x", options.verticalGap)
      .attr("y", (d, i) => {
        let value = getSingleValue(i);
        if (hasDoubleBar) value -= 14;
        return value;
      })
      .text((d) => (d.value === 0 ? "0%" : formatToFloat(d.value)));

    if (hasDoubleBar) {
      svg
        .select(".valuesB")
        .attr(
          "transform",
          `translate(${barsWidth},${
            options.margins.top + options.barsGap / 3
          })`,
        )
        .selectAll("text")
        .data(data)
        .join("text")
        .attr("class", (d) => {
          let className = `${styles.doubled} `;
          if (d.valueB < 0) className += styles.negative;
          return className;
        })
        .attr("x", options.verticalGap)
        .attr("y", (d, i) => {
          const value = getSingleValue(i) + 5;
          return value;
        })
        .text((d) => (d.valueB === 0 ? "0%" : formatToFloat(d.valueB)));
    }
  }, [
    data,
    dimensions,
    options,
    hasDoubleBar,
    colorScale,
    fullGroupHeight,
    getSingleValue,
  ]);

  return (
    <div className="h-fit grid gap-2 mb-4">
      <div className="flex items-center flex-start">
        <h1 className=" text-xs text-slate-400 font-bold">{title}</h1>
        <RenderIf isTrue={hasIconShowComponent}>{iconComponent}</RenderIf>
      </div>
      <div className="h-fit max-w-full " ref={wrapperRef}>
        <svg
          ref={svgRef}
          className="w-full fill-slate-200"
          height={`${svgHeight}px`}
          width="100%"
        >
          <g className="bar_bg" />
          <g className="bar_bg_b" />
          <g className="bars" />
          <g className="barsB" />
          <g className={`${styles.values} valuesA`} />
          <g className={`${styles.values} valuesB`} />
          <g className={`${styles.labels} label-container`} />
          <g className="clip_paths" />
        </svg>
      </div>
    </div>
  );
}

HorizontalBarChart.propTypes = {
  rawData: AllocationDataArray.isRequired,
  title: PropTypes.string.isRequired,
  iconComponent: PropTypes.element,
  hasIconShowComponent: PropTypes.bool,
  config: PropTypes.objectOf(PropTypes.string),
  portfolioExistenceStatus: PropTypes.shape({
    portFolioA: PropTypes.bool,
    portFolioB: PropTypes.bool,
    doblePortFolio: PropTypes.bool,
  }),
  colors: PropTypes.arrayOf(PropTypes.string),
};

HorizontalBarChart.defaultProps = {
  hasIconShowComponent: false,
  iconComponent: null,
  config: {},
  portfolioExistenceStatus: {
    portFolioA: false,
    portFolioB: false,
    doblePortFolio: false,
  },
  colors: ["#FBBF24", "#14B8A6"],
};

export default HorizontalBarChart;
