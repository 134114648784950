import { useContext } from "react"
import PropTypes from "prop-types"

// STORE
import { useSelector, useDispatch } from "react-redux"
import { togglePreviewPanel } from "../../../store/portfolioPanelSlice"
import { showNotifications } from "../../../store/notificationsSlice"

// CONTEXT
import { FirebaseContext } from "../../../firebase"

// PROP TYPES
import { FundDataShape } from "../../../propTypes/AppPropTypes"
import usePreviewPanel from "../PreviewPanel/usePreviewPanel"
import useTracking from "../../../hooks/useTracking"

function DataGridRowNav({ rowData, isPanelOpen, rowSizeInfo }) {
  // TRACK
  const trackEvent = useTracking()
  // HOOKS
  const { addFundToBucket } = usePreviewPanel()
  // CONTEXT
  const { logAnalyticsEvent } = useContext(FirebaseContext)
  // STORE
  const user = useSelector((state) => state.user.user)
  const isPreviewOpen = useSelector(
    (state) => state.portfolioPanel.isPreviewOpen,
  )
  const panelWidth = useSelector(
    (state) => state.portfolioPanel.previewPanelWidth,
  )

  const dispatch = useDispatch()

  // CALLBACKS
  const handleAddClick = (fund) => {
    trackEvent({
      action: "ADD_FUND_TO_PREVIEW",
      timestamp: Date.now(),
      type: "CLICK",
      data: `fund-${fund?.run}-${fund?.serie}`,
    })
    addFundToBucket(
      fund,
      () =>
        dispatch(
          showNotifications({
            show: true,
            status: "warning",
            title: "Información",
            message: "Puedes agregar un máximo de 12 fondos a un portafolio.",
          }),
        ),
      () =>
        dispatch(
          showNotifications({
            show: true,
            status: "warning",
            title: "Importante",
            message: "Este fondo está repetido en el portafolio activo.",
          }),
        ),
      () => {
        logAnalyticsEvent("select_item", {
          item_list_id: fund.run,
          item_list_name: "Agregar fondo a portafolio",
          items: [
            {
              item_id: fund.run,
              item_name: fund.fund_name,
              affiliation: fund.fund_provider,
              item_category: fund.category,
              item_category2: fund.fund_type,
              item_category3: fund.asset_class,
              item_category4: fund.fund_currency,
              item_variant: fund.serie,
            },
          ],
          username: `${user.name} ${user.lastname}`,
          company: user.company,
        })
        if (!isPreviewOpen) dispatch(togglePreviewPanel(true))
      },
    )
  }
  const getRightValue = () => {
    let value = 0

    if (isPreviewOpen) {
      value = panelWidth
    }
    return value
  }

  const getPositions = (value) => {
    const top = rowSizeInfo ? rowSizeInfo.top : 0
    const height = rowSizeInfo ? Math.floor(rowSizeInfo.height) : 0
    switch (value) {
      case "top":
        // Add 1px to account for border
        return `${top + 1}px`
      case "height":
        // Remove 1px so it does not overflow
        return `${height - 1}px`
      default:
        return `${rowSizeInfo?.width}px}`
    }
  }

  return (
    <div
      className={`fixed flex items-center justify-end
        pl-2 pr-1
        1xl:px-3
        ${
          isPanelOpen
            ? "bg-slate-100 dark:bg-slate-700"
            : "bg-white dark:bg-slate-900"
        }
        ${isPreviewOpen ? "z-40" : "z-10"}
        group-hover:bg-slate-100 dark:group-hover:bg-slate-700
      `}
      style={{
        top: getPositions("top"),
        right: getRightValue(),
        height: getPositions("height"),
      }}
    >
      <button
        type="button"
        className={`flex items-center justify-center w-5 h-5 rounded-full text-slate-50 bg-blue-500 text-xs uppercase
          1xl:w-6 1xl:h-6
         hover:bg-blue-400 hover:text-slate-50 dark:hover:text-slate-900 transition-colors duration-300
         disabled:cursor-not-allowed disabled:opacity-20`}
        onClick={() => handleAddClick(rowData)}
      >
        <span className="inline-block text-[17px] font-semibold mb-0.5">+</span>
      </button>
    </div>
  )
}

DataGridRowNav.propTypes = {
  rowData: PropTypes.shape(FundDataShape).isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  rowSizeInfo: PropTypes.shape({
    height: PropTypes.number,
    top: PropTypes.number,
    width: PropTypes.number,
  }),
}
DataGridRowNav.defaultProps = {
  rowSizeInfo: null,
}

export default DataGridRowNav
