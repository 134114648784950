import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    resultsPerPage: 10,
    currentPage: 1,
    sortBy: "ret_1y",
    sortOrder: "DESC",
    totalPages: 0,
  },
}

export const SortingSlice = createSlice({
  name: "sorting",
  initialState,
  reducers: {
    updateSortOptions: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { updateSortOptions } = SortingSlice.actions

export default SortingSlice.reducer
