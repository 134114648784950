import { useState, useEffect, useContext, useSelector } from "react"
import PropTypes from "prop-types"
import { onAuthStateChanged } from "firebase/auth"

import FirebaseContext from "./FirebaseContext"
import AuthUserContext from "./AuthUserContext"
import {
  getDataInStorage,
  insertInStorageByKey,
  removeDataInStorage,
} from "../utils/CacheManager"

import { Fetch } from "../hooks/useFetch"
// SENTRY
import * as Sentry from "@sentry/browser";


function User({ children }) {
  const urls = useSelector((state) => state.urls)
  // FIREBASE
  const firebase = useContext(FirebaseContext)
  const { auth } = useContext(FirebaseContext)
  const [authUser, setAuthUser] = useState(null)
  const addUser = async (uid, data) => {
    await insertInStorageByKey({ storeName: "users", key: uid, data })
  }
  const removeUser = async () => {
    await removeDataInStorage({ storeName: "users", key: "authUser" })
  }
  const getUserStore = async () => {
    await getDataInStorage({ storeName: "users", key: "authUser" })
  }

  useEffect(() => {
    getUserStore()
  }, [])

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      if (user) {
        try {
          const advisorUrl = `${urls.API_URL}/advisors/${user.uid}/get`;
          Fetch(
            advisorUrl,
            {},
            { firebase },
            (response) => {
              const dbUser = response;
              const userData = {
                uid: user.uid,
                email: user.email,
                metadata: user.metadata,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                ...dbUser,
              }
              addUser("authUser", userData)
              return setAuthUser(userData)
            },
          )
        } catch (err) {
          Sentry.captureException(err);
          console.log("Error al recuperar info de Usuario", err)
        }
      } else {
        removeUser()
        setAuthUser(null)
      }
    })
    return () => {
      listener()
    }
  }, [auth])

  return (
    <AuthUserContext.Provider value={authUser}>
      {children({ authUser })}
    </AuthUserContext.Provider>
  )
}

User.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default User
