// SENTRY
import * as Sentry from "@sentry/browser";

const BENEFIT_MAPPING = {
  has57lirBenefit: "57LIR",
  has107lirBenefit: "107LIR",
  has108lirBenefit: "108LIR",
  hasApvlirBenefit: "APVLIR",
}

const formatShareClassBenefits = (shareClassData) => {
  try {
    return Object.keys(BENEFIT_MAPPING)
      .filter((benefit) => shareClassData[benefit])
      .map((benefit) => BENEFIT_MAPPING[benefit])
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

export default formatShareClassBenefits
