import { useEffect, useState } from "react"

const useScroll = () => {
  const [scrollPos, setScrollPos] = useState(0)
  const updatePosition = () => {
    setScrollPos(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", updatePosition)
    updatePosition()
    return () => window.removeEventListener("scroll", updatePosition)
  }, [])

  return scrollPos
}

export default useScroll
