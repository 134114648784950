import { useState, useRef, useEffect, useContext } from "react"
import { EllipsisVerticalIcon, CheckIcon } from "@heroicons/react/24/solid"
import { orderBy } from "lodash"
// UI
import { useSelector, useDispatch } from "react-redux"
import DataGridHeaderCol from "./DataGridHeaderCol"
// CONTEXT
import { FirebaseContext } from "../../../firebase"
// STORE
import { updateSortOptions } from "../../../store/sortingSlice"
import { updateCurrent } from "../../../store/customColumnSlice"
// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import useViewportSize from "../../../hooks/useViewportSize"
// PROP TYPES
import { dataGridConfigProptypes } from "../../../propTypes/AppPropTypes"
import useTracking from "../../../hooks/useTracking"

function DataGridHead({ headings }) {
  // TRACKING
  const trackEvent = useTracking()
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  const { logAnalyticsEvent } = firebase
  // REF
  const headRef = useRef()
  const menuRef = useRef()
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const sortOptions = useSelector((state) => state.sorting.data)
  const currentColumn = useSelector((state) => state.column.current)
  const columnOptions = useSelector((state) => state.column.options)
  // HOOKS
  const { width } = useViewportSize()

  const [columnOpen, setColumnOpen] = useState(false)

  const handleSort = (cellKey) => {
    trackEvent({
      action: "ORDER_DATA_HEAD_TABLE",
      timestamp: Date.now(),
      type: "SUBMIT",
      data: cellKey,
    })
    const { sortBy } = sortOptions
    let { sortOrder } = sortOptions
    if (sortBy === cellKey) {
      sortOrder = sortOptions.sortOrder === "ASC" ? "DESC" : "ASC"
    }
    dispatch(
      updateSortOptions({
        ...sortOptions,
        ...{ sortBy: cellKey, sortOrder },
      }),
    )
  }

  const handleColumnChange = (option, logEvent = true) => {
    trackEvent({
      action: "SCREENER_CUSTOM_COLUMN",
      timestamp: Date.now(),
      type: "SELECT",
      data: option,
    })
    if (logEvent) {
      logAnalyticsEvent("select_content", {
        content_type: "Columna personalizada catalogo",
        content_id: option,
        username: `${user.name} ${user.lastname}`,
        company: user.company,
      })
    }
    dispatch(updateCurrent(option))
    setColumnOpen(false)
  }

  useOnClickOutside(menuRef, () => {
    setColumnOpen(false)
  })

  useEffect(() => {
    // Si el tamaño del viewport es mayor a 1700px (3xl en tailwind.config.js) resetear la columna custom
    if (width >= 1700) {
      handleColumnChange("fundDuration", false)
    }
  }, [width])

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 27) {
        if (columnOpen) {
          setColumnOpen(false)
        }
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [columnOpen, setColumnOpen])

  const getCustomOptions = () => {
    const optionsAsArray = []
    Object.keys(columnOptions).forEach((key) => {
      const newObject = {
        key,
        ...columnOptions[key],
      }
      optionsAsArray.push(newObject)
    })
    return orderBy(optionsAsArray, ["order"], ["asc"])
  }

  return (
    <div
      ref={headRef}
      className="relative 1xl:sticky 1xl:top-14 z-[44] border-t border-slate-100 dark:border-slate-800 
        sm:pr-2
        lg:pr-8
      "
    >
      <div className="h-16 grid grid-cols-dataGrid lg:grid-cols-lgDataGrid 1xl:grid-cols-xl1DataGrid 2xl:grid-cols-xl2DataGrid 3xl:grid-cols-xl3DataGrid 4xl:grid-cols-xl4DataGrid">
        {headings.map((heading, index) => (
          <DataGridHeaderCol
            key={`col-${heading.cellKey}-${index + 1}`}
            headingInfo={heading}
            handleSort={handleSort}
            isCustom={index === headings.length - 1}
          />
        ))}
      </div>
      <button
        title="Select custom column"
        type="button"
        onClick={() => setColumnOpen(!columnOpen)}
        className="absolute top-0 right-0 grid place-content-center w-8 h-full !outline-none bg-slate-100 dark:bg-slate-800"
      >
        <EllipsisVerticalIcon className="w-5 h-5 1xl:w-6 1xl:h-6" />
      </button>
      <div
        ref={menuRef}
        className={`
          absolute top-13 right-0 py-2 bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent rounded-s-md rounded-br-sm z-20 flex flex-col align-stretch justify-start text-xs text-left
          1xl:right-4
          ${columnOpen ? "block" : "hidden"}
        `}
      >
        <h3 className="px-4 pt-3 mb-4 text-xs text-left text-slate-500 dark:text-slate-400">
          Seleccionar columna:
        </h3>
        {getCustomOptions().map((option) => (
          <button
            key={`custom-${option.name}`}
            type="button"
            onClick={() => handleColumnChange(option.key)}
            className={`items-center justify-start w-full text-xs cursor-pointer px-2 py-1
            hover:text-slate-50 hover:bg-blue-500 dark:hover:text-blue-500 dark:hover:bg-slate-600
              ${
                option.key === "fundReturn2Y" || option.key === "fundReturn3Y"
                  ? "flex 3xl:hidden"
                  : "flex"
              }
              ${
                option.key === currentColumn
                  ? "text-slate-50 bg-blue-500 dark:text-blue-500 dark:bg-slate-600"
                  : "text-slate-900 dark:text-slate-50"
              } 
            `}
          >
            <span
              className={`flex items-center w-5
                ${
                  option.key !== currentColumn
                    ? "invisible"
                    : "visible text-blue-500"
                }
              `}
            >
              <CheckIcon className="h-3 w-3" aria-hidden="true" />
            </span>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}
DataGridHead.propTypes = {
  headings: dataGridConfigProptypes.isRequired,
}

export default DataGridHead
