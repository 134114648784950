 
import PropTypes from "prop-types"
import { getCompositionsData } from "@lva/functions"
// HOOKS
import usePrepareDataForCharts from "../../../../hooks/usePrepareDataForCharts"
import usePreviewPanel from "../usePreviewPanel"
// COMPONENTS
import CompositionsChart from "../../../charts/CompositionsChart/CompositionsChart"
// UTILS
import { EXPOSURE_TYPE_NAME_MAPPING } from "../../../../utils/ExposurePresenter"

import useColorCompany from "../../../../hooks/useColorCompany"

import CompositionsOver100PercAlert from "../../CompositionsOver100PercAlert/CompositionsOver100PercAlert"

function SectionCompositions({ data }) {
  const { getColorsCompany } = useColorCompany()
  const { getCompositionsOrder, compositionHasOver100Perc } = usePrepareDataForCharts()
  const compositionsData = getCompositionsData(data[0])
  const showWarningOver100Perc = compositionHasOver100Perc(compositionsData)
  const { isPortfolioBEnabled } = usePreviewPanel()
  // console.log("DATA COMPOSICION: ", compositionsData)
  // console.log("DEBE MOSTRARSE: ", showWarningOver100Perc)

  return (
    data && (
      <div className="space-y-5">
        <div
          className={`flex flex-col items-stretch justify-start ${
            isPortfolioBEnabled ? "gap-16" : "gap-8"
          }`}
        >
          {getCompositionsOrder().map((item) => {
            const name = `Por ${EXPOSURE_TYPE_NAME_MAPPING[item]}`
            const rows = compositionsData[item]

            return (
              rows && (
                <CompositionsChart
                  key={item}
                  chartData={rows}
                  name={name}
                  colors={getColorsCompany()}
                />
              )
            )
          })}
        </div>
        <CompositionsOver100PercAlert shouldShow={showWarningOver100Perc} />
      </div>
    )
  )
}

SectionCompositions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
          valueB: PropTypes.number,
        }),
      ),
    ),
  ).isRequired,
}

export default SectionCompositions
