import _ from "lodash"
const unifyExposure = (expA, expB) => {
    const exposureA = expA?.[0]
    const exposureB = expB?.[0]
    const sortedA = {}
    const sortedB = {}
    const finalExposure = {}
    if (exposureA) {
        Object.keys(exposureA)?.forEach((alloc) => {
            sortedA[alloc] = _.sortBy(exposureA[alloc], "value").reverse()
        })
    }
    if (exposureB) {
        Object.keys(exposureB)?.forEach((alloc) => {
            exposureB[alloc] = exposureB[alloc]?.map((element) => ({
                labelB: element?.label,
                value: element?.value,
            }))
            sortedB[alloc] = _.sortBy(exposureB[alloc], "value").reverse()
        })
    }
    if (!exposureB) {
        return sortedA
    }
    if (!exposureA) {
        return sortedB
    }
    // si no, hago merge
    // ambos tienen el mismo numero de allocs
    Object.keys(sortedA).forEach((alloc) => {
        const expAlloc = []
        let sortedAB = sortedA[alloc].concat(sortedB[alloc])
        sortedAB = _.sortBy(sortedAB, "value").reverse()

        const labelList = []
        for (let index = 0; index < sortedAB.length; index += 1) {
            if (!sortedAB[index]) return
            const name = sortedAB[index].label || sortedAB[index].labelB
            if (!labelList.find((e) => e === name)) {
                labelList.push(name)
                if (Object.keys(sortedAB[index])[0] === "label") {
                    const rowB = sortedB[alloc]?.find((row) => row.labelB === name)
                    expAlloc.push({
                        label: name,
                        value: sortedAB[index].value,
                        valueB: rowB ? rowB.value : 0,
                    })
                } else {
                    const rowA = sortedA[alloc]?.find((row) => row.label === name)
                    expAlloc.push({
                        label: name,
                        value: rowA ? rowA.value : 0,
                        valueB: sortedAB[index].value,
                    })
                }
            }
        }
        finalExposure[alloc] = expAlloc
    })
    return finalExposure
}

export { unifyExposure }