import { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import TrackingContext from "./TrackingContext"
import TrackEvents from "./TrackEvents"

function TrackingProvider({ children }) {
  const handleTrackEvent = useCallback((eventData, url, firebase) => {
    TrackEvents(eventData, url, firebase)
  }, [])

  const trackingContextValue = useMemo(
    () => ({
      handleTrackEvent,
    }),
    [handleTrackEvent],
  )

  return (
    <TrackingContext.Provider value={trackingContextValue}>
      {children}
    </TrackingContext.Provider>
  )
}

TrackingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TrackingProvider
