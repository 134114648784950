// SENTRY
import * as Sentry from "@sentry/browser";

const useCopyTextToClipboard = () => {
  const isCopyToClipboardAvailable = () => navigator?.clipboard?.writeText
  async function copyToClipboard(text, cb) {
    try {
      await navigator.clipboard.writeText(text)
      cb()
    } catch (error) {
      Sentry.captureException(error);
      return "ERROR"
    }
    return "OK"
  }

  return { isCopyToClipboardAvailable, copyToClipboard }
}

export default useCopyTextToClipboard
