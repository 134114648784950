import { useState } from "react";
import AuthPageFrame from "../../components/ui/auth/AuthPageFrame";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import * as Sentry from "@sentry/browser";
// STORE
import { useSelector } from "react-redux";
import {
  Form,
  Label,
  Input,
  Textarea,
  ErrorMessage,
} from "@/components/ui/auth/formLayouts/FormLayouts";
import * as APP_ROUTES from "@/routes/APP_ROUTES";
import SETTINGS from "@/settings/settings";
import { Link } from "react-router-dom";

const subjectOptions = [
  { label: "Solicitar Demo", value: "SOLITAR_DEMO" },
  { label: "Consulta comercial", value: "CONSULTA_COMERCIAL" },
  { label: "Cuenta para empresas", value: "CUENTA_EMPRESAS" },
  { label: "Problemas de acceso", value: "PROBLEMAS_ACCESO" },
];

const contactSchema = z.object({
  name: z.string().trim().min(3, {
    message: "Por favor ingresa tu nombre completo",
  }),
  email: z.string().trim().email({
    message: "Por favor ingresa un correo válido",
  }),
  subject: z
    .enum(
      subjectOptions.map((opt) => opt.value),
      {
        required_error: "Debes seleccionar una de las opciones",
      },
    )
    .optional(),
  message: z.string().trim().optional(),
});

function ContactPage({ user }) {
  const [formStatus, setFormStatus] = useState(null);
  const urls = useSelector((state) => state.urls);
  const form = useForm({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      name: "",
      email: "",
      subject: "SOLITAR_DEMO",
      message: "",
    },
  });

  const onSubmit = async (formData) => {
    // enviarlo al url de la API
    const selectedValue = subjectOptions.find(
      (opt) => opt.value === formData.subject,
    ).label;

    const response = await fetch(`${urls.API_URL}/send_ticket`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        subject: selectedValue,
        message: formData.message,
      }),
    });

    if (!response.status !== "200") {
      setFormStatus("ERROR");
      Sentry.captureException(
        new Error("Error en envío de correo de contacto."),
      );
    }
    await response.json();
    setFormStatus("OK");
  };
  const onError = (error) => {
    // console.log("ERROR DE VALIDACION:", error);
  };
  const clearForm = () => {
    form.reset();
    setFormStatus(null);
  };

  return (
    <AuthPageFrame
      user={user}
      title="Comienza hoy"
      subtitle="Solicita tu demo"
      description="Descubre todo lo que Elevest tiene para apoyar tu asesoría."
    >
      <div className="space-y-2">
        {formStatus == "OK" ? (
          <div className="md:max-w-xl mx-auto my-4 p-4 bg-blue-50 border border-blue-500 rounded-lg text-center space-y-3">
            <div>
              <p>
                <strong>Recibimos tu mensaje</strong>
              </p>
              <p>Nos pondremos en contacto contigo a la brevedad.</p>
            </div>
            <div className="w-full inline-flex items-center justify-center text-center gap-5">
              <Link
                to={SETTINGS.LANDING_URL}
                className="text-blue-500 hover:underline"
              >
                Volver
              </Link>
              <Link
                to={APP_ROUTES.LOGIN}
                className="text-blue-500 hover:underline"
              >
                Ingresar
              </Link>
            </div>
          </div>
        ) : (
          <Form onSubmit={form.handleSubmit(onSubmit, onError)}>
            {formStatus === "ERROR" && (
              <div className="p-4 bg-blue-50 border border-blue-500 rounded-lg">
                <p>
                  <strong>Lo sentimos, no pudimos enviar el mensaje.</strong>
                </p>
                <p>
                  Por favor inténtelo nuevamente o utilice otras opciones de
                  contacto.
                </p>
              </div>
            )}
            <Label id="name">
              <Input name="name" form={form} placeholder="Nombre" />
              {form.formState.errors.name && (
                <ErrorMessage message={form.formState.errors.name.message} />
              )}
            </Label>

            <Label>
              <Input
                name="email"
                form={form}
                placeholder="micorreo@correo.com"
              />
              {form.formState.errors.email && (
                <ErrorMessage message={form.formState.errors.email.message} />
              )}
            </Label>

            <button
              type="submit"
              className="py-4 px-9 w-full text-white font-semibold border border-blue-500 rounded-xl shadow-4xl focus:ring focus:ring-blue-300 bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-200"
            >
              Enviar mensaje
            </button>
            <button
              type="button"
              className="w-full text-center"
              onClick={clearForm}
            >
              Limpiar formulario
            </button>
          </Form>
        )}
      </div>
    </AuthPageFrame>
  );
}

export default ContactPage;
