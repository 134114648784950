import { useMemo } from "react"
import PropTypes from "prop-types"
import { Tab } from "@headlessui/react"
import {
  DocumentChartBarIcon,
  ArrowTrendingUpIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid"
import { useSelector, useDispatch } from "react-redux"
import { updateDefaultTab } from "../../../store/overviewPanelSlice"

// UI
import LoadingSpinner from "../loadingSpinner/LoadingSpinner"
import FundDetailsGeneral from "./FundDetailsGeneral"
import FundDetailsGeneralDAP from "./FundDetailsGeneralDAP"
import FundDetailsExposure from "./FundDetailsExposure"
import FundDetailsPerformance from "./FundDetailsPerformance"
import FundDetailsPerformanceAPV from "./FundDetailsPerformanceAPV"
import FundDetailsCosts from "./FundDetailsCosts"

// PROP TYPES
import {
  panelDataPropTypes,
  FundDataShape,
} from "../../../propTypes/AppPropTypes"
import useTracking from "../../../hooks/useTracking"

function FundDetails({ isPanelOpen, panelData, rowData }) {
  // STORE
  const defaultTab = useSelector((state) => state.overviewPanel.defaultTab)
  const isAPV = useSelector((state) => state.proposalType.isAPV)
  const dispatch = useDispatch()
  // TRACK
  const trackEvent = useTracking()
  const tabNames = useMemo(
    () => [
      {
        name: "General",
        icon: <DocumentChartBarIcon className="w-5 h-5" />,
        excluded: false,
      },
      {
        name: "Composiciones",
        icon: <ChartBarIcon className="w-5 h-5" />,
        excluded: false,
      },
      {
        name: "Rentabilidad",
        icon: <ArrowTrendingUpIcon className="w-5 h-5" />,
        excluded: false,
      },
      {
        name: "Costos",
        icon: <CurrencyDollarIcon className="w-5 h-5" />,
        excluded: rowData.fund_type === "IN",
      },
    ],
    [],
  )
  const handleSendDataTracking = ({ type, action, data }) => {
    trackEvent({
      action,
      timestamp: Date.now(),
      type,
      data,
    })
  }

  const getFundDetailsGeneral = () => {
    switch (rowData.fund_type) {
      case "IN":
        return <FundDetailsGeneralDAP data={panelData.overview} />
      default:
        return <FundDetailsGeneral data={panelData.overview} />
    }
  }

  const getDetailsTabs = () =>
    panelData.status === "OK" ? (
      <main className="relative w-full h-full p-8 bg-slate-50 dark:bg-slate-800">
        <Tab.Group defaultIndex={defaultTab}>
          <div className="w-full h-full grid grid-cols-[200px,1fr] gap-10">
            <Tab.List className="flex flex-col items-start justify-start gap-6">
              {tabNames.map((tabName, index) =>
                !tabName.excluded ? (
                  <Tab
                    key={`tab-${tabName.name}`}
                    className={({ selected }) =>
                      `text-sm !outline-none hover:text-blue-500 ${
                        selected
                          ? "text-blue-500 dark:text-blue-400"
                          : "text-slate-900 dark:text-slate-50"
                      }`
                    }
                    onClick={() => {
                      dispatch(updateDefaultTab(index))
                      handleSendDataTracking({
                        type: "CLICK",
                        action: "TAB_FUND_DETAILS",
                        data: tabName.name,
                      })
                    }}
                  >
                    <span className="flex items-center gap-2">
                      {tabName.icon} {tabName.name}
                    </span>
                  </Tab>
                ) : null,
              )}
            </Tab.List>

            <Tab.Panels className="overflow-hidden">
              <Tab.Panel>{getFundDetailsGeneral()}</Tab.Panel>
              <Tab.Panel>
                {Object.keys(panelData.allocations.values).length === 0 ? (
                  <div className="w-full h-full p-8 bg-slate-200 dark:bg-slate-800">
                    <h2 className="overview-section-title">Lo sentimos</h2>
                    <p className="overview-section-text">
                      No hay información de composiciones disponible para esta
                      serie.
                    </p>
                  </div>
                ) : (
                  <FundDetailsExposure data={panelData} />
                )}
              </Tab.Panel>
              <Tab.Panel>
                {isAPV ? (
                  <FundDetailsPerformanceAPV data={panelData} />
                ) : (
                  <FundDetailsPerformance data={panelData} rowData={rowData} />
                )}
              </Tab.Panel>
              {rowData.fund_type !== "IN" && (
                <Tab.Panel>
                  <FundDetailsCosts data={panelData} />
                </Tab.Panel>
              )}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </main>
    ) : (
      <div className="w-full h-full p-8 bg-slate-200 dark:bg-slate-800">
        <h2 className="overview-section-title">
          Encontramos un problema al recuperar información
          <strong>{rowData.name}</strong>.
        </h2>
        <p className="overview-section-text">
          Favor notificar este problema a través del botón de soporte o enviar
          un correo a{" "}
          <a
            href="mailto:soporte@lvaindices.com"
            target="_blank"
            rel="noreferrer"
            className="underline text-blue-500 dark:text-blue-400 hover:text-blue-400 dark:hover:text-blue-500"
          >
            soporte@lvaindices.com
          </a>
        </p>
      </div>
    )

  return (
    isPanelOpen && (
      <div
        className={`overflow-hidden border-t-2 border-slate-200 dark:border-slate-800 transition-height duration-100 ${
          !isPanelOpen ? "h-0" : "h-[460px]"
        }`}
      >
        {!panelData ? (
          <div className="h-full grid place-content-center">
            <LoadingSpinner />
          </div>
        ) : (
          getDetailsTabs()
        )}
      </div>
    )
  )
}
FundDetails.propTypes = {
  isPanelOpen: PropTypes.bool.isRequired,
  panelData: panelDataPropTypes,
  rowData: PropTypes.shape(FundDataShape).isRequired,
}
FundDetails.defaultProps = {
  panelData: null,
}

export default FundDetails
