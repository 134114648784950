import { useCallback } from "react"
import { useSelector } from "react-redux"
import useColorScheme from "./useColorScheme"

const DEFAULT_SITE_COLORS = ["#FBBF24", "#14B8A6"]
/**
 * Custom hook to get the color configuration for a company.
 * @returns {Object} An object containing the getColorPortfolio function.
 */
function useColorCompany() {
  const user = useSelector((state) => state?.user?.user)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )
  const [colorScheme] = useColorScheme()
  /**
   * Function to get the color for a specific portfolio.
   * This function accepts a portfolio identifier which can be "A", "a", "B", or "b".
   * It returns the corresponding color based on the company's site configuration or
   * defaults to predefined colors if the configuration is not available.
   *
   * @param {string} portfolio - The portfolio identifier, accepting "portfolioA" or "portfolioB".
   * @returns {string} The color for the specified portfolio. Defaults to predefined colors if the specific configuration is unavailable.
   */
  const getColorPortfolio = (portfolio) => {
    const validPortfolios = ["portfolioA", "portfolioB"]
    if (!validPortfolios.includes(portfolio)) {
      throw new Error(
        'Invalid portfolio. Please use "portfolioA" or "portfolioB".',
      )
    }
    const portfolioSelected = portfolio.toLowerCase() === "portfolioa" ? 0 : 1
    if (!user?.colors) {
      return DEFAULT_SITE_COLORS[portfolioSelected]
    }
    if (!user?.colors?.[colorScheme]) {
      return DEFAULT_SITE_COLORS[portfolioSelected]
    }
    return user?.colors?.[colorScheme][portfolioSelected]?.length
      ? user?.colors?.[colorScheme][portfolioSelected]
      : DEFAULT_SITE_COLORS[portfolioSelected]
  }
  /**
   * Function to get the color for a specific portfolio in PublicPAge.
   * This function accepts a portfolio identifier which can be "A", "a", "B", or "b".
   * It returns the corresponding color based on the company's site configuration or
   * defaults to predefined colors if the configuration is not available.
   *
   * @param {string} portfolio - The portfolio identifier, accepting "portfolioA" or "portfolioB".
   * @returns {string} The color for the specified portfolio. Defaults to predefined colors if the specific configuration is unavailable.
   */
  const getPublicColorPortfolio = (portfolio) => {
    const validPortfolios = ["portfolioA", "portfolioB"]
    if (!validPortfolios.includes(portfolio)) {
      throw new Error(
        'Invalid portfolio. Please use "portfolioA" or "portfolioB".',
      )
    }
    const portfolioSelected = portfolio.toLowerCase() === "portfolioa" ? 0 : 1
    if (!analysisDataConfig?.companySiteConfig?.colors) {
      return DEFAULT_SITE_COLORS[portfolioSelected]
    }
    if (!analysisDataConfig?.companySiteConfig?.colors?.[colorScheme]) {
      return DEFAULT_SITE_COLORS[portfolioSelected]
    }
    return analysisDataConfig?.companySiteConfig?.colors?.[colorScheme][
      portfolioSelected
    ]?.length
      ? analysisDataConfig?.companySiteConfig?.colors?.[colorScheme][
          portfolioSelected
        ]
      : DEFAULT_SITE_COLORS[portfolioSelected]
  }

  /**
   * Function to get the color for a specific portfolio.
   * This function accepts a portfolio identifier which can be "A", "a", "B", or "b".
   * It returns the corresponding color based on the company's site configuration or
   * defaults to predefined colors if the configuration is not available.
   *
   * @param {string} portfolio - The portfolio identifier, accepting "portfolioA" or "portfolioB".
   * @param {string} scheme - The scheme identifier, accepting "light" or "dark".
   * @returns {string} The color for the specified portfolio. Defaults to predefined colors if the specific configuration is unavailable.
   */
  const getColorPortfolioScheme = useCallback(
    ({ portfolio, scheme }) => {
      const validPortfolios = ["portfolioA", "portfolioB"]
      const validSchemes = ["light", "dark"]
      if (!validPortfolios.includes(portfolio)) {
        throw new Error(
          'Invalid portfolio. Please use "portfolioA" or "portfolioB".',
        )
      }
      if (!validSchemes.includes(scheme)) {
        throw new Error('Invalid portfolio. Please use "light" or "dark".')
      }
      const portfolioSelected = portfolio.toLowerCase() === "portfolioa" ? 0 : 1
      if (!user?.colors) {
        return DEFAULT_SITE_COLORS[portfolioSelected]
      }
      if (!user?.colors?.[scheme]) {
        return DEFAULT_SITE_COLORS[portfolioSelected]
      }
      return user?.colors[scheme][portfolioSelected]?.length
        ? user?.colors[scheme][portfolioSelected]
        : DEFAULT_SITE_COLORS[scheme][portfolioSelected]
    },
    [user],
  )

  const getColorsCompany = () => {
    if (!user?.colors) {
      return DEFAULT_SITE_COLORS
    }
    if (!user?.colors?.[colorScheme]) {
      return DEFAULT_SITE_COLORS
    }
    const colorA = user?.colors?.[colorScheme][0]?.length
      ? user?.colors?.[colorScheme][0]
      : DEFAULT_SITE_COLORS[0]
    const colorB = user?.colors?.[colorScheme][1]?.length
      ? user?.colors?.[colorScheme][1]
      : DEFAULT_SITE_COLORS[1]
    return [colorA, colorB]
  }
  const getPublicColorsCompany = () => {
    if (!analysisDataConfig?.companySiteConfig?.colors) {
      return DEFAULT_SITE_COLORS
    }
    if (!analysisDataConfig?.companySiteConfig?.colors?.[colorScheme]) {
      return DEFAULT_SITE_COLORS
    }
    const colorA = analysisDataConfig?.companySiteConfig?.colors?.[
      colorScheme
    ][0]?.length
      ? analysisDataConfig?.companySiteConfig?.colors?.[colorScheme][0]
      : DEFAULT_SITE_COLORS[0]
    const colorB = analysisDataConfig?.companySiteConfig?.colors?.[
      colorScheme
    ][1]?.length
      ? analysisDataConfig?.companySiteConfig?.colors?.[colorScheme][1]
      : DEFAULT_SITE_COLORS[1]
    return [colorA, colorB]
  }
  return {
    getColorPortfolio,
    getPublicColorPortfolio,
    getColorPortfolioScheme,
    getColorsCompany,
    getPublicColorsCompany,
    defaultColors: DEFAULT_SITE_COLORS,
  }
}

export default useColorCompany
