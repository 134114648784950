import { useContext } from "react"

// STORE
import { useSelector, useDispatch } from "react-redux"
import { showNotifications } from "../../../../store/notificationsSlice"

// HOOKS
import { Fetch } from "../../../../hooks/useFetch"
import useCopyTextToClipboard from "../../../../hooks/useCopyTextToClipboard"

// CONTEXTS
import { FirebaseContext } from "../../../../firebase"

// UTILS
import { toURLString } from "../../utilities/functions"
// SENTRY
import * as Sentry from "@sentry/browser";


const useMyAnalysisFunctions = () => {
  // STORE
  const dispatch = useDispatch()
  const urls = useSelector((state) => state.urls)
  // FIREBASE
  const firebase = useContext(FirebaseContext)
  // HOOKS
  const { copyToClipboard } = useCopyTextToClipboard()

  // SETUP MY ANALYSIS PAGE
  const getPortfolioTotals = (proposal) => {
    // Get total amounts for portfolioA and portfolioB
    const portfolioATotal =
      proposal.portfolios.portfolioA.portfolioFunds.reduce(
        (acc, fund) => acc + fund.amount,
        0,
      )
    const portfolioBTotal =
      proposal.portfolios.portfolioB.portfolioFunds.reduce(
        (acc, fund) => acc + fund.amount,
        0,
      )

    return {
      max: Math.max(portfolioATotal, portfolioBTotal),
      min: Math.min(portfolioATotal, portfolioBTotal),
    }
  }

  const findLargestAndLowestOverall = (proposalList) => {
    // Get largest and smallest totals for each object
    const portfolioResults = proposalList.map(getPortfolioTotals)

    // Find the maximum and minimum total amount across all objects
    const maxProposalAmount = Math.max(
      ...portfolioResults.map((result) => result.max),
    )
    const minProposalAmount = Math.min(
      ...portfolioResults.map((result) => result.min),
    )

    return { maxProposalAmount, minProposalAmount }
  }

  const getUniqueValues = (proposalList, key, putLast) => {
    const uniqueValues = new Set(
      proposalList.map((proposal) => proposal[key].toLowerCase()),
    )
    const uniqueList = [...uniqueValues]

    const sortedItems = uniqueList.filter(
      (item) => item !== putLast.toLowerCase() && item !== "",
    )
    sortedItems.sort()
    const lastItem = uniqueList.filter((item) => item === putLast.toLowerCase())

    return sortedItems.concat(lastItem)
  }

  const getPortfolioNumber = (proposal) => {
    let total = 0
    if (proposal.portfolios.portfolioA.portfolioFunds.length > 0) total += 1
    if (proposal.portfolios.portfolioB.portfolioFunds.length > 0) total += 1

    return total
  }

  const setupAnalysisData = (list, setControlOptions) => {
    const transformedData = Object.keys(list).map((aid) => {
      const date = new Date(list[aid].timestamp)
      return {
        aid,
        ...list[aid],
        ...{ timestamp: date },
        ...{ portfolios_number: getPortfolioNumber(list[aid]) },
      }
    })
    const proposalListByDate = transformedData.sort(
      (key1, key2) => key1.timestamp - key2.timestamp,
    )

    // DATA FOR FILTERS
    const firstDate = proposalListByDate[0].timestamp
    const lastDate = proposalListByDate[proposalListByDate.length - 1].timestamp
    const { minProposalAmount, maxProposalAmount } =
      findLargestAndLowestOverall(proposalListByDate)
    const clientNames = getUniqueValues(
      proposalListByDate,
      "client_name",
      "No tiene cliente asignado",
    )
    const clientProfiles = getUniqueValues(
      proposalListByDate,
      "client_profile",
      "Sin perfil de riesgo",
    )
    const clientInvestmentTypes = getUniqueValues(
      proposalListByDate,
      "client_investment_type",
      "Sin horizonte de inversión",
    )
    // UPDATE CONTROL OPTIONS
    setControlOptions({
      firstDate,
      lastDate,
      clientNames,
      clientProfiles,
      clientInvestmentTypes,
      minAmount: minProposalAmount,
      maxAmount: maxProposalAmount,
      portfolioNumber: [1, 2],
    })

    return proposalListByDate.reverse()
  }

  // ACTIONS
  const downloadProposalPDF = (proposalId, title) => {
    try {
      Fetch(
        `${urls.API_URL}/proposals/${proposalId}/pdf_public_url`,
        {},
        { firebase },
        (response) => {
          const link = document.createElement("a")
          link.href = response
          link.setAttribute("download", `${toURLString(title)}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        },
      )
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error al recuperar link a PDF", err)
    }
  }

  const copyWebProposalToClipboard = (url) => {
    copyToClipboard(url, () => {
      dispatch(
        showNotifications({
          show: true,
          status: "success",
          title: "¡Copiado!",
          message: "",
          autoHide: true,
          time: 2000,
        }),
      )
    })
  }

  return {
    setupAnalysisData,
    downloadProposalPDF,
    copyWebProposalToClipboard,
  }
}

export default useMyAnalysisFunctions
