import  { useState, useContext } from "react"

// CONTEXT
import FirebaseContext from "../../../firebase/FirebaseContext"

// COMPONENTS
import InputField from "../forms/InputField"
import FormGroup from "../forms/FormGroup"

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
  message: "",
  status: "",
  colors: "",
}

function AccountPasswordChange() {
  // CONTEXT
  const { doPasswordUpdate } = useContext(FirebaseContext)
  // STATE
  const [formFields, setFormFields] = useState(INITIAL_STATE)
  const isInvalid =
    formFields.passwordOne !== formFields.passwordTwo ||
    formFields.passwordOne === ""

  // CALLBACK
  const onSubmitHandler = (event) => {
    event.preventDefault()
    const { passwordOne } = formFields

    doPasswordUpdate(passwordOne)
      .then(() => {
        setFormFields({
          ...INITIAL_STATE,
          ...{
            error: true,
            message: "Ya cambiamos tu contraseña.",
            status: "success",
            colors: "border-emerald-400 bg-emerald-50 text-slate-900",
          },
        })
      })
      .catch((error) => {
        let message = ""
        let color = ""
        switch (error.code) {
          case "auth/requires-recent-login":
            message =
              "Por seguridad, te solicitamos que vuelvas a ingresar antes de cambiar la contraseña"
            color = "border-yellow-400 bg-yellow-50 text-slate-900"
            break
          case "auth/weak-password":
            message =
              "La contraseña que seleccionaste es muy débil. Asegúrate que tenga más de 6 caracteres entre letras y números."
            color = "border-yellow-400 bg-yellow-50 text-slate-900"
            break
          default:
            message =
              "Nos encontramos con un problema, por favor inténtalo nuevamente."
            color = "border-rose-400 bg-rose-50 text-slate-900"
        }
        setFormFields({
          ...formFields,
          ...{ error, message },
          ...{ colors: color },
        })
      })
  }
  const onChangeHandler = (event) => {
    setFormFields({
      ...formFields,
      ...{ [event.target.name]: event.target.value },
    })
  }

  return (
    <form
      onSubmit={onSubmitHandler}
      noValidate
      className="w-full max-w-[800px]"
    >
      <FormGroup>
        <div className="grid grid-cols-[200px,_1fr] items-center gap-2">
          <p className="text-sm">Nueva contraseña</p>
          <InputField
            type="password"
            name="passwordOne"
            placeholder="Ingresa tu nueva contraseña"
            useShowPassword
            defaultValue={formFields.passwordOne}
            label=""
            callback={onChangeHandler}
            size="small"
          />
        </div>
      </FormGroup>

      <FormGroup>
        <div className="grid grid-cols-[200px,_1fr] items-center gap-2">
          <p className="text-sm">Repetir nueva contraseña</p>
          <InputField
            type="password"
            name="passwordTwo"
            placeholder="Confirmar nueva contraseña"
            useShowPassword
            defaultValue={formFields.passwordTwo}
            label=""
            callback={onChangeHandler}
            size="small"
          />
        </div>
      </FormGroup>

      {formFields.error && (
        <p
          className={`
            my-5 text-sm p-3 border rounded-md
            ${formFields.colors}
        `}
        >
          {formFields.message}
        </p>
      )}

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isInvalid}
          className="w-[200px] py-2 px-3 text-sm uppercase border border-blue-500 text-blue-500 bg-transparent rounded-md
                    hover:bg-blue-500 hover:text-slate-600
                    disabled:opacity-50 disabled:bg-slate-500 disabled:text-slate-300 disabled:border-slate-700 disabled:cursor-default
                    hover:disabled:bg-slate-600 hover:disabled:text-slate-400
                    !focus:ring-0 focus:border-blue-500 !focus-visible:ring-0
                    !active:ring-0
                    "
        >
          Cambiar contraseña
        </button>
      </div>
    </form>
  )
}

export default AccountPasswordChange
