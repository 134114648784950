import React from "react"
import FreshDeskButton from "../../components/ui/FreshDeskButton/FreshDeskButton"

function ErrorPage() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <span className="text-red-500">
        Al parecer hay un problema al cargar la pagina, por favor haga click en
        el boton para reportar el problema.
      </span>
      <div className="ml-4">
        <FreshDeskButton errorPage />
      </div>
    </div>
  )
}

export default ErrorPage
