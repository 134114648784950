// QA
const SETTINGS = {
  environment: "prod",
  urls: {
    API_URL: "https://elevest.app",
    SITE_URL: "https://elevest.app",
    LANDING_URL:"/landing/index.html",
  },
  featureConditionals: {
    ROUTE_ANALYSIS: false,
  },
  SHOW_PROP_TYPES_ERRORS: false,
  CLEAR_SITE_DATA_ACCORDING_VERSION: true,
  DATA_COMPRESS: true,
  TRACK_EVENTS_LOGS: true,
  MOMENT_DATE_FORMAT: "D [de] MMMM [de] YYYY",
  NUMERAL_FLOAT_FORMAT: "0.00",
  NUMERAL_PERCENTAGE_FORMAT: "0.00%",
  firebase_config: {
    apiKey: "AIzaSyBto2XvHi5AdNeY_p7IQ_xPUg44pW9NdeE",
    authDomain: "iadvisor-qa.firebaseapp.com",
    projectId: "iadvisor-qa",
    storageBucket: "iadvisor-qa.appspot.com",
    messagingSenderId: "372066961913",
    appId: "1:372066961913:web:435e25a5d6382333b19528",
    measurementId: "G-L7KFR53E79",
  },
  USER_PROFILES: {
    APP_ADMINISTRATOR: "APP_ADMINISTRATOR",
    ADVISOR: "ADVISOR",
  },
}

export default SETTINGS
