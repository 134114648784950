import { useState } from "react"

import { panelDataPropTypes } from "../../../propTypes/AppPropTypes"

// HOOKS
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"

// UTILS
import {
  displayString,
  formatMinimumInvestment,
  formatToFloat,
} from "../utilities/functions"
import ParagraphWithTooltip from "../ParagraphWithTooltip/ParagraphWithTooltip"

function FundDetailsCosts({ data }) {
  // HOOKS
  const { filterUnqualifiedSeries } = usePrepareDataForCharts()

  const tableReturns = useState(filterUnqualifiedSeries(data.returnsTable.data))

  const tableFill = (tData) => {
    const table = {}
    tData.forEach((tableRow) => {
      table[tableRow.serie] = {
        tac: tableRow.tac,
        annual_max_managenent_fee: tableRow.annual_max_managenent_fee,
        max_placement_fee: tableRow.max_placement_fee,
        max_redemption_payment_time: tableRow.max_redemption_payment_time,
        entry_requirements: tableRow.entry_requirements,
        minimum_investment: tableRow.minimum_investment,
      }
    })
    return table
  }
  const series = useState(Object.keys(tableFill(tableReturns[0])))
  const displaySeries = series[0]

  const addDataToTable = (table, shareClassName, shareClassData) => {
    table["Tasa anual de costos"].push({
      [shareClassName]: formatToFloat(shareClassData.tac) || "-",
    })
    table["Monto mínimo de inversión"].push({
      [shareClassName]:
        formatMinimumInvestment(shareClassData.minimum_investment) || "-",
    })
    table["Remuneración anual máxima"].push({
      [shareClassName]:
        formatToFloat(shareClassData.annual_max_managenent_fee) || "-",
    })
    table["Comisión máxima"].push({
      [shareClassName]: formatToFloat(shareClassData.max_placement_fee) || "-",
    })
    table["Requisitos de ingreso"].push({
      [shareClassName]: displayString(shareClassData.entry_requirements) || "-",
    })
    table["Plazo máximo rescate"].push({
      [shareClassName]:
        displayString(shareClassData.max_redemption_payment_time) || "-",
    })
    return table
  }
  const presenter = (tData) => {
    const currentTData = { ...tData }
    let table = {
      "Tasa anual de costos": [],
      "Monto mínimo de inversión": [],
      "Remuneración anual máxima": [],
      "Comisión máxima": [],
      "Plazo máximo rescate": [],
      "Requisitos de ingreso": [],
    }

    // add data for current shareclass
    const currentShareClassName = data.overview.shareClass.shareClassName
    const tableRowCS = currentTData[currentShareClassName]
    table = addDataToTable(table, currentShareClassName, tableRowCS)

    // move current shareclass to begin
    displaySeries.splice(displaySeries.indexOf(currentShareClassName), 1)
    displaySeries.unshift(currentShareClassName)

    delete currentTData[currentShareClassName]
    Object.keys(currentTData).forEach((s) => {
      const tableRow = currentTData[s]
      table = addDataToTable(table, s, tableRow)
    })
    return table
  }

  const [tableData] = useState(presenter(tableFill(tableReturns[0])))

  if (Object.keys(tableData).length === 0) {
    return (
      <div className="w-full">
        <p>No data</p>
      </div>
    )
  }

  return (
    <section className="h-full">
      <h1 className="overview-section-title">
        Condiciones comerciales por serie
      </h1>

      <div className="table-container pb-5">
        <table
          className={`text-slate-900 dark:text-slate-50 table-fixed ${
            displaySeries.length > 4 ? "w-full" : "w-auto"
          }`}
        >
          <thead className="table-head">
            <tr className="border-b border-slate-300 dark:border-slate-600 ">
              <th
                scope="col"
                className="pb-1 font-light  w-[200px] sticky left-0 z-10 bg-slate-50 dark:bg-slate-800"
              />
              {displaySeries.map((serie) => (
                <th
                  key={serie}
                  scope="col"
                  className={`pb-1 font-light text-center w-[180px] max-w-[180px]
                        ${
                          data.overview.shareClass.shareClassName === serie
                            ? "text-blue-500 dark:text-blue-400"
                            : ""
                        }`}
                >
                  {`Serie ${serie}`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(tableData).map((item) => (
              <tr
                key={item}
                className="text-xs text-slate-900 dark:text-slate-50 border-b border-slate-300 dark:border-slate-600 "
              >
                <td className="py-2 text-left w-[200px] sticky left-0 z-10 bg-slate-50 dark:bg-slate-800 align-top">
                  {item}
                </td>
                {tableData[item].map((element) => (
                  <td
                    key={`${item}-${Object.keys(element)[0]}`}
                    className={`py-2 px-2 font-normal text-center w-[180px] max-w-[180px] align-top ${
                      data.overview.shareClass.shareClassName ===
                      Object.keys(element)[0]
                        ? "text-blue-400"
                        : ""
                    }`}
                  >
                    {item === "Requisitos de ingreso" ? (
                      <ParagraphWithTooltip
                        text={Object.values(element)[0]}
                        length={60}
                        justify={false}
                        position="top"
                      />
                    ) : (
                      Object.values(element)[0]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}
FundDetailsCosts.propTypes = {
  data: panelDataPropTypes.isRequired,
}
export default FundDetailsCosts
