import {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { Combobox, Transition } from "@headlessui/react"
import { MinusCircleIcon, CheckIcon } from "@heroicons/react/24/outline"
import { FunnelIcon } from "@heroicons/react/24/solid"

// CONTEXT
import { useSelector, useDispatch } from "react-redux"
import { FirebaseContext } from "../../../firebase"

// STORE
import { updateFilters, resetFilters } from "../../../store/filtersSlice"
import { resetReducer } from "../../../store/reducedDataSlice"

// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import useViewportSize from "../../../hooks/useViewportSize"
import useTracking from "../../../hooks/useTracking"

function TableFilterButtonType({ title, filterId }) {
  // TRACKING
  const trackEvent = useTracking()
  // CONTEXT
  const { logAnalyticsEvent } = useContext(FirebaseContext)
  // REF
  const buttonRef = useRef(null)
  const panelRef = useRef(null)
  // HOOKS
  const viewportSize = useViewportSize()
  // STORE
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters.data)
  const reducedData = useSelector((state) => state.reducer.data)
  const user = useSelector((state) => state.user.user)
  // STATE
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState("")
  const [panelPos, setPanelPos] = useState("left-0")
  const [query, setQuery] = useState("")
  const [filteredOptions, setFilteredOptions] = useState(reducedData[filterId])

  const typeNameMap = {
    FM: "Fondos Mutuos",
    FI: "Fondos de Inversión",
    IN: "Índice Depósito a Plazo",
  }

  // TRACKING
  const handleSendDataTracking = ({ type, action, data }) => {
    trackEvent({
      action,
      timestamp: Date.now(),
      type,
      data,
    })
  }

  // CALLBACKS
  const closePanel = useCallback(() => {
    handleSendDataTracking({
      action: ` FILTER_${filterId.toUpperCase()}`,
      type: "SELECT",
      data: "CLOSE",
    })
    setOpen(false)
    setQuery("")
  }, [])

  const removeSelection = (selection) => {
    const newArray = filters[filterId].filter((f) => f !== selection)
    const newFilters = { ...filters, ...{ [filterId]: newArray } }
    dispatch(updateFilters(newFilters))

    let isFiltered = false
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key].length > 0) isFiltered = true
    })
    if (!isFiltered) {
      dispatch(resetFilters())
      dispatch(resetReducer())
    }
  }

  const handleSelection = (selection) => {
    if (!filters[filterId].includes(selection)) {
      const newSelection = [...filters[filterId], ...[selection]]
      dispatch(updateFilters({ ...filters, ...{ [filterId]: newSelection } }))
      setSelected("")
      setQuery("")
      logAnalyticsEvent("select_item", {
        item_list_id: filterId,
        item_list_name: "Filtro de catálogo",
        items: [
          {
            item_id: filterId,
            item_name: selection,
          },
        ],
        username: `${user.name} ${user.lastname}`,
        company: user.company,
      })
      trackEvent({
        action: `FILTER_${filterId.toUpperCase()}`,
        timestamp: Date.now(),
        type: "OPTION",
        data: selection,
      })
    } else {
      removeSelection(selection)
    }
  }

  useEffect(() => {
    const newFilteredOptions =
      query === ""
        ? reducedData[filterId]
        : reducedData[filterId].filter((d) =>
            d
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
          )
    setFilteredOptions(newFilteredOptions)
  }, [reducedData, query, filterId])

  useOnClickOutside(panelRef, () => {
    if (open) {
      closePanel()
    }
  })

  useEffect(() => {
    if (buttonRef.current) {
      let pos = "left-0"
      const clientRect = buttonRef.current.getBoundingClientRect()
      if (clientRect.x > viewportSize.width / 2) {
        pos = "right-0"
      }
      setPanelPos(pos)
    }
  }, [buttonRef, viewportSize.width])

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 27) {
        if (open) {
          closePanel()
        }
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [open, closePanel])

  return (
    <div ref={panelRef} className="relative">
      <button
        ref={buttonRef}
        type="button"
        className="px-2 py-1.5 flex items-center justify-between gap-1
              border border-slate-300 dark:border-slate-700 rounded-md bg-slate-50 dark:bg-slate-800
              text-xxs text-blue-500 dark:text-blue-400 uppercase
              hover:border-blue-500 dark:hover:border-blue-400
              3xl:px-3 3xl:text-[11px]
              "
        onClick={() => {
          setOpen(!open)
          handleSendDataTracking({
            action: `FILTER_${filterId.toUpperCase()}`,
            type: "SELECT",
            data: "OPEN",
          })
        }}
      >
        <FunnelIcon className="w-3 h-3 shrink-0" />
        <span className="mr-auto text-left">{title}</span>
        {filters[filterId].length > 0 && (
          <span
            className="w-4 h-4 shrink-0 bg-blue-400 text-slate-50 dark:text-slate-900 rounded-full grid place-content-center text-xxs
              3xl:text-[11px]"
          >
            {filters[filterId].length}
          </span>
        )}
      </button>

      <Transition show={open} as={Fragment}>
        <aside
          className={`bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent w-80 absolute ${panelPos} top-9 rounded-md z-20`}
        >
          <header className="flex items-center justify-between mb-4 px-4 pt-3">
            <h3 className="text-sm text-left font-semibold">{title}</h3>
          </header>
          <Combobox value={selected} onChange={handleSelection}>
            {filters[filterId].length > 0 && (
              <div className="px-4 w-full text-sm">
                {filters[filterId].map((item) => (
                  <div
                    key={item}
                    className="flex items-center justify-start cursor-pointer pl-1 py-1 text-blue-500 dark:text-blue-400"
                  >
                    <span className="flex items-center w-5 shrink-0 text-blue-500 dark:text-blue-400">
                      <CheckIcon className="h-3 w-3" />
                    </span>
                    <span className="flex-auto text-sm text-left whitespace-nowrap text-ellipsis overflow-hidden">
                      {typeNameMap[item]}
                    </span>
                    <button
                      type="button"
                      className="text-blue-500 dark:text-blue-400"
                      onClick={() => removeSelection(item)}
                    >
                      <MinusCircleIcon className="w-4 h-4" />
                    </button>
                  </div>
                ))}
              </div>
            )}

            <Combobox.Options
              className="my-4 px-4 max-h-60 w-full overflow-auto text-sm z-30"
              static
            >
              {filteredOptions.length === 0 && query !== "" ? (
                <div className="text-sm px-4 ml-5">No hay resultados.</div>
              ) : (
                filteredOptions.map(
                  (option) =>
                    !filters[filterId].includes(option) && (
                      <Combobox.Option
                        key={option}
                        className={({ active }) =>
                          `flex items-center justify-start cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md ${
                            active
                              ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                              : "text-slate-900 dark:text-slate-50"
                          }`
                        }
                        value={option}
                      >
                        <>
                          <span
                            className={`flex items-center w-5 shrink-0
                                                            ${
                                                              !filters[
                                                                filterId
                                                              ].includes(option)
                                                                ? "invisible"
                                                                : "visible text-blue-500 dark:text-blue-400"
                                                            }
                                                        `}
                          >
                            <CheckIcon className="h-3 w-3" aria-hidden="true" />
                          </span>
                          <span
                            className={`text-sm ${
                              !filters[filterId].includes(option)
                                ? ""
                                : "text-blue-500 dark:text-blue-400"
                            }`}
                          >
                            {typeNameMap[option]}
                          </span>
                        </>
                      </Combobox.Option>
                    ),
                )
              )}
            </Combobox.Options>
          </Combobox>
        </aside>
      </Transition>
    </div>
  )
}

TableFilterButtonType.propTypes = {
  title: PropTypes.string.isRequired,
  filterId: PropTypes.string.isRequired,
}

export default TableFilterButtonType
