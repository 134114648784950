import { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

// HOOKS
import useResizeObserver from "../../../hooks/useResizeObserver"
import useColorScheme from "../../../hooks/useColorScheme"

function AnalysisDistributionBar({ perc, color }) {
  // STATE
  const [barWidth, setBarWidth] = useState(0)
  // REF
  const wrapperRef = useRef()
  const svgRef = useRef()
  // HOOKS
  const dimensions = useResizeObserver(wrapperRef)
  const [colorScheme] = useColorScheme()
  // OPTIONS
  const barHeight = 13

  useEffect(() => {
    if (!dimensions) return
    setBarWidth(() => Math.ceil((dimensions.width * perc) / 100))
  }, [dimensions, perc, colorScheme])

  return (
    <div className="w-full" ref={wrapperRef}>
      <svg
        ref={svgRef}
        className="w-full fill-slate-200"
        height={`${barHeight}px`}
        width="100%"
      >
        <rect
          width={dimensions?.width}
          height={`${barHeight}px`}
          x={0}
          y={0}
          fill={colorScheme === "light" ? "#E2E8F0" : "#334155"}
        />
        <rect
          width={`${barWidth}px`}
          height={`${barHeight}px`}
          x={0}
          y={0}
          fill={color}
        />
      </svg>
    </div>
  )
}

AnalysisDistributionBar.propTypes = {
  perc: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default AnalysisDistributionBar
