import version from "../version.json"
import SETTINGS from "../settings/settings"
// SENTRY
import * as Sentry from "@sentry/browser";


const verifyMajorMinorVersion = async () => {
  let versionServer
  try {
    const response = await fetch(`${SETTINGS.urls.API_URL}/version`)
    if (!response.ok) {
      throw new Error("Network response was not ok")
    }
    versionServer = await response.text()

    // Function to check if a version is semantic (e.g., major.minor.patch)
    const isSemantic = (version) => /^\d+\.\d+(\.\d+)?$/.test(version);

    if (isSemantic(versionServer) && isSemantic(version?.tag)) {
      // Handle semantic version comparison
      const [majorServer, minorServer] = versionServer.split('.').slice(0, 2);
      const [majorLocal, minorLocal] = version.tag.split('.').slice(0, 2);

      if (majorServer === majorLocal && minorServer === minorLocal) {
        // console.log("Not different!")
        return { versionServer, updated: false };
      }
    } else {
      // For non-semantic versions (e.g., commit hashes), compare directly
      if (versionServer === version?.tag) {
        return { versionServer, updated: false };
      }
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log("error en la consulta", { error })
  }
  console.log("versiones diferentes")
  return { versionServer, updated: true }
}

export default verifyMajorMinorVersion
