/* eslint-disable react/prop-types */
import PropTypes from "prop-types"
import { formatToFloat } from "../../ui/utilities/functions"

// Define the order of the keys explicitly
const timeFrames = ["3 Años", "1 Año", "YTD", "6 meses", "3 meses", "1 mes"]

function ReturnsTableLegacy({ data, portfolioExistenceStatus, colors }) {
  return (
    <div>
      <table className="table-frame">
        <thead className="table-head">
          <tr>
            <th>&nbsp;</th>
            {timeFrames.map((timeFrame) => (
              <th
                key={timeFrame}
                className="pb-1 text-right font-normal w-[60px] 3xl:w-[65px] text-slate-500 dark:text-slate-400"
              >
                {timeFrame}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((portfolioName) => (
            <tr key={portfolioName}>
              <td className="py-0 font-normal text-right text-xs">
                <span className="flex items-center justify-start gap-1 py-2 min-w-max">
                  <span
                    className="w-3 h-3 rounded-sm"
                    style={{
                      backgroundColor: ` ${
                        portfolioName ===
                        portfolioExistenceStatus?.portFolioNameA
                          ? colors[0]
                          : colors[1]
                      }`,
                    }}
                  />
                  {portfolioName}
                </span>
              </td>
              {timeFrames.map((timeFrame) => {
                const value = data[portfolioName][timeFrame]
                return (
                  <td
                    key={`${portfolioName}-${timeFrame}`}
                    className="py-0 font-normal text-right text-xs tabular-nums"
                  >
                    {formatToFloat(value) || "-"}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
ReturnsTableLegacy.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
}

ReturnsTableLegacy.defaultProps = {
  colors: ["#FBBF24", "#14B8A6"],
}

export default ReturnsTableLegacy
