// CUSTOM HOOKS
import useColorCompany from "../../../hooks/useColorCompany";
// UTILS
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor";
// COMPONENTS
import PortfolioHeader from "../../ui/PortfolioHeader/PortfolioHeader"
import EquityDebtSummarizedContent from "./EquityDebtSummarizedContent";
import CompositionsOver100PercAlert from "../../ui/CompositionsOver100PercAlert/CompositionsOver100PercAlert";

const EquityDebtSummarized = ({data, headerData}) => {
    // HOOKS
    const { getColorPortfolio } = useColorCompany()
    // FUNCTIONS
    const createChartColorPallete = (color) => ([ addOpacityToHexColor(color, 1), addOpacityToHexColor(color, 0.6), addOpacityToHexColor(color, 0.2) ])
    
    const getPortfolioData = (portfolioKey) => {
        const pData = data[portfolioKey]
        const color = getColorPortfolio(portfolioKey)
        const labels = [];
        const chartData = []
        Object.keys(pData.detalle).forEach( key => {
            labels.push(key);
            chartData.push(pData.detalle[key])
        })
        return {
            labels,
            datasets: [
              {
                data: chartData,
                backgroundColor: createChartColorPallete(color),
                borderWidth: 0,
                rotation: 90,
              },
            ],
            total: pData.total,
            color
          }
    }
    const portfolioAData = getPortfolioData("portfolioA");
    const portfolioBData = getPortfolioData("portfolioB");
    const showWarningOver100Perc = portfolioAData.total !== 100 || portfolioBData.total !== 100;

    return (
        <div className="@container @[550px]:space-y-12">
            <div className="@[550px]:grid @[550px]:grid-cols-2 space-y-12 @[550px]:gap-14 @[550px]:space-y-0">
                <div className="space-y-8">
                    <PortfolioHeader portfolioName={headerData.portfoliosName["portfolioA"].portfolioName} colorScheme={headerData.colorScheme} bgColor={headerData.bgColor["portfolioA"]} mainColor={portfolioAData.color} />
                    <EquityDebtSummarizedContent portfolioData={portfolioAData} />
                </div>
                <div className="space-y-8">
                    <PortfolioHeader portfolioName={headerData.portfoliosName["portfolioB"].portfolioName} colorScheme={headerData.colorScheme} bgColor={headerData.bgColor["portfolioB"]} mainColor={portfolioBData.color} />
                    <EquityDebtSummarizedContent portfolioData={portfolioBData} />
                </div>
            </div>
            <CompositionsOver100PercAlert shouldShow={showWarningOver100Perc} />
        </div>
    )
}

export default EquityDebtSummarized