import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {
    show: false,
    status: "",
    title: "",
    message: "",
    autoHide: true,
    time: 3500,
    confirmCallback: null,
  },
}

export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showNotifications: (state, action) => {
      state.data = action.payload
    },
    hideNotifications: (state) => {
      state.data = initialState.data
    },
  },
})

export const { showNotifications, hideNotifications } =
  NotificationsSlice.actions

export default NotificationsSlice.reducer
