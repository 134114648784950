import { useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline"

function AccountPagination({ filters, updateFilters, totalPages }) {
  const NUM_PAGES_OPTIONS = useMemo(() => [5, 10, 15, 25, 50, 100], [])
  const pagesBeforeAfter = 2
  let start = filters.currentPage - pagesBeforeAfter
  let stop = filters.currentPage + pagesBeforeAfter
  const range = useCallback(
    (startRange, stopRage, step = 1) =>
      Array.from(
        { length: (stopRage - startRange) / step + 1 },
        (_, i) => startRange + i * step,
      ),
    [],
  )

  if (stop > totalPages) {
    start -= stop - totalPages
    stop = totalPages
  }
  if (start <= 0) {
    stop += (start - 1) * -1
    start = 1
  }
  stop = stop > totalPages ? totalPages : stop

  // EVENT HANDLERS
  const handlePageChange = (pageNum) => {
    let requestedPage = pageNum
    if (requestedPage === 0) {
      requestedPage = 1
    }
    updateFilters("currentPage", requestedPage)
  }

  return totalPages > 1 ? (
    <nav
      className="w-full overflow-hidden px-2 py-3 grid grid-cols-2 items-end gap-2
      sm:px-4
      md:px-0 md:gap-5 md:border-t md:border-slate-300 md:dark:border-slate-700"
      aria-label="Pagination"
    >
      <div className="flex items-center gap-3">
        <div className="flex flex-col items-start justify-start gap-1">
          <p className="text-xxs text-slate-500 dark:text-slate-400">
            Propuestas por página
          </p>
          <select
            name="resultsPerPage"
            value={filters.rowPerPage}
            onChange={(e) => updateFilters("rowPerPage", +e.target.value)}
            className="w-20 h-7 px-2 py-1 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent !outline-none ring-0 focus-visible:outline-offset-0 disabled:opacity-40"
          >
            {NUM_PAGES_OPTIONS.map((num) => (
              <option value={num} key={`rpp-${num}`}>
                {num}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex items-center justify-end gap-2">
        <button
          type="button"
          disabled={filters.currentPage === 1}
          onClick={() => handlePageChange(1)}
          className="h-7 py-1 px-2 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
        >
          Primera
        </button>
        <button
          type="button"
          disabled={filters.currentPage === 1}
          onClick={() => handlePageChange(filters.currentPage - 1)}
          className="h-7 py-1 px-2 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
        >
          Anterior
        </button>

        {filters.currentPage > pagesBeforeAfter + 1 && (
          <button
            type="button"
            disabled
            className="h-7 py-1 px-2 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
          >
            <EllipsisHorizontalIcon className="w-3 h-3" />
          </button>
        )}
        {range(start, stop).map((page) => (
          <button
            type="button"
            className={`h-7 py-1 px-2 text-xs rounded-md  border ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none
              ${
                page === filters.currentPage
                  ? "text-slate-50 bg-blue-500 dark:bg-blue-400 border-transparent"
                  : "text-slate-600 dark:text-slate-400 bg-slate-50 dark:bg-slate-600 border-slate-300 dark:border-transparent"
              }
              `}
            key={page}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        ))}
        {filters.currentPage < totalPages - pagesBeforeAfter && (
          <button
            type="button"
            disabled
            className="h-7 py-1 px-2 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
          >
            <EllipsisHorizontalIcon className="w-3 h-3" />
          </button>
        )}
        <button
          type="button"
          disabled={filters.currentPage === totalPages}
          onClick={() => handlePageChange(filters.currentPage + 1)}
          className="h-7 py-1 px-2 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
        >
          Siguiente
        </button>
        <button
          type="button"
          disabled={filters.currentPage === totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="h-7 py-1 px-2 text-xs text-slate-600 dark:text-slate-400 rounded-md bg-slate-50 dark:bg-slate-600 border border-slate-300 dark:border-transparent ring-0 focus-visible:outline-offset-0 disabled:opacity-40 disabled:pointer-events-none"
        >
          Última
        </button>
      </div>
    </nav>
  ) : (
    <div>&nbsp;</div>
  )
}

AccountPagination.propTypes = {
  filters: PropTypes.shape({
    rowPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    company: PropTypes.string,
    disabled: PropTypes.string,
    searchTerm: PropTypes.string,
    sortOptions: PropTypes.shape({
      sortBy: PropTypes.string,
      sortOrder: PropTypes.string,
    }),
  }).isRequired,
  updateFilters: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default AccountPagination
