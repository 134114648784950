import { useEffect } from "react"
import { PUBLIC, PUBLIC_PDF } from "../../../routes/APP_ROUTES"

function FreshworksWidget() {
  const urlPublic = PUBLIC.split("/")[1]
  const urlPdf = PUBLIC_PDF.split("/")[1]
  useEffect(() => {
    if (
      window.location.pathname.includes(urlPublic) ||
      window.location.pathname.includes(urlPdf)
    ) {
      return
    }

    window.fwSettings = { widget_id: 48000001614 }

    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://widget.freshworks.com/widgets/48000001614.js"
    script.async = true
    script.defer = true

    const inlineScript = document.createElement("script")
    inlineScript.innerHTML = `
      !(function () {
        if ("function" != typeof window.FreshworksWidget) {
          var n = function () {
            n.q.push(arguments);
          };
          (n.q = []), (window.FreshworksWidget = n);
        }
      })();
    `

    document.head.appendChild(inlineScript)
    document.head.appendChild(script)

    // eslint-disable-next-line consistent-return
    return () => {
      document.head.removeChild(inlineScript)
      document.head.removeChild(script)
    }
  }, [])

  return null
}

export default FreshworksWidget
