import { useContext, useEffect, useRef, useState, useCallback } from "react"
import debounce from "lodash/debounce"
import { useSelector } from "react-redux"
import TrackingContext from "../tracking/TrackingContext"
import SETTINGS from "../settings/settings"
import { FirebaseContext } from "../firebase"

const useTracking = (debounceTime = 1000) => {
  const firebase = useContext(FirebaseContext)
  const { handleTrackEvent } = useContext(TrackingContext)
  const urls = useSelector((state) => state.urls)
  const user = useSelector((state) => state.user.user)

  const trackEvent = useCallback(
    (eventData, currentUser) => {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
      const payload = {
        ...eventData,
        uid: currentUser?.uid,
        environment: SETTINGS.environment,
      }
      handleTrackEvent(
        payload,
        urls,
        firebase,
      )
    },
    [handleTrackEvent, urls],
  )

  const debounceTrackEventRef = useRef(
    debounce(
      (eventData, currentUser) => trackEvent(eventData, currentUser),
      debounceTime,
    ),
  )

  useEffect(() => {
    debounceTrackEventRef.current = debounce(
      (eventData, currentUser) => trackEvent(eventData, currentUser),
      debounceTime,
    )
  }, [trackEvent, debounceTime])

  useEffect(
    () => () => {
      debounceTrackEventRef.current.cancel()
    },
    [],
  )

  return (eventData) => debounceTrackEventRef.current(eventData, user)
}

export default useTracking
