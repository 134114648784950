/**
 * Converts a hexadecimal color value to an RGBA color string with a specified opacity.
 *
 * @param {string} hex - The hexadecimal color value (e.g., "#FBBF24" or "#fff"). Can be a 3-character or 6-character hex code, optionally preceded by '#'.
 * @param {number} opacity - The opacity level for the color, where 0 is fully transparent and 1 is fully opaque.
 * @returns {string} The RGBA color string (e.g., "rgba(255,191,36,0.5)") representing the original hexadecimal color with the specified opacity applied.
 */
function addOpacityToHexColor(hex, opacity) {
  // Elimina el símbolo '#' si está presente
  let hexValue = hex.replace("#", "")

  // Expande el hex de 3 dígitos a 6 dígitos
  if (hexValue.length === 3) {
    hexValue = hexValue
      .split("")
      .map((char) => char + char)
      .join("")
  }

  // Calcula los componentes RGB del color hex
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)
  // Retorna el color en formato RGBA con la opacidad deseada
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export default addOpacityToHexColor
