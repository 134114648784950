import { createSlice } from "@reduxjs/toolkit"

const DEFAULT_DATA = {
  asset_class: [],
  fund_provider: [],
  category: [],
  fund_currency: [],
  aum: [],
  fund_type: [],
  searchTerm: "",
  llm_response: {},
}

const initialState = {
  data: DEFAULT_DATA,
}

export const FiltersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.data = action.payload
    },
    resetFilters: (state) => {
      state.data = DEFAULT_DATA
    },
  },
})

export const { updateFilters, resetFilters } = FiltersSlice.actions

export default FiltersSlice.reducer
