/* eslint-disable react/prop-types */
// STORE
import { useSelector } from "react-redux"
import { displayString, formatToFloat } from "../../ui/utilities/functions"
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor"
// HOOKS
import useColorScheme from "../../../hooks/useColorScheme"
import useColorCompany from "../../../hooks/useColorCompany"

// Define the order of the keys explicitly
const timeFrames = [
  "5 Años",
  "3 Años",
  "1 Año",
  "YTD",
  "6 meses",
  "3 meses",
  "1 mes",
]

function ReturnsTable({ data, portfolioExistenceStatus }) {
  const [colorScheme] = useColorScheme()
  const { getColorPortfolio } = useColorCompany()
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const portfoliosName = useSelector((state) => state.portfoliosName.data)

  const getFundName = (fund, shareClassName) => {
    const excludeSeriesList = ["IN"]
    let name = displayString(fund.fundName)
    if (!excludeSeriesList.includes(fund.fundType)) {
      name = `${displayString(fund.fundName)} | ${shareClassName}`
    }
    return name
  }

  return (
    <div>
      {Object.keys(data).map((portfolioName) => (
        <div key={portfolioName}>
          <div
            className={`w-full flex items-center justify-between gap-4 p-2 overflow-x-hidden rounded-sm
              ${colorScheme === "dark" ? "bg-slate-800" : ""}
            `}
            style={{
              backgroundColor:
                colorScheme === "light"
                  ? addOpacityToHexColor(getColorPortfolio(portfolioName), 0.3)
                  : null,
            }}
          >
            <div className="flex items-center gap-2">
              <span
                className="text-base w-4 h-4 rounded-sm grid place-content-center"
                style={{
                  backgroundColor: `${getColorPortfolio(portfolioName)}`,
                }}
              />
              <p className="text-slate-900 dark:text-slate-50 text-sm font-semibold">
                {portfoliosName[portfolioName].portfolioName}
              </p>
            </div>
          </div>
          <div className="overflow-x-scroll sm:overflow-x-hidden">
            <table className="min-w-[500px] table-frame mt-4 mb-2">
              <thead className="table-head">
                <tr>
                  <th className="text-left font-light w-[150px] uppercase">
                    &nbsp;
                  </th>
                  {timeFrames.map((timeFrame) => (
                    <th
                      key={timeFrame}
                      className="pb-1 text-right font-light uppercase"
                    >
                      {timeFrame}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pb-3 text-left text-xs font-bold">
                    {portfoliosName[portfolioName]?.portfolioName}
                  </td>
                  {timeFrames.map((timeFrame) => {
                    const value = data[portfolioName].portfolioData[timeFrame]
                    return (
                      <td
                        key={`${portfolioName}-${timeFrame}`}
                        className={`pb-3 font-bold text-xs tabular-nums ${
                          value ? "text-right" : "text-center"
                        }`}
                      >
                        {formatToFloat(value) || "-"}
                      </td>
                    )
                  })}
                </tr>
                {data[portfolioName].fundsData.map((fund, index) => {
                  const fundKey = `${portfolioName}${fund.fundId}${fund.fundName}${index}`
                  const selectedSerie = portfolios[
                    portfolioName
                  ].portfolioFunds.filter((f) => f.fundId === fund.fundId)
                  return (
                    selectedSerie.length > 0 && (
                      <tr key={fundKey}>
                        <td className="pb-3 text-left text-xxs">
                          {getFundName(fund, selectedSerie[0].shareClassName)}
                        </td>
                        {timeFrames.map((timeFrame) => {
                          const value =
                            fund.returnsByShareClass[
                              selectedSerie[0].shareClassName
                            ][timeFrame]

                          return (
                            <td
                              key={`${portfolioName}-${fund.fundId}-${timeFrame}`}
                              className={`pb-3 text-xxs tabular-nums ${
                                value ? "text-right" : "text-center"
                              }`}
                            >
                              {formatToFloat(value) || "-"}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  )
                })}
                {portfolios[portfolioName]?.portfolioName ===
                  portfolioExistenceStatus?.portFolioNameA && (
                  <tr className="h-4" />
                )}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ReturnsTable
