import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// STORE
import { useSelector, useDispatch } from "react-redux";
import { updateCharstData } from "../../../store/chartsDataSlice";

// PORTFOLIO MANAGET & CALCULATIONS
import PortfolioManagerFactory from "../PreviewPanel/PortfolioManagerFactory";
import PortfolioGroup from "../../../utils/PortfolioGroup";
// CALCULATIONS
import {
  calculatePortfoliosTotals,
  createReturnsTable,
} from "../../../utils/ReturnsCalculations";
// PRESENTERS
import { formatPortfolioMaxMinProfitAndLossesToChart } from "../../../utils/MaxMinProfitAndLossesPresenter";

// COMPONENTS
import AnalysisBox from "./AnalysisBox";
import AnalysisProposalInfo from "./AnalysisProposalInfo";
import AnalysisReturnsAPV from "./AnalysisReturnsAPV";
import AnalysisExposure from "./AnalysisExposure";
import AnalysisIssuers from "./AnalysisIssuers";
import AnalysisRisks from "./AnalysisRisks";
import AnalysisAdvisorInfo from "./AnalysisAdvisorInfo";
import AnalysisEquityDebt from "./AnalysisEquityDebt";
import AnalysisDisclamer from "./AnalysisDisclamer";
import ModalVersion from "../ModalVersion/ModalVersion";

// HELPERS
import { returnsPeriodMapAPV } from "../utilities/functions";
import { FirebaseContext } from "../../../firebase";
import usePortfolioDates from "../../../hooks/usePortfolioDates";


function AnalysisSectionsAPV({ onOfComponent, handleTakeScreenshot }) {
  const firebase = useContext(FirebaseContext);
  // PORTFOLIO MANAGER
  const portfolioManager =
    PortfolioManagerFactory.getPortfolioManagerInstance();

  // STORE
  const dispatch = useDispatch();
  const portfolios = useSelector((state) => state.portfolioSelection.data);
  const chartsData = useSelector((state) => state.chartsData);
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  );
  const baseAmount = useSelector(
    (state) => state.portfolioSelection.baseAmount,
  );
  const isAPV = useSelector((state) => state.proposalType.isAPV);
  // const ageInMonths = useRef(0)
  // const hasLessThanOneYear = useRef(false)

  const catalogByFunds = useSelector((state) => state.catalogByFunds.data);

  // STATE
  const [portfoliosInstances, setPortfoliosInstances] = useState(null);
  // const [timePeriod, setTimePeriod] = useState(chartsData.data.timePeriod)
  // const [periodList, setPeriodList] = useState(INITIAL_PERIODLIST)
  const [portfolioGroup, setPortfolioGroup] = useState(null);
  // const [hasLessThanOneYear, setHasLessThanOneYear] = useState(false)
  // CONFIG
  const isDownSample = true;

  // FUNCTIONS
  // const changeTimePeriod = (value) => {
  //   setTimePeriod(value)
  //   dispatch(updateCharstData({ ...chartsData.data, timePeriod: value }))
  // }

  useEffect(() => {
    portfolioManager
      .enqueueChange(portfolios, isAPV, firebase)
      .then((result) => {
        if (result.status === "OK") {
          setPortfoliosInstances(result.data);
          setPortfolioGroup(new PortfolioGroup(isAPV, result.data));
        }
      })
      .catch((error) => console.error(error));
  }, [portfolioManager, portfolios]);

  usePortfolioDates(
    portfoliosInstances,
    portfolioGroup,
    portfolios,
    distributionOption,
    baseAmount,
    catalogByFunds,
    isAPV,
    returnsPeriodMapAPV,
  );

  return (
    chartsData && (
      <>
        <ModalVersion />

        <div className="space-y-6">
          <AnalysisBox className="z-9">
            <AnalysisProposalInfo
              tacData={chartsData.data?.tacData}
              screenerData={chartsData.data.screenerTable}
              onOfComponent={onOfComponent}
            />
          </AnalysisBox>

          <AnalysisBox>
            <AnalysisReturnsAPV
              performanceData={
                chartsData?.data?.performanceChartData?.[
                chartsData?.data?.timePeriod
                ]
              }
              performanceTableData={chartsData?.data?.portfoliosTableReturns}
              timePeriod={chartsData?.data?.timePeriod}
              periodList={chartsData?.data?.periodList}
              isDownSample={isDownSample}
              emptyDates={chartsData?.data?.nullDates}
              onOfComponent={onOfComponent}
              handleTakeScreenshot={handleTakeScreenshot}
            />
          </AnalysisBox>

          {chartsData?.data?.exposureSummaryData &&
            Object.keys(chartsData?.data?.exposureSummaryData).length > 0 && (
              <AnalysisBox>
                <AnalysisEquityDebt
                  data={chartsData.data.exposureSummaryData}
                  onOfComponent={onOfComponent}
                  handleTakeScreenshot={handleTakeScreenshot}
                />
              </AnalysisBox>
            )}

          <AnalysisBox>
            <AnalysisExposure
              exposureData={chartsData.data?.exposureData[0]}
              onOfComponent={onOfComponent}
              handleTakeScreenshot={handleTakeScreenshot}
            />
          </AnalysisBox>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 3xl:gap-14">
            <AnalysisBox>
              <AnalysisIssuers
                issuersData={chartsData.data?.issuersData}
                onOfComponent={onOfComponent}
                handleTakeScreenshot={handleTakeScreenshot}
                portfolios={chartsData.data?.portfolios}
              />
            </AnalysisBox>
            <AnalysisBox>
              <AnalysisRisks
                minMaxData={chartsData?.data?.maxMinProfitLossesChartData}
                onOfComponent={onOfComponent}
                handleTakeScreenshot={handleTakeScreenshot}
                distributionOption={distributionOption}
                baseAmount={baseAmount}
              />
            </AnalysisBox>
          </div>
          <AnalysisBox>
            <AnalysisAdvisorInfo />
          </AnalysisBox>

          <AnalysisDisclamer />
        </div>
      </>
    )
  );
}

AnalysisSectionsAPV.propTypes = {
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
};

export default AnalysisSectionsAPV;
