import { useState, useRef, useContext, useEffect } from "react"
import { useSearchParams, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { ChevronLeftIcon } from "@heroicons/react/24/outline"
import * as ROUTES from "../../routes/APP_ROUTES"

// FETCH
import { Fetch } from "../../hooks/useFetch"

// CONTEXT
import FirebaseContext from "../../firebase/FirebaseContext"

// COMPONENTS
import AccountRegisterUsersForm from "../../components/ui/accounts/AccountRegisterUsersForm"
import AccountUserCreatedResult from "../../components/ui/accounts/AccountUserCreatedResult"
import { checkIsValidEmail } from "../../components/ui/utilities/functions"

const INITIAL_STATE = {
  name: "",
  lastname: "",
  company: {
    id: "",
    name: ""
  },
  email: "",
  contactServices: {
    email: {
      isEnabled: true,
      value: "",
    },
    phone: {
      isEnabled: false,
      value: "",
    },
    whatsapp: {
      isEnabled: false,
      value: "",
    },
  },
  defaultAnalysisInfo: {
    analysisTitle: "",
    analysis_comment: "",
    analysis_disclamer: "",
    analysis_objective: "",
    clientInvestmentType: "",
    clientProfile: "",
    company_info: {
      address: "",
      description: "",
      phone: "",
      url: "",
    },
  },
  role: [],
  error: {
    status: "",
    message: "",
  },
}

function UserRegistryPage() {
  const [params] = useSearchParams()
  const UIDRef = useRef(params.get("uid") || null)
  const isEditing = UIDRef.current !== null
  // STORE
  const urls = useSelector((state) => state.urls)
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  // STATE
  const [isLoading, setIsLoading] = useState(false)
  const [formFields, setFormFields] = useState(INITIAL_STATE)
  const [createdUserMeta, setCreatedUserMeta] = useState(null)
  // VALIDATION
  const isInvalid =
    formFields.name === "" ||
    formFields.lastname === "" ||
    formFields.company === "" ||
    !checkIsValidEmail(formFields.email) ||
    formFields.role === ""
  const getErrorMessage = (code) => {
    let message = ""
    switch (code) {
      case "app/empty-fields":
        message = "Por favor llena todos los campos."
        break
      case "ALREADY_EXISTS":
        message =
          'El usuario ya existe. Navega a la opción "Ver usuarios" para revisar su perfil.'
        break
      default:
        message = "Encontramos un problema. Por favor inténtalo nuevamente"
        break
    }
    return message
  }
  // LIFE CYCLE
  useEffect(() => {
    if (UIDRef.current) {
      Fetch(
        `${urls.API_URL}/advisors/${UIDRef.current}/get`,
        {
          uid: UIDRef.current,
          userData: formFields,
          action: isEditing ? "UPDATE" : "CREATE",
        },
        { firebase },
        (data) => {
          if (Object.keys(data).length) {
            setFormFields({
              ...INITIAL_STATE,
              ...{
                name: data.name,
                lastname: data.lastname,
                company: data.company,
                email: data.email,
                contactServices: data.contactServices,
                defaultAnalysisInfo: data.defaultAnalysisInfo,
                role: data.role,
                error: null,
              },
            })
          }
        },
      )
    }
  }, [setFormFields]);

  // CALLBACK
  const onSubmitHandler = (event) => {
    setIsLoading(true)
    event.preventDefault()

    if (isInvalid) {
      setFormFields({
        ...formFields,
        ...{
          error: {
            message: getErrorMessage("app/empty-fields"),
            status: "error",
          },
        },
      })
      setIsLoading(false)
    } else {
      const url = isEditing ? `${urls.API_URL}/advisors/${UIDRef.current}/update_info` : `${urls.API_URL}/advisors/create`;
      Fetch(
        url,
        {
          uid: UIDRef.current,
          userData: formFields,
        },
        { firebase },
        (response) => {
          if (response.status === "OK") {
            // EXITO
            if (!isEditing) setCreatedUserMeta(response.payload)

            if (isEditing) {
              setFormFields({
                ...formFields,
                ...{
                  error: {
                    message: "Datos del usuario actualizados.",
                    status: "success",
                  },
                },
              })
            }
            setIsLoading(false)
          } else {
            setFormFields({
              ...formFields,
              ...{
                error: {
                  message: getErrorMessage(response.code),
                  status: "error",
                },
              },
            })
          }
          setIsLoading(false)
        },
      )
    }
  }
  const onChangeHandler = (event) => {
    setFormFields({
      ...formFields,
      ...{ [event.target.name]: event.target.value },
    })
  }

  // LIFE CYCLE
  useEffect(() => {
    // DEBUG ONLY
    // console.log(formFields)
  }, [formFields])

  return (
    <main className="px-8 pt-20 pb-20 mb-20 grid grid-cols-5">
      <div className="col-span-3 col-start-2">
        {!isEditing && createdUserMeta ? (
          <AccountUserCreatedResult
            userName={formFields.name}
            userLastname={formFields.lastname}
            userEmail={formFields.email}
            userPsw={createdUserMeta.psw}
          />
        ) : (
          <>
            <Link
              to={ROUTES.USER}
              className="text-sm text-slate-400 hover:text-blue-500 flex items-center gap-1 mb-2"
            >
              <ChevronLeftIcon className="w-3 h-3" /> Volver
            </Link>
            <div className="flex items-center gap-2 mb-5">
              {isEditing ? (
                <div>
                  <h1 className="text-base">Actualizar datos</h1>
                  <p className="text-sm text-slate-400">
                    Revisa o modifica tu información de cuenta.
                  </p>
                </div>
              ) : (
                <div>
                  <h1 className="text-base">Crear nuevo usuario</h1>
                  <p className="text-sm text-slate-400">
                    Para crear debes completar todos los campos.
                  </p>
                </div>
              )}
            </div>
            <AccountRegisterUsersForm
              formFields={formFields}
              setFormFields={setFormFields}
              onInputChange={onChangeHandler}
              onSubmit={onSubmitHandler}
              isInvalid={isInvalid}
              isLoading={isLoading}
              actionLabel={isEditing ? "Actualizar usuario" : "Crear usuario"}
              isEditing={isEditing}
            />
          </>
        )}
      </div>
    </main>
  )
}

export default UserRegistryPage
