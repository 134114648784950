import SETTINGS from "../settings/settings"
import { Fetch } from "../hooks/useFetch"

function TrackEvents(eventData, urls,firebase) {

  if (!SETTINGS.TRACK_EVENTS_LOGS) {
    return null
  }
  const { API_URL } = urls

  Fetch(
    `${API_URL}/event_logs/add`,
    eventData,
    {firebase},
    (data) => data
  )
}
export default TrackEvents
