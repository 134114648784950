// STORE
import { useSelector } from "react-redux";
// CUSTOM HOOKS
import useColorCompany from "../../../hooks/useColorCompany";
import useColorScheme from "../../../hooks/useColorScheme";
// UTILS
import addOpacityToHexColor from "../../../utils/addOpacityToHexColor";
import { formatExposuresDate } from "../utilities/functions";
import RenderIf from "../../helpers/RenderIf";
// COMPONENTS
import ToggleSectionButton from "./ToggleSectionButton";
import ScreenshotButton from "../ScreenshotModal/ScreenshotButton";
// CHARTS
import EquityDebtSummarized from "../../charts/EquityDebt/EquityDebtSummarized";
import EquityDebtExtended from "../../charts/EquityDebt/EquityDebtExtended";

function SectionEquityDebt({ data, onOfComponent, handleTakeScreenshot }) {
  // HOOKS
  const { getColorPortfolio } = useColorCompany();
  const [colorScheme] = useColorScheme();
  // STORE
  const appData = useSelector((state) => state.appData.data);
  const portfoliosName = useSelector((state) => state.portfoliosName.data);
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  );
  // STATIC
  const quantity = data.cantidad;

  // CALLBACKS
  // const takeScreenshotHandler = () => {
  // const today = new Date()
  // const formatter = new Intl.DateTimeFormat("es-CL").format(today)
  // const modalData = {
  //     type: "issuers",
  //     filename: `Principales emisores - ${formatter}`,
  //     maxIssuers: maxIssuers.current,
  //     tableA: {
  //         data: issuersData.issuers1,
  //         color:
  //             issuersData?.issuers1?.portfolioName === portFolioNameA
  //                 ? "bg-amber-500"
  //                 : "bg-teal-500",
  //     },
  //     tableB: {
  //         data: issuersData?.issuers2,
  //         color:
  //             issuersData?.issuers1?.portfolioName !== portFolioNameB
  //                 ? "bg-amber-500"
  //                 : "bg-teal-500",
  //     },
  // }
  // handleTakeScreenshot(modalData)
  // };

  // HELPERS
  const getPortfolioKey = () => {
    let portfolioKey = "";
    if (data.portfolioA) {
      portfolioKey = "portfolioA";
    } else {
      portfolioKey = "portfolioB";
    }

    return portfolioKey;
  };

  return (
    <div id="equitydebt">
      <header className="mb-5 flex items-start">
        <div>
          <h2 className="text-base font-bold">
            Resumen de exposiciones: Deuda vs Capitalización
          </h2>
          <p className="text-xs text-slate-500 dark:text-slate-400">{`Datos al ${formatExposuresDate(data.fecha)}`}</p>
        </div>
        <ToggleSectionButton component="equitydebt" action={onOfComponent} />
        {/* <RenderIf isTrue={appData.screenshot_ability}>
          <ScreenshotButton takeScreenshotHandler={takeScreenshotHandler} />
        </RenderIf> */}
      </header>
      <div
        className={` transition-opacity duration-500 ${analysisDataConfig.showComponents.equitydebt ? "opacity-100 pointer-events-auto" : "opacity-30 pointer-events-none"}`}
      >
        {quantity === 1 && (
          <EquityDebtExtended
            data={data[getPortfolioKey()]}
            headerData={{
              portfolioName: portfoliosName[getPortfolioKey()].portfolioName,
              colorScheme: colorScheme,
              bgColor: addOpacityToHexColor(
                getColorPortfolio(getPortfolioKey()),
                0.3,
              ),
            }}
          />
        )}
        {quantity === 2 && (
          <EquityDebtSummarized
            data={data}
            headerData={{
              portfoliosName: portfoliosName,
              colorScheme: colorScheme,
              bgColor: {
                portfolioA: addOpacityToHexColor(
                  getColorPortfolio("portfolioA"),
                  0.3,
                ),
                portfolioB: addOpacityToHexColor(
                  getColorPortfolio("portfolioB"),
                  0.3,
                ),
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SectionEquityDebt;
