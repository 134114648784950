/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useRef } from "react"
import PropTypes from "prop-types"

// HOOKS
import { useSelector } from "react-redux"
import useCheckPortfolioExists from "../../../hooks/useCheckPortfolioExists"
import AnalysisIssuersTable from "./AnalysisIssuersTable"

// COMPONENTS
import ToggleSectionButton from "./ToggleSectionButton"
import ScreenshotButton from "../ScreenshotModal/ScreenshotButton"
import RenderIf from "../../helpers/RenderIf"
import useColorCompany from "../../../hooks/useColorCompany"

function AnalysisIssuers({
  issuersData,
  onOfComponent,
  handleTakeScreenshot,
  portfolios,
}) {
  // PORTFOLIO INFO
  const { getColorPortfolio } = useColorCompany()
  const checkPortfolioExists = useCheckPortfolioExists()
  const portfolioExistenceStatus = checkPortfolioExists()
  const { portFolioNameA, portFolioNameB } = portfolioExistenceStatus
  // STORE
  const appData = useSelector((state) => state.appData.data)
  const portfoliosName = useSelector((state) => state.portfoliosName.data)
  const analysisDataConfig = useSelector((state) => state.analysisDataConfig.data )

  const maxIssuers = useRef(5)
  // CALLBACKS
  const takeScreenshotHandler = () => {
    const today = new Date()
    const formatter = new Intl.DateTimeFormat("es-CL" ).format(today)
    const modalData = {
      type: "issuers",
      filename: `Principales emisores - ${formatter}`,
      maxIssuers: maxIssuers.current,
      tableA: {
        data: issuersData.issuers1,
        color:
          issuersData?.issuers1?.portfolioName === portFolioNameA
            ? "bg-amber-500"
            : "bg-teal-500",
      },
      tableB: {
        data: issuersData?.issuers2,
        color:
          issuersData?.issuers1?.portfolioName !== portFolioNameB
            ? "bg-amber-500"
            : "bg-teal-500",
      },
    }
    handleTakeScreenshot(modalData)
  }

  const renderIssuersTable = (issuersDataTable, portfolioKey) => {
    if (
      !issuersDataTable ||
      !issuersDataTable?.issuers ||
      !issuersDataTable?.closingDate ||
      issuersDataTable?.closingDate?.length === 0
    ) {
      return null
    }

    return (
      <AnalysisIssuersTable
        title={portfoliosName?.[portfolioKey]?.portfolioName}
        issuers={issuersDataTable?.issuers}
        color={getColorPortfolio(portfolioKey)}
        maxIssuers={maxIssuers.current}
      />
    )
  }

  return (
    <div id="issuers">
      <header className="mb-10 flex items-center">
        <h2 className="analysis-box-header">Principales emisores</h2>
        <ToggleSectionButton component="issuers" action={onOfComponent} />
        <RenderIf isTrue={appData.screenshot_ability}>
          <ScreenshotButton takeScreenshotHandler={takeScreenshotHandler} />
        </RenderIf>
      </header>
      <div
        className={`
        transition-opacity duration-500
          ${
            analysisDataConfig.showComponents.issuers
              ? "opacity-100 pointer-events-auto"
              : "opacity-30 pointer-events-none"
          }
        `}
      >
        <div className="flex flex-col items-start justify-between gap-8">
          {issuersData.issuers1 &&
            (issuersData?.issuers1?.portfolioName ===
            portfolios?.portfolioA?.portfolioName
              ? renderIssuersTable(issuersData?.issuers1, "portfolioA")
              : renderIssuersTable(issuersData.issuers1, "portfolioB"))}
          {issuersData?.issuers2 &&
            (issuersData.issuers2.portfolioName ===
            portfolios.portfolioA.portfolioName
              ? renderIssuersTable(issuersData.issuers2, "portfolioA")
              : renderIssuersTable(issuersData.issuers2, "portfolioB"))}
        </div>
      </div>
    </div>
  )
}

AnalysisIssuers.propTypes = {
  issuersData: PropTypes.shape({
    issuers1: PropTypes.shape({
      issuers: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      ).isRequired,
      closingDate: PropTypes.arrayOf(PropTypes.string).isRequired,
      portfolioName: PropTypes.string.isRequired,
    }).isRequired,
    issuers2: PropTypes.shape({
      issuers: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
      closingDate: PropTypes.arrayOf(PropTypes.string),
      portfolioName: PropTypes.string,
    }),
  }).isRequired,
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
}

export default AnalysisIssuers
