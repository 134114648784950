import { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

// STORE
import { useDispatch } from "react-redux"
import { showNotifications } from "../../../store/notificationsSlice"
// HOOKS
import usePreviewPanel from "./usePreviewPanel"

import { childrenPropTypes } from "../../../propTypes/AppPropTypes"
import useTracking from "../../../hooks/useTracking"

function PreviewFundContainer({ children, portfolioKey, dragOriginArea }) {
  // TRACK
  const trackEvent = useTracking()
  // STORE
  const dispatch = useDispatch()
  // REF
  const dragEnterTarget = useRef(null)
  // HOOKS
  const { addFundToBucket } = usePreviewPanel()
  // STATE
  const [isDragOver, setIsDragOver] = useState(false)

  // CALLBACKS
  const handleDrop = (event) => {
    event.preventDefault()
    const fundId = +event.dataTransfer.getData("fund/id")
    const fundName = event.dataTransfer.getData("fund/name")
    const fundShareClass = event.dataTransfer.getData("fund/shareClass")
    const originPortfolio = event.dataTransfer.getData("portfolio/origin")
    trackEvent({
      action: "ADD_FUND_PORTFOLIO",
      timestamp: Date.now(),
      type: "DROP",
      data: `${portfolioKey} => ${fundId} | ${fundName} | ${fundShareClass} | originPortfolio => ${originPortfolio}`,
    })
    if (portfolioKey === originPortfolio) {
      setIsDragOver(false)
      dragEnterTarget.current = null
      return
    }

    // ADD FUND TO BUCKET
    addFundToBucket(
      {
        run: fundId,
        fund_name: fundName,
        serie: fundShareClass,
      },
      () => {
        trackEvent({
          action: "ADD_FUND_PORTFOLIO_MAX_FUNDS",
          timestamp: Date.now(),
          type: "DROP",
          data: `MAX FUNDS ALERT => ${portfolioKey} => ${fundId} | ${fundName} | ${fundShareClass} | originPortfolio => ${originPortfolio}`,
        })
        dispatch(
          showNotifications({
            show: true,
            status: "warning",
            title: "Información",
            message: "Puedes agregar un máximo de 12 fondos a un portafolio.",
            autoHide: true,
            time: 3500,
          }),
        )
      },
      () => {
        trackEvent({
          action: "ADD_FUND_PORTFOLIO_SAME_PORTFOLIO",
          timestamp: Date.now(),
          type: "DROP",
          data: `SAME PORTFOLIO ALERT=> ${portfolioKey} => ${fundId} | ${fundName} | ${fundShareClass} | originPortfolio => ${originPortfolio}`,
        })
        dispatch(
          showNotifications({
            show: true,
            status: "warning",
            title: "Información",
            message: "Ya agregaste este fondo.",
            autoHide: true,
            time: 3500,
          }),
        )
      },
      () => {},
      portfolioKey,
    )

    // RESET FLAGS
    setIsDragOver(false)
    dragEnterTarget.current = null
  }
  const handleDragOver = (event) => {
    event.preventDefault()
  }
  const handleDragEnter = () => {
    if (portfolioKey !== dragOriginArea) setIsDragOver(true)
  }
  const handleDragLeave = (event) => {
    const { target } = event
    if (portfolioKey !== dragOriginArea && "portfolio" in target.dataset)
      setIsDragOver(false)
  }

  useEffect(() => {
    if (dragOriginArea === "") {
      setIsDragOver(false)
    }
  }, [dragOriginArea])

  return (
    <div
      key={portfolioKey}
      id={portfolioKey}
      data-portfolio="portfolio"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      className={`p-3 flex flex-col items-stretch justify-start gap-2 rounded-md border border-dashed border-transparent transition-all min-h-[60px] ${
        isDragOver
          ? "!border-slate-500 dark:!border-slate-400 bg-slate-300 dark:bg-slate-700 bg-opacity-60 active:cursor-grabbing"
          : ""
      }`}
    >
      {children}
    </div>
  )
}

PreviewFundContainer.propTypes = {
  children: childrenPropTypes.isRequired,
  portfolioKey: PropTypes.string.isRequired,
  dragOriginArea: PropTypes.string.isRequired,
}

export default PreviewFundContainer
