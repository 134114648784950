export const Form = ({ children, onSubmit }) => (
  <form
    onSubmit={onSubmit}
    className="px-11 pt-9 pb-11 bg-white bg-opacity-80 md:max-w-xl mx-auto rounded-4xl shadow-12xl space-y-4"
  >
    {children}
  </form>
);

export const Label = ({ id, children }) => (
  <label htmlFor={id} className="block">
    {children}
  </label>
);

export const Input = ({ name, form, placeholder = "" }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      {...form.register(name)}
      className="px-4 py-3 w-full text-gray-500 font-medium placeholder-gray-500 bg-white bg-opacity-50 outline-none border border-blueGray-200 rounded-lg focus:ring focus:ring-blue-300"
    />
  );
};

export const Textarea = ({ name, form, placeholder = "" }) => {
  return (
    <textarea
      placeholder={placeholder}
      {...form.register(name)}
      className="p-4 w-full h-48 font-medium text-gray-500 placeholder-gray-500 bg-white bg-opacity-50 outline-none border border-blueGray-200 resize-none rounded-lg focus:ring focus:ring-blue-300"
    />
  );
};
export const ErrorMessage = ({ message }) => (
  <p className="text-sm text-rose-700">{message}</p>
);
