import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { childrenPropTypes } from "../../../propTypes/AppPropTypes"
import cn from "../../ui/utilities/ClassMerge"

import useColorCompany from "../../../hooks/useColorCompany"

function PreviewSectionBox({ title, subtitle, children, showLegend, className }) {
  // STORE
  const portfolios = useSelector((state) => state.portfolioSelection.data)
  const portfoliosName = useSelector((state) => state.portfoliosName.data)
  // HOOKS
  const { getColorPortfolio } = useColorCompany()

  // FUNCTIONS
  const getLegend = () => {
    const isDoublePortfolio = portfolios.portfolioB.portfolioFunds.length > 0 // ESTÁ ACTIVO EL PORTFOLIO B

    return (
      <div className="w-full flex items-center gap-5 justify-end text-xs text-slate-600 dark:text-slate-500">
        {portfolios?.portfolioA?.portfolioFunds?.length > 0 && (
          <div className="flex items-center gap-1">
            <span
              className="w-3 h-3 rounded-sm block"
              style={{ backgroundColor: getColorPortfolio("portfolioA") }}
            />
            <h3 className="text-xxs">
              {portfoliosName.portfolioA.portfolioName}
            </h3>
          </div>
        )}

        {isDoublePortfolio && (
          <div className="flex items-center gap-1">
            <span
              className="w-3 h-3 rounded-sm block"
              style={{ backgroundColor: getColorPortfolio("portfolioB") }}
            />
            <h3 className="text-xxs">
              {portfoliosName.portfolioB.portfolioName}
            </h3>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="mb-8 bg-white dark:bg-slate-700 dark:bg-opacity-30 rounded-md p-8">
      {title && (
        <div className={cn("w-full grid grid-cols-2 items-center gap-4 mb-8", className)}>
          <div className="space-y-1">
            <h2 className="text-base font-bold">{title}</h2>
            {subtitle !== "" && <p className="text-xs text-slate-500 dark:text-slate-400">{subtitle}</p>}
          </div>
          {showLegend && getLegend()}
        </div>
      )}
      {children}
    </div>
  )
}

PreviewSectionBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: childrenPropTypes.isRequired,
  showLegend: PropTypes.bool,
  className: PropTypes.string
}

PreviewSectionBox.defaultProps = {
  showLegend: false,
  className: "",
  subtitle: ""
}

export default PreviewSectionBox
