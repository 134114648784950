import PropTypes from "prop-types"

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline"

function AccountSearchBar({ term, handleChange, placeholder }) {
  return (
    <div className="relative w-full">
      <input
        type="text"
        value={term}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        className="w-full py-3 pl-2 pr-6 bg-white dark:bg-slate-900 text-xxs uppercase rounded-lg border border-slate-200 dark:border-slate-700 !outline-none  placeholder:text-slate-600 dark:placeholder:text-slate-400 focus:ring-transparent"
      />
      {term !== "" ? (
        <button
          type="button"
          onClick={() => handleChange("")}
          className="absolute top-1/2 -translate-y-1/2 right-2 text-slate-600 dark:text-slate-400 opacity-70"
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      ) : (
        <span className="absolute top-1/2 -translate-y-1/2 right-2 text-slate-600 dark:text-slate-400 opacity-70">
          <MagnifyingGlassIcon className="w-5 h-5" />
        </span>
      )}
    </div>
  )
}

AccountSearchBar.propTypes = {
  term: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default AccountSearchBar
