import { Switch } from "@headlessui/react"
// HOOKS
import useColorScheme from "../../../hooks/useColorScheme"

function ColorSchemeToggle() {
  // HOOKS
  const [colorScheme, toggleColorScheme] = useColorScheme()
  // Light is false, dark is true

  const handleChange = (state) => {
    const newScheme = state ? "dark" : "light"

    toggleColorScheme(newScheme)
  }

  return (
    <div className="flex items-center justify-center gap-4 mb-2">
      <span className="text-xs text-slate-500 dark:text-slate-400">Light</span>
      <Switch
        checked={colorScheme === "dark"}
        onChange={handleChange}
        className={`${
          colorScheme === "dark" ? "bg-slate-600" : "bg-slate-300"
        } relative inline-flex h-4 w-10 items-center rounded-full`}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={`
          inline-block h-3 w-3 transform rounded-full transition
          ${colorScheme === "dark" ? "translate-x-6" : "translate-x-1"}
          ${colorScheme === "dark" ? "bg-slate-400" : "bg-slate-50"}
          `}
        />
      </Switch>
      <span className="text-xs text-slate-500 dark:text-slate-400">Dark</span>
    </div>
  )
}

export default ColorSchemeToggle
