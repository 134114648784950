/* eslint-disable no-prototype-builtins */
import { useSelector } from "react-redux";

const usePrepareDataForCharts = () => {
  // STORE
  const minimumInvestment = useSelector(
    (state) => state.minimumInvestment.data,
  );

  // HELPERS
  // SOURCE: https://docs.google.com/spreadsheets/d/1wnU-w65SAzn7bePr--6oVW1d5meJQrZZitemoEevWC0/edit#gid=0
  const INTERNAL_SERIES_EXCLUDE_LIST = {
    BANCHILE: ["BCH"],
    LARRAINVIAL: ["LV"],
    ITAU: ["IT"],
    "BICE INVERSIONES": ["K"],
    "BANCO INTERNACIONAL": ["E"],
    BCI: ["BCI"],
    SURA: ["SURA"],
    SECURITY: ["IP"],
    SANTANDER: ["AM"],
    "BTG PACTUAL CHILE": ["D"],
    "ZURICH CHILE": ["Z"],
    "CREDICORP CAPITAL": ["IM"],
    "AMERIS CAPITAL": ["X"],
    "COMPASS GROUP CHILE": ["CG"],
    "FRONTAL TRUST": ["FT"],
    MBI: ["B"],
    PRUDENTIAL: ["H"],
    "SCOTIA   CHILE": ["SBK"],
  };

  const getCompositionsOrder = () => [
    "ASSET_CLASS",
    "COUNTRY",
    "CURRENCY",
    "ZONE",
    "SECTOR_ICB",
    "RISK",
  ];
  const compositionHasOver100Perc = (data) => {
    for (const comp in data) {
      if (data.hasOwnProperty(comp)) {
        const rows = data[comp];
        // console.log("LA FILA EN LA QUE ESTOY: ", comp)
        let valueSum = 0;
        let valueBSum = 0;
        for (const row of rows) {
          valueSum += row.value;
          if (row.valueB !== undefined) {
            valueBSum += row.valueB;
          }
          // console.log("VALUE SUM: ", valueSum)
          // console.log("VALUEB SUM: ", valueBSum)
          if (Math.floor(valueSum) !== 100 || Math.floor(valueBSum) !== 100) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const getTACTableData = (TACData, portfolioFunds) => {
    const data = [];
    portfolioFunds?.forEach((fund) => {
      // CONSTRUIR FONDO CON DATA BASE
      const obj = {};
      obj.fundId = fund?.fundId;
      obj.fund_name = fund?.fund_name;
      obj.shareclassName = fund?.shareClassName;
      // BUSCAR EN EL TACDATA LA INFO DEL TAC PARA EL FONDO
      TACData.forEach((tacFund) => {
        const fundNameParts = tacFund?.fund_name?.split(/-(.*)/s);
        const fundId = `${fundNameParts[0]}`;
        const fundShareclass = fundNameParts[1];
        if (
          fundId === `${fund?.fundId}` &&
          fundShareclass === fund?.shareClassName
        ) {
          obj.tac = tacFund?.tac;
          obj.tacDate = tacFund?.tacDate;
        }
      });
      data.push(obj);
    });

    return data;
  };

  const filterUnqualifiedSeries = (fundList) =>
    fundList.filter((serie) => {
      let totalFee = 0;

      // REMOVE INTERNAL SERIES
      if (
        serie.fund_provider in INTERNAL_SERIES_EXCLUDE_LIST &&
        INTERNAL_SERIES_EXCLUDE_LIST[
          serie?.fund_provider.toUpperCase()
        ].includes(serie.serie.toUpperCase())
      ) {
        return false;
      }

      if (
        serie.max_placement_fee === null &&
        serie.annual_max_managenent_fee === null
      ) {
        totalFee = 1;
      }

      if (serie.max_placement_fee !== null && +serie.max_placement_fee !== 0) {
        totalFee += +serie.max_placement_fee;
      }
      if (
        serie.annual_max_managenent_fee !== null &&
        +serie.annual_max_managenent_fee !== 0
      ) {
        totalFee += +serie.annual_max_managenent_fee;
      }
      if (totalFee === 0 || (serie.tac !== null && +serie.tac === 0)) {
        return false;
      }
      if (+minimumInvestment === 0) {
        return true;
      }
      return +minimumInvestment >= +serie.minimum_investment_clp;
    });

  return {
    getCompositionsOrder,
    getTACTableData,
    filterUnqualifiedSeries,
    compositionHasOver100Perc,
  };
};

export default usePrepareDataForCharts;
