import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {},
}

export const ReducedData = createSlice({
  name: "reducer",
  initialState,
  reducers: {
    updateReducedData: (state, action) => {
      state.data = action.payload
    },
    resetReducer: (state) => {
      state.data = {}
    },
  },
})

export const { updateReducedData, resetReducer } = ReducedData.actions

export default ReducedData.reducer
