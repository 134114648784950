import {useContext} from 'react';
import { useDispatch, useSelector } from "react-redux"
import { showNotifications } from "../store/notificationsSlice"
import { updateUser } from "../store/userSlice"
import { updateAnalysisDataConfig } from "../store/analysisDataConfigSlice"
import FirebaseContext from "../firebase/FirebaseContext"
import { PostFile } from './useFetch';

function useAddLogo() {
  // context
  const firebase = useContext(FirebaseContext)
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const urls = useSelector((state) => state.urls)
  const analysisDataConfig = useSelector(
    (state) => state.analysisDataConfig.data,
  )


  const handlerUploadImage = async ({ file, schema }) => {
    const uid = user?.uid
    const LOGO_SCHEMA = schema === "light" ? "light" : "dark"
    if (file) {
      const formData = new FormData();
      formData.append('file', file); // 'file' is the key used to access the file in Flask
      formData.append('uid', uid);
      formData.append('mode', schema);
      PostFile({
        url: `${urls.API_URL}/advisors/${uid}/update_logo`,
        formData,
        context: firebase,
        onComplete: (data) => {
          if( data.status === 'OK' ){
            dispatch(
              showNotifications({
                show: true,
                status: "success",
                title: "Logo actualizado con éxito.",
                message: "",
                autoHide: true,
                time: 3500,
              }),
            )
            dispatch(
              updateUser({
                ...user,
                logo: {
                  ...user.logo,
                  [LOGO_SCHEMA]: data.public_link,
                },
              }),
            )
            dispatch(
              updateAnalysisDataConfig({
                ...analysisDataConfig,
                logo: {
                  ...analysisDataConfig.logo,
                  [LOGO_SCHEMA]: data.public_link,
                },
              }),
            )
          } else {
            dispatch(
              showNotifications({
                show: true,
                status: "warning",
                title: "Error al actualizar los datos del usuario.",
                message: "Intente mas tarde",
                autoHide: true,
                time: 3500,
              }),
            )
          }
        }
      })
    }
  }
  return { handlerUploadImage }
}

export default useAddLogo
