import PropTypes from "prop-types"
import { Checkbox as FlowbiteCheckbox } from "flowbite-react"
import Label from "./Label"

// const toggleTheme = {
//   root: {
//     base: "text-xs uppercase font-semibold",
//     colors: {
//       default: "text-slate-900 dark:text-slate-50",
//     },
//   },
// }

function Checkbox({ id, label, onChangeCallback, defaultChecked }) {
  return (
    <div className="flex items-center gap-2">
      <FlowbiteCheckbox
        id={id}
        onChange={(e) => onChangeCallback(e.target.checked)}
        checked={defaultChecked}
      />
      <Label id={id} className="flex">
        {label}
      </Label>
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
}

export default Checkbox
