import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// STORE
import { useSelector } from "react-redux";

// PORTFOLIO MANAGET & CALCULATIONS
import PortfolioManagerFactory from "../PreviewPanel/PortfolioManagerFactory";
import PortfolioGroup from "../../../utils/PortfolioGroup";
// CALCULATIONS
//TODO: sacar librerias que no se usan

// COMPONENTS
import AnalysisBox from "./AnalysisBox";
import AnalysisProposalInfo from "./AnalysisProposalInfo";
import AnalysisReturns from "./AnalysisReturns";
import AnalysisExposure from "./AnalysisExposure";
import AnalysisIssuers from "./AnalysisIssuers";
import AnalysisRisks from "./AnalysisRisks";
import AnalysisAdvisorInfo from "./AnalysisAdvisorInfo";
import AnalysisDisclamer from "./AnalysisDisclamer";
import ModalVersion from "../ModalVersion/ModalVersion";

// HELPERS
import { returnsPeriodMap } from "../utilities/functions";
import usePortfolioDates from "../../../hooks/usePortfolioDates";
import { FirebaseContext } from "../../../firebase";

function AnalysisSections({ onOfComponent, handleTakeScreenshot }) {
  const firebase = useContext(FirebaseContext);
  // PORTFOLIO MANAGER
  const portfolioManager =
    PortfolioManagerFactory.getPortfolioManagerInstance();
  // STORE
  const portfolios = useSelector((state) => state.portfolioSelection.data);
  const chartsData = useSelector((state) => state.chartsData);
  const distributionOption = useSelector(
    (state) => state.portfolioSelection.distributionOption,
  );
  const baseAmount = useSelector(
    (state) => state.portfolioSelection.baseAmount,
  );
  const isAPV = useSelector((state) => state.proposalType.isAPV);
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data);
  // STATE
  const [portfoliosInstances, setPortfoliosInstances] = useState(null);
  const [portfolioGroup, setPortfolioGroup] = useState(null);

  // CONFIG
  const isDownSample = true;

  // FUNCTIONS

  useEffect(() => {
    portfolioManager
      .enqueueChange(portfolios, isAPV, firebase)
      .then((result) => {
        if (result.status === "OK") {
          setPortfoliosInstances(result.data);
          setPortfolioGroup(new PortfolioGroup(isAPV, result.data));
        }
      })
      .catch((error) => console.error(error));
  }, [portfolioManager, portfolios]);

  usePortfolioDates(
    portfoliosInstances,
    portfolioGroup,
    portfolios,
    distributionOption,
    baseAmount,
    catalogByFunds,
    isAPV,
    returnsPeriodMap,
  );

  return (
    chartsData && (
      <>
        <ModalVersion />

        <div className="space-y-6">
          <AnalysisBox className="z-9">
            <AnalysisProposalInfo
              tacData={chartsData?.data?.tacData}
              summaryData={
                chartsData?.data?.exposureSummaryData
                  ? chartsData?.data?.exposureSummaryData
                  : null
              }
              screenerData={chartsData?.data?.screenerTable}
              handleTakeScreenshot={handleTakeScreenshot}
              onOfComponent={onOfComponent}
            />
          </AnalysisBox>

          <AnalysisBox>
            {chartsData?.data?.performanceChartData &&
              chartsData?.data?.periodList && (
                <AnalysisReturns
                  performanceData={
                    chartsData?.data?.performanceChartData?.[
                      chartsData?.data?.timePeriod
                    ]
                  }
                  returnsDates={chartsData?.data?.returnsDates}
                  performanceTableData={
                    chartsData?.data?.portfoliosTableReturns
                  }
                  timePeriod={chartsData?.data?.timePeriod}
                  periodList={chartsData?.data?.periodList}
                  isDownSample={isDownSample}
                  emptyDates={chartsData?.data?.nullDates}
                  onOfComponent={onOfComponent}
                  handleTakeScreenshot={handleTakeScreenshot}
                />
              )}
          </AnalysisBox>

          <AnalysisBox>
            <AnalysisExposure
              exposureData={chartsData.data?.exposureData[0]}
              onOfComponent={onOfComponent}
              handleTakeScreenshot={handleTakeScreenshot}
            />
          </AnalysisBox>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 3xl:gap-14">
            <AnalysisBox>
              <AnalysisIssuers
                issuersData={chartsData.data?.issuersData}
                onOfComponent={onOfComponent}
                handleTakeScreenshot={handleTakeScreenshot}
                portfolios={chartsData.data?.portfolios}
              />
            </AnalysisBox>
            <AnalysisBox>
              <AnalysisRisks
                minMaxData={chartsData?.data?.maxMinProfitLossesChartData}
                onOfComponent={onOfComponent}
                handleTakeScreenshot={handleTakeScreenshot}
                distributionOption={distributionOption}
                baseAmount={baseAmount}
              />
            </AnalysisBox>
          </div>
          <AnalysisBox>
            <AnalysisAdvisorInfo />
          </AnalysisBox>

          <AnalysisDisclamer />
        </div>
      </>
    )
  );
}

AnalysisSections.propTypes = {
  onOfComponent: PropTypes.func.isRequired,
  handleTakeScreenshot: PropTypes.func.isRequired,
};

export default AnalysisSections;
