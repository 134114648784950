export const INITIAL_PERIODLIST = [
    { key: "5 Años", value: 60 },
    { key: "3 Años", value: 36 },
    { key: "1 Año", value: 12 },
    { key: "YTD", value: -2 },
    { key: "6 meses", value: 6 },
    { key: "3 meses", value: 3 },
    { key: "1 mes", value: 1 },
  ]
export const INITIAL_PERIODLIST_APV = [
    { key: "5 Años", value: 60 },
    { key: "3 Años", value: 36 },
    { key: "1 Año", value: 12 },
    { key: "2 Años", value: 24},
    { key: "6 meses", value: 6 },
  ]