import {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { Combobox, Transition } from "@headlessui/react"
import {
  MinusCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { FunnelIcon } from "@heroicons/react/24/solid"

// CONTEXT
import { useSelector, useDispatch } from "react-redux"
import { FirebaseContext } from "../../../firebase"

// STORE
import { updateFilters, resetFilters } from "../../../store/filtersSlice"
import { resetReducer } from "../../../store/reducedDataSlice"

// HOOKS
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import useViewportSize from "../../../hooks/useViewportSize"
import useTracking from "../../../hooks/useTracking"

function TableFilterButtonCategory({ title, filterId }) {
  // TRACKING
  const trackEvent = useTracking()
  // MEMO
  const fundTypeInfo = useMemo(
    () => ({
      IN: [
        "IN",
        "border-amber-500 dark:border-amber-400 text-amber-500 dark:text-amber-400",
      ],
      FM: [
        "FM",
        "border-violet-500 dark:border-violet-400 text-violet-500 dark:text-violet-400",
      ],
      FI: [
        "FI",
        "border-cyan-500 dark:border-cyan-400 text-cyan-500 dark:text-cyan-400",
      ],
      GB: [
        "GB",
        "border-gray-500 dark:border-gray-400 text-gray-500 dark:text-gray-400",
      ],
    }),
    [],
  )
  // REF
  const buttonRef = useRef(null)
  const panelRef = useRef(null)
  // HOOKS
  const viewportSize = useViewportSize()
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  const { logAnalyticsEvent } = firebase
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const filters = useSelector((state) => state.filters.data)
  const categoriesMapping = useSelector((state) => state.categoriesMapping.data)
  const reducedData = useSelector((state) => state.reducer.data)
  // STATE
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState("")
  const [panelPos, setPanelPos] = useState("left-0")
  const [query, setQuery] = useState("")
  const [filteredOptions, setFilteredOptions] = useState(null)

  // TRACKING
  const handleSendDataTracking = ({ type, action, data }) => {
    trackEvent({
      action,
      timestamp: Date.now(),
      type,
      data,
    })
  }

  // CALLBACKS
  const getSelectedType = (item) => {
    let selectedType
    if (item === "Sin Categoría") {
      selectedType = "GB"
    } else if (item in categoriesMapping) {
      selectedType = categoriesMapping[item]
    }
    return selectedType
  }
  const closePanel = useCallback(() => {
    handleSendDataTracking({
      action: "FILTER_CATEGORY",
      type: "SELECT",
      data: "CLOSE",
    })
    setOpen(false)
  }, [])

  const removeSelection = (selection) => {
    const newArray = filters[filterId].filter((f) => f !== selection)
    const newFilters = { ...filters, ...{ [filterId]: newArray } }
    dispatch(updateFilters(newFilters))

    let isFiltered = false
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key].length > 0) isFiltered = true
    })
    if (!isFiltered) {
      dispatch(resetFilters())
      dispatch(resetReducer())
    }
  }

  const handleSelection = (selection) => {
    if (!filters[filterId].includes(selection)) {
      const newSelection = [...filters[filterId], ...[selection]]
      dispatch(updateFilters({ ...filters, ...{ [filterId]: newSelection } }))
      setSelected("")
      setQuery("")
      logAnalyticsEvent("select_item", {
        item_list_id: filterId,
        item_list_name: "Filtro de catálogo",
        items: [
          {
            item_id: filterId,
            item_name: selection,
          },
        ],
        username: `${user.name} ${user.lastname}`,
        company: user.company,
      })
      trackEvent({
        action: "FILTER_CATEGORY",
        timestamp: Date.now(),
        type: "OPTION",
        data: selection,
      })
    } else {
      removeSelection(selection)
    }
  }

  useEffect(() => {
    const newFilteredOptions =
      query === ""
        ? reducedData[filterId]
        : reducedData[filterId].filter((d) =>
            d
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
          )

    const groupedFilteredOptions = {
      IN: [],
      FM: [],
      FI: [],
      GB: [],
    }

    if (newFilteredOptions) {
      newFilteredOptions.forEach((cat) => {
        if (cat in categoriesMapping && cat !== "Sin Categoría") {
          const fundType = categoriesMapping[cat]
          if (fundType === "IN") groupedFilteredOptions.IN.push(cat)
          if (fundType === "FI") groupedFilteredOptions.FI.push(cat)
          if (fundType === "FM") groupedFilteredOptions.FM.push(cat)
        }
        if (cat === "Sin Categoría") {
          groupedFilteredOptions.GB.push("Sin Categoría")
        }
      })
    }
    setFilteredOptions(groupedFilteredOptions)
  }, [reducedData, query, filterId])

  useOnClickOutside(panelRef, () => {
    if (open) {
      closePanel()
    }
  })

  useEffect(() => {
    if (buttonRef.current) {
      let pos = "left-0"
      const clientRect = buttonRef.current.getBoundingClientRect()
      if (clientRect.x > viewportSize.width / 2) {
        pos = "right-0"
      }
      setPanelPos(pos)
    }
  }, [buttonRef, viewportSize.width])

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.keyCode === 27) {
        if (open) {
          closePanel()
        }
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [open, closePanel])

  // UTILITIES
  const cleanFIName = (option) =>
    option
      .replace("Fondos de Inversión ", "")
      .replace("F. de Inv. ", "")
      .replace("de ", "")

  const getSelectedStructure = (item) => {
    const selectedType = getSelectedType(item)
    return (
      selectedType && (
        <>
          <span
            className={`flex items-center justify-center w-5 h-5 shrink-0 rounded-full text-[8px] border ${fundTypeInfo[selectedType][1]}`}
          >
            {fundTypeInfo[selectedType][0]}
          </span>
          <span className="flex-auto text-sm text-left whitespace-nowrap text-ellipsis overflow-hidden">
            {cleanFIName(item)}
          </span>
        </>
      )
    )
  }

  return (
    filteredOptions && (
      <div ref={panelRef} className="relative">
        <div>
          <button
            ref={buttonRef}
            type="button"
            className="px-2 py-1.5 flex items-center justify-between gap-1
            border border-slate-300 dark:border-slate-700 rounded-md bg-slate-50 dark:bg-slate-800
            text-xxs text-blue-500 dark:text-blue-400 uppercase
            hover:border-blue-500 dark:hover:border-blue-400
            3xl:px-3 3xl:text-[11px]
            "
            onClick={() => {
              setOpen(!open)
              handleSendDataTracking({
                action: "FILTER_CATEGORY",
                type: "SELECT",
                data: "OPEN",
              })
            }}
          >
            <FunnelIcon className="w-3 h-3 shrink-0" />
            <span className="mr-auto text-left">{title}</span>
            {filters[filterId].length > 0 && (
              <span
                className="w-4 h-4 shrink-0 bg-blue-400 text-slate-50 dark:text-slate-900 rounded-full grid place-content-center text-xxs
              3xl:text-[11px]"
              >
                {filters[filterId].length}
              </span>
            )}
          </button>
        </div>
        <Transition show={open} as={Fragment}>
          <aside
            className={`bg-white dark:bg-slate-700 border border-slate-300 dark:border-transparent w-80 absolute ${panelPos} top-9 rounded-md z-20`}
          >
            <header className="flex items-center justify-between mb-4 px-4 pt-3">
              <h3 className="text-sm text-left font-semibold">{title}</h3>
              {/* <button
                onClick={() => closePanel()}
                type="button"
                className="text-sm font-semibold text-blue-400"
              >
                <XMarkIcon className="w-4 h-4" />
              </button> */}
            </header>
            <Combobox value={selected} onChange={handleSelection}>
              <div className="relative px-4 mb-1">
                <div className="w-full h-10 flex items-stretch cursor-default rounded-md overflow-hidden bg-slate-200 dark:bg-slate-500 text-slate-500 dark:text-slate-400 focus-visible:ring-0">
                  <Combobox.Button className="flex items-center justify-center px-2">
                    <MagnifyingGlassIcon
                      className="h-4 w-4 text-slate-500 dark:text-slate-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                  <Combobox.Input
                    className="w-full border-none text-sm leading-5 bg-slate-200 dark:bg-slate-500 placeholder:italic placeholder:text-slate-400 dark:placeholder:text-slate-300 !outline-none focus:ring-transparent"
                    displayValue={(data) => data}
                    onChange={(event) => {
                      setQuery(event.target.value)
                      handleSendDataTracking({
                        action: "SEARCH_FILTER_CATEGORY",
                        type: "SUBMIT",
                        data: event.target.value,
                      })
                    }}
                    placeholder={`Buscar ${title}`}
                    autoFocus
                  />
                </div>
              </div>
              {filters[filterId].length > 0 && (
                <div className="px-4 w-full text-sm">
                  {filters[filterId].map((item) => (
                    <div
                      key={item}
                      className="flex items-center justify-start gap-1 cursor-pointer pl-1 py-1 text-blue-500 dark:text-blue-400"
                    >
                      {getSelectedStructure(item)}
                      <button
                        type="button"
                        className="text-blue-500 dark:text-blue-400"
                        onClick={() => removeSelection(item)}
                      >
                        <MinusCircleIcon className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <Combobox.Options
                className="my-4 px-4 max-h-60 w-full overflow-auto text-sm z-30 space-y-0.5"
                static
              >
                {filteredOptions.FM.length === 0 &&
                filteredOptions.FI.length === 0 &&
                filteredOptions.IN.length === 0 &&
                query !== "" ? (
                  <div className="text-sm px-4 ml-6">No hay resultados.</div>
                ) : (
                  <>
                    {filteredOptions.IN.length > 0 &&
                      !filters.category.includes("Depósito a Plazo") && (
                        <p className="text-xs text-slate-500 dark:text-slate-400 pl-1 ml-6">
                          Índices
                        </p>
                      )}
                    {filteredOptions.IN.map(
                      (option) =>
                        !filters[filterId].includes(option) && (
                          <Combobox.Option
                            key={option}
                            className={({ active }) =>
                              `flex items-center justify-start gap-1 cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md ${
                                active
                                  ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                                  : "text-slate-900 dark:text-slate-50"
                              }`
                            }
                            value={option}
                          >
                            <>
                              <span
                                className="flex items-center justify-center w-5 h-5 shrink-0 rounded-full text-[8px] bg-transparent
                                border border-amber-500 dark:border-amber-400
                                text-amber-500 dark:text-amber-400
                                group-hover/fm:bg-amber-500 group-hover/fm:text-slate-50
                              "
                              >
                                IN
                              </span>
                              <span
                                className={`text-sm ${
                                  !filters[filterId].includes(option)
                                    ? ""
                                    : "text-blue-400"
                                }`}
                              >
                                {option}
                              </span>
                            </>
                          </Combobox.Option>
                        ),
                    )}
                    {filteredOptions.FM.length > 0 && (
                      <p className="text-xs text-slate-500 dark:text-slate-400 pl-1 ml-6">
                        Fondos Mutuos
                      </p>
                    )}
                    {filteredOptions.FM.map(
                      (option) =>
                        !filters[filterId].includes(option) && (
                          <Combobox.Option
                            key={option}
                            className={({ active }) =>
                              `flex items-center justify-start gap-1 cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md ${
                                active
                                  ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                                  : "text-slate-900 dark:text-slate-50"
                              }`
                            }
                            value={option}
                          >
                            <>
                              <span
                                className="flex items-center justify-center w-5 h-5 shrink-0 rounded-full text-[8px] bg-transparent
                                border border-violet-500 dark:border-violet-400
                                text-violet-500 dark:text-violet-400
                                group-hover/fm:bg-violet-500 group-hover/fm:text-slate-50
                              "
                              >
                                FM
                              </span>
                              <span
                                className={`text-sm ${
                                  !filters[filterId].includes(option)
                                    ? ""
                                    : "text-blue-400"
                                }`}
                              >
                                {option}
                              </span>
                            </>
                          </Combobox.Option>
                        ),
                    )}
                    {filteredOptions.FI.length > 0 && (
                      <p className="text-xs text-slate-500 dark:text-slate-400 pl-1 ml-6">
                        Fondos de Inversión
                      </p>
                    )}
                    {filteredOptions.FI.map(
                      (option) =>
                        !filters[filterId].includes(option) && (
                          <Combobox.Option
                            key={option}
                            className={({ active }) =>
                              `flex items-center justify-start gap-1 cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md ${
                                active
                                  ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                                  : "text-slate-900 dark:text-slate-50"
                              }`
                            }
                            value={option}
                          >
                            <>
                              <span
                                className="flex items-center justify-center w-5 h-5 shrink-0 rounded-full text-[8px] bg-transparent
                                border border-cyan-500 dark:border-cyan-400
                                text-cyan-500 dark:text-cyan-400
                                group-hover/fm:bg-cyan-500 group-hover/fm:text-slate-50
                              "
                              >
                                FI
                              </span>
                              <span
                                className={`text-sm ${
                                  !filters[filterId].includes(option)
                                    ? ""
                                    : "text-blue-400"
                                }`}
                              >
                                {cleanFIName(option)}
                              </span>
                            </>
                          </Combobox.Option>
                        ),
                    )}
                    {filteredOptions.GB.length > 0 &&
                      !filters.category.includes("Sin Categoría") && (
                        <p className="text-xs text-slate-500 dark:text-slate-400 pl-1 ml-6">
                          Globales
                        </p>
                      )}
                    {filteredOptions.GB.map(
                      (option) =>
                        !filters[filterId].includes(option) && (
                          <Combobox.Option
                            key={option}
                            className={({ active }) =>
                              `flex items-center justify-start gap-1 cursor-pointer pl-1 py-1 transition-all duration-300 rounded-md ${
                                active
                                  ? "text-blue-500 bg-slate-200 dark:bg-slate-600"
                                  : "text-slate-900 dark:text-slate-50"
                              }`
                            }
                            value="Sin Categoría"
                          >
                            <>
                              <span
                                className="flex items-center justify-center w-5 h-5 shrink-0 rounded-full text-[8px] bg-transparent
                                      border border-gray-500 dark:border-gray-400
                                      text-gray-500 dark:text-gray-400
                                      group-hover/fm:bg-gray-500 group-hover/fm:text-slate-50
                                    "
                              >
                                GB
                              </span>
                              <span
                                className={`text-sm ${
                                  !filters[filterId].includes("Sin Categoría")
                                    ? ""
                                    : "text-blue-500"
                                }`}
                              >
                                Sin Categoría
                              </span>
                            </>
                          </Combobox.Option>
                        ),
                    )}
                  </>
                )}
              </Combobox.Options>
            </Combobox>
          </aside>
        </Transition>
      </div>
    )
  )
}

TableFilterButtonCategory.propTypes = {
  title: PropTypes.string.isRequired,
  filterId: PropTypes.string.isRequired,
}

export default TableFilterButtonCategory
