import PropTypes from "prop-types"
import { Label as FlowbiteLabel } from "flowbite-react"

const labelTheme = {
  root: {
    base: "text-[10px] uppercase font-semibold",
    colors: {
      default: "text-slate-900 dark:text-slate-50",
    },
  },
}

function Label({ id, children }) {
  return (
    <FlowbiteLabel htmlFor={id} theme={labelTheme}>
      {children}
    </FlowbiteLabel>
  )
}

Label.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Label
