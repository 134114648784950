import PropTypes from "prop-types"
import { Select as FlowbiteSelect } from "flowbite-react"

import Label from "./Label"

const selectTheme = {
  field: {
    select: {
      base: "block w-full border rounded-lg disabled:cursor-not-allowed disabled:opacity-50",
      colors: {
        gray: "border-slate-200 bg-white text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-slate-700 dark:bg-slate-900 dark:text-white dark:placeholder:text-slate-400 dark:focus:border-blue-500 dark:focus:ring-blue-500",
      },
    },
  },
}

function Select({ children, onChangeCallback, label, id, selectedValue }) {
  return (
    <div className="w-full">
      <Label id={id}>{label}</Label>
      <FlowbiteSelect
        id={id}
        onChange={(e) => onChangeCallback(id, e.target.value)}
        sizing="sm"
        theme={selectTheme}
        value={selectedValue}
      >
        {children}
      </FlowbiteSelect>
    </div>
  )
}

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default Select
