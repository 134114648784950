import PropTypes from "prop-types"

// COMPONENTS
import AccountSearchBar from "../AccountSearchBar"
import Select from "../../../libs/Select"
import Checkbox from "../../../libs/Checkbox"

// UTILS
import { displayString } from "../../utilities/functions"

function MyAnalysisSidebar({
  filters,
  updateFilters,
  controlOptions,
  clearFilters,
  showClearFilters,
}) {
  return (
    <aside className="sticky top-28 h-[calc(100vh-56px)] scroll-pt-28">
      <div className="mb-8 flex items-center gap-2">
        <h1 className="text-md sm:text-base md:text-lg uppercase font-bold">
          Mis Propuestas
        </h1>
        {showClearFilters && (
          <button
            onClick={clearFilters}
            type="button"
            className="text-blue-400 text-xs 3xl:text-sm hover:text-blue-500"
          >
            Limpiar filtros
          </button>
        )}
      </div>
      <div className="flex flex-col items-start justify-between gap-5">
        <AccountSearchBar
          term={filters.searchTerm}
          handleChange={(value) => updateFilters("searchTerm", value)}
          placeholder="Buscar por nombre de propuesta"
        />

        <hr className="w-full h-px bg-slate-300 border-0 dark:bg-slate-700" />

        <p className="text-sm">Refina la búsqueda de tus propuestas por:</p>

        <Select
          id="clientName"
          label="Cliente"
          onChangeCallback={(key, value) => updateFilters(key, value)}
          selectedValue={filters.clientName}
        >
          <option value="all" className="text-xs uppercase">
            Todos los clientes
          </option>
          {controlOptions.clientNames.length &&
            controlOptions.clientNames.map((name) => (
              <option key={name} value={name}>
                {displayString(name)}
              </option>
            ))}
        </Select>

        <Select
          id="clientProfile"
          label="Perfil del cliente"
          onChangeCallback={(key, value) => updateFilters(key, value)}
          selectedValue={filters.clientProfile}
        >
          <option value="all" className="text-xs uppercase">
            Todos los perfiles
          </option>
          {controlOptions.clientProfiles &&
            controlOptions.clientProfiles.map((name) => (
              <option key={name} value={name}>
                {displayString(name)}
              </option>
            ))}
        </Select>

        <Select
          id="clientInvestmentType"
          label="Horizonte de inversión"
          onChangeCallback={(key, value) => updateFilters(key, value)}
          selectedValue={filters.clientInvestmentType}
        >
          <option value="all" className="text-xs uppercase">
            Todos los horizontes de inversión
          </option>
          {controlOptions.clientInvestmentTypes &&
            controlOptions.clientInvestmentTypes.map((name) => (
              <option key={name} value={name}>
                {displayString(name)}
              </option>
            ))}
        </Select>

        <Checkbox
          id="apvOnly"
          label="Mostrar sólo propuestas APV"
          onChangeCallback={(value) => updateFilters("apvOnly", value)}
          defaultChecked={filters.apvOnly}
        />
      </div>
    </aside>
  )
}

MyAnalysisSidebar.propTypes = {
  filters: PropTypes.shape({
    rowPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    clientName: PropTypes.string,
    clientProfile: PropTypes.string,
    clientInvestmentType: PropTypes.string,
    amountMin: PropTypes.number,
    amountMax: PropTypes.number,
    portfolioNumber: PropTypes.number,
    apvOnly: PropTypes.bool,
    searchTerm: PropTypes.string,
  }).isRequired,
  updateFilters: PropTypes.func.isRequired,
  controlOptions: PropTypes.shape({
    firstDate: PropTypes.instanceOf(Date),
    lastDate: PropTypes.instanceOf(Date),
    clientNames: PropTypes.arrayOf(PropTypes.string),
    clientProfiles: PropTypes.arrayOf(PropTypes.string),
    clientInvestmentTypes: PropTypes.arrayOf(PropTypes.string),
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
    portfolioNumber: PropTypes.arrayOf([PropTypes.number]),
  }).isRequired,
  clearFilters: PropTypes.func.isRequired,
  showClearFilters: PropTypes.bool.isRequired,
}

export default MyAnalysisSidebar
