// SENTRY
import * as Sentry from "@sentry/browser";

const RISK_MAPPING = {
  hasMarketRisk: "Riesgo de Mercado",
  hasCreditRisk: "Riesgo de Crédito",
  hasDerivativesRisk: "Riesgo de Derivados",
  hasCurrencyRisk: "Riesgo de Moneda",
  hasLiquidityRisk: "Riesgo de Liquidez",
  hasInterestRateRisk: "Riesgo de Tasa de Interés",
}
const formatFundRisks = (fundData) => {
  try {
    return Object.keys(RISK_MAPPING)
      .filter((risk) => fundData[risk])
      .map((risk) => RISK_MAPPING[risk])
  } catch (e) {
    Sentry.captureException(e);
    console.error(e)
    throw e
  }
}

export default formatFundRisks
