import cn from "../../utilities/ClassMerge";
import { RadioGroup } from "@headlessui/react";

const SidebarStatus = ({
  totalClients,
  totalCompanies,
  clearFilters,
  filtersApplied,
  showSidebarBy,
  switchMode,
}) => {
  return (
    <div className="flex items-start justify-between">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={showSidebarBy} onChange={switchMode}>
          <RadioGroup.Label className="sr-only">
            Ver propuestas por
          </RadioGroup.Label>
          <div className="flex items-center justify-start">
            <RadioGroup.Option
              value="advisors"
              className={({ checked }) =>
                `rounded-l-lg ${checked ? "bg-blue-500 dark:bg-blue-400 text-white border-l border-y border-blue-500 dark:border-blue-400" : "bg-slate-100 dark:bg-slate-700 border-r border-y border-slate-300 dark:border-slate-700 hover:bg-slate-200 dark:hover:bg-slate-800"} cursor-pointer`
              }
            >
              {() => (
                <RadioGroup.Label as="p" className="text-xxs px-1 py-1">
                  Asesores
                </RadioGroup.Label>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option
              value="companies"
              className={({ checked }) =>
                `rounded-r-lg ${checked ? "bg-blue-500 dark:bg-blue-400 text-white border-l border-y border-blue-500 dark:border-blue-400" : "bg-slate-100 dark:bg-slate-700 border-r border-y border-slate-300 dark:border-slate-700 hover:bg-slate-200 dark:hover:bg-slate-800"} cursor-pointer`
              }
            >
              {() => (
                <RadioGroup.Label as="p" className="text-xxs px-1 py-1">
                  Empresas
                </RadioGroup.Label>
              )}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </div>

      <button
        type="button"
        className={cn(
          "px-2 py-1 bg-slate-50 rounded-lg text-xs pointer-events-none shrink-0",
          { "bg-blue-500 text-slate-50 pointer-events-auto": filtersApplied },
        )}
        onClick={() => clearFilters()}
      >
        {filtersApplied
          ? "Ver todos"
          : `${showSidebarBy === "advisors" ? totalClients : totalCompanies} ${showSidebarBy === "advisors" ? "usuarios" : "empresas"}`}
      </button>
    </div>
  );
};

export default SidebarStatus;
