import { useState, useCallback, useEffect, useContext } from "react"
import { sortBy } from "lodash"
// STORE
import { useDispatch, useSelector } from "react-redux"
// CONTEXT
import { FirebaseContext } from "../../../firebase"
import {
  updateReducedData,
  resetReducer,
} from "../../../store/reducedDataSlice"
import { resetSearch } from "../../../store/searchSlice"
import {
  updateCurrentPage,
  updateTotalPages,
} from "../../../store/paginationSlice"
import { resetFilters, updateFilters } from "../../../store/filtersSlice"
// HOOKS
import usePrepareDataForCharts from "../../../hooks/usePrepareDataForCharts"
import { Fetch } from "../../../hooks/useFetch"
// PROPTYPES
import { appFiltersProptypes } from "../../../propTypes/AppPropTypes"
// COMPONENTS
import DataGrid from "../dataGrid/DataGrid"
import reduceData from "../../helpers/Reducers"
import ScreenerStatus from "../ScreenerStatus/ScreenerStatus"
import ScreenerAdvancedSearch from "./ScreenerAdvancedSearch"
// SETTINGS
import SETTINGS from "../../../settings/settings"

// HOOKS
import useTracking from "../../../hooks/useTracking"

function Screener({ appFilters }) {
  // TRACK
  const trackEvent = useTracking()
  // STATES
  const [isFiltered, setIsFiltered] = useState(false)
  const [showClearFilters, setShowClearFilters] = useState(false)
  const [advancedSearchValue, setAdvancesSearchValue] = useState("")
  const [advancedSearchResponse, setAdvancedSearchResponse] = useState("")
  const [isAdvancedSearchFetching, setIsAdvancedSearchFetching] =
    useState(false)
  // HOOKS
  const { filterUnqualifiedSeries } = usePrepareDataForCharts()
  // CONTEXT
  const firebase = useContext(FirebaseContext)
  const { logAnalyticsEvent } = firebase
  // STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const filters = useSelector((state) => state.filters.data)
  const pagination = useSelector((state) => state.pagination.data)
  const searchTerm = useSelector((state) => state.search.data)
  const minimumInvestment = useSelector((state) => state.minimumInvestment.data)
  const catalogByFunds = useSelector((state) => state.catalogByFunds.data)
  // const isAPV = useSelector((state) => state.proposalType.isAPV)
  // DATA
  const [totalResultsFound, setTotalResultsFound] = useState(null)
  const [tableData, setTableData] = useState(null)
  // CALLBACKS
  const getBetterTACFund = useCallback(
    (fundsArray) => {
      // ITERAR PARA VERIFICAR SI LA LISTA TIENE ALGUN NULL
      const fundList = sortBy(fundsArray, (o) => o.tac)
      if (+minimumInvestment === 0) {
        fundList.reverse()
      }
      return fundList[0]
    },
    [minimumInvestment],
  )

  const handleClearClick = () => {
    setAdvancesSearchValue("")
    setAdvancedSearchResponse("")
    dispatch(resetFilters())
    dispatch(resetSearch())
    dispatch(resetReducer())
  }

  const handleAdvancedSearch = (event) => {
    if (
      (event.key === "Enter" || event.type === "click") &&
      advancedSearchValue !== ""
    ) {
      setIsAdvancedSearchFetching(true)
      Fetch(
        `${SETTINGS.urls.API_URL}/llm/query_funds`,
        {
          query: advancedSearchValue,
        },
        { firebase },
        (response) => {
          trackEvent({
            action: "ADD_FUND_TO_PREVIEW",
            timestamp: Date.now(),
            type: "INPUT",
            data: advancedSearchValue,
          })
          logAnalyticsEvent("search", {
            search_term: advancedSearchValue,
            username: `${user.name} ${user.lastname}`,
            company: user.company,
          })
          dispatch(
            updateFilters({
              ...filters,
              ...{ llm_response: response },
            }),
          )
          setAdvancedSearchResponse(response.description)
          setIsAdvancedSearchFetching(false)
        },
        "post",
      )
    }
  }

  const dispatchPaginationInfo = useCallback(
    (data) => {
      const totalPages = Math.ceil(data.length / pagination.resultsPerPage)
      dispatch(updateCurrentPage(1))
      dispatch(updateTotalPages(totalPages))
    },
    [dispatch, pagination.resultsPerPage],
  )

  const applyFilters = useCallback(() => {
    // FILTER BY MINIMUM INVESTMENT
    const cleanCatalog = []
    Object.keys(catalogByFunds).forEach((catalogItem) => {
      const filteredFundsArray = filterUnqualifiedSeries(
        catalogByFunds[catalogItem],
      )
      if (filteredFundsArray.length > 0) {
        cleanCatalog.push(getBetterTACFund(filteredFundsArray))
      }
    })

    const regex = new RegExp(filters.searchTerm, "gi")
    // APPLY FILTERS
    const filteredFundList = cleanCatalog.filter((fund) => {
      if (
        Object.keys(filters.llm_response).length >= 0 &&
        filters.llm_response.status === "FUNDS_NOT_FOUND"
      ) {
        return false
      }

      if (
        (fund.fund_name.match(regex) || String(fund.run)?.match(regex)) &&
        (filters.asset_class.length === 0 ||
          filters.asset_class.includes(fund.asset_class)) &&
        (filters.category.length === 0 ||
          filters.category.includes(fund.category)) &&
        (filters.fund_currency.length === 0 ||
          filters.fund_currency.includes(fund.fund_currency)) &&
        (filters.aum.length === 0 || fund.aum >= Math.min(...filters.aum)) &&
        (filters.fund_provider.length === 0 ||
          filters.fund_provider.includes(fund.fund_provider)) &&
        (filters.fund_type.length === 0 ||
          filters.fund_type.includes(fund.fund_type)) &&
        (Object.keys(filters.llm_response).length === 0 ||
          filters.llm_response.runs.length === 0 ||
          filters.llm_response.runs.includes(fund.run))
      ) {
        return true
      }
      return false
    })
    const reducedDataNew = reduceData(appFilters, filters, cleanCatalog)
    dispatch(updateReducedData(reducedDataNew))
    setTotalResultsFound(filteredFundList.length)
    dispatchPaginationInfo(filteredFundList)
    setTableData(filteredFundList)
  }, [
    filters,
    dispatch,
    appFilters,
    dispatchPaginationInfo,
    catalogByFunds,
    getBetterTACFund,
  ])

  useEffect(() => {
    applyFilters()
  }, [filters, applyFilters])

  useEffect(() => {
    let hasBeenFiltered = false
    Object.keys(filters).forEach((key) => {
      if (key === "llm_response" && Object.keys(filters[key]).length > 0) {
        hasBeenFiltered = true
      } else if (filters[key].length > 0) {
        hasBeenFiltered = true
      }
    })
    if (hasBeenFiltered) {
      setIsFiltered(true)
      setShowClearFilters(true)
    } else {
      setIsFiltered(false)
      setShowClearFilters(false)
    }
  }, [filters])

  return (
    <div className="pt-16 lg:pt-20">
      <main className="w-full mb-4">
        <header className="py-2 px-2 sm:px-4 lg:px-8 md:grid md:grid-cols-2 md:pb-4 md:items-center">
          <div className="mb-4 flex flex-col items-start justify-start gap-0">
            <ScreenerStatus
              isFiltered={isFiltered}
              searchTerm={searchTerm}
              totalResultsFound={totalResultsFound}
            />
            {showClearFilters && (
              <button
                onClick={handleClearClick}
                type="button"
                className="text-blue-400 text-xs 3xl:text-sm hover:text-blue-500"
              >
                Limpiar filtros
              </button>
            )}
          </div>

          <ScreenerAdvancedSearch
            advancedSearchValue={advancedSearchValue}
            setAdvancesSearchValue={setAdvancesSearchValue}
            advancedSearchResponse={advancedSearchResponse}
            handleAdvancedSearch={handleAdvancedSearch}
            isAdvancedSearchFetching={isAdvancedSearchFetching}
          />
        </header>
        <DataGrid catalog={tableData} />
      </main>
    </div>
  )
}

Screener.propTypes = {
  appFilters: appFiltersProptypes.isRequired,
}

export default Screener
