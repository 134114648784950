import PropTypes from "prop-types"

// UI
import { PanelOverviewShape } from "../../../propTypes/AppPropTypes"
import ParagraphWithTooltip from "../ParagraphWithTooltip/ParagraphWithTooltip"

function FundDetailsGeneralDAP({ data }) {
  return (
    <section>
      <h1 className="overview-section-title">Información General</h1>
      <div className="overview-section-grid">
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">Descripción</h2>
          {data.fund.objective ? (
            <ParagraphWithTooltip text={data.fund.objective} />
          ) : (
            <p className="overview-section-text">Sin información</p>
          )}
        </div>
        <div className="col-span-3">
          <h2 className="overview-section-subtitle">
            Información del Instrumento
          </h2>
          <div className="overview-section-text overview-detail-list">
            {data.fund.asset_class && (
              <div className="overview-detail-row">
                <p>Clase de activo</p>
                <p>{data.fund.asset_class}</p>
              </div>
            )}
            {data.fund.currency && (
              <div className="overview-detail-row">
                <p>Moneda</p>
                <p>{data.fund.currency}</p>
              </div>
            )}
            {data.fund.category && (
              <div className="overview-detail-row">
                <p>Categoría</p>
                <p>{data.fund.category}</p>
              </div>
            )}
            {data.fund.isRenewable && (
              <div className="overview-detail-row">
                <p>Renovable</p>
                <p>{data.fund.isRenewable ? "Si" : "No"}</p>
              </div>
            )}
            {/* {data.fund.aum && (
              <div className="overview-detail-row">
                <p>Patrimonio del fondo</p>
                <p>{formatAmounts(+data.fund.aum)}</p>
              </div>
            )} */}
            {data.fund.risks && (
              <div className="overview-detail-row">
                <p>Riesgos</p>
                <p>{data.fund.risks}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
FundDetailsGeneralDAP.propTypes = {
  data: PropTypes.shape(PanelOverviewShape).isRequired,
}

export default FundDetailsGeneralDAP
